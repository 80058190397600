import { handleActions } from 'redux-actions';
import actions from '../actions/selectActions';
import { initialState } from '../initial-state';
export default handleActions(
    {
        [actions.UpdatePrimary]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateTertiary]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateTheme]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLogo]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateText]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateTextColor]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateBase64]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateUserRole]: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
    initialState.Select
);
