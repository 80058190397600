import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import historyActions from '../../store/actions/user';
import luminaireActions from '../../store/actions/luminaireActions';
import columnActions from '../../store/actions/columnActions';
import userActions from '../../store/actions/user';
import Notification from '../shared/notification.component';
import tourActions from '../../store/actions/tourActions';
import searchActions from '../../store/actions/searchActions';

function findGroup(groups, id) {
    for (let key in groups) {
        if (groups[key].id === id) {
            return groups[key];
        }

        if (groups[key].groups) {
            let foundGroup = findGroup(groups[key].groups, id);
            if (foundGroup) {
                return foundGroup;
            }
        }
    }

    return null;
}

const OptimizeButton = (props) => {

	const [state, setState] = useState({ open: false, message: "", severity: "info" });
	const store = useSelector(state => state);
	const dispatch = useDispatch();
	const [processing, setProcessing] = useState(false);
	const [chunks, setChunks] = useState([]);
	useEffect(() => {
		if (processing && chunks.length) {
			startProcessingChunks(chunks);
		}
	}, [processing, chunks]);
	
	var text = props.min ? 'Minimum' : props.folder ? 'Optimize' : 'Optimize';
	const handleClose = () => {
		setState({ open: false });
	}
	
	const chunkSize = 5;
  
	const onChunkProcessed = (result) => {
		return dispatch(luminaireActions.UpdateGroupResults(result));
	};
	
	const processChunk = async (chunk, callback) => {
		return EdgeOrREST(
			null,
		  'OPTIMIZE_FOLDER',
		  	callback,
		  	API_OBJECTS.getCalculationObject(store, "luminaireIds", chunk)
		);	  
	};
  
	const startProcessingChunks = async (chunks) => {
		// Map each chunk to a promise that resolves when the chunk is processed
		const promises = chunks.map(chunk => {
			return new Promise((resolve, reject) => {
				if (processing) {
					processChunk(chunk, (result) => {
						onChunkProcessed(result);
						resolve();
					});
				} else {
					resolve();
				}
			});
		});
	
		// Wait for all promises to resolve
		await Promise.all(promises);
	
		setState({ open: true, message: "Group optimize complete", severity: "success" });
		// All chunks have been processed, so set processing to false
		setProcessing(false);
		dispatch(searchActions.UpdateCondition({load:false}));
	};
  
	const cancelProcessing = () => {
	  setProcessing(false);
	  dispatch(searchActions.UpdateCondition({load:false}));
	};

	const handleChange = () => {
		if (store.EnabledTour.helpMode && props.folder){	
			dispatch(tourActions.UpdateHelpURL({ helpURL: "Group Optimize"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "This button starts the group optimize process. You can optimize a whole group of luminaires at the same time for the same configuration."}));
		} else if (store.EnabledTour.helpMode){			
			dispatch(tourActions.UpdateHelpURL({ helpURL: "Optimize"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "This button starts the optimize process. Selecting Optimize will calculate results without going over the standard. Selecting Minimum will calculate results without going under the standard."}))	
		} else {
		performance.mark('start');
		var min = props.min;
		if (min === undefined) min = false;
		var edgeInput = {
			luminaire: store.LuminaireCfg.luminaireFilePath,
			min: min,
		};
		var optimizeValues = store.ColumnCfg.optimizeValues
		if (optimizeValues.chosenValues.length === 0) {
			setState({ open: true, message: "No optimize variables set", severity: "warning" });
			return;
		}
		setState({ open: true, message: "Optimize started", severity: "info" });
		var optimizedValues;
		if (props.folder) {
			setProcessing(true);
			dispatch(searchActions.UpdateCondition({load:true}));
			const selectedFolderId = store.LuminaireCfg.selectedFolderIds[0];
		
			// Find the matching group
			const selectedGroup = findGroup(store.user.groups, selectedFolderId);
		
			// If no matching group is found, handle the error (e.g., show a message to the user)
			if (!selectedGroup) {
			  console.error(`No group found with ID ${selectedFolderId}`);
			  return;
			}
		
			// Get the list of luminaire IDs
			const data = selectedGroup.luminaires.map(luminaire => luminaire.luminaireId);
			const chunks = [];
			
			for (let i = 0; i < data.length; i += chunkSize) {
			  chunks.push(data.slice(i, i + chunkSize));
			}
			setChunks(chunks);
			return;
		}
		else {
			dispatch(searchActions.UpdateCondition({load:true}));
			optimizedValues = EdgeOrREST(edgeInput, 'OPTIMIZE_GRID', onCalculateGridResults, {
				min,
				restObject: API_OBJECTS.getCalculationObject(store, "optimizeValues", optimizeValues)
			});
		}

		if (optimizedValues) {
			if (optimizedValues.Spacing > 100) {
				Object.keys(optimizedValues).forEach((o, item, array) => {
					optimizedValues[array[item]] = 'Failed';
				});
				dispatch(luminaireActions.UpdateAllResults({
					optimizedValues: optimizedValues,
					gridResults: [],
				}));
				setState({ open: true, message: 'Optimization not possible with current configuration.', severity: "error" });
			} else {
				dispatch(luminaireActions.UpdateAllResults({
					optimizedValues: optimizedValues,
					gridResults: optimizedValues.gridArray,
				}));
			}
		}}
	};

	const onCalculateFolderResults = (response) => {
		if (response.data === undefined) setState({ open: true, message: response, severity: "error" });
		else populateFolderResults(response.data);
	}

	const populateFolderResults = (returnObject) => {
		setState({ open: true, message: "Folder optimized!", severity: "success" });
		let optimizedFolder = returnObject.optValues.map(item => ({ OptMax: item.Item1, OptMin: item.Item2 }));
		let photometries = returnObject.photometries;
		dispatch(luminaireActions.UpdateLuminCfg({ ...store.LuminaireCfg, optimizedFolder, photometries }));
	}

	const onCalculateGridResults = (response) => {
		if (response.data === undefined) setState({ open: true, message: response, severity: "error" });
		else populateGridResults(response.data);
	}

	const populateGridResults = (returnObject) => {
		if (returnObject) {
			var optimizedValues = returnObject.optimizedValues;
			performance.mark('end');
			performance.measure('optimize', 'start', 'end');
			var perf = performance.getEntriesByName('optimize');
			console.log(perf);

			var optValues = store.ColumnCfg.optimizeValues;
			var numOfCalcs = 1;
			var index = store.GridsCfg.currentIndex;
			optValues.chosenValues.forEach(element => {
				numOfCalcs *= ((optValues[element].max - optValues[element].min) / optValues[element].step);
			});
			dispatch(userActions.UpdateUserCfg({
				numOfOptimizes: store.user.numOfOptimizes + 1,
				optimizeTime: (perf[store.user.numOfOptimizes].duration / 1000).toFixed(
					3,
				),
				numOfCalcs: numOfCalcs
			}));
			dispatch(historyActions.SaveHistory('Optimize Time (ms): ' + (perf[store.user.numOfOptimizes].duration / 1000).toFixed(3)));
			if (!optimizedValues.Valid) {
				Object.keys(optimizedValues).forEach((o, item, array) => {
					optimizedValues[array[item]] = 'Failed';
				});
				dispatch(luminaireActions.UpdateAllResults({
					index,
					optimizedValues: optimizedValues,
					gridResults: [],
					contoursGridResults: []
				}));
				setState({ open: true, message: 'Optimization not possible with current configuration.', severity: "error" });
			} else {
				setState({ open: true, message: "Optimize successful!", severity: "success" })
				const gridResults = returnObject.allGrids;
				var Columns = store.ColumnCfg.Columns;
				optValues.chosenValues.forEach(element => {
					element = element.charAt(0).toUpperCase() + element.slice(1);
					gridResults[index].calcValues[element] = optimizedValues[element];
					Columns.forEach((c, i) => {
						Columns[i]["column" + element] = optimizedValues[element];
					})
				});
				dispatch(luminaireActions.UpdateAllResults({
					index,
					gridResults
				}));
				dispatch(columnActions.UpdateColumnCfg({
					Columns
				}));
			}
		}
		cancelProcessing();
		
	}




	return (
		<>
			<Button
				color={processing ? "red" : "primary"}
				variant="contained"
				className="resultsSummaryButton"
				onClick={processing ? cancelProcessing : handleChange}
				fullWidth={true}
			>
				{processing ? 'Cancel' : text}
			</Button>
			<Notification
				open={state.open}
				onClose={handleClose}
				severity={state.severity}
				message={state.message}
			/>
		</>
	);
}

export default OptimizeButton;