import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { Stage, Layer, Shape, Transformer } from "react-konva";
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import Konva from 'konva';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormGroup,
	Button
} from '@material-ui/core'; 
import Notification from '../../components/shared/notification.component';

function transform(widthScale, heightScale, xTransform, yTransform) {
	return d3.geoTransform({
		point: function (x, y) {
			this.stream.point(-x * widthScale + xTransform, y * heightScale + yTransform);
		}
	});
} 

const Isolux = () => {
	const props = useSelector(state => state);
	const targetRef = useRef(); 
	const [gridResults, setGridResults] = useState([]);
	const [luminId, setLuminId] = useState(0);
	const canvasRef = useRef(null);
	const stageRef = useRef(null);
	const trRef = useRef(null); 
    const [displayRotation, setDisplayRotation] = useState(0);
    const [actualRotation, setActualRotation] = useState(0);

	const [height, setHeight] = useState(100);
	const [width, setWidth] = useState(100);
	const [stage, setStage] = useState({
		scale: 0.75,
		x: 250,
		y: 300,
	});

	const onRotationChange = (event) => {
        const {
          target: { value },
        } = event;
    
        setDisplayRotation( Number(typeof value === 'string' ? value.split(',') : value));    
        console.log("value of dropDown", value); 
		console.log("displayRotation is:", displayRotation);
     };
	
	const redraw = () => {
		setWidth(targetRef.current.clientWidth);
		setHeight(targetRef.current.clientHeight);
		console.log("called redraw");
	}


	const drawBase = (ctx) => {
		ctx.getCanvas()._canvas.id = "isolux-id"
		ctx.strokeStyle = 'black';
		var linearScale = d3.scaleLinear().domain([0, 25]).range([0, height]);
		var lampLen = linearScale(0.4);
		var lampWidth = linearScale(0.2);
		ctx.fillRect(linearScale(9.5), linearScale(12.4), lampLen, lampWidth)
		ctx.beginPath();
		ctx.arc(linearScale(8.5), linearScale(12.5), 4, 0, Math.PI * 2, false);
		ctx.moveTo(linearScale(8.5), linearScale(12.5));
		ctx.lineTo(linearScale(9.5), linearScale(12.5));
		ctx.fill();
		ctx.stroke(); 
	}

	const drawContours = (context) => {
		if (gridResults.length > 0) {
			var data = gridResults;
			var maxSpacing = 25;
			for (let j = 0; j < data.length; j++) {
				data[j] = parseFloat(data[j]);
			}
			var dataMax = Math.max(...data);
			var dataMin = Math.min(...data);
			var lineColor = d3.scaleSequential(d3.interpolateSpectral).domain([dataMax, 0]);
			var xPoints = Math.sqrt(gridResults.length); 
			var yPoints = data.length / xPoints;
			var widthScale = width / xPoints;
			var heightScale = height / yPoints;
			var contours = d3.contours().size([xPoints, yPoints]);
			var cntrs = contours.thresholds(d3.range(0, dataMax, (dataMax / xPoints)))(data);
			var roadWidth = 25;
			var yScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
			var scaledTotalWidth = yScale(roadWidth);
			var translateWidth = yScale(roadWidth);
			var scaledWidth = translateWidth * widthScale / width;
			var projection = transform(scaledWidth, heightScale, (width / 2 + scaledTotalWidth / 2), 0);
			var path = d3.geoPath(projection, context); 

			for (var i = 0; i < cntrs.length; i++) { 

				if (cntrs[i].coordinates.length == 0) continue;
				context.beginPath();
				path(cntrs[i]);
				context.fillStyle = lineColor(cntrs[i].value);
				context.fill();
			}
		}
	}
  
	const handleWheel = (e) => {
		e.evt.preventDefault();

		const scaleBy = 1.05;
		const stage = e.target.getStage();
		const oldScale = stage.scaleX();
		const mousePointTo = {
			x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
			y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
		};

		const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

		setStage({
			scale: newScale,
			x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
			y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale
		});
	};

	const handleGrid = useCallback((response) => {
		console.log("grid response", response);
		if(response?.data!=null && response.status == 200){
			setGridResults(response.data.reverse());
		}
	}, []);



	useEffect(() => {
		let isMounted = true;
		EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': props.LuminaireCfg.selectedLuminaireIds[0] } }); 
		return () => { isMounted = false };
	}, []);

	useEffect(() => {
		if (targetRef.current) {
			setWidth(targetRef.current.offsetWidth);
			setHeight(targetRef.current.offsetHeight);
			// trRef.current.nodes([stageRef.current]);
       		// trRef.current.getLayer().batchDraw();
		}
	}, [targetRef]);

	useEffect(() => {
		window.addEventListener("resize", redraw);
		return () => window.removeEventListener("resize", redraw);
	})

	useEffect(() => {
		if (gridResults.length == 0 || props.LuminaireCfg.selectedLuminaireIds != luminId) {
			EdgeOrREST(null, "GET_ISOLUX", handleGrid, API_OBJECTS.getCalculationObject(props, null, null));
		}
	}, [gridResults.length, handleGrid, props]);

 
	  const doUpdateRotation = (event) => {  
		  EdgeOrREST(null, 'UPDATE_LUMINAIRE_ROTATION', onUpdateRotation, { luminaireId: props.LuminaireCfg.selectedLuminaireIds[0], rotation : displayRotation});   
	  };


	const onGetPhotometricInformation = (response) => { 
		if(response.status == 200){ 
			console.log("photometric info data", response.data); 
			setDisplayRotation(response.data.luminaire.rotation);
			setActualRotation(response.data.luminaire.rotation);
		}
	} 
	 
	const onUpdateRotation = (response) => { 
		if(response.status == 200){
		setSnack({
			open: true,
			message : 'Rotation has been successfully updated.',
			severity : 'success'
		}); 
		} else { 
		setSnack({
			open: true,
			message : response.status + response.message,
			severity : 'error'
		});
		}
	} 

	const [snack, setSnack] = React.useState({
		open: false
	});
	
	const handleClose = () => {
		setSnack({
		...snack,
		open: false
		});
	};
	
	return (
		<>
		 <FormControl size="small"> 
			<InputLabel id="rotationLabel">Rotation</InputLabel>
			<Select
			labelId="rotationSelector"
			id="rotationSelector"
			value={displayRotation}
			onChange={onRotationChange}
			name="rotation" 
			>
			<MenuItem value={"0"}>0</MenuItem>
			<MenuItem value={"90"}>90</MenuItem>
			<MenuItem value={"180"}>180 </MenuItem>
			<MenuItem value={"270"}> 270</MenuItem>
			</Select>
		</FormControl> 
		
		<div id="isoluxWrapper" ref={targetRef}>
			<Stage
				ref={stageRef}
				width={width}
				height={height}
				onWheel={handleWheel}
				scaleX={stage.scale}
				scaleY={stage.scale}
				x={stage.x}
				y={stage.y}
				style={{ border: '1px solid #000000', height: '65vh' }}
				draggable={true}   
				id="isoluxStage"   
			>
				<Layer id="isoluxLayer" ref={canvasRef} style={{ border: '1px solid #000000', maxHeight: '65vh' }}>
					<Shape sceneFunc={(ctx) => drawContours(ctx)} 
						onRotationChange={(ctx) => { 
						}}
						rotation={displayRotation - actualRotation < 0 ? (displayRotation - actualRotation) + 360: displayRotation - actualRotation }
						rotationSnaps={[0,90,180,270]}
						offset={{x:width/2, y:height/2}}
					 />
					<Shape sceneFunc={(ctx) => drawBase(ctx)}  
						offset={{x:width/2, y:height/2}} 
					/> 
				</Layer> 
			</Stage>
		</div>
		<FormGroup className="MuiFormGroup-options" row>      
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary" 
				id="submitAttributesBUtton"
				onClick={doUpdateRotation}>
				Update Rotation
			</Button>  
			</FormGroup> 
		<Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification> 
		</>
	);
}

export default Isolux;
