import { apiService } from './axios.client';

export const LuminaireEndPoints = {
    getConfigurationLuminaires: function (input, callback) {
        apiService.get('/fullConfiguration/GetLuminaires', input)
            .then(callback)
            .catch(error => error.response);
    },
    deleteConfigurationLuminaire: function (input, callback) {
        apiService.post('/hierarchy/RemoveTreeItem', input)
            .then(callback)
            .catch(error => alert(error.response.data.message));
    },
    uploadLuminaire: function (input, callback) {
        apiService.post('/photometric/addLuminaire', input)
            .then(callback)
            .catch(callback);
    },
    uploadLuminaires: function (input, callback) {
        apiService.post('/photometric/addLuminaires', input)
            .then(callback)
            .catch(callback);
    },
    uploadLuminairesAndAddToGroup: function (input, callback) {
        apiService.post('/photometric/addLuminairesAndAddToGroup', input)
            .then(callback)
            .catch(callback);
    },
    getLuminaireData: function (input, callback) {
        apiService.get('/photometric/getLuminaireData', input)
            .then(callback)
            .catch(error => error.response);
    },
    updateAttributes: function (input, callback) {
        apiService.post('/photometric/setLuminaireAttributesForGroup', input)
            .then(callback)
            .catch(callback);
    },
    searchLuminaires: function (input, callback) {
        apiService.post('/photometric/searchLuminaires', input)
            .then(callback)
            .catch(error => alert(error.response));
    },
    searchUploadedLuminaires: function (input, callback) {
        apiService.post('/photometric/SearchUploadedLuminaires', input)
            .then(callback)
            .catch(callback);
    },
    getManufacturerNames: function (input, callback) {
        apiService.get('/photometric/getManufacturerNames', input)
            .then(callback)
            .catch(error => alert(error.response));
    },
    getLuminaireImages: function (input, callback) {
        apiService.get('/photometricMedia/GetLuminaireImage', input)
            .then(callback)
            .catch(error => error.response);
    },
    updateLuminaireRotation: function (input, callback) {
        apiService.post('/photometric/UpdateRotationOnLuminaireScope', input)
            .then(callback)
            .catch(callback);
    },
    deleteLuminaire: function (input, callback) {
        apiService.delete('/photometric/DeleteLuminaire', input)
            .then(callback)
            .catch(callback);
    },
    createVirtualLuminaireAndAddToGroupBySku: function (input, callback) {
        apiService.post('/photometric/CreateVirtualLuminaireAndAddToGroupBySku', input)
            .then(callback)
            .catch(callback);
    },
};