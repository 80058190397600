import React, { useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../../store/actions/user';
import {
    Grid,
    Checkbox,
    FormControlLabel,
    TextField
} from '@material-ui/core';

const ReiLimit = () => {

    const props = useSelector(state => state.user)
    const [checked, setChecked] = useState(props.enableRei);
    const [reiWidth, setReiWidth] = useState(props.reiWidth);
    const dispatch = useDispatch();

    const handleCheck = () => {
        setChecked(!checked);
        dispatch(userActions.UpdateUserCfg({enableRei: !checked}));
    } 
    const handleChange = (e) => {        
        setReiWidth(e.target.value);
        dispatch(userActions.UpdateUserCfg({reiWidth: e.target.value}));
    }


    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleCheck}
                            name="checkboxReiEnable"
                            color="primary"
                        />
                    }
                    label="Enable Rei Limit"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    disabled={!checked}
                    type="number"
                    label={"Rei Width Limit"}
                    name="reiWidth"
                    value={reiWidth}
                    step="0.25"
                    min="0.00"
                    onChange={handleChange}
                    variant="outlined"
                    placeholder="0.00"
                />
            </Grid>
        </>
    )
}

export default ReiLimit;