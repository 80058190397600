import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Notification from '../components/shared/notification.component';
import EdgeOrREST from '../EdgeOrREST';
import staticProductList from './staticProductList';

const AddCom = (props) => {
  const [comName, setComName] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [isManufacturer, setIsManufacturer] = React.useState('false');
  const [snack, setSnack] = useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  useEffect(() => {
    const isFormValid =
      comName.trim() !== "" && selectedProducts.length > 0;
    setIsValid(isFormValid);
  }, [comName, selectedProducts]);

  const onComName = (event) => {
    setComName(event.target.value);
  };

  const handleProductToggle = (productName) => {
    const updatedProducts = selectedProducts.includes(productName)
      ? selectedProducts.filter((name) => name !== productName)
      : [...selectedProducts, productName];

    setSelectedProducts(updatedProducts);
  };

  const handleIsManufacturer = (event) => {
    if (event.target.checked) setIsManufacturer('true');
    else setIsManufacturer('false');
  };

  const handleSubmit = () => {
    if (isValid) {
      console.log('Selected Products:', selectedProducts);
      EdgeOrREST(null, 'ADD_COMPANY', onAddCom, { companyId: 0, comName: comName, isManufacturer: isManufacturer, selectedProducts: selectedProducts });
    } else {
      setSnack({
        open: true,
        message: 'Form Incomplete',
        severity: 'error',
      });
    }
  };

  const onAddCom = (response) => {
    if (response && response.status == '200') {
      setSnack({
        open: true,
        message: 'Add Company Successfully',
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: 'Add Company Fail.',
        severity: 'error'
      });
    }
  }

  return (
    <Box>
      <Card style={{ minHeight: 'inherit' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                id="comName"
                value={comName}
                onChange={onComName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isManufacturer === 'true'}
                    onChange={handleIsManufacturer}
                    color="primary"
                  />
                }
                label="Manufacturer"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ minHeight: 'inherit', marginTop: '16px' }}>
        <CardContent>
          <Box borderColor="primary.main" borderRadius={5} p={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div style={{ fontWeight: 'bold' }}>Products:</div>
              </Grid>
              {staticProductList.map((productName) => (
                <Grid item key={productName} xs={6} sm={4} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedProducts.includes(productName)}
                        onChange={() => handleProductToggle(productName)}
                        color="primary"
                      />
                    }
                    label={productName}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Card style={{ minHeight: 'inherit', marginTop: '16px' }}>
        <CardContent>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Box>
  );
};

export default AddCom;
