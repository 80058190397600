import React from 'react';
function steps (){return[
    {
        content: () => (
            <div>
                Tour currently unavaliable.                
            </div>
        ),
    },
]}

export default steps;