import React, { Component } from 'react';
import EdgeOrREST from '../../EdgeOrREST';
import {
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControl,
    makeStyles,
    Button,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Typography,
    Slider,
    Switch,
    MenuItem,
    Select,
    TextField,
    Radio,
    RadioGroup,
    Grid,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import user from '../../store/actions/user';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PhotometricAttributeMenu from './photometric-attribute-menu.component';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    },
    formControl: {
        width: '230px',
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formGroup: {
        margin: theme.spacing(1),
    },
    formLabel: {
        color: 'darkblue',
        marginBottom: '0px'
    },
    paper: {
        boxShadow: '0 0 0 rgb(63 63 68 / 5%), 0 1px 2px rgb(63 63 68 / 15%)'
    },
    formGroupSlider: {
        marginRight: '10px',
        marginLeft: '10px'
    }

}));

const initialState = {
    //spektd options
    //light
    lightSource: 'any',
    manufacturer: 'any',
    lampBase: 'any',
    cri: 0,
    r9: 0,
    macAdamStep: 'any',
    direction: 'any',
    beamAngle: 0,
    cctOptions: 'any',
    cct: 0,
    lor: 0,
    opticalAccessories: 'any',
    lampCount: 0,
    deliveredLumens: 0,
    ulor: 0,
    secondaryBeamAngle: 0,
    directElement: 0,
    indirectElement: 0,
    cctMin: 0,
    cctMax: 0,
    colourChangeType: 'any',
    lumenSuffix: 'lm',

    //physical
    finish: 'any',
    housingMaterial: 'any',
    adjustableTilt: 0,
    adjustableRotation: 0,
    shape: 'any',
    height: 0,
    length: 0,
    width: 0,
    diameter: 0,
    installation: 'any',
    placement: [],
    weight: 0,
    recessDepthRequirement: 0,
    cutOutLength: 0,
    cutOutWidth: 0,
    cutOutDiameter: 0,
    anyHeight: '',
    anyLength: '',
    anyWidth: '',
    anyDiameter: '',
    anyCutOutLength: '',
    anyCutOutWidth: '',
    anyCutOutDiameter: '',
    anyRecessDepthRequirement: '',

    //electrical
    voltageType: 'any',
    voltage: 0,
    wattage: 0,
    mA: 0,
    luminWatt: 0,
    driverType: 'any',
    control: 'any',
    applianceClass: 0,
    calMa: '',
    wattageSuffix: "W",

    //protection
    ip: 0,
    ik: 0,
    operatingTemp: [-30, 100],
    emergencyLight: 'any',
    antiSlip: 'any',
    designLabel: [],
    explosionZoneSuitability: '',
    rolloverProtection: '',
    suitableForCleanRooms: '',

    //integration
    daylightSensors: 'any',
    motionSensors: 'any',
    presenceSensors: 'any',

    //environment
    tm66: 0,
    envCert: 'any',

    //brands
    brands: 'any',
    sku: '',
    productType: 'any',
    // status: 'any',
    // imagesLineDrawing: 'any',
    // imageVariantPhoto: 'any',
    // imagePolarcurve: 'any',
    // twoDCadFiles: 'any',
    // pdfFiles: 'any',
    // specificationSheet: 'any',
    // published: 'any',
    // allFilesUploaded: 'any',
    // controlsData: 'any',
};

const initialDisabledList = {
    //spektd options
    //light
    lightSource: false,
    manufacturer: false,
    lampBase: false,
    cri: false,
    r9: false,
    macAdamStep: false,
    direction: false,
    beamAngle: false,
    cctOptions: false,
    cct: false,
    lor: false,
    opticalAccessories: false,
    lampCount: false,
    deliveredLumens: false,
    ulor: false,
    secondaryBeamAngle: false,
    directElement: false,
    indirectElement: false,
    cctMin: false,
    cctMax: false,
    colourChangeType: false,
    lumenSuffix: false,

    //physical
    finish: false,
    housingMaterial: false,
    adjustableTilt: false,
    adjustableRotation: false,
    shape: false,
    height: false,
    length: false,
    width: false,
    installation: false,
    placement: false,
    weight: false,
    diameter: false,
    recessDepthRequirement: false,
    cutOutLength: false,
    cutOutWidth: false,
    cutOutDiameter: false,
    anyHeight: false,
    anyLength: false,
    anyWidth: false,
    anyDiameter: false,
    anyCutOutLength: false,
    anyCutOutWidth: false,
    anyCutOutDiameter: false,
    anyRecessDepthRequirement: false,

    //electrical
    voltageType: false,
    voltage: false,
    wattage: false,
    mA: false,
    luminWatt: false,
    driverType: false,
    control: false,
    applianceClass: false,
    calMa: false,
    wattageSuffix: false,

    //protection
    ip: false,
    ik: false,
    operatingTempMin: false,
    operatingTempMax: false,
    emergencyLight: false,
    antiSlip: false,
    designLabel: false,
    explosionZoneSuitability: false,
    rolloverProtection: false,
    suitableForCleanRooms: false,

    //integration
    daylightSensors: false,
    motionSensors: false,
    presenceSensors: false,

    //environmental
    tm66: false,
    envCert: false,
    //brands
    brands: false,
    sku: false,
    productType: false,
    // status: false,
    // imagesLineDrawing: false,
    // imageVariantPhoto: false,
    // imagePolarcurve: false,
    // twoDCadFiles: false,
    // pdfFiles: false,
    // specificationSheet: false,
    // published: false,
    // allFilesUploaded: false,
    // controlsData: false,
};


const PhotometricAttributeSelector = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [attributeParams, setAttributeParams] = React.useState(initialState);
    const buttonRef = React.useRef(null);
    const [disabledList, setDisabledList] = React.useState([]);

    const onCriChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            cri: newValue
        }));
    };

    const onR9Change = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            r9: newValue
        }));
    };

    const onBeamAngleChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            beamAngle: newValue
        }));
    };

    const onLorChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            lor: newValue
        }));
    };

    const onAdjustableTiltChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            adjustableTilt: newValue
        }));
    };

    const onAdjustableRotationChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            adjustableRotation: newValue
        }));
    };

    const onOperatingTempChange = (event, newValue) => {
        setAttributeParams(prevState => ({
            ...prevState,
            operatingTemp: newValue
        }));
    };

    const finishOptions = [
        { value: 'any', label: 'Any' },
        { value: 'Black', label: 'Black' },
        { value: 'White', label: 'White' },
        { value: 'Grey', label: 'Grey' },
        { value: 'Silver', label: 'Silver' },
        { value: 'Gold', label: 'Gold' },
        { value: 'Bronze', label: 'Bronze' },
        { value: 'Rust', label: 'Rust' },
        { value: 'Copper', label: 'Copper' },
        { value: 'Red', label: 'Red' },
        { value: 'Orange', label: 'Orange' },
        { value: 'Yellow', label: 'Yellow' },
        { value: 'Green', label: 'Green' },
        { value: 'Blue', label: 'Blue' },
        { value: 'Purple', label: 'Purple' },
        { value: 'Unpainted', label: 'Unpainted' },
        { value: 'Other', label: 'Other' },
    ];

    const onFinishChange = (event) => {
        if (event.target.value.includes("any") && !attributeParams.finish.includes("any")) {
            setAttributeParams(prevState => ({
                ...prevState,
                finish: "any"
            }));
        } else if (event.target.value.includes("any") && attributeParams.finish.includes("any")) {
            var newValue = event.target.value.filter(item => item !== "any");
            setAttributeParams(prevState => ({
                ...prevState,
                finish: newValue.join(",")
            }));
        } else {
            setAttributeParams(prevState => ({
                ...prevState,
                finish: event.target.value.join(",")
            }));
        }
    };

    const installationOptions = [
        { value: 'any', label: 'Any' },
        { value: 'Recessed-trim', label: 'Recessed-trim' },
        { value: 'Recessed-trimless', label: 'Recessed-trimless' },
        { value: 'Semi-recessed', label: 'Semi-recessed' },
        { value: 'Surface', label: 'Surface' },
        { value: 'Suspended', label: 'Suspended' },
        { value: 'Free-standing', label: 'Free-standing' },
        { value: 'Track', label: 'Track' },
        { value: 'Other', label: 'Other' },
    ];

    const onInstallationChange = (event) => {
        if (event.target.value.includes("any") && !attributeParams.installation.includes("any")) {
            setAttributeParams(prevState => ({
                ...prevState,
                installation: "any"
            }));
        } else if (event.target.value.includes("any") && attributeParams.installation.includes("any")) {
            var newValue = event.target.value.filter(item => item !== "any");
            setAttributeParams(prevState => ({
                ...prevState,
                installation: newValue.join(",")
            }));
        } else {
            setAttributeParams(prevState => ({
                ...prevState,
                installation: event.target.value.join(",")
            }));
        }
    };


    const placementOptions = [
        { value: 'any', label: 'Any' },
        { value: 'Wall', label: 'Wall' },
        { value: 'Ceiling', label: 'Ceiling' },
        { value: 'Floor', label: 'Floor' },
        { value: 'Desk/table', label: 'Desk/table' },
        { value: 'Pole top', label: 'Pole top' },
        { value: 'Other', label: 'Other' },
    ];

    const onPlacementChange = (event) => {
        if (event.target.value.includes('any') && !attributeParams.placement.includes('any')) {
            setAttributeParams(prevState => ({
                ...prevState,
                placement: ['any']
            }));
        } else if (event.target.value.includes('any') && attributeParams.placement.includes('any')) {
            var newValue = event.target.value.filter(item => item !== 'any');
            console.log('newValue', newValue);
            setAttributeParams(prevState => ({
                ...prevState,
                placement: newValue
            }));
        } else {
            setAttributeParams(prevState => ({
                ...prevState,
                placement: event.target.value
            }));
        }
    };

    const designLabelOptions = [
        { value: 'any', label: 'Any' },
        { value: 'CCC', label: 'CCC' },
        { value: 'CE', label: 'CE' },
        { value: 'ENEC', label: 'ENEC' },
        { value: 'F', label: 'F' },
        { value: 'FF', label: 'FF' },
        { value: 'GS', label: 'GS' },
        { value: 'M', label: 'M' },
        { value: 'MM', label: 'MM' },
        { value: 'UL', label: 'UL' },
        { value: 'OTHER', label: 'OTHER' },
    ];

    const onDesignLabelChange = (event) => {
        console.log("event.target.value", event.target.value);
        if (event.target.value.includes("any") && !attributeParams.designLabel.includes("any")) {
            setAttributeParams(prevState => ({
                ...prevState,
                designLabel: ["any"]
            }));
        } else if (event.target.value.includes("any") && attributeParams.designLabel.includes("any")) {
            var newValue = event.target.value.filter(item => item !== "any");
            console.log("newValue", newValue);
            setAttributeParams(prevState => ({
                ...prevState,
                designLabel: newValue
            }));
        } else {
            setAttributeParams(prevState => ({
                ...prevState,
                designLabel: event.target.value
            }));
        }
    };

    const controlOptions = [
        { value: 'any', label: 'Any' },
        { value: 'Wired DALI', label: 'Wired DALI' },
        { value: 'Wired DALI 2', label: 'Wired DALI 2' },
        { value: 'Wired 0-10v', label: 'Wired 0-10v' },
        { value: 'Wired 1-10v', label: 'Wired 1-10v' },
        { value: 'Wired DALI + 1-10v', label: 'Wired DALI + 1-10v' },
        { value: 'Wired DSi', label: 'Wired DSi' },
        { value: 'Wired Leading Edge', label: 'Wired Leading Edge' },
        { value: 'Wired Trailing Edge', label: 'Wired Trailing Edge' },
        { value: 'Wired Switch Dim', label: 'Wired Switch Dim' },
        { value: 'Wired Push Dim', label: 'Wired Push Dim' },
        { value: 'Wired Non Dim', label: 'Wired Non Dim' },
        { value: 'Wired DMX', label: 'Wired DMX' },
        { value: 'Wireless Casambi', label: 'Wireless Casambi' },
        { value: 'Wireless Xicato', label: 'Wireless Xicato' },
        { value: 'Other', label: 'Other' },
    ];

    const onControlChange = (event) => {
        if (event.target.value.includes("any") && !attributeParams.control.includes("any")) {
            setAttributeParams(prevState => ({
                ...prevState,
                control: "any"
            }));
        } else if (event.target.value.includes("any") && attributeParams.control.includes("any")) {
            var newValue = event.target.value.filter(item => item !== "any");
            setAttributeParams(prevState => ({
                ...prevState,
                control: newValue.join(",")
            }));
        } else {
            setAttributeParams(prevState => ({
                ...prevState,
                control: event.target.value.join(",")
            }));
        }
    };

    const onSetAttributeParams = (e, object) => {
        const { name, value } = e.target;
        console.log("name, value", name, value);
        if (e.target.type === "checkbox" && object === "brands") {
            setAttributeParams(prevState => ({
                ...prevState,
                brands: { ...prevState.brands, [name]: e.target.checked }
            }));
        } else {
            console.log("setting basic name");
            setAttributeParams(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    useEffect(() => {
        console.log("attributeParams", attributeParams);
        props.handleAttributeParams(attributeParams);
    }, [attributeParams]);

    useEffect(() => {
        props.hasOwnProperty('disabledAttList') ? setDisabledList(props.disabledAttList) : setDisabledList(initialDisabledList);
        console.log("useEffect props", props);
        if (props.parentGroupId != 0 && props.parentGroupId != null) {
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllParentGroupAttributes, { headers: { 'id': props.parentGroupId } });
        } else if (props.editLumin && props.luminaireId != 0) {
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_LUMINAIRE', onGetAllAttributes, { headers: { 'id': props.luminaireId } });
        } else if (props.edit || props.upload) {
            console.log("edit or upload props", props);
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllAttributes, { headers: { 'id': props.groupId } });
        }
    }, []);

    const onGetAllParentGroupAttributes = (response) => {
        console.log("onGetAllParentGroupAttributes response", response);
        if (response.status === 200) {
            const nonNullData = Object.entries(response.data)
                .filter(([key, value]) => value !== null)
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
            const operatingTempMin = response.data.operatingTempMin == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? -30 : response.data.operatingTempMin;
            const operatingTempMax = response.data.operatingTempMax == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? 100 : response.data.operatingTempMax;
            const placement = response.data.placement == null || response.data.placement == "" ? [] : response.data.placement.split(",");
            const designLabel = response.data.designLabel == null || response.data.designLabel == "" ? [] : response.data.designLabel.split(",");
            console.log("nonNullData", nonNullData);
            console.log("attributeParams", attributeParams);
            const updatedAttributeParams = {
                ...attributeParams,
                ...nonNullData,
                operatingTemp: [operatingTempMin, operatingTempMax],
                placement: placement,
                designLabel: designLabel
            };
            console.log("Parent updatedAttributeParams: ", updatedAttributeParams);
            setAttributeParams(updatedAttributeParams);
            //disable attributes that assign by group already
            console.log("test disable attributes");
            const nonNullKeys = Object.keys(response.data).filter(key => response.data[key] !== null && response.data[key] != 0 && key != "id");
            const updatedDisabledList = { ...disabledList };
            nonNullKeys.forEach(key => {
                updatedDisabledList[key] = true;
            });
            if (operatingTempMin == -30 && operatingTempMax == 100) {
                updatedDisabledList.operatingTempMin = false;
                updatedDisabledList.operatingTempMax = false;
            }
            console.log("updatedDisabledList", updatedDisabledList);
            setDisabledList(updatedDisabledList);

            if (props.editLumin && props.luminaireId != 0) {
                EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_LUMINAIRE', onGetAllAttributes, { headers: { 'id': props.luminaireId } });
            } else if (props.edit || props.upload) {
                EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllAttributes, { headers: { 'id': props.groupId } });
            }
        }
    };

    const onGetAllAttributes = (response) => {
        console.log("onGetAllAttributes response", response);
        if (response.status === 200) {
            const nonNullData = Object.entries(response.data)
                .filter(([key, value]) => value !== null)
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
            console.log("nonNullData", nonNullData);
            const operatingTempMin = response.data.operatingTempMin == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? -30 : response.data.operatingTempMin;
            const operatingTempMax = response.data.operatingTempMax == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? 100 : response.data.operatingTempMax;
            const placement = response.data.placement == null || response.data.placement == "" ? [] : response.data.placement.split(",");
            const designLabel = response.data.designLabel == null || response.data.designLabel == "" ? [] : response.data.designLabel.split(",");
            const updatedAttributeParams = {
                ...attributeParams,
                ...nonNullData,
                operatingTemp: [operatingTempMin, operatingTempMax],
                placement: placement,
                designLabel: designLabel
            };
            console.log("updatedAttributeParams: ", updatedAttributeParams);
            setAttributeParams(updatedAttributeParams);
            console.log("attributeParams", attributeParams);
            //disable attributes that assign by group already
            if (!props.edit && !props.editLumin) {
                const nonNullKeys = Object.keys(response.data).filter(key => response.data[key] !== null && response.data[key] != 0 && key != "id");
                console.log("nonNullKeys", nonNullKeys);
                const updatedDisabledList = { ...disabledList };
                console.log("updatedDisabledList", updatedDisabledList);
                nonNullKeys.forEach(key => {
                    updatedDisabledList[key] = true;
                });
                if (operatingTempMin == -30 && operatingTempMax == 100) {
                    updatedDisabledList.operatingTempMin = false;
                    updatedDisabledList.operatingTempMax = false;
                }
                console.log("updatedDisabledList", updatedDisabledList);
                setDisabledList(updatedDisabledList);
            }

        }
    };

    function selectItem(title, id, labelId, name, value, onSetSearchParams, itemsValue, selectOptions) {
        return (
            <FormControl className={classes.formControl} size="small">
                <Typography className={classes.formLabel} gutterBottom>
                    {title}
                </Typography>
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    onChange={onSetSearchParams}
                    name={name}
                    disabled={disabledList[name]}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: 'auto',
                            },
                        },
                    }}
                >
                    {itemsValue.map((item, id) => {
                        return (
                            <MenuItem value={item}><Typography >{selectOptions[id]}</Typography ></MenuItem>
                        )

                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <>
            <div ref={buttonRef} style={{ width: '150px' }} />
            {/* Light, Physical, Electrical, Protection, Integration, Environment, Brands */}
            {PhotometricAttributeMenu({
                props, category: 'Light', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            {selectItem("Light source", "lightSource", "lightSource", "lightSource", attributeParams.lightSource, onSetAttributeParams,
                                ["any", "LED", "Fluorescent", "Metal Halide", "Halogen", "OLED", "Fibreoptic", "Neon", "Laser", "Other"],
                                ["Any", "LED", "Fluorescent", "Metal Halide", "Halogen", "OLED", "Fibreoptic", "Neon", "Laser", "Other"])}
                            {selectItem("Light source manufacturer", "manufacturer", "manufacturer", "manufacturer", attributeParams.manufacturer, onSetAttributeParams,
                                ["any", "Bridgelux", "Calex", "Citizen", "Cree", "Philips", "Samsung", "Seoul", "Xicato", "Undefined/not fixed", "Other"],
                                ["Any", "Bridgelux", "Calex", "Citizen", "Cree", "Philips", "Samsung", "Seoul", "Xicato", "Undefined/not fixed", "Other"])}
                            {selectItem("Lamp base", "lampBase", "lampBase", "lampBase", attributeParams.lampBase, onSetAttributeParams,
                                ["any", "E14", "E27", "BC14", "BC22", "GU10", "GU5.3", "Other"],
                                ["Any", "E14", "E27", "BC14", "BC22", "GU10", "GU5.3", "Other"])}
                        </FormGroup>

                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    CRI
                                </Typography>
                                <Slider
                                    value={attributeParams.cri}
                                    onChange={onCriChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.cri}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={100}
                                    marks={[{ value: 0, label: '0' }, { value: attributeParams.cri, label: attributeParams.cri }, { value: 100, label: '100' }]}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    R9 Value
                                </Typography>
                                <Slider
                                    value={attributeParams.r9}
                                    onChange={onR9Change}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.r9}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={100}
                                    marks={[{ value: 0, label: '0' }, { value: attributeParams.r9, label: attributeParams.r9 }, { value: 100, label: '100' }]}
                                />
                            </FormControl>

                            {selectItem("MacAdam step", "macAdamStep", "macAdamStep", "macAdamStep", attributeParams.macAdamStep, onSetAttributeParams,
                                ["any", "1", "2", "3", "4", "5+"],
                                ["Any", "1 step", "2 step", "3 step", "4 step", "5+ step"])}

                            {selectItem("Direction", "direction", "direction", "direction", attributeParams.direction, onSetAttributeParams,
                                ["any", "Direct", "Asymmetric", "Direct/Indirect", "Omnidirect", "Multidirectional", "Other"],
                                ["Any", "Direct", "Asymmetric", "Direct/Indirect", "Omnidirect", "Multidirectional", "Other"])}

                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    Beam Angle
                                </Typography>
                                <Slider
                                    value={attributeParams.beamAngle}
                                    onChange={onBeamAngleChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.beamAngle}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={360}
                                    marks={[{ value: 0, label: '0°' }, { value: attributeParams.beamAngle, label: attributeParams.beamAngle + "°" }, { value: 360, label: '360°' }]}
                                />
                            </FormControl>
                            {selectItem("CCT options", "cctOptions", "cctOptions", "cctOptions", attributeParams.cctOptions, onSetAttributeParams,
                                ["any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"],
                                ["Any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    CCT (K)
                                </Typography>
                                <TextField className={classes.formLabel} name="cct" type="number"
                                    disabled={disabledList.cct}
                                    inputProps={{ min: 0, step: 500 }} value={attributeParams.cct} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Colour tuning/Warm dimming CCT min (K)
                                </Typography>
                                <TextField className={classes.formLabel} name="cctMin" type="number"
                                    disabled={disabledList.cctMin}
                                    inputProps={{ min: 0, step: 500 }} value={attributeParams.cctMin} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Colour tuning/Warm dimming CCT max (K)
                                </Typography>
                                <TextField className={classes.formLabel} name="cctMax" type="number"
                                    disabled={disabledList.cctMax}
                                    inputProps={{ min: 0, step: 500 }} value={attributeParams.cctMax} onChange={onSetAttributeParams} />
                            </FormControl>
                            {selectItem("Colour change type", "colourChangeType", "colourChangeType", "colourChangeType", attributeParams.colourChangeType, onSetAttributeParams,
                                ["any", "RGB", "RGBA", "RGBW", "Other"],
                                ["Any", "RGB", "RGBA", "RGBW", "Other"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    LOR
                                </Typography>
                                <Slider
                                    value={attributeParams.lor}
                                    onChange={onLorChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.lor}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={100}
                                    marks={[{ value: 0, label: '0' }, { value: attributeParams.lor, label: attributeParams.lor }, { value: 100, label: '100' }]}
                                />
                            </FormControl>
                            {selectItem("Optical accessories", "opticalAccessories", "opticalAccessories", "opticalAccessories", attributeParams.opticalAccessories, onSetAttributeParams,
                                ["any", "true", "false"],
                                ["Any", "Yes", "No"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Number of lamps/light engines
                                </Typography>
                                <TextField className={classes.formLabel} name="lampCount" type="number"
                                    disabled={disabledList.lampCount}
                                    inputProps={{ min: 0 }} value={attributeParams.lampCount} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Delivered Lumens
                                </Typography>
                                <TextField className={classes.formLabel} name="deliveredLumens" type="number"
                                    disabled={disabledList.deliveredLumens}
                                    inputProps={{ min: 0 }} value={attributeParams.deliveredLumens} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    ULOR
                                </Typography>
                                <TextField className={classes.formLabel} name="ulor" type="number"
                                    disabled={disabledList.ulor}
                                    inputProps={{ min: 0 }} value={attributeParams.ulor} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Secondary Beam Angle
                                </Typography>
                                <TextField className={classes.formLabel} name="secondaryBeamAngle" type="number"
                                    disabled={disabledList.secondaryBeamAngle}
                                    inputProps={{ min: 0 }} value={attributeParams.secondaryBeamAngle} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Direct Element(%)
                                </Typography>
                                <TextField className={classes.formLabel} name="directElement" type="number"
                                    disabled={disabledList.directElement}
                                    inputProps={{ min: 0 }} value={attributeParams.directElement} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Indirect Element (%)
                                </Typography>
                                <TextField className={classes.formLabel} name="indirectElement" type="number"
                                    disabled={disabledList.indirectElement}
                                    inputProps={{ min: 0 }} value={attributeParams.indirectElement} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Lumen Suffix
                                </Typography>
                                <TextField className={classes.formLabel} name="lumenSuffix" type="text"
                                    disabled={disabledList.lumenSuffix}
                                    value={attributeParams.lumenSuffix} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}
            {/* Physical */}
            {PhotometricAttributeMenu({
                props, category: 'Physical', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>
                                    Finish
                                </Typography>
                                <Select
                                    id="finish"
                                    value={attributeParams.finish.includes(",") ? attributeParams.finish.split(",") : [attributeParams.finish]}
                                    onChange={onFinishChange}
                                    name="finish"
                                    disabled={disabledList["finish"]}
                                    multiple
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {finishOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {selectItem("Luminaire Housing Material", "housingMaterial", "housingMaterial", "housingMaterial", attributeParams.housingMaterial, onSetAttributeParams,
                                ["any", "Aluminium", "Stainless steel", "Steel", "Nickel", "Plastic", "Glass", "Wood", "Brass", "Other"],
                                ["Any", "Aluminium", "Stainless steel", "Steel", "Nickel", "Plastic", "Glass", "Wood", "Brass", "Other"])}
                            {/* {selectItem("Adjustable Tilt", "adjustableTilt", "adjustableTilt", "adjustableTilt", attributeParams.adjustableTilt, onSetAttributeParams,
                                ["any", "true", "false"],
                                ["Any", "Yes", "No"])} */}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    Adjustable Tilt
                                </Typography>
                                <Slider
                                    value={attributeParams.adjustableTilt}
                                    onChange={onAdjustableTiltChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.adjustableTilt}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={361}
                                    marks={[{ value: 0, label: '0°' }, { value: attributeParams.adjustableTilt, label: attributeParams.adjustableTilt + '°' }, { value: 361, label: '361°' }]}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    Adjustable Rotation
                                </Typography>
                                <Slider
                                    value={attributeParams.adjustableRotation}
                                    onChange={onAdjustableRotationChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.adjustableRotation}
                                    track={false}
                                    step={1}
                                    min={0}
                                    max={361}
                                    marks={[{ value: 0, label: '0°' }, { value: attributeParams.adjustableRotation, label: attributeParams.adjustableRotation + '°' }, { value: 361, label: '361°' }]}
                                />
                            </FormControl>
                            {selectItem("Shape", "shape", "shape", "shape", attributeParams.shape, onSetAttributeParams,
                                ["any", "Round", "Square", "Rectangle", "Sphere", "Curve", "Custom", "Other"],
                                ["Any", "Round", "Square", "Rectangle", "Sphere", "Curve", "Custom", "Other"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Height (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="height" type="number"
                                    disabled={disabledList.height}
                                    inputProps={{ min: 0 }} value={attributeParams.height} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Length (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="length" type="number"
                                    disabled={disabledList.length}
                                    inputProps={{ min: 0 }} value={attributeParams.length} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Width (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="width" type="number"
                                    disabled={disabledList.width}
                                    inputProps={{ min: 0 }} value={attributeParams.width} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Diameter (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="diameter" type="number"
                                    disabled={disabledList.diameter}
                                    inputProps={{ min: 0 }} value={attributeParams.diameter} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>
                                    Installation and Cut-out
                                </Typography>
                                <Select
                                    id="installation"
                                    value={attributeParams.installation.includes(",") ? attributeParams.installation.split(",") : [attributeParams.installation]}
                                    onChange={onInstallationChange}
                                    name="installation"
                                    disabled={disabledList["installation"]}
                                    multiple
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {installationOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>
                                    Placement
                                </Typography>
                                <Select
                                    id="placement"
                                    value={attributeParams.placement}
                                    onChange={onPlacementChange}
                                    name="placement"
                                    disabled={disabledList["placement"]}
                                    multiple
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {placementOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Weight (kg)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="weight" type="number"
                                    disabled={disabledList.weight}
                                    inputProps={{ min: 0 }} value={attributeParams.weight} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Recess Depth Requirement
                                </Typography>
                                <TextField className={classes.formLabel} name="recessDepthRequirement" type="number"
                                    disabled={disabledList.recessDepthRequirement}
                                    inputProps={{ min: 0 }} value={attributeParams.recessDepthRequirement} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Cut Out Length (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="cutOutLength" type="number"
                                    disabled={disabledList.cutOutLength}
                                    inputProps={{ min: 0 }} value={attributeParams.cutOutLength} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Cut Out Width (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="cutOutWidth" type="number"
                                    disabled={disabledList.cutOutWidth}
                                    inputProps={{ min: 0 }} value={attributeParams.cutOutWidth} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Cut Out Diameter (mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="cutOutDiameter" type="number"
                                    disabled={disabledList.cutOutDiameter}
                                    inputProps={{ min: 0 }} value={attributeParams.cutOutDiameter} onChange={onSetAttributeParams} />
                            </FormControl>
                            {/* <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    Any Height
                                </Typography>
                                <TextField className={classes.formLabel} name="anyHeight" type="text"
                                    disabled={disabledList.anyHeight}
                                    value={attributeParams.anyHeight} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} >
                                    Any Length
                                </Typography>
                                <TextField className={classes.formLabel} name="anyLength" type="text"
                                    disabled={disabledList.anyLength}
                                    value={attributeParams.anyLength} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Width
                                </Typography>
                                <TextField className={classes.formLabel} name="anyWidth" type="text"
                                    disabled={disabledList.anyWidth}
                                    value={attributeParams.anyWidth} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Diameter
                                </Typography>
                                <TextField className={classes.formLabel} name="anyDiameter" type="text"
                                    disabled={disabledList.anyDiameter}
                                    value={attributeParams.anyDiameter} onChange={onSetAttributeParams} />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Cut Out Length
                                </Typography>
                                <TextField className={classes.formLabel} name="anyCutOutLength" type="text"
                                    disabled={disabledList.anyCutOutLength}
                                    value={attributeParams.anyCutOutLength} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Cut Out Width
                                </Typography>
                                <TextField className={classes.formLabel} name="anyCutOutWidth" type="text"
                                    disabled={disabledList.anyCutOutWidth}
                                    value={attributeParams.anyCutOutWidth} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Cut Out Diameter
                                </Typography>
                                <TextField className={classes.formLabel} name="anyCutOutDiameter" type="text"
                                    disabled={disabledList.anyCutOutDiameter}
                                    value={attributeParams.anyCutOutDiameter} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Any Recess Depth Requirement
                                </Typography>
                                <TextField className={classes.formLabel} name="anyRecessDepthRequirement" type="text"
                                    disabled={disabledList.anyRecessDepthRequirement}
                                    value={attributeParams.anyRecessDepthRequirement} onChange={onSetAttributeParams} />
                            </FormControl> */}
                        </FormGroup>
                    </>
            })}
            {/* Electrical */}
            {PhotometricAttributeMenu({
                props, category: 'Electrical', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>Voltage Type</Typography>
                                <RadioGroup aria-label="voltageType" name="voltageType" id='voltageType' value={attributeParams.voltageType}
                                    row onChange={onSetAttributeParams}>
                                    <FormControlLabel
                                        value="any"
                                        control={<Radio color='primary' size='small' />}
                                        label="Any"
                                        disabled={disabledList.voltageType}
                                    />
                                    <FormControlLabel
                                        value="AC"
                                        control={<Radio color='primary' size='small' />}
                                        label="AC"
                                        disabled={disabledList.voltageType}
                                    />
                                    <FormControlLabel
                                        value="DC"
                                        control={<Radio color='primary' size='small' />}
                                        label="DC"
                                        disabled={disabledList.voltageType}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Voltage"}
                                </Typography>
                                <TextField className={classes.formLabel} name="voltage" type="number"
                                    disabled={disabledList.voltage}
                                    inputProps={{ min: 0 }} value={attributeParams.voltage} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Luminaire Wattage"}
                                </Typography>
                                <TextField className={classes.formLabel} name="wattage" type="number"
                                    disabled={disabledList.wattage}
                                    inputProps={{ min: 0 }} value={attributeParams.wattage} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"MilliAmp"}
                                </Typography>
                                <TextField className={classes.formLabel} name="mA" type="number"
                                    disabled={disabledList.mA}
                                    inputProps={{ min: 0 }} value={attributeParams.mA} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Lumin per Watt"}
                                </Typography>
                                <TextField className={classes.formLabel} name="luminWatt" type="number"
                                    disabled={disabledList.luminWatt}
                                    inputProps={{ min: 0 }} value={attributeParams.luminWatt} onChange={onSetAttributeParams} />
                            </FormControl>
                            {selectItem("Driver Type", "driverType", "driverType", "driverType", attributeParams.driverType, onSetAttributeParams,
                                ["any", "integral", "external"], ["Any", "Integral", "External"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>
                                    Control
                                </Typography>
                                <Select
                                    id="control"
                                    value={attributeParams.control.includes(",") ? attributeParams.control.split(",") : [attributeParams.control]}
                                    onChange={onControlChange}
                                    name="control"
                                    disabled={disabledList["control"]}
                                    multiple
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {controlOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {selectItem("Appliance Class", "applianceClass", "applianceClass", "applianceClass", attributeParams.applianceClass, onSetAttributeParams,
                                ["0", "1", "2", "3"], ["Any", "1", "2", "3"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Calc Ma
                                </Typography>
                                <TextField className={classes.formLabel} name="calMa" type="text"
                                    disabled={disabledList.calMa}
                                    value={attributeParams.calMa} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Wattage Suffix
                                </Typography>
                                <TextField className={classes.formLabel} name="wattageSuffix" type="text"
                                    disabled={disabledList.wattageSuffix}
                                    value={attributeParams.wattageSuffix} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}

            {/* Protection */}
            {PhotometricAttributeMenu({
                props, category: 'Protection', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"IP Rating"}
                                </Typography>
                                <TextField className={classes.formLabel} name="ip" type="number"
                                    disabled={disabledList.ip}
                                    inputProps={{ min: 0 }} value={attributeParams.ip} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"IK Rating"}
                                </Typography>
                                <TextField className={classes.formLabel} name="ik" type="number"
                                    disabled={disabledList.ik}
                                    inputProps={{ min: 0 }} value={attributeParams.ik} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="slider" className={classes.formLabel} gutterBottom>
                                    {"Ambient Operating Temperature(°C)"}
                                </Typography>
                                <Slider
                                    value={[attributeParams.operatingTemp[0], attributeParams.operatingTemp[1]]}
                                    onChange={onOperatingTempChange}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="slider"
                                    disabled={disabledList.operatingTempMin || disabledList.operatingTempMax}
                                    name='operatingTemp'
                                    step={5}
                                    min={-30}
                                    max={100}
                                    marks={[{ value: -30, label: '-30' }, { value: attributeParams.operatingTemp[0], label: attributeParams.operatingTemp[0] },
                                    { value: attributeParams.operatingTemp[1], label: attributeParams.operatingTemp[1] }, { value: 100, label: '100' }]}
                                />
                            </FormControl>
                            {selectItem("Emergency Light", "emergencyLight", "emergencyLight", "emergencyLight", attributeParams.emergencyLight, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Anti-Slip", "antiSlip", "antiSlip", "antiSlip", attributeParams.antiSlip, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>
                                    Designation Label
                                </Typography>
                                <Select
                                    id="designLabel"
                                    value={attributeParams.designLabel}
                                    onChange={onDesignLabelChange}
                                    name="designLabel"
                                    disabled={disabledList["designLabel"]}
                                    multiple
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {designLabelOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Explosion Zone Suitability
                                </Typography>
                                <TextField className={classes.formLabel} name="explosionZoneSuitability" type="text"
                                    disabled={disabledList.explosionZoneSuitability}
                                    value={attributeParams.explosionZoneSuitability} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Rollover Protection
                                </Typography>
                                <TextField className={classes.formLabel} name="rolloverProtection" type="text"
                                    disabled={disabledList.rolloverProtection}
                                    value={attributeParams.rolloverProtection} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel}>
                                    Suitable for Clean Rooms
                                </Typography>
                                <TextField className={classes.formLabel} name="suitableForCleanRooms" type="text"
                                    disabled={disabledList.suitableForCleanRooms}
                                    value={attributeParams.suitableForCleanRooms} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}

            {PhotometricAttributeMenu({
                props, category: 'Integration', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            {selectItem("Daylight sensors", "daylightSensors", "daylightSensors", "daylightSensors", attributeParams.daylightSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Motion sensors", "motionSensors", "motionSensors", "motionSensors", attributeParams.motionSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Presence sensors", "presenceSensors", "presenceSensors", "presenceSensors", attributeParams.presenceSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                        </FormGroup>
                    </>
            })}

            {PhotometricAttributeMenu({
                props, category: 'Environment', buttonRef: buttonRef, menuList:
                    <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                        <FormControl className={classes.formControl} size="small">
                            <Typography id="textField" className={classes.formLabel}>
                                TM66 Score
                            </Typography>
                            <TextField className={classes.formLabel} name="tm66" type="text"
                                disabled={disabledList.tm66}
                                value={attributeParams.tm66} onChange={onSetAttributeParams} />
                            <Typography className={classes.formLabel} gutterBottom>Environmental Certification</Typography>
                            <RadioGroup aria-label="envCert" name="envCert" id='envCert' value={attributeParams.envCert}
                                row onChange={onSetAttributeParams}>
                                <FormControlLabel
                                    value="any"
                                    control={<Radio color='primary' size='small' />}
                                    label="Any"
                                    disabled={disabledList.envCert}
                                />
                                <FormControlLabel
                                    value="EPD"
                                    control={<Radio color='primary' size='small' />}
                                    label="EPD"
                                    disabled={disabledList.envCert}
                                />
                                <FormControlLabel
                                    value="other"
                                    control={<Radio color='primary' size='small' />}
                                    label="Other"
                                    disabled={disabledList.envCert}
                                />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
            })}
            {PhotometricAttributeMenu({
                props, category: 'Others', buttonRef: buttonRef, menuList:
                    <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                        {selectItem("Brands", "brands", "brands", "brands", attributeParams.brands, onSetAttributeParams,
                            ["any", "afx", "applelecLighting", "brightGreenTechnologyLtd", "canteloLighting", "enigma", "enttec", "heper", "luminoLighting", "optaled", "optelma", "wila"],
                            ["Any", "AFX", "Applelec Lighting", "Bright Green Technology Ltd", "Cantelo Lighting", "Enigma", "ENTTEC", "Heper", "Lumino Lighting", "Optaled", "Optelma.", "WILA"])}
                        <FormControl className={classes.formControl} size="small">
                            <Typography id="textField" className={classes.formLabel}>
                                SKU
                            </Typography>
                            <TextField className={classes.formLabel} name="sku" type="text"
                                disabled={disabledList.sku || props.disabledSku}
                                value={attributeParams.sku} onChange={onSetAttributeParams} />
                        </FormControl>
                        {selectItem("Product Type", "productType", "productType", "productType", attributeParams.productType, onSetAttributeParams,
                            ["any", "Downlight", "Spotlight", "Linear", "Linear continuous", "Pendant", "Wall light", "Uplight", "Track system", "Panel", "Bollard", "Pole", "Floodlight", "Floor lamp", "Desk/table lamp", "Amenity lantern", "Underwater", "Emergency standalone", "Emergency signage", "Other"],
                            ["Any", "Downlight", "Spotlight", "Linear", "Linear continuous", "Pendant", "Wall light", "Uplight", "Track system", "Panel", "Bollard", "Pole", "Floodlight", "Floor lamp", "Desk/table lamp", "Amenity lantern", "Underwater", "Emergency standalone", "Emergency signage", "Other"])}
                    </FormGroup>
            })}
        </>
    );
}
export default PhotometricAttributeSelector; 