import React from 'react';
import { TextField } from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST'
import { API_OBJECTS } from '../../API/model/calculation.model'
import { useDispatch, useSelector } from 'react-redux';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';

const HSWidth = ({ left, index }) => {

    const props = useSelector(state => state);
    const dispatch = useDispatch();
    const [leftOffset, setLeftOffset] = React.useState(props.GridsCfg.RoadGrids[index].LeftOffset);
    const [rightOffset, setRightOffset] = React.useState(props.GridsCfg.RoadGrids[index].RightOffset);

    const handleChange = (event) => {
        const RoadGrids = [...props.GridsCfg.RoadGrids];
        if (left) {
            if (RoadGrids[index]) {
                RoadGrids[index] = {
                    ...RoadGrids[index],
                    LeftOffset: Number.parseFloat(leftOffset)
                }
            }
            else RoadGrids.splice(index, 0, {
                LeftOffset: Number.parseFloat(leftOffset)
            });
            dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));

        }
        else {
            if (RoadGrids[index]) {
                RoadGrids[index] = {
                    ...RoadGrids[index],
                    RightOffset: Number.parseFloat(rightOffset)
                }
            }
            else RoadGrids.splice(index, 0, {
                RightOffset: Number.parseFloat(rightOffset)
            });
            dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
        }
        EdgeOrREST(props.LuminaireCfg.luminaireFilePath, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, "roadGrids", RoadGrids));
    };

    const onCalculateGridResults = (response) => {
        populateGridResults(response.data);
    }

    const populateGridResults = (gridResults) => {
        if (gridResults) {
            dispatch(luminaireActions.UpdateAllResults({
                index: index,
                gridResults
            }));
        }
    }


    let value = left ? leftOffset : rightOffset;

    let label = left ? "Left Offset (m)" : "Right Offset (m)";
    return (
        <TextField
            fullWidth
            id="offset"
            label={label}
            name="offset"
            value={value}
            inputProps={{ step: 0.25 }}
            autoComplete={"off"}
            type="number"
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleChange(event);
                    event.preventDefault();
                    event.target.blur();
                }
            }}
            onBlur={(event) => handleChange(event)}
            onChange={(event) => (left ? setLeftOffset(event.target.value) : setRightOffset(event.target.value))}
            variant="outlined"
            placeholder="0.00"

        />
    );

}

export default HSWidth;