import { apiService } from './axios.client';

export const SelectEndPoints = {
    updateCfg: function (input, callback) {
        apiService.post('/select/updateCfg', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getCfg: function (input, callback) {
        apiService.post('/select/getCfg', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getRoleId: function (input, callback) {
        apiService.get('/select/getRoleId', input)
            .then(callback)
            .catch(error => console.log(error));
    },
}