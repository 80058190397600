import { createTheme , colors } from '@material-ui/core';
import shadows from './shadows';
import { store } from '../store/store';

const updateCustomTheme = () => {
    const state = store.getState().Select;
    const primary = state.primary;
    const tertiary = state.tertiary;
	const textColor = state.textColor

	

	customTheme.palette.primary.main = primary;		//Change theme colours
    customTheme.palette.tertiary.main = tertiary;

	defaultTheme.overrides.MuiSvgIcon.root['&.tour-icon'].color = primary;	//Tour icons (prev/next/inside the description) for both themes
	customTheme.overrides.MuiSvgIcon.root['&.tour-icon'].color = primary;
	
	customTheme.overrides.MuiSvgIcon.root['&.menu-icon'].color = textColor;	//Top bar menu icon

	customTheme.overrides.MuiButton.containedPrimary.color = textColor;	//Contained button text colour
	
	customTheme.overrides.MuiButton.containedPrimary['&:hover'].backgroundColor = tertiary;	//Button hover colour
    
	if (textColor == "white"){
		customTheme.overrides.MuiTab.textColorPrimary["&.Mui-selected"].color = primary;	//Tab text colour
		customTheme.overrides.MuiButton.outlinedPrimary.color = primary;	//Button text colour
		
	} else {
		customTheme.overrides.MuiTab.textColorPrimary["&.Mui-selected"].color = textColor;	//Tab text colour
		customTheme.overrides.MuiButton.outlinedPrimary.color = textColor;	//Button text colour

	}

};

export const defaultTheme = createTheme ({
	palette: {
		background: {
			dark: '#F4F6F8',
			default: colors.common.white,
			paper: colors.common.white
		},
		primary: {
			main: colors.indigo[500]
		},
		secondary: {
			main: colors.green.A700
		},
		tertiary: {
			main: colors.indigo[200]
		},
		text: {
			primary: colors.blueGrey[900],
			secondary: colors.blueGrey[600]
		},
	},
	shadows,
	typography: {
		fontSize : '12px'
	},
	formControl: {
		fontSize : '12px'
	},
	select: {
		fontSize : '12px'
	},
	overrides: {
		MuiSvgIcon: {
			root: {
				"&.tour-icon":{
					color: "#000000"
				}
			}
		},
	}
});

export const customTheme = createTheme ({
	palette: {
		background: {
			dark: '#F4F6F8',
			default: colors.common.white,
			paper: colors.common.white
		},
		primary: {
			main: colors.indigo[500]
		},
		secondary: {
			main: colors.green.A700
		},
		tertiary: {
			main: colors.indigo[200]
		},
		text: {
			primary: colors.blueGrey[900],
			secondary: colors.blueGrey[600]
		}
	},
	shadows,
	typography: {
		fontSize : '12px'
	},
	formControl: {
		fontSize : '12px'
	},
	select: {
		fontSize : '12px'
	},
	overrides: {
		MuiButton: {
			outlinedPrimary: {
				color: "#ff0000",
			},
			containedPrimary: {
				color: "#ffffff",
				'&:hover': {
					backgroundColor: "#808080"
				}
			},
		},
		MuiSvgIcon: {
			root: {
				"&.menu-icon":{
					color: "#000000"
				},
				"&.tour-icon":{
					color: "#000000"
				}
			}
		},
		MuiTab: {
			textColorPrimary: {
				"&.Mui-selected":{
					color: "#000000"
				}
			}
		}
	}
});

const unsubscribe = store.subscribe(() => {
    updateCustomTheme();
});

updateCustomTheme();

export { unsubscribe };
