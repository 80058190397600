import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Grid,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';

const illumTypes = [
	{
		value: 'Horizontal',
	},
	{
		value: 'Vertical',
	},
	{
		value: 'Semi Cyl',
	},
	{
		value: 'Hemi Sph',
	},
	// {
	// 	value: 'Veiling Lum',
	// },
];





const CalcGrid = ({index}) => {
	
	const dropbox = [
		'EN13201:2015 Illuminance',
		'EN13201:2015 Luminance',
		'BS5489:2020 Illuminance',
		'IES RP-8-18',
	]

	const props = useSelector(state => state);
	const dispatch = useDispatch();

	const getGridResults = (props, standardId, illumTypeId) => {
		var postObject = props;
		postObject.GridsCfg.RoadGrids[index].IllumType = illumTypeId;
		postObject.GridsCfg.RoadGrids[index].IllumTypeId = illumTypeId;
		postObject.GridsCfg.RoadGrids[index].Standard = standardId;
		postObject.GridsCfg.RoadGrids[index].CalcGridFile = standardId;
		var gridResults = EdgeOrREST(props.LuminaireCfg.luminaireFilePath, 'CALC_GRID', onCalculateGridResults, {
			postObject: API_OBJECTS.getCalculationObject(postObject, null, null),
			props: props
		});
		populateGridResults(gridResults, props);
	};

	const onStandardChange = (event, props) => {
		var standardId = event.target.value;
		if (standardId.includes("Illuminance")) var illumTypeId = "Horizontal";
		else illumTypeId = "Luminance";
		var Standard = EdgeOrREST(props.LuminaireCfg.luminaireFilePath, 'SET_ROAD_CONFIG', onGetStandardsCfg, {
			postObject: { standardId, illumTypeId },
			props: props
		});
		getGridResults(props, standardId, illumTypeId);
	}

	const onIllumTypeChange = (event, props) => {
		var illumTypeId = event.target.value;
		var standardId = props.ProjectConfig.StandardsCfg.CalcGridFile
		EdgeOrREST(illumTypeId, 'SET_ILLUM_STANDARD', onGetIllumType, {
			postObject: { standardId, illumTypeId },
			props: props
		});
		getGridResults(props, standardId, illumTypeId);
	}

	const onGetIllumType = (response, props) => {
		const RoadGrids = [...props.GridsCfg.RoadGrids];
		if (RoadGrids[index]) RoadGrids[index] = {
			...RoadGrids[index],
			CalcGridClassValue: response.data.standard.CalcGridClassValue,
			CurrentStandards: response.data.standard.CurrentStandards,
			DefaultStandards: response.data.standard.DefaultStandards,
			IllumType: response.data.standard.IllumTypeId
		};
		else RoadGrids.splice(index, 0, {
			CalcGridClassValue: response.data.standard.CalcGridClassValue,
			CurrentStandards: response.data.standard.CurrentStandards,
			DefaultStandards: response.data.standard.DefaultStandards,
			IllumType: response.data.standard.IllumTypeId
		});
		dispatch(roadActions.UpdateStandardsCfg({
			...props.ProjectConfig.StandardsCfg,
			CalcGridClassValue: response.data.standard.CalcGridClassValue,
			CalcGridClass: response.data.standard.CalcGridClass,
			CurrentStandards: response.data.standard.CurrentStandards,
			DefaultStandards: response.data.standard.DefaultStandards,
			IllumTypeId: response.data.standard.IllumTypeId
		}));
		dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
	}

	const setupIllumTypes = (props) => {
		if (props.GridsCfg.RoadGrids[index].CalcGridFile.includes("Illuminance")) return illumTypes;
		else if (props.GridsCfg.RoadGrids[index].CalcGridFile.includes("RP-8")) return [{ value: 'Luminance' }, { value: 'Illuminance' }];
		else return [{ value: 'Luminance' }];
	}

	const onCalculateGridResults = (response, props) => {
		populateGridResults(response.data, props);
	}

	const populateGridResults = (gridResults, props) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				gridResults
			}));
		}
	}

	const onGetStandardsCfg = (response, props) => {
		updateStandardsCfg(response.data.standard, props);
	}

	const updateStandardsCfg = (StandardsCfg, props) => {
		if (StandardsCfg) {
			const RoadGrids = [...props.GridsCfg.RoadGrids];
			if (RoadGrids[index]) {
				RoadGrids[index] = {
					...RoadGrids[index],
					CalcGridClassValue: StandardsCfg.CalcGridClassValue,
					IllumType: StandardsCfg.IllumTypeId,
					IllumTypeId: StandardsCfg.IllumTypeId,
					CalcGridFile: StandardsCfg.CalcGridFile,
					CurrentStandards: StandardsCfg.CurrentStandards,
					DefaultStandards: StandardsCfg.DefaultStandards,
					CalcGridClass: StandardsCfg.CalcGridClass,
				}
			}
			else RoadGrids.splice(index, 0, {
				CalcGridClassValue: StandardsCfg.CalcGridClassValue,
				IllumType: StandardsCfg.IllumTypeId,
				IllumTypeId: StandardsCfg.IllumTypeId,
				CalcGridFile: StandardsCfg.CalcGridFile,
				CurrentStandards: StandardsCfg.CurrentStandards,
				DefaultStandards: StandardsCfg.DefaultStandards,
				CalcGridClass: StandardsCfg.CalcGridClass,
			});
			StandardsCfg.Standards = dropbox;
			dispatch(roadActions.UpdateStandardsCfg(StandardsCfg));
			dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
		}
	}

	const handleGridClass = (event, props) => {
		var gridClassId = event.target.value;
		EdgeOrREST(gridClassId, 'SET_CALC_GRID_CLASS', onGetGridClass, {
			postObject: {
				gridClassId,
				standardId: props.GridsCfg.RoadGrids[index].Standard,
				illumTypeId: props.GridsCfg.RoadGrids[index].IllumType
			},
			props: props
		});
	}

	const onGetGridClass = (response, props) => {
		if (response) {
			response = response.data.standard;
			const RoadGrids = [...props.GridsCfg.RoadGrids];
			if (RoadGrids[index]) {
				RoadGrids[index] = {
					...RoadGrids[index],
					CalcGridClassValue: response.CalcGridClassValue,
					CurrentStandards: response.CurrentStandards,
					DefaultStandards: response.DefaultStandards,
				}
			}
			else RoadGrids.splice(index, 0, {
				CalcGridClassValue: response.CalcGridClassValue,
				CurrentStandards: response.CurrentStandards,
				DefaultStandards: response.DefaultStandards,
			});
			dispatch(roadActions.UpdateStandardsCfg({
				...props.ProjectConfig.StandardsCfg,
				CalcGridClassValue: response.CalcGridClassValue,
				CurrentStandards: response.CurrentStandards,
				DefaultStandards: response.DefaultStandards,
			}));
			dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
		}
	}
	return (
		<Grid container spacing={2} >
			<Grid item md={6} sm={6} xs={12}>
			<TextField
				fullWidth
				label="Grid Class"
				name="calcGridClass"
				onChange={(e) => onStandardChange(e, props)}
				select
				SelectProps={{ native: true }}
				value={props.GridsCfg.RoadGrids[index].CalcGridFile}
				variant="outlined"
				>
					{dropbox.map((option) => (
						<option
							key={option}
						>
							{option}
						</option>
					))}
				</TextField>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<TextField
					fullWidth
					label="Grid Class"
					name="calcGridClass"
					onChange={(e) => handleGridClass(e, props)}
					select
					SelectProps={{ native: true }}
					value={props.GridsCfg.RoadGrids[index].CalcGridClassValue}
					variant="outlined"
				>
					{props.GridsCfg.RoadGrids[index].CalcGridClass.map((option) => (
						<option
							key={option}
						>
							{option}
						</option>
					))}
				</TextField>
			</Grid>
			<Grid item md={12} sm={12} xs={12} >
				<TextField
					fullWidth
					label="Calculation Type"
					name="illumTypes"
					onChange={(e) => onIllumTypeChange(e, props)}
					select
					SelectProps={{ native: true }}
					value={props.GridsCfg.RoadGrids[props.GridsCfg.currentIndex].IllumTypeId}
					variant="outlined"
				>
					{setupIllumTypes(props).map((option) => (
						<option
							key={option.value}
							value={option.value}
						>
							{option.value}
						</option>
					))}
				</TextField>

			</Grid>
		</Grid>
	);
};

export default CalcGrid;