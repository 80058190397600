import React, { useState, useCallback, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Notification from '../components/shared/notification.component';
import {
	makeStyles,
	Tooltip,
	Paper,
	TextField,
	MenuItem,
	Button
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';

const useTreeItemStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.secondary,
		'&:hover > $content': {
			backgroundColor: theme.palette.action.hover,
		},
		'&:focus > $content, &$selected > $content': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
			color: 'var(--tree-view-color)',
		},
		'&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
			backgroundColor: 'transparent',
		},
	},
	content: {
		color: theme.palette.text.secondary,
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		'$expanded > &': {
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	group: {
		marginLeft: 0,
		'& $content': {
			paddingLeft: theme.spacing(2),
		},
	},
	expanded: {},
	selected: {},
	label: {
		fontWeight: 'inherit',
		color: 'inherit',
		marginRight: theme.spacing(1),
		fontSize: '20px'
	},
	labelRoot: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0.5, 0),
	},
	labelIcon: {
		marginRight: theme.spacing(1),
	},
	labelText: {
		fontWeight: 'inherit',
		flexGrow: 1,
	},
	dialogPaper: {
		minWidth: '550px',
	}
}));

const useStyles = makeStyles((theme) => ({
	root: {
		height: '70vh',
		flexGrow: 1,
		width: '100%',
		overflowY: 'auto'
	},
	dialogPaper: {
		minWidth: '550px',
	},
	labelText: {
		fontWeight: 'inherit',
		flexGrow: 1,
	},
}));

function Group(props) {
	const classes = useTreeItemStyles();
	const { labelText, labelIcon: LabelIcon, labelInfo: LabelInfo, removeLuminaire: RemoveInfo, editGroupAttributes: EditGroupAttributes, color, bgColor, group, onUpdate, parentGroupId, ...other } = props;
	return (
		<>
			<TreeItem
				key={props.key}
				onClick={other.handleClick}
				label={
					<div className={classes.labelRoot}>
						<Tooltip title={props?.description}>
							<LabelIcon color="inherit" className={classes.labelIcon} />
						</Tooltip>
						<Typography variant="body2" className={classes.labelText}>
							{labelText}
						</Typography>
					</div>
				}
				style={{
					'--tree-view-color': color,
					'--tree-view-bg-color': bgColor,
				}}
				classes={{
					root: classes.root,
					content: classes.content,
					expanded: classes.expanded,
					selected: classes.selected,
					group: classes.group,
					label: classes.label,
				}}
				{...other}
			>
			</TreeItem>
		</>

	);
}

//good
// function Luminaire(props) {
// 	const classes = useTreeItemStyles();
// 	const { labelText, labelIcon: LabelIcon, removeLuminaire: RemoveInfo, color, bgColor, luminaireId, ...other } = props;
// 	return (
// 		<>
// 			<TreeItem
// 				key={props.key}
// 				onClick={other.handleClick}
// 				label={
// 					<div className={classes.labelRoot} style={(props.luminaireId == props.selectedLuminaire) ? { background: 'lightgreen' } : {}} >
// 						<LabelIcon color="inherit" className={classes.labelIcon} />
// 						<Typography variant="body2" className={classes.labelText}>
// 							{labelText}
// 						</Typography>
// 					</div>
// 				}
// 				style={{
// 					'--tree-view-color': color,
// 					'--tree-view-bg-color': bgColor,
// 				}}
// 				classes={{
// 					root: classes.root,
// 					content: classes.content,
// 					expanded: classes.expanded,
// 					selected: classes.selected,
// 					group: classes.group,
// 					label: classes.label,
// 				}}
// 				{...other}
// 			/>
// 		</>

// 	);
// }

export default function PhotometricSubscribeGroupsViewer(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const state = useSelector(state => state);
	const [expanded, setExpanded] = React.useState([]);
	const [gotChildren, setGotChildren] = React.useState([]);
	const [selectedGroup, setSelectedGroup] = React.useState(null);
	const [lowestGroups, setLowestGroups] = React.useState([]);
	const [sort, setSort] = React.useState(state.user.sortBy);
	const sortBy = [
		{ value: 0, label: 'A-Z' },
		{ value: 1, label: 'Z-A' },
		{ value: 2, label: 'Created ↑' },
		{ value: 3, label: 'Created ↓' }
	];
	const [snack, setSnack] = React.useState({
		open: false
	});

	const getAllLowestChildrenFolders = () => {
		EdgeOrREST(null, 'GET_ALL_LOWEST_CHILDREN_FOLDER', getAllLowestChildrenFolder, props);
	}

	const getAllLowestChildrenFolder = (response) => {
		if (response) {
			setLowestGroups(response.data);
		}
	};

	const onChangeGroup = (response) => {
		console.log("onChangeGroup", response);
		props.forceUpdate();
	};

	function checkForAdminPriv() {
		var isAdmin = false;
		var checkbox = [];
		if (state.user.roles) {
			for (var i = 0; i < state.user.roles.length; i++) {
				if (state.user.roles[i].name === "admin") {
					isAdmin = true;
				}
			}
		}
		return checkbox;
	}

	const handleNodeToggle = async (event, nodeIds) => {
		const expandedNodes = nodeIds.filter((nodeId) => !expanded.includes(nodeId));
		if (expandedNodes.length > 0) {
			handleExpand(expandedNodes[0]);
		}
		setExpanded(nodeIds);
	};

	const handleExpand = (nodeId) => {
		if (!gotChildren.includes(nodeId)) {
			EdgeOrREST(null, 'GET_TREE_CHILDREN', onGetChildren, { headers: { 'id': nodeId } });
			setGotChildren([...gotChildren, nodeId]);
		}
	};

	function resetGotChildren() {
		setExpanded([]);
		setGotChildren([]);
	}

	useEffect(() => {
		//props.forceUpdate();
		resetGotChildren();
		getAllLowestChildrenFolders();
	}, [])

	const onGetChildren = (response) => {
		var wholeGroup = state?.user?.groups;
		if (response.data != null && response.data.length > 0 && wholeGroup) {
			if (response.data[0].luminaireId == 0) {
				//groups
				dispatch(user.UpdateUserCfg({ groups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'groups') }));
			} else {
				//luminaires
				dispatch(user.UpdateUserCfg({ groups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'luminaires') }));
			}
		}
	};

	const updateGroup = (parentId, wholeGroup, updatedGroup, type) => {
		const update = groups => {
			return groups.map(group => {
				if (group.id === parentId) {
					group[type] = updatedGroup;
					return group;
				} else if (group.groups) {
					return {
						...group,
						groups: update(group.groups)
					};
				} else {
					return group;
				}
			});
		};
		return update(wholeGroup);
	};

	const handleSortChange = (value) => {
		setSort(value);
		state.user.sortBy = value;
		dispatch(user.UpdateUserCfg(state.user));
	}

	const copyGroup = (event) => {
		if (lowestGroups.some((lowestGroups) => lowestGroups.id === selectedGroup.id)) {
			console.log("selectedGroupToCopy", props?.selectedGroupToCopy);
			EdgeOrREST(null, 'COPY_GROUP', onCopyGroup, { headers: { 'treeId': props?.selectedGroupToCopy?.id, 'selectedTreeId': selectedGroup.id } });
		} else {
			setSnack({
				open: true,
				message: "Selected folder contain luminaire or not lowest folder",
				severity: 'error'
			});
		}
	}

	const onCopyGroup = (response) => {
		if (response) {
			props.forceUpdate();
			setSnack({
				open: true,
				message: "Copy Group Success",
				severity: 'success'
			});
			props.close();
		}
	}

	const handleClick = (item, event) => {
		if (item != null) setSelectedGroup(item);
	}

	useEffect(() => {
		console.log("selectedGroup Changed", selectedGroup);
	}, [selectedGroup])

	const handleClose = () => {
		setSnack({
			...snack,
			open: false
		});
	};

	const sortedGroups = (groups) => {
		switch (sort) {
			case 0:
				// Code for sorting alphabetically (A-Z)
				return groups?.slice().sort((a, b) => {
					return a.name.localeCompare(b.name);
				}).map((item) => {
					return (
						renderTree(item)
					);
				});
			case 1:
				// Code for sorting in reverse alphabetical order (Z-A)
				return groups?.slice().sort((a, b) => {
					return b.name.localeCompare(a.name);
				}).map((item) => {
					return (
						renderTree(item)
					);
				});

			case 2:
				// Code for sorting by creation date in ascending order (Created ↑)
				return groups?.slice().map((item) => {
					return (
						renderTree(item)
					);
				});
			case 3:
				// Code for sorting by creation date in descending order (Created ↓)
				return groups?.slice().reverse().map((item) => {
					return (
						renderTree(item)
					);
				});
			default:
				console.error('Invalid option');
				break;
		}
	}

	function renderTree(item) {
		return (
			<Paper className={classes.paper}>
				<Group forceUpdate={onChangeGroup} name={item?.name} description={item?.description} type="group" nodeId={item.id} labelText={item.name /*+ " - ID: " + item.id*/} labelIcon={FolderIcon}
					color="#1a73e8" bgColor="#e8f0fe" labelInfo={DeleteIcon} removeLuminaire={RemoveIcon} editGroupAttributes={EditIcon} parentGroupId={item.parentId}
					resetGotChildren={resetGotChildren} handleClick={() => handleClick(item)}
				>
					{checkForAdminPriv()}
					<TreeView
						defaultCollapseIcon={lowestGroups.some(group => group.id === item.id) ? <ArrowDropDownIcon /> : null}
						defaultExpandIcon={lowestGroups.some(group => group.id === item.id) ? <ArrowRightIcon /> : null}
						defaultEndIcon={<div style={{ width: 24 }} />}
						expanded={expanded}
						onNodeToggle={handleNodeToggle}
						style={{ paddingLeft: '10px' }}
					>

						{sortedGroups(item?.groups)}

						{/* {item?.luminaires?.map((luminaire) => {
							return (
								<Luminaire parentId={luminaire.parentId} treeId={luminaire.id} luminaireId={luminaire.luminaireId} key={luminaire.id}
									selectedLuminaire={props.selectedLuminaires ? props.selectedLuminaires[0] : 0} color="#1a73e8" bgColor="#e8f0fe" labelText={luminaire.name}
									labelIcon={DescriptionIcon} forceUpdate={onChangeGroup} resetGotChildren={resetGotChildren} handleClick={() => props.handleClick(luminaire, false)} />
							);
						})} */}

					</TreeView>
				</Group>
			</Paper>
		);
	}

	return (
		<>
			{checkForAdminPriv()}
			<div style={{ display: 'flex', justifyContent: 'center', width: '500px' }}>
				<TextField
					value={sort}
					onChange={(e) => handleSortChange(e.target.value)}
					select
					label="Sort By"
					variant="outlined"
					style={{ width: 120, marginLeft: 10, alignSelf: 'flex-end' }}
				>
					{sortBy.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</div>
			<div style={{ paddingLeft: '10px', paddingRight: '10px', overflowY: 'auto', maxHeight: '650px' }}>
				<TreeView
					className={classes.root}
					defaultCollapseIcon={<ArrowDropDownIcon />}
					defaultExpandIcon={<ArrowRightIcon />}
					defaultEndIcon={<div style={{ width: 24 }} />}
					expanded={expanded}
					onNodeToggle={handleNodeToggle}
				>
					{sortedGroups(state?.user?.groups)}
				</TreeView>
			</div>
			<div style={{ textAlign: "right" }}>
				<Button variant="contained" color="primary" onClick={copyGroup}>
					Copy Group
				</Button>
			</div>
			<Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
		</>
	);
}
