import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Button,
  Grid,
  Checkbox,
  Paper,
  makeStyles,
  Typography,
  CardContent,
  TextField,
  FormControl,
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import Notification from '../components/shared/notification.component';
import { useSelector, useDispatch } from 'react-redux';
import user from '../store/actions/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FolderIcon from '@material-ui/icons/Folder';
import PhotometricUpload from './photometric-upload.component';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 135,
    float: 'right'
  },
  root: {
  },
  paper: {
    minWidth: '12vw',
    height: '55vh',
    overflow: 'auto',
  },
  upload: {
    minHeight: '500px',
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  topRight: { vertical: 'top', horizontal: 'right' }
}));


function not(a, b) {
  if (a && b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
}

function intersection(a, b) {
  if (a && b) return a.filter((value) => b.indexOf(value) !== -1);
}

export default function PhotometricTransferList(props) {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [left, setLeft] = React.useState(state.user?.searchedLuminaires);
  const [groups, setGroups] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, groups);
  const [leftFilter, setLeftFilter] = React.useState("");
  const [rightFilter, setRightFilter] = React.useState("");
  const onLumFilterChange = (e) => {
    if (e.nativeEvent.inputType == "insertText") {
      var currText = leftFilter;
      setLeftFilter(currText += e.nativeEvent.data);
    } else if (e.nativeEvent.inputType == "deleteContentBackward") {
      var currText = leftFilter;
      setLeftFilter(currText.slice(0, -1));
    } else if (e.nativeEvent.inputType == "deleteWordBackward") {
      var currText = leftFilter;
      setLeftFilter("");
    }
    console.log("inputType insertText", leftFilter);
  };

  const doSelectAllToggle = (e) => {
    console.log("toggle", e);
    if (e.target.checked) {
      console.log("leftChecked", leftChecked)
      console.log("left", left);
      setSelectAll(true);
      if (left) {
        if (leftFilter && leftFilter.length && left) {
          setSelectAll(true);
          setChecked(left.filter(item =>
            (item.lumcat ? item.lumcat.toLowerCase() : item.description?.toLowerCase())
              .includes(leftFilter.toLowerCase())));
        } else if (left) {
          setSelectAll(true);
          setChecked(left);
        }

      }
    } else {
      setSelectAll(false);
      setChecked([]);
    }
  }

  const onGroupFilterChange = (e) => {
    if (e.nativeEvent.inputType == "insertText") {
      var currText = rightFilter;
      setRightFilter(currText += e.nativeEvent.data);
    } else if (e.nativeEvent.inputType == "deleteContentBackward") {
      var currText = rightFilter;
      setRightFilter(currText.slice(0, -1));
    } else if (e.nativeEvent.inputType == "deleteWordBackward") {
      var currText = rightFilter;
      setRightFilter("");
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (value.luminaireId == 0) {
      const groupIndex = newChecked.findIndex((val) => val.luminaireId == 0);
      if (groupIndex !== -1) {
        newChecked.splice(groupIndex, 1);
      }
    }

    console.log("currentIndex", currentIndex);
    if (currentIndex === -1) {
      newChecked.push(value);
      console.log("push value", value);
    } else {
      newChecked.splice(currentIndex, 1);
      console.log("splice");
    }

    console.log("newChecked", newChecked);
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    // let folderIds = [];
    // for (let i = 0; i < rightChecked.length; i++) {
    //   folderIds.push(rightChecked[i].id);
    // }

    for (let i = 0; i < leftChecked.length; i++) {
      leftChecked[i].name = leftChecked[i].description;
    }

    console.log("leftChecked", leftChecked);
    console.log("rightChecked[0].id", rightChecked[0].id);
    EdgeOrREST(null, 'ADD_LUMINAIRES_TO_FOLDERS', onAddLuminaires, { body: { 'luminaireIds': leftChecked, 'folderId': rightChecked[0].id }, props: props });
    setChecked(not(checked, rightChecked));
  };

  const onAddLuminaires = (response, props) => {
    if (response.status == 200) {
      setSnack({
        open: true,
        message: leftChecked.length + ' Luminaire(s) have been added to ' + rightChecked.length + ' Group(s)',
        severity: 'success'
      });

      dispatch(user.UpdateUserCfg({ updatePhotometricManager: true }))
    } else {
      setSnack({
        open: true,
        message: response.status + " " + response.message,
        severity: 'error'
      });
    }
  };

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const customList = (items, header, filter) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" style={{ background: 'white' }}>
            {header}
            {header === "Luminaires" ?
              <Checkbox
                checked={selectAll}
                onChange={doSelectAllToggle}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 0 }}
                color="primary" />
              : <></>
            }
          </ListSubheader>
        }
      >
        {items?.filter(item => (item.name ? item.name.toLowerCase() : item.lumcat ? item.lumcat.toLowerCase() : item.description.toLowerCase()
        ).includes(filter.toLowerCase())).map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItem key={value.name} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={value.id} primary={value.name ? value.name : value.lumcat ? value.lumcat : value.description} />
            </ListItem>
          );
        })
        }
        <ListItem />
      </List>
    </Paper>
  );

  const getAllLowestChildrenFolders = () => {
    EdgeOrREST(null, 'GET_ALL_LOWEST_CHILDREN_FOLDER', getAllLowestChildrenFolder, props);
  }
  const getAllLowestChildrenFolder = (response) => {
    if (response) {
      setGroups(response.data);
    }
  };
  useEffect(() => {
    getAllLowestChildrenFolders();
  }, []);

  return (
    <div>
      {/* <Button
        aria-controls={uploadOpen ? 'menu-list-grow' : undefined}
        color="primary"
        variant={uploadOpen ? 'contained' : 'outlined'}
        aria-haspopup="true"
        onClick={handleUploadClick}
        style={{ width: '120px', justifyContent: 'center', margin: '15px' }}
      >
        Upload
      </Button> */}

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Select Luminaires and add them to Group using the arrow.
        </Typography>
        <Grid container spacing={2} justify="space-evenly" alignItems="center" className={classes.root}>
          <Grid item sm={5}>
            <TextField onChange={onLumFilterChange} value={leftFilter}
              label={<Typography >Luminaire Filter</Typography>} id="outlined-size-small-lum" defaultValue="..." size="small"
            />
          </Grid>
          <Grid item sm={2}>
            <div></div>
          </Grid>
          <Grid item sm={5}>
            <TextField onChange={onGroupFilterChange} value={rightFilter} label={<Typography >Group Filter</Typography>} id="outlined-size-small-group"
              defaultValue="..." size="small"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="space-evenly" alignItems="center" className={classes.root}>
          <Grid item sm={5} style={{ 'padding': '0px' }}>{customList(left ? left : [], "Luminaires", leftFilter)}</Grid>
          <Grid item sm={2}>
            <Grid container direction="column" justify="space-evenly" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={!leftChecked || leftChecked.length === 0 || !rightChecked || rightChecked.length === 0}
                aria-label="move selected right"
              >
                <i className={"fa fa-arrow-right"}
                  style={{ fontSize: '14pt' }}></i>
              </Button>
            </Grid>
          </Grid>
          <Grid>
          </Grid>
          <Grid item sm={5} style={{ 'padding': '0px' }}>{customList(groups ? groups : [], "Groups", rightFilter)}</Grid>
          <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Grid>
      </CardContent>
    </div>
  );
}
