import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

const TemplateImport = ({ isOpen, onClose, onConfirm, onChangeFile }) => {
  const handleClose = () => {
    onClose();
  };
  const [snack, setSnack] = useState({
		open: false
	});

  const handleConfirm = () => {
    //onSelectTemplate(selectedTemplate);
    onConfirm();
  };

  // const handleConfigurationFileReader = (event) => {
  // 	if (event.target) {
  // 		let reader = new FileReader();
  // 		reader.readAsDataURL(event.target.files[0]);
  // 		reader.onload = (e) => {
  // 			onChangeFile({ data: reader.result.split(',').pop(), fileName: event.target.files[0].name })
  // 		};
  // 	}
  // }

  const handleConfigurationFileReader = (event) => {
    const file = event.target?.files?.[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    if (file.type !== "application/pdf") {
      setSnack({
				open: true,
				message: "Selected file is not a PDF",
				severity: 'error'
			});
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result.split(',').pop();
      onChangeFile({ data: base64Data, fileName: file.name });
    };

    reader.onerror = (error) => {
      setSnack({
				open: true,
				message: "Error reading file:",
				severity: 'error'
			});
    };

    reader.readAsDataURL(file);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Template</DialogTitle>
      <DialogContent>
        <input
          onChange={handleConfigurationFileReader}
          type="file"
          accept=".pdf"
        />
        <label htmlFor="raised-button-file">
          <Button variant="raised" component="span">
            Upload
          </Button>
        </label>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateImport;
