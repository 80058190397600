import React from 'react'
import { Grid, TextField, FormControlLabel, FormGroup, Switch, Typography } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gridActions from '../../store/actions/gridActions'
import luminaireActions from '../../store/actions/luminaireActions'
import EdgeOrREST from '../../EdgeOrREST'
import { API_OBJECTS } from '../../API/model/calculation.model'

const Obstruction = ({index}) => {
    const dispatch = useDispatch();
    const props = useSelector(state => state);
    const RoadGrid = useSelector(state => state.GridsCfg.RoadGrids[index]);
    
    const [state, setState] = useState({
        startHeight: RoadGrid.startHeight,
        endHeight: RoadGrid.endHeight,
        offset: RoadGrid.offset,
        right: RoadGrid.right
    }); 

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState({ ...state, [e.target.name]: value });
        var postObject = props;
        postObject.GridsCfg.RoadGrids[index][e.target.name] = value;
        EdgeOrREST(null, "CALC_GRID", onCalculateGridResults, API_OBJECTS.getCalculationObject(postObject, null, null));
    }

    const handleBlur = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        dispatch(gridActions.UpdateCurrentGrid({[e.target.name]: value}));
    }

    const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				index: index,
				gridResults
			}));
		}
	}

    return (    
        <Grid container spacing={2}>
            <Grid item md={3} sm={12} xs={12}>
                <TextField
                    fullWidth
                    id="startHeight"
                    label="Start Height"
                    name="startHeight"
                    value={state.startHeight}
                    inputProps={{ step: 0.25 }}
                    autoComplete={"off"}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="0.00"
                />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <TextField
                    fullWidth
                    id="endHeight"
                    label="End Height"
                    name="endHeight"
                    value={state.endHeight}
                    inputProps={{ step: 0.25 }}
                    autoComplete={"off"}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="0.00"
                />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
            <TextField
                    fullWidth
                    id="offset"
                    label="Offset"
                    name="offset"
                    value={state.offset}
                    inputProps={{ step: 0.25 }}
                    autoComplete={"off"}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="0.00"
                />
            </Grid>
            {/* <Grid item md={3} sm={12} xs={12}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant="h6">Grid Side</Typography>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={3}>
                            <Typography variant="body1" align="right">Left</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch checked={state.right} color="default" onChange={handleChange} onBlur={handleBlur} name="right" />
                                }
                                style={{ marginLeft: '3px' }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" align="left">Right</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>        */}
        </Grid>
    ) 
}

export default Obstruction;