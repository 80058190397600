import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import gridActions from '../../store/actions/gridActions';
import {
	TextField,
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';

const WetRoadSurface = ({getGridResults}) => {

    const props = useSelector(state => state);
    const ProjectConfig = useSelector(state => state.ProjectConfig);
    const GridsCfg = useSelector(state => state.GridsCfg);
    const [value, setValue] = useState(GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoadSurface);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setValue(e.target.value);
        EdgeOrREST(null, 'SET_ROADSURFACE', onGetQ0, {
            postObject: { roadSurfaceId: e.target.value }
        });
        dispatch(gridActions.UpdateCurrentGrid({WetRoadSurface: e.target.value}));
        var postObject = props;
        postObject.GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoadSurface = e.target.value;
        getGridResults(postObject, null, null);
    }

    const onGetQ0 = (response) => {
        if (response) {
            dispatch(gridActions.UpdateCurrentGrid({WetQ0: response.data.roadCfg.DefaultQ0}));
            var postObject = props;
            postObject.GridsCfg.RoadGrids[GridsCfg.currentIndex].WetQ0 = response.data.roadCfg.DefaultQ0;
            getGridResults(postObject, "wetRoadSurface", response.data.roadSurfaceId);
        }
    }

    return (
        <TextField
            fullWidth
            label="Wet Surface"
            name="roadSurface"
            disabled={!GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoad}
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={value}
            variant="outlined"
        >
            {ProjectConfig?.RoadCfg?.WetSurfaceArray?.map((option) => (
                <option key={option}>
                    {option}
                </option>
            ))}
        </TextField>
    )
}

export default WetRoadSurface;