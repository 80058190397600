import React, { useEffect, useContext } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Page from '../components/Page';
import { Outlet } from 'react-router-dom';
import {
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    Tabs,
    Tab,
    Typography,
    Box,
    makeStyles,
    Button
} from '@material-ui/core';
import PhotometricTable from './photometric-table.component';
import PhotometricTree from './photometric-tree.component';
import SubscribedTree from './photometric-subscribedTree.component';
import PhotometricAttributeEditor from './photometric-attribute-editor.component';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import { AuthContext } from '../auth-provider.component';
import PhotometricInformation from './photometric-information.component';
import SubscribeManu from './photometric-subscribe.component';
import PhotometricSearch from './photometric-search.component';
import PhotometricTransferList from './photometric-transfer-list.component';
import PhotometricStreetlightSearch from './photometric-streetlight-search.component';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));

export default function PhotometricDashboard(props) {
    const dispatch = useDispatch();
    const { userId } = useContext(AuthContext);
    const state = useSelector(state => state);
    const [value, setValue] = React.useState(2);
    const [groups, setGroups] = React.useState([]);
    const [subscribedGroups, setSubscribedGroups] = React.useState([]);
    const [leftTabIndex, setLeftTabs] = React.useState(0);
    const [searchTabIndex, setSearchTabIndex] = React.useState(0);
    const [midTabIndex, setMidTabs] = React.useState(0);
    const [rightTabIndex, setRightTabs] = React.useState(0);
    const [searchLuminiaires, setLuminaires] = React.useState([]);

    function forceUpdate() {
        console.log("force update called");
        EdgeOrREST(null, 'GET_USER_ROOT_FOLDERS', onGetRootFolders, props);
        setGroups(state?.user?.groups);
        EdgeOrREST(null, 'GET_SUBSCRIBED_ROOT_FOLDERS', onGetSubscribedRootFolders, props);
        //EdgeOrREST(null, 'GET_SUBSCRIBED_ROOT_FOLDERS', onGetSubscribedRootFolders, props);
        //setGroups(state?.user?.groups);

        // EdgeOrREST(null, 'GET_MANUFACTURER_NAMES', onGetManufacturers, props);
        return () => setValue(value => value + 1); // update the state to force render
    }

    function getLuminaires(data) {
        console.log("in dashboard getluminaires");
        setLuminaires(data);
    }

    function handleSubscribedListupdated() {
        forceUpdate();
    }

    const onGetSubscribedRootFolders = (response) => {
        if (response && response.data) {
            console.log("onGetSubscribedRootFolders", response.data);
            setSubscribedGroups(response.data);
            dispatch(user.UpdateUserCfg({ subscribedGroups: response.data }))
        }
    };

    const onGetRootFolders = (response) => {
        if (response && response.data) {
            setGroups(response.data);
            dispatch(user.UpdateUserCfg({ groups: response.data }))
        }
    };

    // const onGetManufacturers = (response) => {
    //     if (response && response.data) {
    //         dispatch(user.UpdateUserCfg({ manufacturerNames: response.data }))
    //     }
    // };

    const onGetRoles = (response) => {
        if (response.data) {
            dispatch(user.UpdateUserCfg({ roles: response.data }))
        }
    };

    useEffect(() => {
        let isMounted = true;
        EdgeOrREST(null, 'GET_USER_ROOT_FOLDERS', onGetRootFolders, props);
        EdgeOrREST(null, 'GET_SUBSCRIBED_ROOT_FOLDERS', onGetSubscribedRootFolders, props);
        // EdgeOrREST(null, 'GET_MANUFACTURER_NAMES', onGetManufacturers, props);
        EdgeOrREST(null, 'GET_ROLES', onGetRoles, { headers: { 'id': userId }, props: props });
        return () => { isMounted = false };
    }, []);

    const classes = useStyles();

    function checkIfUpdateRequired() {
        if (state.user.updatePhotometricManager) {
            console.log("update of dashboard logged");
            dispatch(user.UpdateUserCfg({ updatePhotometricManager: false }))
            forceUpdate();
            return (<></>);
        }
    }

    const handleLeftTabs = (event, value) => {
        setLeftTabs(value);
    };
    const handleSearchTab = (event, value) => {
        setSearchTabIndex(value);
    };
    const handleMidTabs = (event, value) => {
        setMidTabs(value);
    };
    const handleRightTabs = (event, value) => {
        setRightTabs(value);
    };

    return (
        <Page className={classes.root} title="Photometric Manager" style={{ 'minHeight': '85vh' }}>
            {checkIfUpdateRequired()}
            <Grid className='manageStep9' container spacing={2} style={{ 'minHeight': 'inherit' }}>
                {/* First row */}
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    {/* {<Outlet></Outlet>} */}
                    {/* <Outlet luminaireCallback={getLuminaires}></Outlet>  */}
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={leftTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleLeftTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="search" {...a11yProps(0)} value={0} />
                                <Tab index={1} name="information" label="information"  {...a11yProps(1)} value={1} />
                                <Tab index={2} name="Subscribe" label="Subscribe"  {...a11yProps(1)} value={2} />
                            </Tabs>
                        </Paper>
                        <TabPanel value={leftTabIndex} index={0}>
                            <CardContent>
                                <Paper square>
                                    <Tabs
                                        value={searchTabIndex}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        onChange={handleSearchTab}
                                        aria-label="disabled tabs example"
                                    >
                                        <Tab index={0} name="lighting" label="street lighting"  {...a11yProps(0)} value={0} />
                                        <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
                                    </Tabs>
                                </Paper>
                                <TabPanel value={searchTabIndex} index={0}>
                                    <PhotometricStreetlightSearch />
                                </TabPanel>
                                <TabPanel value={searchTabIndex} index={1}>
                                    <PhotometricSearch />
                                </TabPanel>
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={leftTabIndex} index={1}>
                            <CardContent>
                                <PhotometricInformation />
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={leftTabIndex} index={2}>
                            <CardContent>
                                <SubscribeManu handleSubscribedListupdated={handleSubscribedListupdated}/>
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
                {/* Second row */}
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={midTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleMidTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="result" {...a11yProps(0)} value={0} />
                                {/* <Tab index={1} label="Update"  {...a11yProps(1)} value={1} /> */}
                                <Tab index={2} label="Transfer"  {...a11yProps(1)} value={2} />
                            </Tabs>
                        </Paper>
                        <Divider />
                        <TabPanel value={midTabIndex} index={0}>
                            <CardContent>
                                <PhotometricTable data={state.user.searchedLuminaires}></PhotometricTable>
                            </CardContent>
                        </TabPanel>
                        {/* <TabPanel value={midTabIndex} index={1}>
                            <CardContent>
                                <PhotometricAttributeEditor groups={groups}></PhotometricAttributeEditor>
                            </CardContent>
                        </TabPanel> */}
                        <TabPanel value={midTabIndex} index={2}>
                            <CardContent>
                                <PhotometricTransferList forceUpdate={forceUpdate} />
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
                {/* Third row */}
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={rightTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleRightTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="Photometric Tree" {...a11yProps(0)} value={0} />
                                <Tab index={1} label="Subscribed Tree" {...a11yProps(1)} value={1} />
                            </Tabs>
                        </Paper>
                        <Divider />
                        <TabPanel value={rightTabIndex} index={0}>
                            <CardContent className='manageStep2'>
                                <PhotometricTree key={value + 1} forceUpdate={forceUpdate} groups={groups} />
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={rightTabIndex} index={1}>
                            <CardContent>
                                <SubscribedTree key={value + 2} forceUpdate={forceUpdate}  groups={subscribedGroups} normalGroups={groups}/>
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid >
        </Page >
    );




} 