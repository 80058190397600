import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import { defaultTheme, customTheme } from '../src/theme';
import routes from '../src/routes';
import { useInterval } from './automate/automate-interval-runner.component';
import EdgeOrREST from './EdgeOrREST';
import { AuthContext } from './auth-provider.component';
import { setAxiosHeaders } from './API/services/axios.client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import selectActions from './store/actions/selectActions';

const App = () => {
    const { authed, logout, login, token, is2FAAuthenticated } = useContext(AuthContext);
    const dispatch = useDispatch()
    const routing = useRoutes(routes(authed, is2FAAuthenticated));
    const navigate = useNavigate();
    const state = useSelector(state => state)

    useInterval(() => {
        if (process.env.NODE_ENV != 'development' && window.location.href.indexOf("resetPassword") == -1) {
            EdgeOrREST(null, 'KEEP_ALIVE', onKeepAlive, null);
        }
    }, 25000);


    useEffect(() => {
        let isMounted = true;
        if (window.localStorage.getItem("remember") == "true" && window.localStorage.getItem("token") && window.location.href.indexOf("forgot") == -1) {
            console.log("checking token")
            setAxiosHeaders(window.localStorage.getItem("token"));
            EdgeOrREST(null, 'CHECK_TOKEN', onCheckToken, null);
        }
        if (!window.localStorage.getItem("token")) {
            window.localStorage.setItem("token", "adkmoADBYn!da@");
        }
        if (window.location.href.indexOf("resetPassword") == -1 && window.location.href.indexOf("login") == -1 &&
            window.location.href.indexOf("launcher") == -1 && window.location.href.indexOf("monitor") == -1 &&
            window.location.href.indexOf("userAdmin") == -1 && window.location.href.indexOf("forgot") == -1 ) {
            EdgeOrREST(null, 'KEEP_ALIVE', onKeepAlive, null);
        }

        return () => { isMounted = false };
    }, []);

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    const getThemeCfg = (response) => {
        if (response.data != null){
            let string = response.data
            let colour = ("#"+string.substring(1,7));
            let logo = ("data:image/png;base64,"+string.substring(7));
            const t = newShade(colour,-30);
            dispatch(selectActions.UpdateLogo({logo:logo}))
            dispatch(selectActions.UpdatePrimary({primary:colour}))
            dispatch(selectActions.UpdateTertiary({tertiary:t}))
            dispatch(selectActions.UpdateText({fileText:"Change Logo"}))
            setTimeout(() => dispatch(selectActions.UpdateTheme({theme:true})),300)
        }
    }

    const getRoleId = (response) => {
        if (response.data != 0 && response.data != null){
            let roleNum = response.data
            if (roleNum == 1){
                dispatch(selectActions.UpdateUserRole({userRole:"admin"}))
            }
        }
        else {
            dispatch(selectActions.UpdateUserRole({userRole:"not admin"}))
            console.log("Not a Select Admin")
        }
    }

    const onCheckToken = (response) => {
        console.log("checked token", response);
        if (response.data && response.status == '200') {
            console.log("after check token", response.data.companyId);
            login(window.localStorage.getItem("token"), response.data.id, response.data.companyId, false);
            EdgeOrREST(null, 'GET_CFG', getThemeCfg, { 'companyId' : response.data.companyId });
            EdgeOrREST(null, 'GET_ROLE_ID', getRoleId, { headers: {'userId' : response.data.id, }});
        }
    }

    const onKeepAlive = (response) => {
        console.log("keep alive", response);
        if (response && response.status != '200') {
            logout();
            navigate('/login');
        }
    }

    return (
        <ThemeProvider theme={state.Select.theme ? customTheme : defaultTheme}>
            <GlobalStyles />
            {routing}
        </ThemeProvider>
    );
};

export default App;