import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import roadActions from '../../store/actions/roadActions';

const ContourValues = () => {
    const dispatch = useDispatch();
    const { contourValues } = useSelector((state) => state.ProjectConfig);
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        dispatch(roadActions.AddToCurrentState({ autoContourValues: event.target.checked }));
    };

    const newValue = useCallback(
        (event) => {
            const index = parseInt(event.target.id.replace('ContourValue', ''));
            const value = parseFloat(event.target.value);
            const newContourValues = [...contourValues];
            newContourValues[index] = value;
            dispatch(roadActions.AddToCurrentState({ contourValues: newContourValues }));
        },
        [contourValues, dispatch]
    );

    const indices = Array.from({ length: 5 }, (_, index) => index);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            name="autoContourValues"
                            color="primary"
                        />
                    }
                    label="Auto Contour Values"
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <label htmlFor="contourValues" style={{ marginRight: '1rem' }}>
                    Contour Values:
                </label>
                {indices.map((index) => {
                    const max = index === 0 ? contourValues[1] - 0.01 : index === 4 ? undefined : contourValues[index + 1] - 0.01;
                    const min = index === 4 ? contourValues[3] + 0.01 : index === 0 ? undefined : contourValues[index - 1] + 0.01;
                    const inputProps = { max, min, step: 0.01 };
                    return (
                        <TextField
                            disabled={checked}
                            key={`ContourValue${index}`}
                            type="number"
                            name="contourValue"
                            value={contourValues[index]}
                            id={`ContourValue${index}`}
                            step="0.01"
                            inputProps={inputProps}
                            onChange={newValue}
                            style={{ width: '45px', marginRight: '1rem' }}
                            className="Contour-Input"
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ContourValues;