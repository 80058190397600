export default function formatNumber(num) {
    // Convert the number to a string
    const numStr = num.toString();
    
    // Check if the number has more than two decimal places
    const hasMoreThanTwoDecimals = numStr.includes('.') && numStr.split('.')[1].length > 2;
  
    // Conditionally format the number
    if (hasMoreThanTwoDecimals) {
      // Use toFixed(2) to format and convert back to number
      return +num.toFixed(2);
    } else {
      // Return the original number if it has 2 or less decimal places
      return num;
    }
  }