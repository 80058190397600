import { handleActions } from 'redux-actions';
import actions from '../actions/notificationActions';
import { initialState } from '../initial-state';
export default handleActions(
    {
        [actions.AddNotificationHistory]: (state, action) => {
            var historyItem = action.payload;
            var historyList = state.history;

            if (historyList==null){
                historyList = [];
                historyList.push(historyItem);
            }
            else if(historyList.length <= 15) {
                historyList.push(historyItem);
            } else if (historyList.length > 15) {
                historyList.shift();
                historyList.push(historyItem);
            }
            return { ...state, history: historyList };
        },
    },
    initialState.NotificationHistory
);
