import React, { useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import EdgeOrREST from '../../EdgeOrREST';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Button,
  Toolbar,
  makeStyles,
  Input,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  withStyles,
} from '@material-ui/core';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import Logo from '../../components/Logo';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import LinearInterp from '../../components/Settings/Comp_LinearInterp';
import DriveSide from '../../components/Settings/component.driveSide';
import Language from '../../components/Settings/Comp_Language';
import ReiLimit from '../../components/Settings/component.reiLimit';
import ContourSettings from '../../components/Settings/Comp_Contour_Values';
import { AuthContext } from '../../auth-provider.component';
import { useDispatch, useSelector } from 'react-redux';
import user from '../../store/actions/user';
import Alert from '@material-ui/lab/Alert';
import luminaireActions from '../../store/actions/luminaireActions';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import columnActions from '../../store/actions/columnActions';
import tourActions from '../../store/actions/tourActions.js';
import selectActions from '../../store/actions/selectActions.js';
import userAction from '../../store/actions/user';
import { initialState } from '../../store/initial-state';
import Tour from 'reactour';
import automateSteps from './TourSteps/automateSteps.js';
import manageSteps from './TourSteps/manageSteps.js';
import optimizeSteps from './TourSteps/optimizeSteps.js';
import userAdminSteps from './TourSteps/userAdminSteps.js';
import monitorSteps from './TourSteps/monitorSteps.js';
import searchSteps from './TourSteps/searchSteps.js';
import planSteps from './TourSteps/planSteps.js';
import unavaliableSteps from './TourSteps/unavaliable.js';
import "./TourSteps/tour.css";
import Draggable from 'react-draggable';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import Notification from '../../components/shared/notification.component.js';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60
  },
  svg: {
    height: 32,
    width: 32
  },
  icon: {
    width: 55
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const { logout, userId, companyId } = useContext(AuthContext);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const classes = useStyles();
  const navigate = useNavigate();
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openTour, setOpenTour] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);
  const anchorRefTour = React.useRef(null);
  const anchorRefHelp = React.useRef(null);
  const anchorRefMenu = React.useRef(null);
  const anchorRefSetting = React.useRef(null);
  const anchorRefNotification = React.useRef(null);
  const prevOpenMenu = React.useRef(openMenu);
  const prevOpenTour = React.useRef(openTour);
  const prevOpenNotification = React.useRef(openNotification);
  const prevOpenSetting = React.useRef(openSetting);
  const [optimize, setOptimize] = React.useState(false);
  const [automate, setAutomate] = React.useState(false);
  const [manage, setManage] = React.useState(false);
  const [plan, setPlan] = React.useState(false);
  const [design, setDesign] = React.useState(false);
  const [select, setSelect] = React.useState(false);
  const [monitor, setMonitor] = React.useState(false);
  const [userAdmin, setUserAdmin] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [tourSteps, setSteps] = React.useState();
  const [color, setColor] = React.useState(state.Select.primary);
  const [colourDisplay, setColourDisplay] = React.useState("");
  const [scaling, setScaling] = React.useState(window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1);

  const handleFontScalingChange = (event, newValue) => {
    if (newValue < 0.5) {
      newValue = 0.5;
    } else if (newValue > 2.5) {
      newValue = 2.5;
    }
    setScaling(newValue);
  };

  const handleFontScaling = (event, newValue) => {
    if (window.localStorage.getItem("fontScaling") != newValue) {
      window.localStorage.setItem("fontScaling", newValue);
      dispatch(user.UpdateUserCfg({ updateGlobalStyles: true }));
    }
  };

  function doSignOut() {
    EdgeOrREST(null, 'SIGN_OUT', onSignOut, null);
  }

  const onSignOut = (response) => {
    if (window.localStorage.getItem("remember")) {
      window.localStorage.removeItem("remember");
      window.localStorage.removeItem("token");
    } else {
      window.localStorage.clear();
    }
    var resetState = initialState;
    resetState.LuminaireCfg.selectedFolderIds = [];
    resetState.LuminaireCfg.selectedLuminaireIds = [];
    resetState.user.searchedLuminaires = [];
    dispatch(luminaireActions.UpdateLuminCfg(resetState.LuminaireCfg));
    dispatch(gridActions.UpdateGridsCfg(resetState.GridsCfg));
    dispatch(columnActions.UpdateColumnCfg(resetState.ColumnCfg));
    dispatch(roadActions.AddToCurrentState(resetState.ProjectConfig));
    dispatch(roadActions.UpdateStandardsCfg(resetState.ProjectConfig.StandardsCfg));
    dispatch(userAction.UpdateUserCfg(resetState.user));
    logout();
  }

  const onGetProducts = (response) => {
    console.log("products", response);
    if (response.data) {
      console.log("dispatching products", response.data);

      dispatch(user.UpdateUserCfg({
        ...state.user,
        products: response.data
      }));
    }
  }

  const onGetRoles = (response) => {
    if (response.data) {
      console.log("roles", response.data);
      dispatch(user.UpdateUserCfg({ roles: response.data }));
    }
  }

  useEffect(() => {
    if (userId && companyId && state.user) {
      //if (userId && companyId && state.user && !(state.user.products)) {
      console.log("authProv", userId);
      console.log("authProv", companyId);
      EdgeOrREST(null, 'GET_ROLES', onGetRoles, { headers: { 'id': userId }, props: { ...rest } });
      EdgeOrREST(null, 'GET_PRODUCTS', onGetProducts, { headers: { 'id': companyId }, props: { ...rest } });
    }
  }, [userId, companyId]);

  useEffect(() => {
    if (prevOpenMenu.current === true && openMenu === false) {
      anchorRefMenu.current.focus();
    }
    if (prevOpenSetting.current === true && openSetting === false) {
      anchorRefSetting.current.focus();
    }
    prevOpenMenu.current = openMenu;
    prevOpenSetting.current = openSetting;
  }, [openMenu]);

  const handleMenuToggle = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };

  const handleMenuClose = (event) => {
    if (anchorRefMenu.current && anchorRefMenu.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  function handleMenuListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleSettingToggle = () => {
    setOpenSetting((prevOpenSetting) => !prevOpenSetting);
  };

  const handleSettingClose = (event) => {
    if (anchorRefSetting.current && anchorRefSetting.current.contains(event.target)) {
      return;
    }
    setOpenSetting(false);
  };

  const handleHelpToggle = () => {
    setTimeout(() => dispatch(tourActions.UpdateHelpText({ helpText: "Please select what you need help with. You are able to drag me around!"})),150)
    setOpenHelp((prevOpenHelp) => !prevOpenHelp);
  };

  const handleHelpClose = (event, reason) => {
    if (reason == "backdropClick") {
      return;
    }

    if (anchorRefHelp.current && anchorRefHelp.current.contains(event.target)) {
      return;
    }
    dispatch(tourActions.UpdateHelpMode({helpMode: false}));
    setOpenHelp(false);
  };

  const helpVideoName = [
    'Help - How to use the help panel',
		'Automate - Automate Help',
    'Manage - Add Groups',
    'Manage - Add Luminaires',
    'Manage - Delete Groups',
    'Manage - Edit Groups/Luminaires',
    'Manage - Export Groups',
    'Manage - Remove Luminaires',
    'Optimize - Configurations',
    'Optimize - Export as PDF',
    'Optimize - Export as Spreadsheet',
    'Optimize - Optimize Help',
	]

  const handleURL = (event, state) => {
    var helpVideo = event.target.value;
    if (helpVideo === helpVideoName[0]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Help"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "Please select what you need help with. You are able to drag me around!"}))
    }
    if (helpVideo === helpVideoName[1]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Automate"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "See video above on how to use automate."}))
    }
    if (helpVideo === helpVideoName[2]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Add Groups"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you create new groups."}))
    }
    if (helpVideo === helpVideoName[3]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Add Luminaires"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you add luminaires to your groups."}))
    }
    if (helpVideo === helpVideoName[4]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Delete Group"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you delete your group or luminaire."}))
    }
    if (helpVideo === helpVideoName[5]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Edit Group"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you edit your group or luminaire."}))
    }
    if (helpVideo === helpVideoName[6]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Export Group"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you export your groups into one of our given formats."}))
    }
    if (helpVideo === helpVideoName[7]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Remove Luminaires"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you remove selected luminaires from your tree. Ensure you select the luminaires you want to remove before pressing this button."}))
    }
    if (helpVideo === helpVideoName[8]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Configs"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you save/reset configurations. Make sure to give them a name in the text input above before saving!"}))
    }
    if (helpVideo === helpVideoName[9]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Export pdf"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you export your results in a PDF format. Select a template or upload your own."}))
    }
    if (helpVideo === helpVideoName[10]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Export Results"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button allows you to export your results as an excel file."}))
    }
    if (helpVideo === helpVideoName[11]) {
      dispatch(tourActions.UpdateHelpURL({helpURL: "Optimize"}))
			const video = document.getElementById("help-video")
			video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button starts the optimize process. Selecting Optimize will calculate results without going over the standard. Selecting Minimum will calculate results without going under the standard."}))
    }
  };

  const newShade = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return hexColor;
    }
};

  const handleRefresh = (p) => { //primary
    setColor(p)
    const t = newShade(p,-30);  //tertiary

    const hex = p.replace("#","");

    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    if ((r*0.299 + g*0.587 + b*0.114) > 186){
      dispatch(selectActions.UpdateTextColor({textColor: "black"}))
    } else {
      dispatch(selectActions.UpdateTextColor({textColor: "white"}))
    }

    dispatch(selectActions.UpdatePrimary({ primary: p }))
    dispatch(selectActions.UpdateTertiary({ tertiary: t }))
  };

  const handleReset = () => {
    dispatch(selectActions.UpdateLogo({logo: initialState.Select.logo}))
    dispatch(selectActions.UpdateTextColor({textColor: initialState.Select.textColor}))
    dispatch(selectActions.UpdatePrimary({ primary: initialState.Select.primary }))
    dispatch(selectActions.UpdateTertiary({ tertiary: initialState.Select.tertiary }))
    dispatch(selectActions.UpdateTheme({theme: false}))
  }

  const onGetTheme = () => {
    EdgeOrREST(null, 'GET_CFG', onGetThemeCfg, { 'companyId' : companyId });
  }

  const onGetThemeCfg = (response) => {
    if (response.data != null){
      let string = response.data
      let colour = ("#"+string.substring(1,7));
      let logo = ("data:image/png;base64,"+string.substring(7));
      setColor(colour) 
      dispatch(selectActions.UpdateLogo({logo:logo}))
      dispatch(selectActions.UpdatePrimary({primary:colour}))
      setTimeout(() => dispatch(selectActions.UpdateTheme({theme:true})),300)
      setSnack({
        open: true,
        message: "Theme has been changed",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: "You have not created a theme",
        severity: 'error'
      });
    }
  }

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const [snack, setSnack] = React.useState({
    open: false
  });

  const onUpdate = () => {
    EdgeOrREST(null, 'UPDATE_CFG', onUpdateCfg, {'companyId' : companyId,'colourCfg' : state.Select.primary, 'logoUrl' : state.Select.base64});
  }

  const onUpdateCfg = (response) => {
    if (response.status == 200){
      setSnack({
        open: true,
        message: "Theme has been updated",
        severity: 'success'
      });
    }else{
      setSnack({
        open: true,
        message: "Error changing theme",
        severity: 'warning'
      });
    }
  }

  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    dispatch(selectActions.UpdateTheme({theme: true}))
    event.preventDefault();
    const file = event.target.files[0];
    const localImageUrl = window.URL.createObjectURL(file);   

    const fileToBase64 = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64Data = reader.result;
          const base64WithoutExtensions = base64Data.split(',')[1];
          resolve(base64WithoutExtensions);
        };
        reader.onerror = reject;
      });
    };

    fileToBase64(file).then((img64) => {
      dispatch(selectActions.UpdateBase64({base64: img64}));
    });

    dispatch(selectActions.UpdateLogo({logo: localImageUrl}));
    dispatch(selectActions.UpdateText({fileText: file.name}))
  };

  const handleTourToggle = () => {
    dispatch(tourActions.UpdateHelpMode({helpMode: false}));
    setOpenHelp(false);
    handleSteps();
    setOpenTour((prevOpenTour) => !prevOpenTour);
  };

  const handleTourClose = (event) => {
    if (anchorRefTour.current && anchorRefTour.current.contains(event.target)) {
      return;
    }
    setOpenTour(false);
  };

  const handleSteps = () => {
    const url = window.location.href;
    var parts = url.split('/');
    var lastSegment = parts.pop();
    if (lastSegment == 'roadway' || lastSegment == 'optimize'){
      setSteps(optimizeSteps);
    } else if (lastSegment == 'automate' || lastSegment == 'upload'){
      setSteps(automateSteps);
    } else if (lastSegment == 'search'){
      setSteps(manageSteps);
    } else if (lastSegment == 'plan'){
      setSteps(planSteps);
    } else if (lastSegment == 'userAdmin'){
      setSteps(userAdminSteps);
    } else if (lastSegment == 'monitor'){
      setSteps(monitorSteps);
    } else if (lastSegment == 'luminaire'){
      setSteps(searchSteps);
    }else{
      setSteps(unavaliableSteps);
    }
  };

  const handleOpen = () => {
    dispatch(tourActions.UpdateCurrentStep({currentStep: 0}));
  };

  const handleNotificationToggle = () => {
    setOpenNotification((prevOpenNotification) => !prevOpenNotification);
  };

  const handleNotificationClose = (event) => {
    if (anchorRefNotification.current && anchorRefNotification.current.contains(event.target)) {
      return;
    }
    setOpenNotification(false);
  };


  function handleSettingListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSetting(false);
    }
  }

  function toUpdatePassword() {
    navigate("/updatePassword");
  }

  function toLauncher() {
    navigate("/launcher");
  }

  function toSupport() {
    navigate("/support");
  }

  function toOptimize() {
    navigate("/optimize")
  }
  function toAutomate() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/automate")
  }
  function toManage() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/photometry/search")
  }
  function toPlan() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/plan")
  }
  function toDesign() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/design")
  }
  function toSelect() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/select")
  }
  function toSearch() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/search/luminaire")
  }
  function toMonitor() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/monitor")
  }
  function toUserAdmin() {
    dispatch(tourActions.UpdateTourAccess({ enabled: false}));
    navigate("/userAdmin")
  }
  

  function createProductMenuItems() {
    var launcherItems = [];
    if (state.user.products) {
      for (var i = 0; i < state.user.products.length; i++) {
        if (state.user.products[i].name === "Optimize") {
          setOptimize(true);
        }
        if (state.user.products[i].name === "Automate") {
          setAutomate(true);
        }
        if (state.user.products[i].name === "Manage") {
          setManage(true);
        }
        if (state.user.products[i].name === "Plan") {
          setPlan(true);
        }
        if (state.user.products[i].name === "Design") {
          setDesign(true);
        }
        if (state.user.products[i].name === "Select") {
          setSelect(true);
        }
        if (state.user.products[i].name === "Search") {
          setSearch(true);
        }
        if (state.user.products[i].name === "Monitor") {
          setMonitor(true);
        }
        if (state.user.products[i].name === "UserAdmin") {
          setUserAdmin(true);
        }
        
      }
      if (optimize) {
        launcherItems.push(
          <MenuItem onClick={toOptimize}> Optimize</MenuItem>
        );
      }
      if (automate) {
        launcherItems.push(
          <MenuItem onClick={toAutomate}> Automate</MenuItem>
        );
      }
      if (manage) {
        launcherItems.push(
          <MenuItem onClick={toManage}> Manage</MenuItem>
        );
      }
      if (plan) {
        launcherItems.push(
          <MenuItem onClick={toPlan}> Plan</MenuItem>
        );
      }
      if (design) {
        launcherItems.push(
          <MenuItem onClick={toDesign}> Design</MenuItem>
        );
      }
      if (select) {
        launcherItems.push(
          <MenuItem onClick={toSelect}> Select</MenuItem>
        );
      }
      if (search) {
        launcherItems.push(
          <MenuItem onClick={toSearch}> Search</MenuItem>
        );
      }
      if (monitor) {
        launcherItems.push(
          <MenuItem onClick={toMonitor}> Monitor</MenuItem>
        );
      }
      if (userAdmin) {
        launcherItems.push(
          <MenuItem onClick={toUserAdmin}> User Admin</MenuItem>
        );
      }      
      return launcherItems;
    }
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flexBasis: '25%' }}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </div>
        <div style={{ flexBasis: '50%', textAlign: 'center', color: state.Select.textColor}}>
          <h4>
            {rest.app}
          </h4>
        </div>
        <div style={{ flexBasis: '25%', textAlign: 'right' }}>
          <Tooltip title={state.EnabledTour.enabled ? "Please refresh the page OR select the roadway tab to start the tour." : "Click me to start the Tour"}>
            <span>
              <Button disabled={state.EnabledTour.enabled}
                ref={anchorRefTour}
                aria-controls={openTour ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleTourToggle}
                color="inherit"
              >
                <InfoOutlinedIcon className='menu-icon' />
              </Button>
            </span>
          </Tooltip>
          <Popper open={openTour} anchorEl={anchorRefTour.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <MenuList autoFocusItem={openTour} id="menu-list-grow">
                    <Tour    
                      className="tour-guide"
                      steps={tourSteps}
                      badgeContent={(curr, tot) => `${curr} / ${tot}`} //curr = currentStep / tot = totalSteps - shows progress of the tour in the badge
                      isOpen={openTour}
                      rounded={10}
                      onRequestClose={handleTourClose}
                      showNavigation={false}
                      disableKeyboardNavigation={['esc']}
                      disableFocusLock
                      closeWithMask={false}
                      nextButton={ <SkipNextRoundedIcon sx = {{color: state.Select.primary}} /> }
                      prevButton={ <SkipPreviousRoundedIcon sx = {{color: state.Select.primary}} /> }
                      lastStepNextButton={ <HighlightOffRoundedIcon sx = {{color: state.Select.primary}} /> }
                      getCurrentStep={(curr) => dispatch(tourActions.UpdateCurrentStep({currentStep: curr}))}
                      goToStep={state.EnabledTour.currentStep}
                      onAfterOpen={handleOpen}
                      accentColor={state.Select.primary}
                    />
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Button
            ref={anchorRefHelp}
            aria-controls={openHelp ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleHelpToggle}
            onMouseDown={() => dispatch(tourActions.UpdateHelpMode({helpMode: !state.EnabledTour.helpMode}))}
            color="inherit"
            title="Help"
          >
            <QuestionMarkRoundedIcon className='menu-icon' />
          </Button>
          <Popper open={openHelp} anchorEl={anchorRefHelp.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>                
                  <MenuList autoFocusItem={openHelp} id="menu-list-grow">
                  <Draggable positionOffset={{ x: '720px', y: '0px' }}>
                  <Dialog
                    open={openHelp}
                    onClose={handleHelpClose}
                    BackdropProps={{ open: false }}
                    disableEscapeKeyDown
                    disableEnforceFocus
                    className='help-dlg'
                    hideBackdrop
                  >
                    <Paper style={{borderStyle: "solid", borderColor: state.Select.primary}}>
                    <DialogTitle className='text'>Help</DialogTitle>
                    <DialogContent>
                      <DialogContentText>  
                        <TextField
                        fullWidth
                        label="Help Videos"
                        name="URLHelpVideos"
                        onChange={(e) => handleURL(e,state)} 
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        >
                          {helpVideoName.map((option) => (
                            <option
                              key={option}
                            >
                              {option}
                            </option>
                          ))}
                        </TextField>                   
                        <video id='help-video' width="432" height="236" controls>
                          <source src={"https://lrhelp.blob.core.windows.net/video/" + state.EnabledTour.helpURL + ".mp4"} type="video/mp4"/>
                        </video>
                        <span className='text'>See video above for an in depth guide.</span>
                        <br/>
                        <br/>
                        <span className='important'>Click on what you need help with and the following text will change:</span>
                        <br/>
                        <br/> 
                        <span className='text'>{state.EnabledTour.helpText}</span>
                        <br/>
                        <br/>
                        <br/>
                        <span className='important'>Remember to click finish when you are done so the app returns to normal.</span>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleHelpClose}>
                        Finish
                      </Button>
                    </DialogActions>
                    </Paper>
                  </Dialog>
                  </Draggable>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Button
            ref={anchorRefNotification}
            aria-controls={openNotification ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleNotificationToggle}
            color="inherit"
            title="Notifications"
          >
            <NotificationsNoneIcon className='menu-icon' />
          </Button>
          <Popper open={openNotification} anchorEl={anchorRefNotification.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleNotificationClose}>
                    <MenuList autoFocusItem={openNotification} id="menu-list-grow">
                      <MenuItem disabled>Error Notification Record :</MenuItem>
                      {state?.NotificationHistory?.history?.map((notification, index) => (
                        <MenuItem><Alert severity="error"> {notification} </Alert></MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Button
            ref={anchorRefSetting}
            aria-controls={openSetting ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleSettingToggle}
            color="inherit"
            title="Settings"
          >
            <SettingsIcon className='menu-icon' />
          </Button>
          <Popper open={openSetting} anchorEl={anchorRefSetting.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleSettingClose}>
                    <MenuList autoFocusItem={openSetting} id="menu-list-grow" onKeyDown={handleSettingListKeyDown}>
                      <MenuItem > <LinearInterp /></MenuItem>
                      <MenuItem > <DriveSide /></MenuItem>
                      <MenuItem > <Language /></MenuItem>
                      <MenuItem > <ReiLimit /></MenuItem>
                      <MenuItem>
                          <label> Font Scaling: </label>
                          <Slider
                            style={{margin: '0px 15px'}}
                            sx = {{
                              "& .MuiSlider-rail": {
                                background: state.Select.primary
                              },
                              "& .MuiSlider-track": {
                                background: state.Select.primary
                              },
                              "& .MuiSlider-thumb": {
                                background: state.Select.primary
                              },
                            }}
                            size="small"
                            value={scaling}
                            aria-label="Small"
                            step={0.1}
                            min={0.5}
                            max={2.5}
                            onChange={handleFontScalingChange}
                            onChangeCommitted={handleFontScaling}
                          />
                          <Input
                            style={{width: '60px'}}
                            className={classes.input}
                            value={scaling}
                            margin="dense"
                            onChange={handleFontScalingChange}
                            onBlur={handleFontScaling}
                            inputProps={{
                              step: 0.1,
                              min: 0.5,
                              max: 2.5,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                          />
                      </MenuItem>
                      <MenuItem > <ContourSettings /></MenuItem>
                      <MenuList onKeyDown={(e) => e.stopPropagation()}>
                        <div className="color-picker" style={{display: state.Select.userRole=="admin" ? "" : "none"}}>
                          <div>
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              onChange={handleFileInputChange}
                            />
                            <Button style={{marginBottom:"8px", marginTop:"8px", marginLeft:"8px"}} variant='outlined' color='primary' onClick={handleButtonClick}>
                              {state.Select.logo==initialState.Select.logo ? "Change Logo" : state.Select.fileText}
                            </Button>
                          </div>
                          <HexColorPicker 
                            color={color} 
                            onMouseDown={() => dispatch(selectActions.UpdateTheme({theme: false}))} 
                            onMouseUp={() => dispatch(selectActions.UpdateTheme({theme: true}))}  
                            onChange={(color) => handleRefresh(color)}
                          />
                          <HexColorInput 
                            className='color-input'
                            color={color} 
                            onKeyDown={() => dispatch(selectActions.UpdateTheme({theme: false}))}
                            onKeyUp={() => dispatch(selectActions.UpdateTheme({theme: true}))}
                            onChange={(color) => handleRefresh(color)}
                          />
                          <div>
                            <Button style={{marginBottom:"8px", marginTop:"8px", marginRight:"8px"}} variant='outlined' color='primary' onClick={() => onUpdate()}>
                              Save Theme
                            </Button>
                            <Button style={{marginBottom:"8px", marginTop:"8px", marginRight:"8px", marginLeft:"8px"}} variant='outlined' color='primary' onMouseDown={() => dispatch(selectActions.UpdateTheme({theme:false}))} onClick={() => onGetTheme()}>
                              Get Theme
                            </Button>
                            <Button style={{marginBottom:"8px", marginTop:"8px", marginLeft:"8px"}} variant='outlined' color='primary' onMouseDown={() => setColor("3F51B5")} onClick={() => handleReset()}>
                              Reset Theme
                            </Button>
                          </div>
                        </div>
                      </MenuList>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Button
            ref={anchorRefMenu}
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleMenuToggle}
            color="inherit"
            title="Menu"
          >
            <MenuIcon className='menu-icon' />
          </Button>
          <Popper open={openMenu} anchorEl={anchorRefMenu.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleMenuListKeyDown}>
                      <MenuItem onClick={toLauncher}>Home Page</MenuItem>
                      {createProductMenuItems()}
                      <MenuItem onClick={toUpdatePassword}>Update Password</MenuItem>
                      <MenuItem onClick={toSupport}>Support Request</MenuItem>
                      <MenuItem onClick={doSignOut}> Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Toolbar>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

