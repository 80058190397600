import { apiService } from './axios.client';

export const AdminEndPoints = {
    getCodeVersion: function (input, callback) {
        apiService.get('/status/GetBuildVersion', input)
            .then(callback)
            .catch(error => error.response);
    },
    getUsersWithQueuedJobs: function (input, callback) {
        apiService.get('/status/IsAJobRunning', input)
            .then(callback)
            .catch(error => error.response);
    },
    getNumberOfJobsInQueue: function (input, callback) {
        apiService.get('/status/GetNumberOfJobsQueued', input)
            .then(callback)
            .catch(error => error.response);
    }, 
    getUserList: function (input, callback) {
        apiService.get('/monitor/getUserList', input)
            .then(callback)
            .catch(error => error.response);
    }, 
    searchMonitorJobs: function (input, callback) {
        apiService.post('/monitor/searchMonitorJobs', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getJobCalcTime: function (input, callback) {
        apiService.get('/monitor/getJobCalcTime', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getCompanysAndRegionLists: function (input, callback) {
        apiService.get('/User/GetCompanysAndRegionLists', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    addUser: function (input, callback) {
        apiService.post('/User/createUserForm', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    addCom: function (input, callback) {
        apiService.post('/User/createCompany', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    editCom: function (input, callback) {
        apiService.post('/User/editCompany', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
}