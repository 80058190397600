import React from 'react';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

function steps (){return[
    {
        content: () => (
            <div>
                Welcome to Automate. Here you can push your luminaire groups into an Excel file format.
                <br/>
                <br/>
                {/* <div className="important"><span><PriorityHighRoundedIcon/></span>If this is your first time using the tour. Please refresh the page to ensure the tour works as intended.<span><PriorityHighRoundedIcon/></span></div> */}
            </div>
        ),                
    },
    {
        selector: '.automateStep2',
        content: 'Firstly, download one of our templates here.',
    },
    {
        selector: '.automateStep3',
        content: () => (
            <div>
                After it has downloaded, select <input type="file" className='input-button'/>
                <br/>
                <br/>
                Then select one of our templates.
            </div>
        ),
    },
    {
        selector: '.automateStep4',
        content: 'Then select your group(s) and click on submit.',
        position: [475,300],
    },
    {
        selector: '.automateStep5',
        content: () => (
            <div>
                Click on confirm and wait for it to be processed.
                <br/>
                <br/>
                When it is processed you can download the file by clicking the <FileDownloadRoundedIcon className="tour-icon" /> button.
            </div>
        ),
        position: [1160,385],
    },
]}

export default steps;