import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../auth-provider.component';
import Page from '../components/Page';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Typography,
  TextField,
  Button,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Notification from '../components/shared/notification.component';
import EdgeOrREST from '../EdgeOrREST';
import UserSearch from './userAdminstrate-search.component';
import { useDispatch, useSelector } from 'react-redux';
import { setAxiosHeaders } from '../API/services/axios.client';
import user from '../store/actions/user';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '85vh',
    padding: theme.spacing(3)
  }
}));

const roleList = ['member', 'manufacturer', 'admin'];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditUser = (props) => {
  const { authed, logout, login, token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [leftTabIndex, setLeftTabs] = useState(0);
  const [middleTabIndex, setMiddleTabIndex] = useState(0);
  const [email, setEmail] = React.useState("");
  const [givenName, setGivenName] = React.useState("");
  const [familyName, setFamilyName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState(0);
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [isGivenName, setIsGivenName] = React.useState(false);
  const [isFamilyName, setIsFamilyName] = React.useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = React.useState(false);
  const [isSelectedCompany, setIsSelectedCompany] = React.useState(false);
  const [isSelectedRole, setIsSelectedRole] = React.useState(false);
  const [isSelectedRegion, setIsSelectedRegion] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [isManufacturer, setIsManufacturer] = React.useState(false);

  const [selectedRole, setSelectedRole] = React.useState("");
  const [companysList, setCompanysList] = React.useState([]);
  const [regionsList, setRegionsList] = React.useState([]);

  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [forceUpdateFlag, setForceUpdateFlag] = React.useState(0);

  const [selectedUserInfo, setSelectedUserInfo] = React.useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [disable2FA, setDisable2FA] = useState(false);
  const [enable2FA, setEnable2FA] = useState(false);

  const classes = useStyles();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [snack, setSnack] = useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  function callBackSelectedUser(value) {
    if (value.length > 0) {
      setSelectedUsers(value);
    }
  };

  useEffect(() => {
    //console.log("selectedUsers on search", selectedUsers);
    if (selectedUsers != null && selectedUsers.length > 0) {
      EdgeOrREST(null, 'GET_USER_BY_ID', onGetUser, { headers: { "userId": selectedUsers[0].id } });
      EdgeOrREST(null, 'GET_USER_ANALYTICS_BY_ID', onGetUserAnalytics, { headers: { "userId": selectedUsers[0].id } });
      EdgeOrREST(null, 'GET_ROLES', onGetRole, { headers: { "id": selectedUsers[0].id } });
    }
  }, [selectedUsers]);

  useEffect(() => {
    EdgeOrREST(null, 'GET_COMPANY_REGIONS_LISTS', onGetCompanysList, null);
  }, []);

  const onGetUser = (response) => {
    if (response) {
      console.log("Get User Res", response);
      setEmail(response.data?.email);
      setGivenName(response.data?.givenName);
      setFamilyName(response.data?.familyName);
      setSelectedCompany(response.data?.companyId);
      setSelectedRegion(response.data?.regionId);
      setIsAdmin(response.data?.isManufacturerAdmin == 1 ? true : false);
    }
  }

  const onGetUserAnalytics = (response) => {
    if (response) {
      setSelectedUserInfo(response.data);
    }
  }

  const onGetRole = (response) => {
    if (response && response.data.length > 0) {
      setSelectedRole(response?.data[0].name);
    }
  }

  const onGetCompanysList = (response) => {
    setCompanysList(response?.data?.companysList);
    setRegionsList(response?.data?.regionsList);
  }

  const isAllValid = () => {
    console.log("isSelectedRegion", isSelectedRegion);
    console.log("isSelectedCompany", isSelectedCompany);
    console.log("isSelectedRole", isSelectedRole);
    console.log("isPasswordMatch", isPasswordMatch);
    console.log("isPasswordValid", isPasswordValid);
    console.log("isFamilyName", isFamilyName);
    console.log("isGivenName", isGivenName);
    console.log("isEmailValid", isEmailValid);

    return isEmailValid && isGivenName && isFamilyName && isPasswordValid && isPasswordMatch && isSelectedCompany && isSelectedRegion && isSelectedRole;
  };

  const handleSubmit = () => {
    if (password != password2) setIsPasswordMatch(false);
    else setIsPasswordMatch(true);
    setIsSubmited(true);
    if (isAllValid()) {
      EdgeOrREST(null, 'EDIT_USER', onEditUser, {
        'id': selectedUsers[0].id,
        'givenName': givenName,
        'familyName': familyName,
        'email': email,
        'password': password ? btoa(password) : "",
        'regionId': selectedRegion,
        'companyId': selectedCompany,
        'role': selectedRole,
        'isManufacturerAdmin': isAdmin ? "1" : "0",
        'disable2FA': disable2FA,
        'enable2FA': enable2FA
      });
    } else {
      setSnack({
        open: true,
        message: 'Form Incomplete',
        severity: 'error'
      });
    }
  };

  const handleLoginAsUser = () => {
    console.log("selectedUsers", selectedUsers);
    EdgeOrREST(null, 'LOGIN_AS_USER', onLoginAttempt, { headers: { 'userId': selectedUsers[0].id } });
  };

  function onLoginAttempt(response) {
    if (response.status == "200") {
      if (response.data.token && response.data.id) {
        setAxiosHeaders(response.data.token);
        //console.log("username", username);

        window.localStorage.setItem("remember", false);
        //window.localStorage.setItem("username", username);

        window.localStorage.setItem("token", response.data.token);

        console.log("response login", response.data.companyId);
        console.log("response login", response.data.id);
        login(response.data.token, response.data.id, response.data.companyId, response.data.isPasswordExpiry);

        dispatch(user.UpdateUserCfg({ pathname: '/launcher' }));
        navigate('/launcher');
      }
    } else {
      setSnack({
        open: true,
        message: "Your login attempt has failed. Please verify your credentials and try again later.",
        severity: 'error'
      });
    }
    //setLoading(false);
  }

  const onEditUser = (response) => {
    if (response && response.status == '200') {
      setForceUpdateFlag(prevFlag => prevFlag + 1);
      setSnack({
        open: true,
        message: 'Edit User Successfully',
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: 'Edit User Fail.',
        severity: 'error'
      });
    }
  }

  useEffect(() => {
    setIsEmailValid(isValidEmail(email.trim()));
  }, [email]);

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const checkPasswordValidity = (inputPassword) => {
    return inputPassword.length >= 8 || inputPassword.length == 0;
  };

  useEffect(() => {
    setIsGivenName(givenName.trim() !== "");
  }, [givenName]);

  useEffect(() => {
    setIsFamilyName(familyName.trim() !== "");
  }, [familyName]);

  useEffect(() => {
    setIsPasswordValid(checkPasswordValidity(password.trim()));
  }, [password]);

  useEffect(() => {
    setIsPasswordMatch(password === password2);
  }, [password, password2]);

  useEffect(() => {
    setIsSelectedCompany(selectedCompany != 0);
  }, [selectedCompany]);

  useEffect(() => {
    setIsSelectedRole(selectedRole != null && selectedRole != '');
  }, [selectedRole]);

  useEffect(() => {
    setIsSelectedRegion(selectedRegion != 0);
  }, [selectedRegion]);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onGivenName = (event) => {
    setGivenName(event.target.value);
  };

  const onFamilyName = (event) => {
    setFamilyName(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onPassword2Change = (event) => {
    setPassword2(event.target.value);
  };

  const handleCompanyChange = (event, value) => {
    setSelectedCompany(value?.id ? value.id : 0);
    const company = companysList.find(company => company.id === value?.id);
    setIsManufacturer(company?.isManufacturer == 1 ? true : false);
    console.log("setSelectedCompany", value?.id);
  };

  const handleRegionChange = (event, value) => {
    setSelectedRegion(value?.id ? value.id : 0);
    console.log("setSelectedRegion", value?.id);
  };

  const handleRoleChange = (event, value) => {
    setSelectedRole(value ? value : '');
    console.log("setSelectedRole", value);
  };


  const handleLeftTabs = (event, value) => {
    setLeftTabs(value);
  };

  const handleMiddleTabs = (event, value) => {
    setMiddleTabIndex(value);
  };

  const handleIsAdminChange = (event) => {
    setIsAdmin(event.target.checked);
  };
  
  const handleDisable2FA = (event) => {
    setDisable2FA(event.target.checked);
  };
  
  const handleEnable2FA = (event) => {
    setEnable2FA(event.target.checked);
  };

  return (
    <Page className={classes.root} title="Monitor" style={{ minHeight: '85vh' }}>
      <Grid container spacing={2} style={{ minHeight: 'inherit' }}>
        <Grid item lg={4} md={6} sm={12} xs={12} style={{ minHeight: 'inherit' }}>
          <Card style={{ minHeight: 'inherit' }}>
            <Paper square>
              <Tabs
                value={leftTabIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={handleLeftTabs}
                aria-label="disabled tabs example"
              >
                <Tab index={0} label="Search" {...a11yProps(0)} value={0} />
              </Tabs>
            </Paper>

            <Divider />
            <TabPanel value={leftTabIndex} index={0}>
              <CardContent>
                <UserSearch callBackSelectedUser={callBackSelectedUser} forceUpdateFlag={forceUpdateFlag} />
              </CardContent>
            </TabPanel>

          </Card>
        </Grid>
        <Grid item lg={8} md={6} sm={12} xs={12} style={{ minHeight: 'inherit' }}>
          <Card style={{ minHeight: 'inherit' }}>
            <Paper square>
              <Tabs
                value={middleTabIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={handleMiddleTabs}
                aria-label="disabled tabs example"
              >
                <Tab index={0} label="Edit" {...a11yProps(0)} value={0} />
                <Tab index={1} label="Information" {...a11yProps(1)} value={1} />
              </Tabs>
            </Paper>
            <Divider />
            <TabPanel value={middleTabIndex} index={0}>
              <CardContent>
                <Grid container item md={12} sm={4} xs={4} direction="column" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography variant="h6" gutterBottom>User Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <TextField
                        error={isSubmited && !isEmailValid}
                        helperText={isSubmited && !isEmailValid && "Please use a valid email"}
                        //label={email ? email : "Email"}
                        label={"Email"}
                        id="email"
                        value={email ? email : ""}
                        onChange={onEmailChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <TextField
                        error={isSubmited && !isGivenName}
                        helperText={isSubmited && !isGivenName && "Please use a valid name"}
                        label={"Given Name"}
                        id="givenName"
                        value={givenName ? givenName : ""}
                        onChange={onGivenName}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <TextField
                        error={isSubmited && !isFamilyName}
                        helperText={isSubmited && !isFamilyName && "Please use a valid name"}
                        label={"Family name"}
                        value={familyName ? familyName : ""}
                        id="familyName"
                        onChange={onFamilyName}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <TextField
                        error={isSubmited && (!isPasswordValid || !isPasswordMatch)}
                        helperText={isSubmited && (!isPasswordValid || !isPasswordMatch) && "Password length should be longer than 8 characters and matching"}
                        label="Change Password"
                        id="password"
                        type="password"
                        defaultValue=""
                        onChange={onPasswordChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <TextField
                        error={isSubmited && (!isPasswordValid || !isPasswordMatch)}
                        helperText={isSubmited && (!isPasswordValid || !isPasswordMatch) && "Password length should be longer than 8 characters and matching"}
                        label="Repeat Change Password"
                        id="password2"
                        type="password"
                        defaultValue=""
                        onChange={onPassword2Change}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>

              </CardContent>
              {/* </Card>

          <Card style={{ 'minHeight': 'inherit' }}> */}
              <CardContent>
                <Grid container item md={12} sm={12} xs={12} direction="column" justifyContent="center">
                  <Box display="flex" justifyContent="flex-start" width="100%">
                    <div style={{ marginBottom: '10px' }}>Companys and Regions</div>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" width="70%">
                    <Grid container item md={12} spacing={2}>
                      <Grid item md={4} sm={12} xs={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          height="100%" // Set height to 100%
                        >
                          <FormControl className={classes.formControl} style={{ width: '100%' }} error={isSubmited && !isSelectedCompany}>
                            <Autocomplete
                              id="company-select"
                              options={companysList}
                              onChange={handleCompanyChange}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              value={companysList.find(company => company.id === selectedCompany) || null}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={<Typography>Select Company</Typography>} error={isSubmited && !isSelectedCompany} helperText={isSubmited && !isSelectedCompany && "Please select company"} />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          height="100%" // Set height to 100%
                        >
                          <FormControl className={classes.formControl} style={{ width: '100%' }}>
                            <Autocomplete
                              id="region-select"
                              options={regionsList}
                              onChange={handleRegionChange}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              value={regionsList.find(region => region.id === selectedRegion) || null}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Region" error={isSubmited && !isSelectedRegion} helperText={isSubmited && !isSelectedRegion && "Please select region"} />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          height="100%" // Set height to 100%
                        >
                          <FormControl className={classes.formControl} style={{ width: '100%' }}>
                            <Autocomplete
                              id="role-select"
                              options={roleList.filter(role => !(role === 'manufacturer' && !isManufacturer))}
                              onChange={handleRoleChange}
                              value={selectedRole}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Role" error={isSubmited && !isSelectedRole} helperText={isSubmited && !isSelectedRole && "Please select role"} />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item md={2} sm={12} xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={isAdmin} onChange={handleIsAdminChange} />}
                          label="ManufacturerAdmin"
                        />
                      </Grid>
                      <Grid item md={2} sm={12} xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={disable2FA} onChange={handleDisable2FA} />}
                          label="Disable 2FA"
                        />
                      </Grid>
                      <Grid item md={2} sm={12} xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={enable2FA} onChange={handleEnable2FA} />}
                          label="Re-enable 2FA"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop="5px">
                    <Button variant="contained" color="secondary" onClick={handleLoginAsUser}>
                      Login As User
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: '10px' }}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </CardContent>
            </TabPanel>

            {/* <TabPanel value={middleTabIndex} index={1}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Last Login Information</Typography>
                <Typography>Last Login Time: {selectedUserInfo.loginTimestamp ? selectedUserInfo.loginTimestamp : " - "}</Typography>
                <Typography>Last Login IP: {selectedUserInfo.loginIP ? selectedUserInfo.loginIP == "::1" ? " Local Login" : selectedUserInfo.loginIP : " - "}</Typography>
              </CardContent>
            </TabPanel> */}

            <TabPanel value={middleTabIndex} index={1}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={3} sm={3} xs={3}>
                    <TextField value="Last Login Time:" InputProps={{ disableUnderline: true }} />
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <TextField value={selectedUserInfo.loginTimestamp ? selectedUserInfo.loginTimestamp : " - "} InputProps={{ disableUnderline: true }} />
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <TextField value="Last Login IP:" InputProps={{ disableUnderline: true }} />
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <TextField value={selectedUserInfo.loginIP ? selectedUserInfo.loginIP == "::1" ? " Local Login" : selectedUserInfo.loginIP : " - "} InputProps={{ disableUnderline: true }} />
                  </Grid>
                </Grid>
              </CardContent>
            </TabPanel>

          </Card>
          <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Grid>
      </Grid>
    </Page>
  );
}

export default EditUser;