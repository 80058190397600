import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Page = forwardRef(({
	children,
	title = '',
	titleExtras,
	...rest
}, ref) => {
	return (
		<div
			ref={ref}
			{...rest}
		>
			<Helmet>
				<title>{title}</title>

			</Helmet>{titleExtras}
			{children}
		</div>
	);
});

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};

export default Page;
