import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    TextField,
    Tooltip,
    makeStyles,
    Input,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@material-ui/data-grid';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import ReplayIcon from '@mui/icons-material/Replay';
import Notification from './shared/notification.component';
import EdgeOrREST from '../EdgeOrREST';
import userAction from '../store/actions/user';
import luminaireActions from '../store/actions/luminaireActions';
import roadActions from '../store/actions/roadActions';
import gridActions from '../store/actions/gridActions';
import columnActions from '../store/actions/columnActions';
import tourActions from '../store/actions/tourActions';
import { style, tree } from 'd3';
import { initialState } from '../store/initial-state';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        height: '65vh',
        width: '100%',
    },
    input: {
        display: 'none',
    },
    rowStyling: {
        backgroundColor: 'lightgreen',
    },
}));

const ConfigurationPage = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [ids, setIds] = React.useState([]);
    const [data, setData] = React.useState(null);
    const [description, setDescription] = React.useState("Description");
    const [downloading, setDownloading] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false
    });
    const [cellValues, setCellValues] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmType, setConfirmType] = React.useState('');
    const [fileDisable, setFileDisable] = React.useState(false)

    const handlConfirmClose = () => {
        setConfirmOpen(false);
    };
    const classes = useStyles();

    const handleTextfieldChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSave = (event) => {
        if (state.EnabledTour.helpMode){
            dispatch(tourActions.UpdateHelpURL({ helpURL: "Configs"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you save/reset your configurations. Make sure to give them a name in the text input above before saving!"}))
		} else {
        const tempState = state;
        tempState.user = [];
        tempState.NotificationHistory = [];
        tempState.LuminaireCfg.groupResults = [];
        const lrsObject = {
            header: "Optimize",
            body: tempState,
        }
        console.log(tempState);
        const postobject = {
            description: description,
            config: JSON.stringify(lrsObject)
        }
        EdgeOrREST(null, "SET_OPTIMIZE_SESSION", saveOptimizeSessionResult, postobject);
    }
    }

    const saveOptimizeSessionResult = (response) => {
        if (response && response.status == "200") {
            setSnack({
                open: true,
                message: 'Configuration Saved',
                severity: 'success'
            });
        } else {
            setSnack({
                open: true,
                message: "Configuration Save Failed",
                severity: 'error'
            });
        }
    }

    const handleLoad = () => {
        EdgeOrREST(null, "GET_OPTIMIZE_SESSION_BY_ID", getOptimizeSessionByIdResult,  { headers: { 'id': cellValues.row.id } });
    }

    const getOptimizeSessionByIdResult = (response) => {
        if (response && response.status == "200") {
            const config = JSON.parse(response.data.config).body;
            state.user.currentConfig = cellValues.row;

            dispatch(userAction.UpdateUserCfg(state.user));
            dispatch(luminaireActions.UpdateLuminCfg(config.LuminaireCfg));
            dispatch(gridActions.UpdateGridsCfg(config.GridsCfg));
            dispatch(columnActions.UpdateColumnCfg(config.ColumnCfg));
            dispatch(roadActions.AddToCurrentState(config.ProjectConfig));
            dispatch(roadActions.UpdateStandardsCfg(config.ProjectConfig.StandardsCfg));
            setSnack({
                open: true,
                message: 'Configuration Loaded',
                severity: 'success'
            });
            handlConfirmClose();
        }
        else {
            setSnack({
                open: true,
                message: "Configuration Load Failed",
                severity: 'error'
            });
        }
    }

    const handleReset = (event, cellValues) => {
        if (state.EnabledTour.helpMode){
            dispatch(tourActions.UpdateHelpURL({ helpURL: "Configs"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "This button resets the current configuration to default."}))
		} else {
        var resetState = initialState;
        resetState.LuminaireCfg.selectedFolderIds = [];
        resetState.LuminaireCfg.selectedLuminaireIds = [];
        dispatch(luminaireActions.UpdateLuminCfg(resetState.LuminaireCfg));
        dispatch(gridActions.UpdateGridsCfg(resetState.GridsCfg));
        dispatch(columnActions.UpdateColumnCfg(resetState.ColumnCfg));
        dispatch(roadActions.AddToCurrentState(resetState.ProjectConfig));
        dispatch(roadActions.UpdateStandardsCfg(resetState.ProjectConfig.StandardsCfg));
        setSnack({
            open: true,
            message: 'Configuration Reset to Default',
            severity: 'success'
        });
    }
    }

    const handleUpdate = () => {
        const lrsObject = {
            header: "Optimize",
            body: state,
        }
        const postobject = {
            id: cellValues.row.id,
            description: cellValues.row.description,
            config: JSON.stringify(lrsObject)
        }
        EdgeOrREST(null, "UPDATE_OPTIMIZE_SESSION", updateOptimizeSession, postobject);
        handlConfirmClose();
    }

    const actionButtonClicked = (event, cellValues) => {
        setCellValues(cellValues);
        setConfirmOpen(true);
        setConfirmType(cellValues.field);
    }

    const updateOptimizeSession = (response) => {
        if (response.status == "200") {
            setSnack({
                open: true,
                message: 'Configuration Updated',
                severity: 'success'
            });
        } else {
            setSnack({
                open: true,
                message: response.status + " " + response.message,
                severity: 'error'
            });
        }
    }

    const handleDelete = () => {
        EdgeOrREST(null, "DELETE_OPTIMIZE_SESSION", deleteOptimizeSession, cellValues.row);
        handlConfirmClose();
    };

    const deleteOptimizeSession = (response) => {
        if (response.status == "200") {
            setSnack({
                open: true,
                message: 'Configuration Deleted',
                severity: 'success'
            });
        } else {
            setSnack({
                open: true,
                message: response.status + " " + response.message,
                severity: 'error'
            });
        }
    }

    React.useEffect(() => {
        EdgeOrREST(null, "GET_OPTIMIZE_SESSION_DESCRIPTION", getOptimizeSessionResult, null);
    }, [snack]);

    const getOptimizeSessionResult = (response) => {
        setData(response.data);
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const handleExportLRSR = () => {
        setDownloading(true);
        if (ids.length == 0) {
            setSnack({
                open: true,
                message: 'Please Select Row to Export',
                severity: 'warning'
            });
        } else {
            for (const id of ids.selectionModel) {
                EdgeOrREST(null, "GET_LRSR", getLrsr, { headers: { 'id': id } });
            }
        }
    };

    const getLrsr = (response) => {
        console.log("export Lrsr");
        console.log(response);
        if (response && response.status == '200') {
            setDownloading(false);
            var mediaType = "data:application/x-gzip;base64,";
            var a = document.createElement('a');
            a.href = mediaType + response.data.base64zip;
            a.download = response.data.filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setSnack({
                open: true,
                message: 'Export Lrsr success',
                severity: 'success'
            });
        } else {
            setDownloading(false);
            setSnack({
                open: true,
                message: "Downloading of results failed",
                severity: 'error'
            });
        }
    }

    const handleExportLRS = () => {
        setDownloading(true);
        console.log("handleExportLRS");
        if (ids.length == 0) {
            setSnack({
                open: true,
                message: 'Please Select Row to Export',
                severity: 'warning'
            });
        } else {
            for (const id of ids.selectionModel) {
                EdgeOrREST(null, "GET_LRS", getLRS, { headers: { 'id': id } });
            }
        }
    };

    const getLRS = (response) => {
        console.log("export LRS");
        console.log(response);
        if (response && response.status == '200') {
            setDownloading(false);
            var mediaType = "data:application/x-gzip;base64,";
            var a = document.createElement('a');
            a.href = mediaType + response.data.base64zip;
            a.download = response.data.filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setSnack({
                open: true,
                message: 'Export LRS success',
                severity: 'success'
            });
        } else {
            setDownloading(false);
            setSnack({
                open: true,
                message: "Downloading of results failed",
                severity: 'error'
            });
        }
    }

    const handleImportLRS = (e) => {
        setDownloading(true);
        // console.log("handleImportLRS: " + e.target.files);

        for (const file of e.target.files) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                EdgeOrREST(null, 'IMPORT_OPTIMIZE_SESSION', importLRS, ({ data: reader.result.split(',').pop(), fileName: file.name }));
            };
        }
    };

    const importLRS = (response) => {
        console.log("import LRS");
        if (response && response.status == '200') {
            setSnack({
                open: true,
                message: 'Configuration import success',
                severity: 'success'
            });
        } else {
            setSnack({
                open: true,
                message: "Import files failed: " + response.message,
                severity: 'error'
            });
        }
    }

    const mapDataToRows = (data) => data ? data.map((row) => ({
        id: row.id,
        description: row.description,
        timestamp: row.timestamp,
    })) : [];

    const columns = [
        {
            field: 'description', headerName: 'Description', filterable: true, width: 160,
            renderCell: (params) => (
                <Tooltip title={params.value} >
                    <div>
                        {params.value}
                    </div>
                </Tooltip>)
        },
        { field: 'timestamp', headerName: 'Time', filterable: true, width: 150 },
        {
            field: 'load', headerName: 'Load', sortable: false, filterable: false, width: 65,
            renderCell: (cellValues) => (
                <IconButton onClick={(event) => actionButtonClicked(event, cellValues)} >
                    <ReplayIcon />
                </IconButton>
            ),
        },
        {
            field: 'update', headerName: 'Update', sortable: false, filterable: false, width: 80,
            renderCell: (cellValues) => (
                <IconButton onClick={(event) => actionButtonClicked(event, cellValues)}>
                    <PublishIcon />
                </IconButton>
            ),
        },
        {
            field: 'delete', headerName: 'Delete', sortable: false, filterable: false, width: 75,
            renderCell: (cellValues) => (
                <IconButton onClick={(event) => actionButtonClicked(event, cellValues)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
        { field: 'id', headerName: 'Id', filterable: false, hide: true },
    ];

    const getRowClassName = useCallback((params) => {
        if (state?.user?.currentConfig?.id === params.row.id) return classes.rowStyling
    }, [state, classes]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button
                    className="MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => handleExportLRS()} >
                    <DownloadIcon />Export LR Studio
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
                <TextField
                    required
                    id="outlined-required"
                    label="Description for New Configuration"
                    fullWidth
                    defaultValue="Description"
                    onChange={handleTextfieldChange}
                />
            </Grid>
            <Grid item md={3} xs={3}>
                <Button
                    color="primary"
                    variant="contained"
                    className="MuiButton-textPrimary"
                    fullWidth
                    onClick={(event) => handleSave(event)}>
                    Save
                </Button>
            </Grid>
            <Grid item md={3} xs={3}>
                <Button
                    color="primary"
                    variant="contained"
                    className="MuiButton-textPrimary"
                    fullWidth
                    onClick={(event) => handleReset(event)}>
                    Reset
                </Button>
            </Grid>
            <Grid item md={4} xs={4}>
                <input
                    disabled={fileDisable}
                    type="file"
                    id="contained-button-file"
                    onChange={(e) => handleImportLRS(e)}
                    multiple
                    className={classes.input}
                    accept=".LRS"
                />
                <label htmlFor="contained-button-file">
                    <Button
                        color="primary"
                        variant="contained"
                        component="span"
                        onMouseDown={() => {if (state.EnabledTour.helpMode){
                            setFileDisable(true)
                        }else{
                            setFileDisable(false)
                        }}}
                        onClick={() => {if (state.EnabledTour.helpMode){
                            dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you import saved LRS configurations from your local files."}))
                        }}}
                        fullWidth
                        className="MuiButton-textPrimary">
                        Import LRS
                    </Button>
                </label>
            </Grid>
            Last loaded configuration: {state?.user?.currentConfig?.description}
            <div className={classes.root}>
                <DataGrid
                    rows={mapDataToRows(data)}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    disableSelectionOnClick={true}
                    disableColumnMenu={true}
                    autoPageSize={true}
                    checkboxSelection={true}
                    onSelectionModelChange={(ids) => { setIds(ids) }}
                    getRowClassName={getRowClassName}
                />
            </div>
            <Dialog
                open={confirmOpen}
                onClose={handlConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{cellValues?.field == 'update' ? "Update" : cellValues?.field == 'delete' ? "Delete" : "Load"} Configuration</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {cellValues?.field} configuration {cellValues?.row.description} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cellValues?.field == 'update' ? handleUpdate : cellValues?.field == 'delete' ? handleDelete : handleLoad} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handlConfirmClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Grid>

    );
}

export default ConfigurationPage;