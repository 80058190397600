import { apiService } from './axios.client';

export const StandardEndPoints = {
	getStandard: function (input, callback) {
		if (input.props) {
			apiService.post('/standard/getStandard', input.postObject)
				.then((response) => callback(response, input.props))
				.catch(error => alert(error.response.data.message));
		}
		else {
			apiService.post('/standard/getStandard', input)
				.then(callback)
				.catch(error => alert(error.response.data.message));
		}
	},
	getGridClass: function (input, callback) {
		apiService.post('/standard/getGridClass', input.postObject)
			.then((response) => callback(response, input.props))
			.catch(error => alert(error.response.data.message));
	}
}