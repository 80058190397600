import React, { Component } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import { connect } from 'react-redux';
import {
    Card,
    CardContent,
    Divider,
    Paper,
    Tabs,
    Tab,
    FormGroup,
    FormControl,
    makeStyles,
    Button,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Typography,
    Slider,
    Switch,
    MenuItem,
    Select,
    TextField,
    MenuList,
    Tooltip,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PhotometricAttributeMenu from './photometice-attributes-menu/photometric-attribute-menu.component';
import Notification from '../components/shared/notification.component';
import searchActions from '../store/actions/searchActions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    },
    formControl: {
        width: '230px',
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formGroup: {
        margin: theme.spacing(1),
    },
    formLabel: {
        color: 'darkblue',
        marginBottom: '0px'
    },
    paper: {
        boxShadow: '0 0 0 rgb(63 63 68 / 5%), 0 1px 2px rgb(63 63 68 / 15%)'
    },
    formGroupSlider: {
        marginRight: '10px',
        marginLeft: '10px'
    }

}));

const initialState = {
    //spektd options
    //light
    lightSource: 'any',
    manufacturer: 'any',
    lampBase: 'any',
    cri: [0, 100],
    r9: [0, 100],
    macAdamStep: 'any',
    direction: 'any',
    beamAngle: [0, 360],
    cctOptions: 'any',
    cct: [0, 10000],
    minLor: 0,
    opticalAccessories: 'any',
    lampCount: 0,
    //physical
    finish: 'any',
    housingMaterial: 'any',
    adjustableTilt: 'any',
    adjustableRotation: 'any',
    shape: 'any',
    height: [0, 400],
    length: [0, 3000],
    width: [0, 3000],
    installation: 'any',
    placement: 'any',
    weight: [0, 10],
    //electrical
    voltageType: 'any',
    voltage: [0, 500],
    wattage: [0, 500],
    mA: [0, 1500],
    luminWatt: [0, 500],
    driverType: 'any',
    control: 'any',
    applianceClass: 0,
    //protection
    ip: 0,
    ik: 0,
    operatingTemp: [-30, 100],
    emergencyLight: 'any',
    antiSlip: 'any',
    designLabel: 'any',
    //integration
    daylightSensors: 'any',
    motionSensors: 'any',
    presenceSensors: 'any',
    //environmental
    tm66: 0,
    envCert: 'any',
    brands: 'any',
};

const PhotometricSearch = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [searchParams, setSearchParams] = React.useState(initialState);
    const buttonRef = React.useRef(null);
    const [disableSearchButton, setDisableSearchButton] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false
    });

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const onCriChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            cri: newValue
        }));
    };

    const onR9Change = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            r9: newValue
        }));
    };

    const onBeamAngleChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            beamAngle: newValue
        }));
    };

    const onCctChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            cct: newValue
        }));
    };

    const onHeightChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            height: newValue
        }));
    };

    const onLengthChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            length: newValue
        }));
    };

    const onWidthChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            width: newValue
        }));
    };

    const onWeightChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            weight: newValue
        }));
    };

    const onVoltageChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            voltage: newValue
        }));
    };

    const onWattageChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            wattage: newValue
        }));
    };
    const onMAChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            mA: newValue
        }));
    };
    const onLuminWattChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            luminWatt: newValue
        }));
    };
    const onOperatingTempChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            operatingTemp: newValue
        }));
    };

    const onSetSearchParams = (e, object) => {
        const { name, value } = e.target;
        console.log("name, value", name, value);
        if (e.target.type === "checkbox" && object === "brands") {
            setSearchParams(prevState => ({
                ...prevState,
                brands: { ...prevState.brands, [name]: e.target.checked }
            }));
        } else {
            console.log("setting basic name");
            setSearchParams(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const doSearch = (event) => {
        setSnack({
            open: true,
            message: 'Searching...',
            severity: 'info'
        });
        dispatch(searchActions.UpdateCondition({ load: true }));
        setDisableSearchButton(true);
        dispatch(user.UpdateUserCfg({ searchedLuminaires: [] }));
        EdgeOrREST(null, 'SEARCH_LUMINAIRES', onSearchLuminaires, searchParams);
    };

    const onSearchLuminaires = (response) => {
        console.log("on search luminaires");
        console.log("response", response);
        if (response && response.data) {
            dispatch(user.UpdateUserCfg({ searchedLuminaires: response.data }));
            setSnack({
                open: true,
                message: 'Search completed. ' + response.data.length + ' luminaire(s) found.',
                severity: 'success'
            });
        }
        dispatch(searchActions.UpdateCondition({ load: false }));
        setDisableSearchButton(false);
    }

    function selectItem(title, id, labelId, name, value, onSetSearchParams, itemsValue, selectOptions) {
        return (
            <FormControl className={classes.formControl} size="small">
                <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                    {title}
                </Typography>
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    onChange={onSetSearchParams}
                    name={name}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: 'auto',
                            },
                        },
                    }}
                >
                    {itemsValue.map((item, id) => {
                        return (
                            <MenuItem value={item}><Typography >{selectOptions[id]}</Typography ></MenuItem>
                        )

                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <Divider />
            <CardContent>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={doSearch}
                    style={{ float: 'right' }}
                    disabled={disableSearchButton}
                >
                    <KeyboardArrowRightIcon fontSize="small" /> Search
                </Button>

                <div ref={buttonRef} style={{ width: '136px' }} />
                {PhotometricAttributeMenu({
                    props, category: 'Light', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                {selectItem("Light source", "lightSource", "lightSource", "lightSource", searchParams.lightSource, onSetSearchParams,
                                    ["any", "LED", "Fluorescent", "Metal Halide", "Halogen", "OLED", "Fibreoptic", "Neon", "Laser", "Other"],
                                    ["Any", "LED", "Fluorescent", "Metal Halide", "Halogen", "OLED", "Fibreoptic", "Neon", "Laser", "Other"])}
                                {selectItem("Light source manufacturer", "manufacturer", "manufacturer", "manufacturer", searchParams.manufacturer, onSetSearchParams,
                                    ["any", "Bridgelux", "Calex", "Citizen", "Cree", "Philips", "Samsung", "Seoul", "Xicato", "Undefined/not fixed", "Other"],
                                    ["Any", "Bridgelux", "Calex", "Citizen", "Cree", "Philips", "Samsung", "Seoul", "Xicato", "Undefined/not fixed", "Other"])}
                                {selectItem("Lamp base", "lampBase", "lampBase", "lampBase", searchParams.lampBase, onSetSearchParams,
                                    ["any", "E14", "E27", "BC14", "BC22", "GU10", "GU5.3", "Other"],
                                    ["Any", "E14", "E27", "BC14", "BC22", "GU10", "GU5.3", "Other"])}
                            </FormGroup>

                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        CRI
                                    </Typography>
                                    <Slider
                                        value={[searchParams.cri[0], searchParams.cri[1]]}
                                        onChange={onCriChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        step={1}
                                        min={0}
                                        max={100}
                                        marks={[{ value: 0, label: '0' }, { value: 100, label: '100' }]}
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        R9 Value
                                    </Typography>
                                    <Slider
                                        value={[searchParams.r9[0], searchParams.r9[1]]}
                                        onChange={onR9Change}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        step={1}
                                        min={0}
                                        max={100}
                                        marks={[{ value: 0, label: '0' }, { value: 100, label: '100' }]}
                                    />
                                </FormControl>

                                {selectItem("MacAdam step", "macAdamStep", "macAdamStep", "macAdamStep", searchParams.macAdamStep, onSetSearchParams,
                                    ["any", "1", "2", "3", "4", "5+"],
                                    ["Any", "1 step", "2 step", "3 step", "4 step", "5+ step"])}

                                {selectItem("Direction", "direction", "direction", "direction", searchParams.direction, onSetSearchParams,
                                    ["any", "Direct", "Asymmetric", "Direct/Indirect", "Omnidirect", "Multidirectional", "Other"],
                                    ["Any", "Direct", "Asymmetric", "Direct/Indirect", "Omnidirect", "Multidirectional", "Other"])}

                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        Beam Angle
                                    </Typography>
                                    <Slider
                                        value={[searchParams.beamAngle[0], searchParams.beamAngle[1]]}
                                        onChange={onBeamAngleChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        step={5}
                                        min={0}
                                        max={360}
                                        marks={[{ value: 0, label: '0°' }, { value: 360, label: '360°' }]}
                                    />
                                </FormControl>
                                {selectItem("CCT options", "cctOptions", "cctOptions", "cctOptions", searchParams.cctOptions, onSetSearchParams,
                                    ["any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"],
                                    ["Any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"])}
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        CCT
                                    </Typography>
                                    <Slider
                                        value={[searchParams.cct[0], searchParams.cct[1]]}
                                        onChange={onCctChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        color='primary'
                                        step={100}
                                        min={0}
                                        max={10000}
                                        marks={[{ value: 0, label: '0K' }, { value: 10000, label: '10000K' }]}
                                    />
                                </FormControl>
                                {selectItem("LOR (min)", "minLor", "minLor", "minLor", searchParams.minLor, onSetSearchParams,
                                    [0, 50, 60, 65, 70, 75, 80, 85, 90, 95, 100],
                                    ["Any", "50", "60", "65", "70", "75", "80", "85", "90", "95", "100"])}
                                {selectItem("Optical accessories", "opticalAccessories", "opticalAccessories", "opticalAccessories", searchParams.opticalAccessories, onSetSearchParams,
                                    ["any", "true", "false"],
                                    ["Any", "Yes", "No"])}
                                {selectItem("Number of lamps/light engines", "lampCount", "lampCount", "lampCount", searchParams.lampCount, onSetSearchParams,
                                    [0, 1, 2, 3, 4],
                                    ["Any", "1", "2", "3", "4+"])}
                            </FormGroup>
                        </>
                })}

                {PhotometricAttributeMenu({
                    props, category: 'Physical', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                {selectItem("Finish", "finish", "finish", "finish", searchParams.finish, onSetSearchParams,
                                    ["any", "Black", "White", "Grey", "Silver", "Gold", "Bronze", "Rust", "Copper", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Unpainted", "Other"],
                                    ["Any", "Black", "White", "Grey", "Silver", "Gold", "Bronze", "Rust", "Copper", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Unpainted", "Other"])}
                                {selectItem("Luminaire Housing Material", "housingMaterial", "housingMaterial", "housingMaterial", searchParams.housingMaterial, onSetSearchParams,
                                    ["any", "Aluminium", "Stainless steel", "Steel", "Nickel", "Plastic", "Glass", "Wood", "Brass", "Other"],
                                    ["Any", "Aluminium", "Stainless steel", "Steel", "Nickel", "Plastic", "Glass", "Wood", "Brass", "Other"])}
                                {selectItem("Adjustable Tilt", "adjustableTilt", "adjustableTilt", "adjustableTilt", searchParams.adjustableTilt, onSetSearchParams,
                                    ["any", "true", "false"],
                                    ["Any", "Yes", "No"])}
                                {selectItem("Adjustable Rotation", "adjustableRotation", "adjustableRotation", "adjustableRotation", searchParams.adjustableRotation, onSetSearchParams,
                                    ["any", "true", "false"],
                                    ["Any", "Yes", "No"])}
                                {selectItem("Shape & Dimension", "shape", "shape", "shape", searchParams.shape, onSetSearchParams,
                                    ["any", "Round", "Square", "Rectangle", "Sphere", "Curve", "Custom", "Other"],
                                    ["Any", "Round", "Square", "Rectangle", "Sphere", "Curve", "Custom", "Other"])}
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Height(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.height[0], searchParams.height[1]]}
                                        onChange={onHeightChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='height'
                                        id="height"
                                        step={10}
                                        min={0}
                                        max={400}
                                        marks={[{ value: 0, label: '0' }, { value: 400, label: '400' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Length(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.length[0], searchParams.length[1]]}
                                        onChange={onLengthChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='length'
                                        step={50}
                                        min={0}
                                        max={3000}
                                        marks={[{ value: 0, label: '0' }, { value: 3000, label: '3000' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Width(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.width[0], searchParams.width[1]]}
                                        onChange={onWidthChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='width'
                                        step={10}
                                        min={0}
                                        max={3000}
                                        marks={[{ value: 0, label: '0' }, { value: 3000, label: '3000' }]}
                                    />
                                </FormControl>

                                {selectItem("Installation and Cut-out", "installation", "installation", "installation", searchParams.installation, onSetSearchParams,
                                    ["any", "Recessed-trim", "Recessed-trimless", "Semi-recessed", "Surface", "Suspended", "Free-standing", "Track", "Other"],
                                    ["Any", "Recessed-trim", "Recessed-trimless", "Semi-recessed", "Surface", "Suspended", "Free-standing", "Track", "Other"])}
                                {selectItem("Placement", "placement", "placement", "placement", searchParams.placement, onSetSearchParams,
                                    ["any", "Wall", "Ceiling", "Floor", "Desk/table", "Pole top", "Other"],
                                    ["Any", "Wall", "Ceiling", "Floor", "Desk/table", "Pole top", "Other"])}
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Weight(kg)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.weight[0], searchParams.weight[1]]}
                                        onChange={onWeightChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='weight'
                                        step={0.25}
                                        min={0}
                                        max={10}
                                        marks={[{ value: 0, label: '0' }, { value: 10, label: '10' }]}
                                    />
                                </FormControl>
                            </FormGroup>
                        </>
                })}

                {PhotometricAttributeMenu({
                    props, category: 'Electrical', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row >
                                <FormControl className={classes.formControl} size="small">
                                    <Typography className={classes.formLabel} gutterBottom>Voltage Type</Typography>
                                    <RadioGroup aria-label="voltageType" name="voltageType" id='voltageType' value={searchParams.voltageType}
                                        row onChange={onSetSearchParams}>
                                        <FormControlLabel
                                            value="any"
                                            control={<Radio color='primary' size='small' />}
                                            label="Any"
                                        />
                                        <FormControlLabel
                                            value="AC"
                                            control={<Radio color='primary' size='small' />}
                                            label="AC"
                                        />
                                        <FormControlLabel
                                            value="DC"
                                            control={<Radio color='primary' size='small' />}
                                            label="DC"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Voltage"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.voltage[0], searchParams.voltage[1]]}
                                        onChange={onVoltageChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='voltage'
                                        step={2}
                                        min={0}
                                        max={500}
                                        marks={[{ value: 0, label: '0' }, { value: 500, label: '500' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Luminaire Wattage"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.wattage[0], searchParams.wattage[1]]}
                                        onChange={onWattageChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='wattage'
                                        step={10}
                                        min={0}
                                        max={500}
                                        marks={[{ value: 0, label: '0' }, { value: 500, label: '500' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"MilliAmp"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.mA[0], searchParams.mA[1]]}
                                        onChange={onMAChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='mA'
                                        step={100}
                                        min={0}
                                        max={1500}
                                        marks={[{ value: 0, label: '0' }, { value: 1500, label: '1500' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Lumin per Watt"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.luminWatt[0], searchParams.luminWatt[1]]}
                                        onChange={onLuminWattChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='luminWatt'
                                        step={10}
                                        min={0}
                                        max={500}
                                        marks={[{ value: 0, label: '0' }, { value: 500, label: '500' }]}
                                    />
                                </FormControl>
                                {selectItem("Driver Type", "driverType", "driverType", "driverType", searchParams.driverType, onSetSearchParams,
                                    ["any", "integral", "external"], ["Any", "Integral", "External"])}
                                {selectItem("Control", "control", "control", "control", searchParams.control, onSetSearchParams,
                                    ["any", "wired dali", "wired dali 2", "wired 0-10v", "wired 1-10v", "wired dali + 1-10v", "wired dsi", "wired leading edge", "wired trailing edge", "wired switch dim", "wired push dim", "wired non dim", "wired dmx", "wireless casambi", "wireless xicato", "other"],
                                    ["Any", "Wired DALI", "Wired DALI 2", "Wired 0-10v", "Wired 1-10v", "Wired DALI + 1-10v", "Wired DSi", "Wired Leading Edge", "Wired Trailing Edge", "Wired Switch Dim", "Wired Push Dim", "Wired Non Dim", "Wired DMX", "Wireless Casambi", "Wireless Xicato", "Other"])}
                                {selectItem("Appliance Class", "applianceClass", "applianceClass", "applianceClass", searchParams.applianceClass, onSetSearchParams,
                                    [0, 1, 2, 3], ["Any", "1", "2", "3"])}
                            </FormGroup>
                        </>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Protection', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row >
                                {selectItem("Min IP Rating", "ip", "ip", "ip", searchParams.ip, onSetSearchParams,
                                    [0, 20, 23, 44, 54, 65, 66, 67, 68],
                                    ["Any", "IP 20", "IP 23", "IP 44", "IP 54", "IP 65", "IP 66", "IP 67", "IP 68"])}
                                {selectItem("Min IK Rating", "ik", "ik", "ik", searchParams.ik, onSetSearchParams,
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                    ["Any", "IK 01", "IK 02", "IK 03", "IK 04", "IK 05", "IK 06", "IK 07", "IK 08", "IK 09", "IK 10"])}
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Ambient Operating Temperature(°C)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.operatingTemp[0], searchParams.operatingTemp[1]]}
                                        onChange={onOperatingTempChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='operatingTemp'
                                        step={10}
                                        min={-30}
                                        max={100}
                                        marks={[{ value: -30, label: '-30' }, { value: 100, label: '100' }]}
                                    />
                                    {selectItem("Emergency Light", "emergencyLight", "emergencyLight", "emergencyLight", searchParams.emergencyLight, onSetSearchParams,
                                        ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                    {selectItem("Anti-Slip", "antiSlip", "antiSlip", "antiSlip", searchParams.antiSlip, onSetSearchParams,
                                        ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                    {selectItem("Designation Label", "designLabel", "designLabel", "designLabel", searchParams.designLabel, onSetSearchParams,
                                        ["any", "CCC", "CE", "ENEC", "F", "FF", "GS", "M", "MM", "UL", "OTHER"],
                                        ["Any", "CCC", "CE", "ENEC", "F", "FF", "GS", "M", "MM", "UL", "OTHER"])}
                                </FormControl>
                            </FormGroup>
                        </>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Integration', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                {selectItem("Daylight sensors", "daylightSensors", "daylightSensors", "daylightSensors", searchParams.daylightSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Motion sensors", "motionSensors", "motionSensors", "motionSensors", searchParams.motionSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Presence sensors", "presenceSensors", "presenceSensors", "presenceSensors", searchParams.presenceSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            </FormGroup>
                        </>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Environment', buttonRef: buttonRef, menuList:
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                            <FormControl className={classes.formControl} size="small">
                                {selectItem("Min TM66 Score", "tm66", "tm66", "tm66", searchParams.tm66, onSetSearchParams,
                                    [0, 1, 2, 3, 4],
                                    ["Any", "1", "2", "3", "4"])}

                                <Typography className={classes.formLabel} gutterBottom>Environmental Certification</Typography>
                                <RadioGroup aria-label="envCert" name="envCert" id='envCert' value={searchParams.envCert}
                                    row onChange={onSetSearchParams}>
                                    <FormControlLabel
                                        value="any"
                                        control={<Radio color='primary' size='small' />}
                                        label="Any"
                                    />
                                    <FormControlLabel
                                        value="EPD"
                                        control={<Radio color='primary' size='small' />}
                                        label="EPD"
                                    />
                                    <FormControlLabel
                                        value="other"
                                        control={<Radio color='primary' size='small' />}
                                        label="Other"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Others', buttonRef: buttonRef, menuList:
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            {selectItem("Brands", "brands", "brands", "brands", searchParams.brands, onSetSearchParams,
                                ["any", "afx", "applelecLighting", "brightGreenTechnologyLtd", "canteloLighting", "enigma", "enttec", "heper", "luminoLighting", "optaled", "optelma", "wila"],
                                ["Any", "AFX", "Applelec Lighting", "Bright Green Technology Ltd", "Cantelo Lighting", "Enigma", "ENTTEC", "Heper", "Lumino Lighting", "Optaled", "Optelma.", "WILA"])}
                        </FormGroup>
                })}
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setSearchParams(initialState)}
                    style={{ float: 'right', margin: '10px' }}
                >
                    <RestartAltIcon fontSize="small" /> Reset
                </Button>
            </CardContent>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Card>
    );
}
export default PhotometricSearch; 