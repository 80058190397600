import React, { Component, useEffect } from 'react';
import LauncherCarousel from './launcher-carousel.component';
import {
    makeStyles,
} from '@material-ui/core';
import EdgeOrREST, { EdgeCaller, EdgeMethods } from '../../EdgeOrREST';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        //   minHeight: '85vh',
        padding: theme.spacing(3)
    },
    hover: {
        "&:hover": {
            backgroundColor: 'rgb(63, 81, 181)',
            cursor: 'pointer',
            color: 'white!important',
        },
        "span": {
            backgroundColor: 'rgb(63, 81, 181)',
            cursor: 'pointer',
            color: 'white!important',
        },
    }
}));



const LauncherNews = (props) => {
    const classes = useStyles();
    const [news, setNews] = React.useState([]);

    const handleRSSResponse = (response, input) => {
        if (response.error) {
            // Handle error
            console.error('Error fetching RSS feed:', response.error);
        } else {
            // Handle successful response
            // Assuming the response contains the RSS feed data
            setNews(response.data); // Update state with the fetched data
        }
    };

    useEffect(() => {
        EdgeOrREST(null, "GET_RSS_FEED", handleRSSResponse, null);
    }, []);

    function createNewsPanels() {
            if (news && news.length > 0) {
            return (<LauncherCarousel news={news} />);
        }
        else {
            return (<div>Loading news feed...</div>);
        }
    }   

    return (
        createNewsPanels()
    );

}
export default LauncherNews;
