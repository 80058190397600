import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Tabs, Tab, Button, Paper, Grow, ClickAwayListener, MenuItem, Popper, MenuList, CircularProgress, } from '@material-ui/core';
import ResultsTable from './Comp_ResultsTable';
import OptimizeButton from '../../components/Luminaire/Comp_OptimizeButton';
import ExportButton from '../../components/Results/Comp_ExcelConvert';
import PdfRenderer from '../../components/pdf/pdf-renderer.component';
import PdfConfig from '../../components/pdf/pdf_config.component';
import SettingsIcon from '@mui/icons-material/Settings';
import ResultsTableSettings from "./Comp_ResultsTableSettings";
import Loading from '../shared/loading.component';
import { Skeleton } from '@mui/material';

const ResultsPage = () => {
    const props = useSelector(state => state);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openSetting, setOpenSetting] = React.useState(false);
    const anchorRefSetting = React.useRef(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSettingToggle = () => {
        setOpenSetting((prevOpenSetting) => !prevOpenSetting);
    };

    const handleSettingClose = (event) => {
        if (anchorRefSetting.current && anchorRefSetting.current.contains(event.target)) {
          return;
        }
        setOpenSetting(false);
    };

    function handleSettingListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpenSetting(false);
        }
    }

    useEffect(() => {
        if (selectedTab >= props.GridsCfg.RoadGrids.length) {
            setSelectedTab(props.GridsCfg.RoadGrids.length - 1);
        }
    }, [props.GridsCfg.RoadGrids.length]);


    return (
        <Grid container spacing={3} style={{ maxHeight: '78vh', overflowY: 'auto' }}>
            <Grid item md={"auto"} xs={"auto"}>
                <OptimizeButton />
            </Grid>
            <Grid item md={"auto"} xs={"auto"}>
                <OptimizeButton min={true} />
            </Grid>
            <Grid item md={"auto"} xs={"auto"}>
                <ExportButton />
            </Grid>
            <Grid item md={"auto"} xs={"auto"}>
                <PdfConfig />
            </Grid>
            <Grid item md={"auto"} xs={"auto"}>
                <Button
                    ref={anchorRefSetting}
                    aria-controls={openSetting ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleSettingToggle}
                    color="primary"
                    title="Result Settings"
                    variant='contained'
                >
                    <SettingsIcon sx={{color: props.Select.textColor}}/>
                </Button>
                <Popper style={{zIndex: 1}} open={openSetting} anchorEl={anchorRefSetting.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleSettingClose}>
                            <MenuList style={{borderStyle: "solid", borderColor: props.Select.primary}}><ResultsTableSettings/></MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
                </Popper>
            </Grid>
            <Grid item md={9} xs={9}>
            </Grid>
            
            {props.Search.load
            ?   <Loading skeleton={true} height={700}/>
            :   <Grid item md={12} xs={12}>
                    <Tabs value={selectedTab} indicatorColor='primary' 
                        variant="fullWidth" onChange={handleTabChange}>
                        {props.GridsCfg.RoadGrids.map((grid, index) => (
                            <Tab label={`Grid ${index + 1}`} key={index} />
                        ))}
                    </Tabs>
                    {props.GridsCfg.RoadGrids.map((grid, index) => (
                        <div hidden={selectedTab !== index} key={index}>
                            <ResultsTable grid={grid} tableIndex={index} />
                        </div>
                    ))}
                </Grid>
            }
        </Grid>
    );
}

export default ResultsPage;
