import { apiService } from './axios.client';

export const SearchEndPoints = {
    getManufacturer: function (input, callback) {
        apiService.get('/search/getManufacturer', input)
            .then((response) => callback(response, input))
            .catch(error => alert(error));
    },
    getLuminaireFiles: function (input, callback) {
        apiService.get('/search/getLuminaireFiles', input)
            .then((response) => callback(response, input))
            .catch(error => alert(error));
    },
};

