import React from 'react';
import { EdgeCaller, EdgeMethods } from '../../EdgeCaller';
import {
    TextField
} from '@material-ui/core';


const states = [
    {
        value: 'en',
        label: 'Europe'
    },
    {
        value: 'de',
        label: 'Germany'
    },
    {
        value: 'tr',
        label: 'Turkey'
    },
    {
        value: 'fr',
        label: 'France'
    },
    {
        value: 'nl',
        label: 'Netherlands'
    },
    {
        value: 'es',
        label: 'Spain'
    },
    {
        value: 'ch',
        label: 'Switzerland'
    },
    {
        value: 'pt',
        label: 'Portugal'
    },
    {
        value: 'ru',
        label: 'Russia'
    },
    {
        value: 'jp',
        label: 'Japan'
    },
    {
        value: 'ar',
        label: 'Argentina'
    },
    {
        value: 'kr',
        label: 'Korea'
    },
    {
        value: 'th',
        label: 'Thailand'
    },
    {
        value: 'vi',
        label: 'Virgin Islands'
    },
    {
        value: 'ms',
        label: 'Montserrat'
    }
];
export default class Language extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropboxValues: [
                'cht',
                'el',
                'th',
                'vi',
                'ms',
            ],
            currentValue: 'en',
        };
    }

    handleChange = (event) => {
        var Language = event.target.value;
        this.setState({ currentValue: event.target.value });
        var TranslationSheet = EdgeCaller(
            Language,
            EdgeMethods.GET_TRANSLATIONS,
        );
        this.props.UpdateUserCfg({ TranslationSheet });
        this.props.UpdateUserCfg({ Language });
    };

    render() {

        return (
            <TextField
                fullWidth
                label="Select Country"
                name="state"
                onChange={this.handleChange}
                required
                select
                SelectProps={{ native: true }}
                value="en"
                variant="outlined"
            >
                {states.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>

            // <div className="labelAndInputContainer" id="DropDown-L" >
            //     <label htmlFor="Language_Input">
            //         {this.props.user.TranslationSheet[85]}
            //     </label>
            //     <select className="selectBox" id="Language_Input" onChange={this.handleChange} value={this.props.user.Language}>
            //         {DropBox}
            //     </select>
            // </div>
        );
    }
}
