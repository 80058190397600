import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {
  Paper,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions
} from '@mui/material';

export default function LauncherCarousel(props) {
  console.log("carousel", props);
  return (
    <Carousel>
      {
        props.news.map((item, i) => <Item key={i} item={item} />)
      }
    </Carousel>
  )
}

function Item(props) {
   return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          src={props.item.ImageUrl}
          alt="news pic"
          height={500}
          style={{ 
            'width': '100%',
            objectFit: 'contain',
            objectPosition: 'center'  
          }}
        />
        <CardContent>
          <Typography variant="h8" component="div">
            {props.item.Title}
          </Typography>
          <Typography variant="body2" color="text.secondary"
            sx={{
              display: '-webkit-box',
              overflow: 'auto', // Enable scrollbar for overflow content
              height: '100px', // Set a fixed height
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: props.item.Description }} />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          {props.item.PublishDate}
        </Button>
      </CardActions>
    </Card>
  )
}