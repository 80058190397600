import { handleActions } from 'redux-actions';
import actions from '../actions/gridActions';
import { initialState } from '../initial-state';

function updateObjectInArray(array, action) {
	return array.map((item, index) => {
	  if (index !== action.index) {
		// This isn't the item we care about - keep it as-is
		return item
	  }
  
	  // Otherwise, this is the one we want - return an updated value
	  return {
		...item,
		...action.payload
	  }
	})
  }

export default handleActions(
	{
		[actions.UpdateGridsCfg]: (state, action) => {
			return { ...state, ...action.payload };
		},
		[actions.UpdateCurrentGrid]: (state, action) => {
			action.index = state.currentIndex;
			return { ...state, RoadGrids: updateObjectInArray(state.RoadGrids, action) };
		},
	},
	initialState.GridsCfg
);
