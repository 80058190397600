import React, { useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControlLabel,
    makeStyles,
    Box,
    ListItem,
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import monitorActions from '../store/actions/monitorActions';
import CompanyAutocomplete from '../monitor/monitor-autocomplete.component.js';
import { useInterval } from '../automate/automate-interval-runner.component';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(),
    },
    margin: {
        margin: '0px',
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    container: {
        maxHeight: '45vh',
        minHeight: '45vh',
    },
    selectedListItem: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const UserSearch = (props) => {
    const [users, setUsers] = React.useState([]);
    const [comList, setComList] = React.useState([]);
    const [selectedComList, setSelectedComList] = React.useState([]);
    const [filterUsers, setFilterUsers] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [tempSelectedUsers, setTempSelectedUsers] = React.useState([]);
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        EdgeOrREST(null, 'GET_USER_LIST', onGetUserList, null);
    }, []);

    useEffect(() => {
        EdgeOrREST(null, 'GET_USER_LIST', onGetUserList, null);
    }, [props.forceUpdateFlag]);

    useEffect(() => {
        console.log("filterUsers", filterUsers);
    }, [filterUsers]);

    useEffect(() => {
        props.callBackSelectedUser(selectedUsers);
    }, [selectedUsers]);

    const onGetUserList = (response) => {
        if (response && response.data.userList && response.data.userList.length > 0) {
            setUsers(response.data.userList);
            setFilterUsers(response.data.userList);
            //setUserComFilter("");
        }
        if (response && response.data.comList && response.data.comList.length > 0) {
            setComList(response.data.comList);
        }
    }

    const onAutoComplete = (event, value) => {
        setSelectedComList(value);
        setIsSelectAll(false);
        setSelectedUsers([])
        if (value) {
            var comsUsers = [];
            if (value.length > 0) {
                value.forEach(element => {
                    users.forEach(user => {
                        if (user.name === element.name) comsUsers.push(user);
                    })
                });
                setFilterUsers([...comsUsers]);
            } else {
                setFilterUsers([...users]);
            }
        }
    };

    const handleSelectRow = (row, event) => {
        let userList = [{ "id": row.id, "email": row.email }];
        setSelectedUsers(userList);
    };

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <CardContent>
                <Grid item md={12} sm={12} xs={12}>
                    <CompanyAutocomplete callback={onAutoComplete} comList={comList}></CompanyAutocomplete>
                </Grid>
            </CardContent>

            <CardContent>
                <Paper sx={{ height: "50%", display: "flex", alignItems: "center" }}>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader aria-label="simple table" size='small' className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select User</TableCell>
                                    <TableCell align="left">Company</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    filterUsers.map((row) => (
                                        <TableRow key={row.id} size="small">
                                            <TableCell component="th" scope="row">
                                                <ListItem
                                                    button
                                                    onClick={(e) => handleSelectRow(row, e)}
                                                    className={selectedUsers.some(user => user.id === row.id) ? classes.selectedListItem : null}
                                                >
                                                    {row.email}
                                                </ListItem>
                                            </TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </CardContent>
        </Card>
    );
}
export default UserSearch;
