import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Collapse, 
    Box, 
    Button, 
    TableSortLabel, 
    TablePagination,
    Tooltip,
    CircularProgress
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import luminaireActions from '../../store/actions/luminaireActions';
import columnActions from '../../store/actions/columnActions';
import { Skeleton } from '@mui/material';
import Loading from '../shared/loading.component';

const skipKeys = [
    'gridArray',
    'contourArray',
    'AboveMargin', 
    'BelowMargin',
    'EnergyPerLantern',
    'Valid',
    'Status'
];

function formatNumber(num) {
    // Convert the number to a string
    const numStr = num.toString();
    
    // Check if the number has more than two decimal places
    const hasMoreThanTwoDecimals = numStr.includes('.') && numStr.split('.')[1].length > 2;
  
    // Conditionally format the number
    if (hasMoreThanTwoDecimals) {
      // Use toFixed(2) to format and convert back to number
      return +num.toFixed(2);
    } else {
      // Return the original number if it has 2 or less decimal places
      return num;
    }
  }

const ResultsTable = ({ results }) => {

    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(Array(results.length).fill(false));
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('Spacing');


    const handleClick = (index) => {
        const newOpen = [...open];
        newOpen[index] = !newOpen[index];
        setOpen(newOpen);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedResults = [...results].sort((a, b) => {
        // Move invalid results to the end
        if (!a.max.Valid) return 1;
        if (!b.max.Valid) return -1;
    
        // Sort the rest
        return (a.max[orderBy] < b.max[orderBy] ? -1 : 1) * (order === 'asc' ? 1 : -1);
    });

    const handleViewClick = (index) => {
        var gridIndex = store.GridsCfg.currentIndex;
        var optimizedValues = sortedResults[index].max;
        var gridResults = sortedResults[index].allGrids;
        var Columns = store.ColumnCfg.Columns;
        var optValues = store.ColumnCfg.optimizeValues;
        optValues.chosenValues.forEach(element => {
            element = element.charAt(0).toUpperCase() + element.slice(1);
            gridResults[0].calcValues[element] = optimizedValues[element];
            Columns.forEach((c, i) => {
                Columns[i]["column" + element] = optimizedValues[element];
            })
        });
        dispatch(luminaireActions.UpdateAllResults({
            gridIndex,
            gridResults
        }));
        dispatch(columnActions.UpdateColumnCfg({
            Columns
        }));
    };

    return (
        <>
        {store.Search.load 
        ?   <Loading skeleton={true} height={700}/>
        :   <TableContainer>
                <Table >
                <TableHead>
                        <TableRow>
                            <TableCell>Luminaire</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'Spacing'}
                                    direction={orderBy === 'Spacing' ? order : 'asc'}
                                    onClick={() => handleSort('Spacing')}
                                >
                                    Spacing
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'AECI'}
                                    direction={orderBy === 'AECI' ? order : 'asc'}
                                    onClick={() => handleSort('AECI')}
                                >
                                    AECI
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'PowerDensity'}
                                    direction={orderBy === 'PowerDensity' ? order : 'asc'}
                                    onClick={() => handleSort('PowerDensity')}
                                >
                                    PDI
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {sortedResults.map((result, index) => 
                        result.max.Valid === false || result.min.Valid === false ? (
                            <TableRow key={index}>
                                <TableCell>{result.luminaire}</TableCell>
                                <TableCell>Failed</TableCell>
                                <TableCell>Failed</TableCell>
                                <TableCell>Failed</TableCell>
                            </TableRow>
                        ) : (
                            <React.Fragment key={index}>                        
                                <TableRow > 
                                    <TableCell>{result.luminaire}</TableCell>
                                    <TableCell align="center">{result.max && result.max?.Spacing !== undefined ? result.max.Spacing : 'N/A'}</TableCell>
                                    <TableCell align="center">{result.max && result.max?.AECI !== undefined ? result.max.AECI.toFixed(2) : 'N/A'}</TableCell>
                                    <TableCell align="center">{result.max && result.max?.PowerDensity !== undefined ? result.max?.PowerDensity.toFixed(2) : 'N/A'}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Add to View">
                                        <Button size="small" onClick={() => handleViewClick(index)}>
                                            {<VisibilityIcon />}
                                        </Button>
                                        </Tooltip>
                                        <Button size="small" onClick={() => handleClick(index)}>
                                            {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Variable</TableCell>
                                                            <TableCell>Max</TableCell>
                                                            <TableCell>Min</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {result.max && Object.entries(result.max).map(([key, value], i) => (
                                                        value !== null && !skipKeys.includes(key) && (
                                                            <TableRow key={i}>
                                                                <TableCell>{key}</TableCell>
                                                                <TableCell>{typeof value === 'number' && Math.floor(value) !== value ? formatNumber(value) : value}</TableCell>
                                                                <TableCell>{result.min && result.min[key] !== undefined ? (typeof result.min[key] === 'number' && Math.floor(result.min[key]) !== result.min[key] ? formatNumber(result.min[key]) : result.min[key]) : 'N/A'}</TableCell>
                                                            </TableRow>
                                                        )
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        )
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </>
        
    );
};

export default ResultsTable;