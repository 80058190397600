import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import columnActions from '../../store/actions/columnActions';
import {
    RadarChart,
    PolarAngleAxis,
    PolarRadiusAxis,
    PolarGrid,
    Radar,
    Legend,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';

const numberFormatter = item => { if (item == 0) return 0; else return item.toFixed(2)};

const LCS = () => {

    const dispatch = useDispatch();
    const props = useSelector(state => state);
    const [array, setArray] = useState([[]]);
    let data = [];
    console.log("LCS", props.ColumnCfg.LCS);
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if(i < 30) data[i] = { "FL": element, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 30 && i < 60) data[i] = { "FL": 0, "FM": element, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 60 && i < 80) data[i] = { "FL": 0, "FM": 0, "FH": element, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 80 && i < 90) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": element, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 90 && i < 100) data[i] = { "FL": 0, "FM": 0, "FH":  0, "FVH": 0, "ULR": element, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 100 && i < 260) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": element, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 260 && i < 270) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": element, "BVH": 0, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 270 && i < 280) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": element, "BH": 0, "BM": 0, "BL": 0 }
        if (i >= 280 && i < 300) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": element, "BM": 0, "BL": 0 }
        if (i >= 300 && i < 330) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": element, "BL": 0 }
        if (i >= 330) data[i] = { "FL": 0, "FM": 0, "FH": 0, "FVH": 0, "ULR": 0, "UH": 0, "ULL": 0, "BVH": 0, "BH": 0, "BM": 0, "BL": element }
    }    

    const RenderGraph = () => {
        return (
            <ResponsiveContainer width="100%" height={500}>
            <RadarChart id="lcsChart" cx="50%" cy="50%" startAngle={-90} endAngle={270} data={data}>
                <PolarGrid gridType='circle' polarAngles={[0, 10, 170, 180, 190, 210, 240, 270, 300, 330, 350]} />
                <PolarAngleAxis type="number" dataKey="angle" tickCount={13} fontSize={20} ticks={[0, 30, 60, 80, 90, 100, 260, 270, 280, 300, 330]} />
                <PolarRadiusAxis angle={90} type="number" fontSize={16} orientation="left" domain={['auto', 'auto']} />
                <Radar dataKey="FL" stroke="#b0ffb0" fill="#b0ffb0" fillOpacity={0.75} />
                <Radar dataKey="FM" stroke="#b0f0b0" fill="#b0f0b0" fillOpacity={0.75} />
                <Radar dataKey="FH" stroke="#b0d0b0" fill="#b0d0b0" fillOpacity={0.75} />
                <Radar dataKey="FVH" stroke="#b0beb0" fill="#b0beb0" fillOpacity={0.75} />
                <Radar dataKey="ULR" stroke="#cf0000" fill="#cf0000" fillOpacity={0.75} />
                <Radar dataKey="UH" stroke="#ff0000" fill="#ff0000" fillOpacity={0.75} />
                <Radar dataKey="ULL" stroke="#cf0000" fill="#cf0000" fillOpacity={0.75} />
                <Radar dataKey="BVH" stroke="#b0b0be" fill="#b0b0be" fillOpacity={0.75} />
                <Radar dataKey="BH" stroke="#b0b0d0" fill="#b0b0d0" fillOpacity={0.75} />
                <Radar dataKey="BM" stroke="#b0b0f0" fill="#b0b0f0" fillOpacity={0.75} />
                <Radar dataKey="BL" stroke="#b0b0ff" fill="#b0b0ff" fillOpacity={0.75} />
                <Tooltip isAnimationActive={false} formatter={numberFormatter}/>
                <Legend />
            </RadarChart>
            </ResponsiveContainer>
        )
    }
    
    const handlePlot = useCallback((response) => {
        if (response != null && response?.data !== null && response.status == 200) {
            setArray(response.data.fullResults);
            dispatch(columnActions.UpdateColumnCfg({LCS: response.data}));
        }
	}, []);

	useEffect(() => {
        EdgeOrREST(null, "GET_LCS", handlePlot, API_OBJECTS.getCalculationObject(props, null, null));		
	}, []);

    return (
        <RenderGraph />
    )
}

export default LCS;