import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../src/layouts/DashboardLayout';
import RoadwayPage from './components/Roadway/Page_Roadway';
import ColumnPage from './components/Column/Page_Column';
import Login from './components/Login/login.component';
import UpdatePassword from './components/Login/update-password.component';
import ForgotPassword from './components/Login/forget-password.component';
import NewPassword from './components/Login/new-password.component';
import Dashboard from './components/Dashboard/dashboard.component';
import Visualize from './visualize/visualize.component';
import CanvasImage from './plan/plan-dashboard.component';
import Launcher from './components/launcher/launcher.component';
import AutomateDashboard from './automate/automate-dashboard.component';
import AutomateUpload from './automate/automate-upload.component';
import AutomateReports from './automate/automate-reports.component';
import AdminDashboard from './admin/admin-dashboard.component';
import PdfRenderer from './components/pdf/pdf-renderer.component';
import PhotometricViewer from './components/Luminaire/Hierarchy/photometry-viewer.component';
import PhotometricDashboard from './photometricManager/photometric-dashboard.component';
import MonitorDashboard from './monitor/monitor-dashboard.component';
import UserAdminDashboard from './userAdministrate/userAdministrate-dashboard.component';
import PhotometricSearch from './photometricManager/photometric-search.component';
import PhotometricTransferList from './photometricManager/photometric-transfer-list.component';
import PhotometricUpload from './photometricManager/photometric-upload.component';
import PhotometricInformation from './photometricManager/photometric-information.component';
import PlanDashboard from './plan/plan-dashboard.component';
import SettingsView from './components/Settings/index';
import SupportView from './components/Support/index';
import Sandbox from './plan/sandbox.component';
import SearchDashboard from './components/Search_Luminaires/Comp_LuminaireSearch';
import TwoFactorAuth from './components/Login/two-factor-auth.component';
import SVGTest from './plan/svgtest.component';

const routes = (isLoggedIn, is2FAAuthed) => [
  {
    path: '/',
    element: !isLoggedIn ? <Navigate to="/login" /> : <Navigate to={is2FAAuthed ? "/launcher" : "/2fa-setup"} />
  },
  {
    path: '/login',
    element: !isLoggedIn ? <Login /> : <Navigate to={is2FAAuthed ? "/launcher" : "/2fa-setup"} />,
  },
  {
    path: '/forgot',
    element: <ForgotPassword />
  },
  {
    path: '/resetPassword',
    element: <NewPassword />
  },
  {
    path: '/updatePassword',
    element: !isLoggedIn ? <Login /> : <UpdatePassword />
  },
  {
    path: 'automate',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Automate"} /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <AutomateDashboard />,
        children: [
          { path: 'upload', element: <AutomateUpload /> },
        ]
      },
      {
        path: '',
        element: <AutomateDashboard />,
        children: [
          { path: 'upload', element: <AutomateUpload /> },
        ]
      },
      { path: 'settings', element: <SettingsView /> },
    ]
  },
  {
    path: 'plan',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Plan"} /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <PlanDashboard />,
      },
    ]
  },
  {
    path: 'optimize',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Optimize"} /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
        children: [
          { path: 'row', element: <ColumnPage /> },
          { path: 'roadway', element: <RoadwayPage /> },
          { path: 'photometry', element: <PhotometricViewer /> },
        ]
      },
    ]
  },
  {
    path: 'photometry',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Photometric Manager"} /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <PhotometricDashboard />,
        children: [
          { path: 'search', element: <PhotometricSearch /> },
          { path: 'upload', element: <PhotometricUpload /> },
          { path: 'information', element: <PhotometricInformation /> },
          { path: 'groups', element: <PhotometricTransferList /> }
        ]
      },
      {
        path: 'photometry/',
        element: <PhotometricDashboard />,
        children: [
          { path: 'search', element: <PhotometricSearch /> },
          { path: 'upload', element: <PhotometricUpload /> },
          { path: 'information', element: <PhotometricInformation /> },
          { path: 'groups', element: <PhotometricTransferList /> }
        ]
      },
    ]
  },
  {
    path: 'search/luminaire',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Search"}/> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <SearchDashboard />,
      }
    ]
  },
  {
    path: 'hidden/',
    element: isLoggedIn && is2FAAuthed ? <Dashboard /> : <Navigate to="/login" />,
    children: [
      { path: 'visualize', element: <Visualize /> },
      { path: 'canvas', element: <CanvasImage /> }
    ]
  },
  {
    path: 'supersecret/admin/123',
    element: isLoggedIn && is2FAAuthed ? <AdminDashboard /> : <Navigate to="/login" />
  },
  {
    path: '/launcher',
    element: isLoggedIn && is2FAAuthed ? <Launcher /> : <Navigate to={isLoggedIn ? "/2fa-setup" : "/login"} />
  },
  {
    path: 'monitor',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"Monitor"} /> : <Login />,
    children: [
      {
        path: '/',
        element: <MonitorDashboard />,
      },
    ]
  },
  {
    path: 'userAdmin',
    element: isLoggedIn && is2FAAuthed ? <DashboardLayout app={"UserAdmin"} /> : <Login />,
    children: [
      {
        path: '/',
        element: <UserAdminDashboard />,
      },
    ]
  },
  {
    path: '/support',
    element: <SupportView />
  },
  {
    path: '/sandbox',
    element: <Sandbox />
  },
  {
    path: '/svgtest',
    element: <SVGTest />
  },
  {
    path: '/2fa-setup',
    element: isLoggedIn && is2FAAuthed ? <Navigate to="/launcher"/> : isLoggedIn ? <TwoFactorAuth /> : <Navigate to="/login" />
  }
];

export default routes;
