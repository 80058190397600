import React from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import searchActions from '../../store/actions/searchActions';
import EdgeOrREST from '../../EdgeOrREST';
import { 
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    Tooltip,
    IconButton,
    CircularProgress,  
    MenuItem,
    MenuList,
    Button,
    Grow,
    ClickAwayListener,
    Popper,
    Fab,  

} from '@mui/material';
import { green } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import { Divider } from '@material-ui/core';
import Notification from '../shared/notification.component';
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,  
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { SortByAlphaRounded } from '@material-ui/icons';
import SaveIcon from '@mui/icons-material/Save';
import { initialState } from '../../store/initial-state';

const columns = [
  {
    field: 'id',
    headerName: 'ID', 
    type: 'number',
    headerAlign: 'left',
    width: 80,
    sortable: false,
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    width: 175,
  },
  {
    field: 'lumcat',
    headerName: 'Lumcat',
    width: 575,
  },
  {
    field: 'flux',
    headerName: 'Flux',
    type: 'number',
    headerAlign: 'left',
    width: 125,
  },
  {
    field: 'inputWattage',
    headerName: 'Input Wattage',
    type: 'number',
    headerAlign: 'left',
    width: 175,
  },
  {
    field: 'gClass',
    headerName: 'G Class',
    type: 'number',
    headerAlign: 'left',
    width: 125,
  },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  // },
];
 
export default function SearchResults() {
  const [selectedLuminaires, setSelectedLuminaires] = React.useState([]);
  const [fileArray, setFileArray] = React.useState([]);
  const state = useSelector(state => state.Search);
  const dispatch = useDispatch();
  const isMounted = useRef(true);  

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [snack, setSnack] = React.useState({
      open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
   };

  useEffect(() => {      
      if (state.load == false){        
        if (state.oneTime == false) {
          dispatch(searchActions.UpdateCondition({ load: true }));
          setSnack({
            open: true,
            message: "DB is loading",
            severity: 'warning'
          })
          EdgeOrREST(null, 'GET_MANUFACTURER', onGetManufacturerList, null);
          dispatch(searchActions.UpdateCondition({oneTime:true}))
        }
      }      
  }, []);

  const onGetManufacturerList = (response) => {
    if (response && response.status === 200) {
        dispatch(searchActions.UpdateCondition({ load: false }));
        setSnack({
            open: true,
            message: "DB has loaded successfully",
            severity: 'success'
        });        
    } else {
        setSnack({
            open: true,
            message: "DB failed to load",
            severity: 'error'
        });
    }

    const manufacturerArray = [];
    const newRows = [];

    if (response?.data) {
        for (let i = 0; i < response.data.length; i++) {
            const item = response.data[i];
            const { id, manufacturer, lumcat, flux, inputWattage, gClass } = item;
            if ( manufacturerArray.indexOf(response?.data[i]?.manufacturer) === -1 ){
              manufacturerArray.push(manufacturer)
            }

            newRows.push({
                id: id,
                manufacturer: manufacturer,
                lumcat: lumcat,
                flux: flux,
                inputWattage: inputWattage,
                gClass: gClass,
            });
        }
    }
    dispatch(searchActions.UpdateCondition({rows:newRows}))
    manufacturerArray.sort();
    dispatch(searchActions.UpdateCondition({manufacturers:manufacturerArray}))
};


const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
  id: false,
});

const [filterModel, setFilterModel] = React.useState({
items: [
  {
    field: 'manufacturer',
    operator: 'contains',
    value: '',
  },
],
});

const handleSaveSelection = () => {
  dispatch(searchActions.UpdateCondition({luminaireSelection:selectedLuminaires}))
  dispatch(searchActions.UpdateCondition({deletedRows:false}))
  dispatch(searchActions.UpdateCondition({filesOfSelected:initialState.Search.filesOfSelected}))
  if (selectedLuminaires.length >= 1){
    dispatch(searchActions.UpdateCondition({ load: true }));
    setSnack({
      open: true,
      message: "Please wait until luminaire files are loaded",
      severity: 'warning'
    })
    for (let i = 0; i < selectedLuminaires.length; i++){
      EdgeOrREST(null, 'GET_LUMINAIRE_FILES', onGetFiles, { headers: { 'id': selectedLuminaires[i]}});
    }
  } else {
    setSnack({
      open: true,
      message: "Please select luminaire(s)",
      severity: 'error'
    });
  }
}

const onGetFiles = (response) => {
  if (isMounted.current) {
    fileArray.push(response.data);
    dispatch(searchActions.UpdateCondition({ filesOfSelected: fileArray }));

    if (fileArray.length === selectedLuminaires.length) {
      dispatch(searchActions.UpdateCondition({ load: false }));
      setSnack({
        open: true,
        message: "Selection has been saved, Continue to Refine",
        severity: 'success'
      })
    }
  }
};

function customToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },
        }}
      />
      <Button 
        variant='outlined' 
        startIcon={<SaveIcon/>}
        color='primary'
        onClick={() => handleSaveSelection()}  
      >
        Save Selection
      </Button>
    </GridToolbarContainer>
  );
}

  const CustomNoRowsOverlay = ({ loading }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography>No rows</Typography>
        )}
      </Box>
    );
  };
  

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <Paper elevation={0}>
        Manufacturers: '{state.manufacturers.join("', '")}'
      </Paper>
      <br/>
      <DataGrid   
        style={{ height: 650, width: '100%' }}
        density="compact"
        slots={{
          toolbar: customToolbar,
          noRowsOverlay: () => <CustomNoRowsOverlay loading={state.load} />,
        }}
        rows={state.rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pageSizeOptions={[25,50,75,100]}
        checkboxSelection
        disableRowSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowSelectionModelChange={(id) => setSelectedLuminaires(id)}
      />
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Box>
  );
}