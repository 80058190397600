import React, { Component } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import { API_OBJECTS } from '../API/model/calculation.model'
import { connect } from 'react-redux';
import { useState, useEffect } from 'react'
import { VisualizeEndPoints } from '../../src/API/external/visualize.service';
import exampleVsf from "../IMG/2277-base.vsf";
import sampleVsf from "../IMG/Sample.rvt.vsf";

const mapStateToProps = (state) => {
    return state;
};

const options = {
urlMemFile:
    "https://opencloud.azureedge.net/libs/visualizejs/21.7/Visualize.js.wasm",
TOTAL_MEMORY: 134217728
};
  

 

class Visualize extends Component {

    state = { 
        canvas: [
            (<canvas  onChange={this.onCanvasEvent} style={{'height' : '50%'}} id="canvas"></canvas>)
        ],
        'viewer' : null,

    };
    //onChange={this.onCanvasEvent}
    onFileChange = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            console.log(text)
            alert(text)

            this.setState({ fileText: text });
        };
        this.setState({ selectedFile: reader.readAsText(e.target.files[0]) });

    }


    onCanvasEvent = () => {
        if(this.state.viewer){
            this.resize(); 
         //   this.updateAnimationFrame();
        } 
    }


    onClickInit = () => {
        console.log("initing visualize JS");
        this.initVisualizeJs(); 
    }

   

    callback = (response) => {
        console.log(response); 
      }

     resize = () => {
         console.log("resize event trigger", this.state.viewer);
        if(this.state.viewer){
            const canvas = document.getElementById("canvas");
            var rect = canvas.getBoundingClientRect();
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
            this.state.viewer.resize(0, rect.width, rect.height, 0);
            this.state.viewer.update();
        }
    };

    //  updateAnimationFrame = () => {
        
    //     requestAnimationFrame(update);
       
    // }

    onLoadedVisualizeScript = (getVisualizeLibInst, control, callback) => {
        if(getVisualizeLibInst){
            console.log("got into the method");

            const lib =  getVisualizeLibInst(options);
            const canvas = document.getElementById("canvas");
        
            var viewer; 
            lib.postRun.push(() => {
                lib.canvas = canvas;
                lib.canvas.width = canvas.clientWidth || 500;
                lib.canvas.height = canvas.clientHeight || 500;
    
                lib.Viewer.initRender(canvas.width, canvas.height, true);
    
                viewer = lib.Viewer.create();
                this.setState({ 'viewer': viewer });

                this.resize();
                // this.setState('lib', lib); 
                // this.setState('viewer', viewer); 
                this.setState({ 'viewer': viewer });

                function update() {
                    requestAnimationFrame(update);
                    viewer.update();
                }
                update();
                if(callback){
                    callback(viewer);
                }


                fetch(exampleVsf)
                .then((response) =>  response.arrayBuffer())
                .then((buffer) => {
                    console.log("buffer",buffer);
                    console.log("array",new Uint8Array(buffer))
                    
                    viewer.clear();

                  viewer.parseFile(new Uint8Array(buffer));
                  viewer.zoomExtents();
                })
                .catch((err) => console.log(err));

                
                // var fr = new FileReader();
                // fr.onload = function() {
                //   var data = fr.result;
                //   var array = new Int8Array(data);
                //   output.value = JSON.stringify(array, null, '  ');
                //   window.setTimeout(ReadFile, 1000);
                // };
                // viewer.parseFile(fr.readAsArrayBuffer(sampleVsf));
                // viewer.zoomExtents(); 

                // var encoder = new TextEncoder("utf-8");
                // var resultArray = encoder.encode(sampleVsf.fileText);
                // console.log("rvt", sampleVsf);
                // console.log("rvt.vsf", sampleVsf.buffer);
                // console.log("dxf.vsf", sampleVsf.text);
                // console.log("dxf.vsf", sampleVsf.fileText);

                // viewer.clear();
                // viewer.parseFile(new Uint8Array(sampleVsf.buffer));
                // viewer.zoomExtents(); 
    
                control && control(canvas, viewer);
                // const model = viewer.getModelByName('Model');
                // console.log("model", model);
                function render() {
                    viewer.update();
                    requestAnimationFrame(render);
                }
                    render();
            })

            // fetch("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4353653/Sample.rvt.vsf")
            // .then((responce) => responce.arrayBuffer())
            // .then((buffer) => {
            //   viewer.clear();
            //   viewer.parseFile(new Uint8Array(buffer));
            //   viewer.zoomExtents();
            // })
            // .catch((err) => console.log(err));

             
            // function render() {
            //     viewer.update();
            //     requestAnimationFrame(render);
            // }
            //     render();
            }
    }
    onGetGeometryData = (response) => {
        return response; 
      }
    //init initial canvas, needs a file location (from open cloud) // will need pre-init request
    initVisualizeJs = (e) => {
        //controls script
        const ctrlScript = document.createElement('script')
        ctrlScript.src = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/4353653/controls.js'
        var controlMethod; 
        ctrlScript.onload = () => controlMethod = window['Controls'];
        document.body.appendChild(ctrlScript);

        //WORKING 
        var getVisualizeLibInst;
        const script = document.createElement('script')
        script.src = 'https://opencloud.azureedge.net/libs/visualizejs/21.12/Visualize.js'
        script.async = true
        script.onload = () =>  this.onLoadedVisualizeScript(window['getVisualizeLibInst'], controlMethod);
        document.body.appendChild(script);
        console.log(window);
        
    };

    onInitVisualize = (response) => {
        
    }


 
/*

    /*
    styles
     <style>
        #canvas {
            width: 100%; height: 100%; position: absolute;
            top: 0; left: 0; z-index: -1;
        }
        .lds-dual-ring {
            display: inline-block;
            width: 64px; height: 64px; position: fixed;
            top: 20%; left: CALC(50% - 32px)
        }
        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 46px; height: 46px; margin: 1px; border-radius: 50%; border: 5px solid #000;
            border-color: #000 transparent #000 transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
        @keyframes lds-dual-ring {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        .upload-header {
            display: flex; justify-content: center; padding: 10px;
        }
*/

    render() {
        return (
            <>
            <button onClick={this.onClickInit}>Init</button>
            {this.state.canvas} 
 
        
            </>
        );
    }
}
export default connect(mapStateToProps)(Visualize);

