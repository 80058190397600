import { apiService } from './axios.client';

export const DesignEndPoints = {
    convertToSVG: function (file, callback) {
        const formData = new FormData();
        formData.append('file', file);
    
        apiService.post('/drawing/ConvertToSVG', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => callback(response.data))
        .catch(error => {
          if (error.response) {
            callback(null, error.response.data);
          } else {
            callback(null, error.message);
          }
        });
    }
};