import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Button, TextField, Grid } from '@mui/material';
import QRCode from 'qrcode.react';
import { authenticator } from 'otplib';
import { AuthContext } from '../../auth-provider.component';
import EdgeOrREST from '../../EdgeOrREST';

const QRCodeSetup = ({ setNotification, navigate, enabled }) => {
  const { complete2FASetup } = useContext(AuthContext);
  const [secret, setSecret] = useState(null);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if(!enabled) handleEnable2FA();
  }, []);

  const handleEnable2FA = () => {
    const secret = authenticator.generateSecret();
    const otpauthUrl = authenticator.keyuri('Login', 'LR Studio', secret);
    setSecret({ secret, otpauthUrl });
  };

  const handleVerifyNewOTP = async () => {
    try {
      if (!authenticator.check(otp, secret.secret))
        return setNotification({ open: true, message: 'Invalid OTP. Please try again.', severity: 'error' });
      EdgeOrREST(null, "ENABLE_2FA", newVerifyCallback, { token: otp, secret: secret.secret });
    } catch (error) {
      setNotification({ open: true, message: 'Failed to verify OTP. Please try again.', severity: 'error' });
    }
  };

  const newVerifyCallback = (response) => {
    if (response.status == "200") {
      complete2FASetup();
      navigate('/launcher');
    } else {
      setNotification({ open: true, message: 'Invalid OTP. Please try again.', severity: 'error' });
    }
  };  
  
  const handleVerifyOTP = () => {
    try {
      EdgeOrREST(null, "VERIFY_OTP", verifyCallback, { token: otp });
    } catch (error) {
      setNotification({ open: true, message: 'Failed to verify OTP. Please try again.', severity: 'error' });
    }
  }
  
  const verifyCallback = (response) => {
    if (response.data.success) {
      complete2FASetup();
      navigate('/launcher');
    } else {
      setNotification({ open: true, message: 'Invalid OTP. Please try again.', severity: 'error' });
    }
  };

  return (
    <Box>
      {secret && <Typography variant="body1">
        Scan the QR code below with the Google Authenticator app and enter the code to verify.
      </Typography>}
      <Grid container justifyContent="center" sx={{ mt: 2 }}>
        {secret && <QRCode value={secret.otpauthUrl} />}
      </Grid>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="otp"
        label="Enter 2FA Code"
        type="text"
        id="otp"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        sx={{ mt: 2 }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (secret !== null) {
              handleVerifyNewOTP();
              } else {
              handleVerifyOTP();
            }
          }
        }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          if (secret !== null) {
            handleVerifyNewOTP();
          } else {
            handleVerifyOTP();
          }
        }}
      >
        Verify and Continue
      </Button>
    </Box>
  );
};

export default QRCodeSetup;
