import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Button,
  Box,
  FormControl,
  Checkbox,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Notification from '../components/shared/notification.component';
import EdgeOrREST from '../EdgeOrREST';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `tertiary, ${theme.palette.grey[400]})`,
      color: 'secondary',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
}));

const roleList = ['member', 'manufacturer', 'admin'];

const AddUser = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [selectedRole, setSelectedRole] = useState("");
  const [companysList, setCompanysList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isGivenName, setIsGivenName] = useState(false);
  const [isFamilyName, setIsFamilyName] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isSelectedCompany, setIsSelectedCompany] = useState(false);
  const [isSelectedRole, setIsSelectedRole] = useState(false);
  const [isSelectedRegion, setIsSelectedRegion] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isManufacturer, setIsManufacturer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [snack, setSnack] = useState({ open: false });

  useEffect(() => {
    EdgeOrREST(null, 'GET_COMPANY_REGIONS_LISTS', onGetCompanysList, null);
  }, []);

  useEffect(() => {
    setIsEmailValid(isValidEmail(email.trim()));
  }, [email]);

  useEffect(() => {
    setIsGivenName(givenName.trim() !== "");
  }, [givenName]);

  useEffect(() => {
    setIsFamilyName(familyName.trim() !== "");
  }, [familyName]);

  useEffect(() => {
    setIsPasswordValid(checkPasswordValidity(password.trim()));
  }, [password]);

  useEffect(() => {
    setIsPasswordMatch(password === password2);
  }, [password, password2]);

  useEffect(() => {
    setIsSelectedCompany(selectedCompany !== 0);
  }, [selectedCompany]);

  useEffect(() => {
    setIsSelectedRole(selectedRole !== null && selectedRole !== '');
  }, [selectedRole]);

  useEffect(() => {
    setIsSelectedRegion(selectedRegion !== 0);
  }, [selectedRegion]);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const checkPasswordValidity = (inputPassword) => {
    return inputPassword.length >= 8;
  };

  const onGetCompanysList = (response) => {
    setCompanysList(response?.data?.companysList);
    setRegionsList(response?.data?.regionsList);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onGivenName = (event) => {
    setGivenName(event.target.value);
  };

  const onFamilyName = (event) => {
    setFamilyName(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onPassword2Change = (event) => {
    setPassword2(event.target.value);
  };

  const handleCompanyChange = (event, value) => {
    setSelectedCompany(value?.id ? value.id : 0);
    const company = companysList.find(company => company.id === value?.id);
    setIsManufacturer(company?.isManufacturer === 1);
  };

  const handleRegionChange = (event, value) => {
    setSelectedRegion(value?.id ? value.id : 0);
  };

  const handleRoleChange = (event, value) => {
    setSelectedRole(value ? value : '');
  };

  const handleIsAdminChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const handleSubmit = () => {
    if (password !== password2) setIsPasswordMatch(false);
    else setIsPasswordMatch(true);
    setIsSubmited(true);
    if (isAllValid()) {
      EdgeOrREST(null, 'ADD_USER', onAddUser, {
        'givenName': givenName,
        'familyName': familyName,
        'email': email,
        'password': btoa(password),
        'regionId': selectedRegion,
        'companyId': selectedCompany,
        'role': selectedRole,
        'isManufacturerAdmin': isAdmin ? "1" : "0"
      });
    } else {
      setSnack({
        open: true,
        message: 'Form Incomplete',
        severity: 'error'
      });
    }
  };

  const onAddUser = (response) => {
    if (response && response.status == '200') {
      setSnack({
        open: true,
        message: 'Add User Successfully',
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: 'Add User Fail. Please check the email is already exist or not.',
        severity: 'error'
      });
    }
  };

  const isAllValid = () => {
    return isEmailValid && isGivenName && isFamilyName && isPasswordValid && isPasswordMatch && isSelectedCompany && isSelectedRegion && isSelectedRole;
  };

  const handleClose = () => {
    setSnack({ ...snack, open: false });
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h5" className={classes.sectionTitle}>
            User Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={isSubmited && !isEmailValid}
                helperText={isSubmited && !isEmailValid && "Please use a valid email"}
                label="Email"
                id="email"
                defaultValue=""
                onChange={onEmailChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={isSubmited && !isGivenName}
                helperText={isSubmited && !isGivenName && "Please use a valid name"}
                label="Given name"
                id="givenName"
                defaultValue=""
                onChange={onGivenName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={isSubmited && !isFamilyName}
                helperText={isSubmited && !isFamilyName && "Please use a valid name"}
                label="Family name"
                id="familyName"
                defaultValue=""
                onChange={onFamilyName}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5" className={classes.sectionTitle}>
            Password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={isSubmited && (!isPasswordValid || !isPasswordMatch)}
                helperText={isSubmited && (!isPasswordValid || !isPasswordMatch) && "Password length should be at least 8 characters and matching"}
                label="Password"
                id="password"
                type="password"
                defaultValue=""
                onChange={onPasswordChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={isSubmited && (!isPasswordValid || !isPasswordMatch)}
                helperText={isSubmited && (!isPasswordValid || !isPasswordMatch) && "Password length should be at least 8 characters and matching"}
                label="Repeat Password"
                id="password2"
                type="password"
                defaultValue=""
                onChange={onPassword2Change}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid item md={12} sm={4} xs={4} direction="column" justifyContent="center">
            <Box display="flex" justifyContent="flex-start" width="100%">
              <div style={{ marginBottom: '10px' }}>Companys and Regions</div>
            </Box>
            <Box display="flex" justifyContent="flex-start" width="70%">
              <Grid item md={3} sm={12} xs={12} direction="column" justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  height="5vh"
                >
                  {/* <FormControl style={{ width: '200px' }}>
                    <InputLabel id="company-label">Select Company</InputLabel>
                    <Select
                      error={isSubmited && !isSelectedCompany}
                      helperText={isSubmited && !isSelectedCompany && "Please selcet company"}
                      labelId="company-label"
                      label="Select Company"
                      id="company"
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                      variant="outlined"
                    >
                      {companysList?.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}

                  <FormControl className={classes.formControl} style={{ width: '200px' }}
                    error={isSubmited && !isSelectedCompany}
                    helperText={isSubmited && !isSelectedCompany && "Please selcet company"}>
                    <Autocomplete
                      id="company-select"
                      options={companysList}
                      onChange={handleCompanyChange}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}

                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={<Typography>Select Company</Typography>} error={isSubmited && !isSelectedCompany}
                          helperText={isSubmited && !isSelectedCompany && "Please selcet company"} />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12} direction="column" justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  height="5vh"
                >
                  {/* <FormControl style={{ width: '200px' }}>
                    <InputLabel id="region-label">Select Region</InputLabel>
                    <Select
                      error={isSubmited && !isSelectedRegion}
                      helperText={isSubmited && !isSelectedRegion && "Please selcet region"}
                      labelId="region-label"
                      label="Select Region"
                      id="region"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      variant="outlined"
                    >
                      {regionsList?.map((region) => (
                        <MenuItem key={region.id} value={region.id}>
                          {region.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}

                  <FormControl className={classes.formControl} style={{ width: '200px' }}>
                    <Autocomplete
                      id="region-select"
                      options={regionsList}
                      onChange={handleRegionChange}

                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}

                      //value={(option)  => option.id || ""}
                      //getOptionLabel={(option)  => option.name || ""}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Select Region" error={isSubmited && !isSelectedRegion}
                          helperText={isSubmited && !isSelectedRegion && "Please selcet region"} />
                      )}
                    />
                  </FormControl>

                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12} direction="column" justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  height="5vh"
                >
                  <FormControl className={classes.formControl} style={{ width: '200px' }}>
                    <Autocomplete
                      id="role-select"
                      options={roleList.filter(role => !(role === 'manufacturer' && !isManufacturer))}
                      onChange={handleRoleChange}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Select Role" error={isSubmited && !isSelectedRole} helperText={isSubmited && !isSelectedRole && "Please select role"} />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                      <FormControlLabel
                        control={<Checkbox checked={isAdmin} onChange={handleIsAdminChange} />}
                        label="Admin"
                      />
                    </Grid>
              <Box display="flex" justifyContent="flex-start" marginTop="5px">
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </CardContent>
      </Card>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message} />
    </div>
  );
};

export default AddUser;