import React, { useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Divider,
    Box
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';

const MonitorAnalysis = (props) => {
    const state = useSelector(state => state.MonitorCfg);
    const [classCount, setClassCount] = React.useState([]);
    useEffect(() => {
        //const result = state?.classCount? state.classCount: [];
        setClassCount(state.monitorClassCount ? state.monitorClassCount : []);
    }, [state.monitorClassCount]);

    const columns = [
        { field: 'className', headerName: 'Class', width: 250 },
        { field: 'classCount', headerName: 'Count', width: 250 },
    ];

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <CardContent>
                <Grid container spacing={2} >
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Number of Jobs:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorJobsCount} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Number of Calculations:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorJobsCals} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Average Luminaires:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorAvgLum} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Avg CalsTime in Sec:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorAvgCalTime + "s"} InputProps={{ disableUnderline: true }} />
                    </Grid>
                </Grid>
            </CardContent>
            <Divider variant="middle" />
            <CardContent>
                <Grid container spacing={2} >
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Number of Road Jobs:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorRoadTypeCount} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Number of Area Jobs:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorAreaTypeCount} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value="Total Valid Results:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                        <TextField value={state.monitorTotalValidResults} InputProps={{ disableUnderline: true }} />
                    </Grid>
                </Grid>
            </CardContent>
            <Divider variant="middle" />
            <CardContent>
                <Grid container spacing={2} justifyContent ="center">
                    <div style={{ height: 380, width: '80%'}}>
                        <DataGrid
                            rows={state.monitorClassCount ? state.monitorClassCount : []}
                            getRowId={row => row.className}
                            columns={columns}
                            pageSize={5}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </CardContent>
        </Card>
    );

}
export default MonitorAnalysis; 