import React, { useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    TextField,
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';

const MonitorJobAnalysis = (props) => {
    const [jobAvgCalcTime, setJobAvgCalcTime] = React.useState(0.0);
    const [calcTimestamp, setCalcTimestamp] = React.useState("");
    const [optiTimestamp, setOptiTimestamp] = React.useState("");
    const [resultTimestamp, setResultTimestamp] = React.useState("");
    const [writerTimestamp, setWriterTimestamp] = React.useState("");
    const state = useSelector(state => state.MonitorCfg);
    useEffect(() => {
        if(state.selectedJobId && state.selectedJobId > 0) EdgeOrREST(null, 'GET_JOB_CALC_TIME', onGetJobCalcTime, { headers: { 'jobId': state.selectedJobId}});
    }, [state.selectedJobId]);

    const onGetJobCalcTime = (response) => {
        setJobAvgCalcTime(response.data.avgCalcSec);
        setCalcTimestamp(response.data.calcTimestamp);
        setOptiTimestamp(response.data.optiTimestamp);
        setResultTimestamp(response.data.resultTimestamp);
        setWriterTimestamp(response.data.writerTimestamp);
    }

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <CardContent>
                <Grid container spacing={2} >
                    <Grid item md={6} sm={3} xs={3}>
                        <TextField value="Avg Sec/Calc:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={6} sm={3} xs={3}>
                        <TextField value={jobAvgCalcTime + "s"} InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value="Timestamp - Calc:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value={calcTimestamp} InputProps={{ disableUnderline: true }}  />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value="Timestamp - Optim:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value={optiTimestamp} InputProps={{ disableUnderline: true }}  />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value="Timestamp - Record:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value={resultTimestamp} InputProps={{ disableUnderline: true }}  />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value="Timestamp - Writer:" InputProps={{ disableUnderline: true }} />
                    </Grid>
                    <Grid item md={12} sm={3} xs={3}>
                        <TextField value={writerTimestamp} InputProps={{ disableUnderline: true }}  />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

}
export default MonitorJobAnalysis; 