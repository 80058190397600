import { handleActions } from 'redux-actions';
import actions from '../actions/tourActions';
import { initialState } from '../initial-state';
export default handleActions(
    {
        [actions.UpdateTourAccess]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateCurrentStep]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateCurrentTab]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateHelpMode]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateHelpText]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateHelpURL]: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
    initialState.EnabledTour
);
