import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { AuthContext } from '../../auth-provider.component';
import EdgeOrREST from '../../EdgeOrREST';

const EmailSetup = ({ setNotification, navigate, enabled }) => {
  const { complete2FASetup } = useContext(AuthContext);
  const [otp, setOtp] = useState('');  

  const handleEnableEmail2FA = () => {    
    EdgeOrREST(null, "ENABLE_EMAIL_2FA", emailSetupCallback, null);     
  }; 
  
  useEffect(() => {
    handleEnableEmail2FA();
  }, []);

  const emailSetupCallback = (response) => {
    if (response.status == "200") {
      setNotification({ open: true, message: 'Verification code sent to your email.', severity: 'info' });
    } else {
      setNotification({ open: true, message: 'Failed to send verification code. Please try again.', severity: 'error' });
    }
  };

  const handleVerifyEmailOTP = async () => {
    try {
      EdgeOrREST(null, "VERIFY_EMAIL_OTP", verifyEmailCallback, { token: otp });
    } catch (error) {
      setNotification({ open: true, message: 'Failed to verify OTP. Please try again.', severity: 'error' });
    }
  };

  const verifyEmailCallback = (response) => {
    if (response.data.success) {
      complete2FASetup();
      navigate('/launcher');
    } else {
      setNotification({ open: true, message: 'Invalid OTP. Please try again.', severity: 'error' });
    }
  };

  return (
    <Box>
      <Typography variant="body1">
        A verification code has been sent to your email. Please enter the code below to verify.
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="otp"
        label="Enter Verification Code"
        type="text"
        id="otp"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        sx={{ mt: 2 }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleVerifyEmailOTP();
          }
        }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleVerifyEmailOTP}
      >
        Verify and Continue
      </Button>
    </Box>
  );
};

export default EmailSetup;
