import React, { useEffect } from 'react';
import { Layer, Rect, Circle, RegularPolygon, Transformer } from "react-konva";

export default function Mask(props) {
    const trRef = React.useRef();
    const shapeRef = React.useRef();

    useEffect(() => {
        if (trRef.current && props.selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [props.selected])


    return (
        <Layer key={props.i} >
            {props.maskType == "polygon" ?
                <RegularPolygon
                    ref={shapeRef}
                    sides={10}
                    x={100}
                    y={100}
                    width={100}
                    height={100}
                    fill="lightblue"
                    shadowBlur={5}
                    draggable={true}
                    onTap={(e) => {
                        props.selectMask(props.i);
                    }}
                    onClick={(e) => {
                        props.selectMask(props.i);
                    }}
                    onDragEnd={
                        (e) => {
                            props.updateMask(e, props.i, props.maskType);
                        }
                    }
                />

                : props.maskType == "rectangle" ?
                    <Rect
                        ref={shapeRef}
                        x={100}
                        y={100}
                        width={100}
                        height={100}
                        fill="lightblue"
                        opacity={0.5}
                        draggable={true}
                        onTap={(e) => {
                            props.selectMask(props.i);
                        }}
                        onClick={(e) => {
                            props.selectMask(props.i);
                        }}
                        onDragEnd={
                            (e) => {
                                props.updateMask(e, props.i, props.maskType);
                            }
                        }
                    />
                    : props.maskType == "circle" ?
                        <Circle x={200} y={100} radius={50}
                            fill="lightblue"
                            ref={shapeRef}
                            draggable={true}
                            opacity={0.5}
                            onTap={(e) => {
                                props.selectMask(props.i);
                            }}
                            onClick={(e) => {
                                props.selectMask(props.i);
                            }}
                            onDragEnd={
                                (e) => {
                                    props.updateMask(e, props.i, props.maskType);
                                }
                            }

                        />
                        :
                        <></>
            }
            {props.selected ?
                <Transformer ref={trRef} keepRatio={true}
                    onTransformEnd={(e) => {
                        props.updateMask(e, props.i, props.maskType);
                    }}

                />
                : <></>
            }
        </Layer>
    )
} 