import React, { Component } from 'react';
import EdgeOrREST from '../../EdgeOrREST';
import {
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControl,
    makeStyles,
    Button,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Typography,
    Slider,
    Switch,
    MenuItem,
    Select,
    TextField,
    Radio,
    RadioGroup,
    Grid,
    Chip
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import user from '../../store/actions/user';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PhotometricAttributeMenu from './photometric-attribute-menu.component';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    },
    formControl: {
        width: '230px',
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formGroup: {
        margin: theme.spacing(1),
    },
    formLabel: {
        color: 'darkblue',
        marginBottom: '0px'
    },
    paper: {
        boxShadow: '0 0 0 rgb(63 63 68 / 5%), 0 1px 2px rgb(63 63 68 / 15%)'
    },
    formGroupSlider: {
        marginRight: '10px',
        marginLeft: '10px'
    }

}));

const initialState = {
    //spektd options
    //light
    lightSource: 'any',
    manufacturer: 'any',
    lampBase: 'any',
    cri: 0,
    r9: 0,
    macAdamStep: 'any',
    direction: 'any',
    beamAngle: 0,
    cctOptions: 'Fixed white',
    cct: 0,
    lor: 0,
    opticalAccessories: 'any',
    lampCount: 0,
    //physical
    finish: 'any',
    housingMaterial: 'any',
    adjustableTilt: 0,
    adjustableRotation: 0,
    shape: 'any',
    height: 0,
    length: 0,
    width: 0,
    installation: 'any',
    placement: [],
    weight: 0,
    //electrical
    voltageType: 'any',
    voltage: 0,
    wattage: 0,
    mA: 0,
    luminWatt: 0,
    driverType: 'any',
    control: 'any',
    applianceClass: 0,
    //protection
    ip: 0,
    ik: 0,
    operatingTemp: [-30, 100],
    emergencyLight: 'any',
    antiSlip: 'any',
    designLabel: [],
    //integration
    daylightSensors: 'any',
    motionSensors: 'any',
    presenceSensors: 'any',
    nema3: 'any',
    nema7: 'any',
    zhagaBook18: 'any',
    photocell: 'any',
    clo: 'any',
    //environment
    tm66: 0,
    envCert: 'any',
    //brands
    brands: 'any',
    filePaths: '',
};

const initialDisabledList = {
    //spektd options
    //light
    lightSource: false,
    manufacturer: false,
    lampBase: false,
    cri: false,
    r9: false,
    macAdamStep: false,
    direction: false,
    beamAngle: false,
    cctOptions: false,
    cct: false,
    lor: false,
    opticalAccessories: false,
    lampCount: false,
    //physical
    finish: false,
    housingMaterial: false,
    adjustableTilt: false,
    adjustableRotation: false,
    shape: false,
    height: false,
    length: false,
    width: false,
    installation: false,
    placement: false,
    weight: false,
    //electrical
    voltageType: false,
    voltage: false,
    wattage: false,
    mA: false,
    luminWatt: false,
    driverType: false,
    control: false,
    applianceClass: false,
    //protection
    ip: false,
    ik: false,
    operatingTempMin: false,
    operatingTempMax: false,
    emergencyLight: false,
    antiSlip: false,
    designLabel: false,
    //integration
    daylightSensors: false,
    motionSensors: false,
    presenceSensors: false,
    nema3: false,
    nema7: false,
    zhagaBook18: false,
    photocell: false,
    clo: false,
    //environmental
    tm66: false,
    envCert: false,
    //brands
    brands: false,
    filePaths: false,
};


const PhotometricAttributeStreetlightSelector = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [attributeParams, setAttributeParams] = React.useState(initialState);
    const buttonRef = React.useRef(null);
    const [disabledList, setDisabledList] = React.useState(initialDisabledList);
    const [paths, setPaths] = React.useState([]);
    const [tempPaths, setTempPaths] = React.useState("");

    const onSetAttributeParams = (e, object) => {
        const { name, value } = e.target;
        console.log("name, value", name, value);
        if (e.target.type === "checkbox" && object === "brands") {
            setAttributeParams(prevState => ({
                ...prevState,
                brands: { ...prevState.brands, [name]: e.target.checked }
            }));
        } else {
            console.log("setting basic name");
            setAttributeParams(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleChange = (e) => {
        console.log("handleChange", e.target.value);
        setTempPaths(e.target.value);
    }

    const handleAddFilePathsClick = (e) => {
        console.log("handleAddFilePathsClick", tempPaths);
        var temp = paths;
        temp.push(tempPaths);
        console.log("temp: ", temp.join(','));
        const updatedAttributeParams = {
            ...attributeParams,
            filePaths: temp.join(', ')
        };
        setPaths(temp);
        setAttributeParams(updatedAttributeParams);
        setTempPaths("");
    }

    const handleDelete = (path) => {
        console.log("handleDelete", path);
        const updatedPaths = paths.filter(item => item !== path);
        const updatedAttributeParams = {
            ...attributeParams,
            filePaths: updatedPaths == [] ? [] : updatedPaths.join(', ')
        };
        setPaths(updatedPaths);
        setAttributeParams(updatedAttributeParams);
    }

    useEffect(() => {
        props.handleAttributeParams(attributeParams);
    }, [attributeParams]);

    useEffect(() => {
        props.hasOwnProperty('disabledStreetList') ? setDisabledList(props.disabledStreetList) : setDisabledList(initialDisabledList);
        console.log("useEffect props", props);
        if (props.parentGroupId != 0 && props.parentGroupId != null) {
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllParentGroupAttributes, { headers: { 'id': props.parentGroupId } });
        } else if (props.editLumin && props.luminaireId != 0) {
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_LUMINAIRE', onGetAllAttributes, { headers: { 'id': props.luminaireId } });
        } else if (props.edit || props.upload) {
            console.log("edit or upload props", props);
            EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllAttributes, { headers: { 'id': props.groupId } });
        }
    }, []);

    const onGetAllParentGroupAttributes = (response) => {
        console.log("onGetAllParentGroupAttributes response", response);
        if (response.status === 200) {
            const nonNullData = Object.entries(response.data)
                .filter(([key, value]) => value !== null)
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
            const operatingTempMin = response.data.operatingTempMin == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? -30 : response.data.operatingTempMin;
            const operatingTempMax = response.data.operatingTempMax == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? 100 : response.data.operatingTempMax;
            const placement = response.data.placement == null || response.data.placement == "" ? [] : response.data.placement.split(",");
            const designLabel = response.data.designLabel == null || response.data.designLabel == "" ? [] : response.data.designLabel.split(",");
            console.log("nonNullData", nonNullData);
            console.log("attributeParams", attributeParams);
            const updatedAttributeParams = {
                ...attributeParams,
                ...nonNullData,
                operatingTemp: [operatingTempMin, operatingTempMax],
                placement: placement,
                designLabel: designLabel
            };
            console.log("Parent updatedAttributeParams: ", updatedAttributeParams);
            setAttributeParams(updatedAttributeParams);
            //disable attributes that assign by group already
            console.log("test disable attributes");
            const nonNullKeys = Object.keys(response.data).filter(key => response.data[key] !== null && response.data[key] != 0 && key != "id");
            const updatedDisabledList = { ...disabledList };
            nonNullKeys.forEach(key => {
                updatedDisabledList[key] = true;
            });
            if (operatingTempMin == -30 && operatingTempMax == 100) {
                updatedDisabledList.operatingTempMin = false;
                updatedDisabledList.operatingTempMax = false;
            }
            console.log("updatedDisabledList", updatedDisabledList);
            setDisabledList(updatedDisabledList);

            if (props.editLumin && props.luminaireId != 0) {
                EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_LUMINAIRE', onGetAllAttributes, { headers: { 'id': props.luminaireId } });
            } else if (props.edit || props.upload) {
                EdgeOrREST(null, 'GET_ALL_ATTRIBUTES_BY_GROUP', onGetAllAttributes, { headers: { 'id': props.groupId } });
            }
        }
    };

    const onGetAllAttributes = (response) => {
        console.log("onGetAllAttributes response", response);
        if (response.status === 200) {
            const nonNullData = Object.entries(response.data)
                .filter(([key, value]) => value !== null)
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
            console.log("nonNullData", nonNullData);
            const operatingTempMin = response.data.operatingTempMin == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? -30 : response.data.operatingTempMin;
            const operatingTempMax = response.data.operatingTempMax == null ||
                (response.data.operatingTempMin == 0 && response.data.operatingTempMax == 0) ? 100 : response.data.operatingTempMax;
            const placement = response.data.placement == null || response.data.placement == "" ? [] : response.data.placement.split(",");
            const designLabel = response.data.designLabel == null || response.data.designLabel == "" ? [] : response.data.designLabel.split(",");
            const updatedAttributeParams = {
                ...attributeParams,
                ...nonNullData,
                operatingTemp: [operatingTempMin, operatingTempMax],
                placement: placement,
                designLabel: designLabel
            };
            console.log("updatedAttributeParams: ", updatedAttributeParams);
            setAttributeParams(updatedAttributeParams);
            //disable attributes that assign by group already
            if (!props.edit && !props.editLumin) {
                const nonNullKeys = Object.keys(response.data).filter(key => response.data[key] !== null && response.data[key] != 0 && key != "id");
                console.log("nonNullKeys", nonNullKeys);
                const updatedDisabledList = { ...disabledList };
                console.log("updatedDisabledList", updatedDisabledList);
                nonNullKeys.forEach(key => {
                    updatedDisabledList[key] = true;
                });
                if (operatingTempMin == -30 && operatingTempMax == 100) {
                    updatedDisabledList.operatingTempMin = false;
                    updatedDisabledList.operatingTempMax = false;
                }
                console.log("updatedDisabledList", updatedDisabledList);
                setDisabledList(updatedDisabledList);
            }

        }
    };

    function selectItem(title, id, labelId, name, value, onSetSearchParams, itemsValue, selectOptions) {
        return (
            <FormControl className={classes.formControl} size="small">
                <Typography className={classes.formLabel} gutterBottom>
                    {title}
                </Typography>
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    onChange={onSetSearchParams}
                    name={name}
                    disabled={disabledList[name]}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: 'auto',
                            },
                        },
                    }}
                >
                    {itemsValue.map((item, id) => {
                        return (
                            <MenuItem value={item}><Typography >{selectOptions[id]}</Typography ></MenuItem>
                        )

                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <>
            <div ref={buttonRef} style={{ width: '150px' }} />
            {/* Light, Physical, Electrical, Protection, Integration, Environment, Brands */}
            {PhotometricAttributeMenu({
                props, category: 'Light', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            {selectItem("CCT options", "cctOptions", "cctOptions", "cctOptions", attributeParams.cctOptions, onSetAttributeParams,
                                ["any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"],
                                ["Any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"])}
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    CCT
                                </Typography>
                                <TextField className={classes.formLabel} name="cct" type="number"
                                    disabled={disabledList.cct}
                                    inputProps={{ min: 0, step: 500 }} value={attributeParams.cct} onChange={onSetAttributeParams} />
                            </FormControl>

                        </FormGroup>
                    </>
            })}
            {/* Physical */}
            {PhotometricAttributeMenu({
                props, category: 'Physical', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"}>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Height(mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="height" type="number"
                                    disabled={disabledList.height}
                                    inputProps={{ min: 0 }} value={attributeParams.height} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Length(mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="length" type="number"
                                    disabled={disabledList.length}
                                    inputProps={{ min: 0 }} value={attributeParams.length} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Width(mm)"}
                                </Typography>
                                <TextField className={classes.formLabel} name="width" type="number"
                                    disabled={disabledList.width}
                                    inputProps={{ min: 0 }} value={attributeParams.width} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}
            {/* Electrical */}
            {PhotometricAttributeMenu({
                props, category: 'Electrical', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            <FormControl className={classes.formControl} size="small">
                                <Typography className={classes.formLabel} gutterBottom>Voltage Type</Typography>
                                <RadioGroup aria-label="voltageType" name="voltageType" id='voltageType' value={attributeParams.voltageType}
                                    row onChange={onSetAttributeParams}>
                                    <FormControlLabel
                                        value="any"
                                        control={<Radio color='primary' size='small' />}
                                        label="Any"
                                        disabled={disabledList.voltageType}
                                    />
                                    <FormControlLabel
                                        value="AC"
                                        control={<Radio color='primary' size='small' />}
                                        label="AC"
                                        disabled={disabledList.voltageType}
                                    />
                                    <FormControlLabel
                                        value="DC"
                                        control={<Radio color='primary' size='small' />}
                                        label="DC"
                                        disabled={disabledList.voltageType}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Voltage"}
                                </Typography>
                                <TextField className={classes.formLabel} name="voltage" type="number"
                                    disabled={disabledList.voltage}
                                    inputProps={{ min: 0 }} value={attributeParams.voltage} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Luminaire Wattage"}
                                </Typography>
                                <TextField className={classes.formLabel} name="wattage" type="number"
                                    disabled={disabledList.wattage}
                                    inputProps={{ min: 0 }} value={attributeParams.wattage} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}

            {/* Protection */}
            {PhotometricAttributeMenu({
                props, category: 'Protection', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"IP Rating"}
                                </Typography>
                                <TextField className={classes.formLabel} name="ip" type="number"
                                    disabled={disabledList.ip}
                                    inputProps={{ min: 0 }} value={attributeParams.ip} onChange={onSetAttributeParams} />
                            </FormControl>
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"IK Rating"}
                                </Typography>
                                <TextField className={classes.formLabel} name="ik" type="number"
                                    disabled={disabledList.ik}
                                    inputProps={{ min: 0 }} value={attributeParams.ik} onChange={onSetAttributeParams} />
                            </FormControl>
                        </FormGroup>
                    </>
            })}

            {PhotometricAttributeMenu({
                props, category: 'Integration', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            {selectItem("Daylight sensors", "daylightSensors", "daylightSensors", "daylightSensors", attributeParams.daylightSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Motion sensors", "motionSensors", "motionSensors", "motionSensors", attributeParams.motionSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Presence sensors", "presenceSensors", "presenceSensors", "presenceSensors", attributeParams.presenceSensors, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("NEMA 3", "nema3", "nema3", "nema3", attributeParams.nema3, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("NEMA 7", "nema7", "nema7", "nema7", attributeParams.nema7, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Zhaga Book 18", "zhagaBook18", "zhagaBook18", "zhagaBook18", attributeParams.zhagaBook18, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("Photocell", "photocell", "photocell", "photocell", attributeParams.photocell, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            {selectItem("CLO", "clo", "clo", "clo", attributeParams.clo, onSetAttributeParams,
                                ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                        </FormGroup>
                    </>
            })}

            {PhotometricAttributeMenu({
                props, category: 'Other', buttonRef: buttonRef, menuList:
                    <>
                        <FormGroup className={classes.formGroup + " MuiFormGroup-options"} >
                            <FormControl className={classes.formControl} size="small">
                                <Typography id="textField" className={classes.formLabel} gutterBottom>
                                    {"Image Paths"}
                                </Typography>
                                <TextField className={classes.formLabel} name="filePaths" type="text"
                                    disabled={disabledList.filePaths}
                                    value={tempPaths} onChange={handleChange} />
                                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleAddFilePathsClick}
                                        disabled={tempPaths == ""}
                                        color='primary'
                                    >
                                        Add
                                    </Button>
                                </div>
                                {!paths ? <></> : paths.map((path) => {
                                    return (
                                        <Chip
                                            label={path}
                                            disabled={disabledList.filePaths}
                                            onDelete={() => handleDelete(path)}
                                        />
                                    )
                                })}
                            </FormControl>
                        </FormGroup>
                    </>
            })}
        </>
    );
}
export default PhotometricAttributeStreetlightSelector; 