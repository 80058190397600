import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import OptimizeButton from '../../components/Luminaire/Comp_OptimizeButton';
import luminaireActions from '../../store/actions/luminaireActions';
import GroupTable from './groupTable.component';
import tourActions from '../../store/actions/tourActions';

const Group = () => {
    const props = useSelector(state => state);
    const dispatch = useDispatch();    

    const handleClick = () => {
        if (props.EnabledTour.helpMode){
            dispatch(tourActions.UpdateHelpText({ helpText: "This button clears the results table when you have done a group optimize."}))
        } else {
        dispatch(luminaireActions.ClearGroupResults())
        }
    }

    return (
        <Grid container spacing={3} style={{ maxHeight: '78vh', overflowY: 'auto' }}>
            <Grid item md={"auto"} xs={"auto"}>
                <OptimizeButton folder={true} />
            </Grid>
            <Grid item md={"auto"} xs={"auto"}>
                <Button color="primary" onClick={handleClick}>
                    Clear
                </Button>
            </Grid>
            <Grid item md={12} xs={12}>
                <GroupTable results={props.LuminaireCfg.groupResults} />                
            </Grid>
        </Grid>
    );
};

export default Group;