import React, { Component, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
//import PolarPlots from '../components/Luminaire/D3_RadarPlots';
//import CartesianPlots from '../components/Luminaire/CartesianPlot';
import PropTypes from 'prop-types';
import LuminCard from '../../Column/LuminCard';
import {
  Card,
  CardContent,
  Divider,
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  List,
  ListItem,
  Box,
  Typography,
  TextField,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import EdgeOrREST from '../../../EdgeOrREST';
import Isolux from '../../Calc_Area/isolux.component';
import Polar from '../../Calc_Area/polar.component';
import LCS from '../../Calc_Area/LCS.component';
import Cartesian from '../../Calc_Area/cartesian.component';
import LCSResults from '../../Results/LCSResults.component';
import { useInterval } from '../../../automate/automate-interval-runner.component';
import { useDispatch } from 'react-redux';
import user from '../../../store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '70vh',
    padding: theme.spacing(3),
    width: '100%',
    overflowY: 'auto'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PhotometryViewer = (props) => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [luminaireImages, setImages] = React.useState([]);

  const [data, setData] = React.useState({

    info: {
      zero: [],
      ninety: [],
      "manufacturer": "",
      "flux": 0,
      "inputWattage": 0,
      id: 0
    },
    attributes: {

    },
    luminaire: {

    }

  });


  const onGetLuminaireImages = (response) => {
    if (response && response.status == '200') {
      setImages(response.data);
    }
  };

  const onGetPhotometricInformation = (response) => {
    console.log("GET_PHOTOMETRIC_INFORMATION, response: ", response.status, response.data);
    if (response && response.status == '200') {
      setData(response.data);
    }
  };

  useInterval(() => {
    if (state.user.refreshLuminaireInfo) {
      //add images
      EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': state.LuminaireCfg.selectedLuminaireIds[0] } });
      EdgeOrREST(null, 'GET_LUMINAIRE_IMAGES', onGetLuminaireImages, { headers: { 'id': '227' } });
      dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: false }));
    }
  }, 1000);


  useEffect(() => {
    EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': state.LuminaireCfg.selectedLuminaireIds[0] } });
    dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: false }));
  }, []);


  function luminaireFileViewer() {
    if (!state.LuminaireCfg.selectedLuminaireIds || state.LuminaireCfg.selectedLuminaireIds.length == 0) {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            Please select a luminaire.
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="outlined-read-only-input"
              label="TM33 Converted File"
              defaultValue={data.luminaire.file}
              InputProps={{
                readOnly: true,
              }}
              multiline={true}
              style={{ height: '60vh', width: '100%', overflowY: 'scroll' }}
            />
          </Grid>
        </Grid>
      );
    }
  }

  function luminaireImagesTab() {

    if (luminaireImages.length > 0) {
      var mediaType = "data:image/jpeg;base64,";
      var images = [];
      for (let i = 0; i < luminaireImages.length; i++) {
        images.push(<img src={mediaType + luminaireImages[i].image} />);
      }

      return (
        <Grid container spacing={2} style={{ height: '68vh', overflowY: 'scroll' }}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            {images}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            No media found for luminaire.
          </Grid>
        </Grid>
      );
    }

  }

  function luminaireInformationTab() {
    if (!state.LuminaireCfg.selectedLuminaireIds || state.LuminaireCfg.selectedLuminaireIds.length == 0) {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            Please select a luminaire.
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={7}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Light">
                Light
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="Light source"
                  secondary={data.attributes.lightSource}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Manufacturer"
                  secondary={data.attributes.manufacturer}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Lamp base"
                  secondary={data.attributes.lampBase}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="CRI"
                  secondary={data.attributes.cri}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="R9 Value"
                  secondary={data.attributes.r9}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="MacAdam step"
                  secondary={data.attributes.macAdamStep}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Direction"
                  secondary={data.attributes.direction}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Beam Angle"
                  secondary={data.attributes.beamAngle + "°"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="CCT options"
                  secondary={data.attributes.cctOptions}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="CCT"
                  secondary={data.attributes.cct + "K"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="LOR"
                  secondary={data.attributes.lor}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Optical accessories"
                  secondary={data.attributes.opticalAccessories ? 'Yes' : 'No'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Number of lamps/light engines"
                  secondary={data.attributes.lampCount}
                />
              </ListItem>
            </List>
          </Grid>

          {/* Physical */}
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Physical">
                Physical
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="Finish"
                  secondary={data.attributes.finish}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Housing Material"
                  secondary={data.attributes.housingMaterial}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Adjustable Tilt"
                  secondary={data.attributes.adjustableTilt + '°'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Adjustable Rotation"
                  secondary={data.attributes.adjustableRotation + '°'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Shape"
                  secondary={data.attributes.shape}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Height"
                  secondary={data.attributes.height + " mm"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Length"
                  secondary={data.attributes.length + " mm"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Width"
                  secondary={data.attributes.width + " mm"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Installation and Cut-out"
                  secondary={data.attributes.installation}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Placement"
                  secondary={data.attributes.placement}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Weight"
                  secondary={data.attributes.weight + " kg"}
                />
              </ListItem>
            </List>
          </Grid>

          {/* Electrical */}
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Electrical">
                Electrical
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="Voltage Type"
                  secondary={data.attributes.voltageType}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Voltage"
                  secondary={data.attributes.voltage}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Wattage"
                  secondary={data.attributes.wattage}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="MilliAmp"
                  secondary={data.attributes.mA}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Lumin per Watt"
                  secondary={data.attributes.luminWatt}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Driver Type"
                  secondary={data.attributes.driverType}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Control"
                  secondary={data.attributes.control}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Appliance Class"
                  secondary={data.attributes.applianceClass}
                />
              </ListItem>
            </List>
          </Grid>
          {/* Protection */}
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Protection">
                Protection
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="IP Rating"
                  secondary={"IP " + data.attributes.ip}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="IK Rating"
                  secondary={"IK " + data.attributes.ik}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Operating Temperature(°C)"
                  secondary={data.attributes.operatingTempMin + "°C to " + data.attributes.operatingTempMax + "°C"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Emergency Light"
                  secondary={data.attributes.emergencyLight ? 'Yes' : 'No'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Anti-Slip"
                  secondary={data.attributes.antiSlip ? 'Yes' : 'No'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Designation Label"
                  secondary={data.attributes.designLabel}
                />
              </ListItem>
            </List>
          </Grid>

          {/* Environment */}
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Environment">
                Environment
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="Min TM66 Score"
                  secondary={data.attributes.tm66 = 0 ? '' : data.attributes.tm66}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Environmental Certification"
                  secondary={data.attributes.envCert}
                />
              </ListItem>
            </List>
          </Grid>

          {/* Brands */}
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <List dense={true} subheader={
              <ListSubheader disableSticky disableGutters component="div" id="Brands">
                Brands
              </ListSubheader>
            }>
              <ListItem>
                <ListItemText
                  primary="Brands"
                  secondary={data.attributes.brands}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      );
    }
  }
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto" indicatorColor="primary" textColor="primary">
          <Tab label="Selector" {...a11yProps(1)} />
          <Tab name="attributesTab" label="Attributes" {...a11yProps(2)} />
          <Tab name="isoluxTab" label="ISO Lux" {...a11yProps(3)} />
          <Tab name="polarTab" label="Polar Plot" {...a11yProps(4)} />
          <Tab name="cartesianTab" label="Cartesian Plot" {...a11yProps(5)} />
          <Tab name="lcsTab" label="LCS" {...a11yProps(6)} />
          <Tab name="lcsTableTab" label="LCS Table" {...a11yProps(7)} />
          <Tab name="imagesTab" label="LCS Images" {...a11yProps(8)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Card>
          <CardContent>
            <LuminCard index={0} />
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.root}>
        <Typography variant="body2" className={classes.labelText}>
          Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
        </Typography>
        <Divider />
        {luminaireInformationTab()}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.labelText}>
              Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
            </Typography>
            <Divider />
            <Isolux />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.labelText}>
              Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
            </Typography>
            <Divider />
            <Polar />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.labelText}>
              Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
            </Typography>
            <Divider />
            <Cartesian />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.labelText}>
              Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
            </Typography>
            <Divider />
            <LCS />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.labelText}>
              Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
            </Typography>
            <Divider />
            <LCSResults />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={7}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {luminaireImagesTab()}
          </Grid>
        </Grid>
      </TabPanel>

    </>

  );

}
export default PhotometryViewer; 