import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { AuthContext } from '../../auth-provider.component';
import Notification from '../shared/notification.component';
import QRCodeSetup from './qr-code-setup.component';
import EmailSetup from './email-setup.component';

const TwoFactorAuth = () => {
  const { is2FAEnabled, complete2FASetup } = useContext(AuthContext);
  const [is2FASetupInitiated, setIs2FASetupInitiated] = useState(false);
  const [setupMethod, setSetupMethod] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();

  useEffect(() => {
    if (is2FAEnabled) {
      setSetupMethod('app');
      setIs2FASetupInitiated(true);
    }
  }, [is2FAEnabled]);

  const handleEnable2FA = (method) => {
    setSetupMethod(method);
    setIs2FASetupInitiated(true);
  };

  const handleSkip2FA = () => {
    complete2FASetup();
    navigate('/launcher');
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography component="h1" variant="h5">
          Two-Factor Authentication
        </Typography>
        <Box sx={{ mt: 2 }}>
          {!is2FAEnabled && !is2FASetupInitiated ? (
            <>
              <Typography variant="body1">
                Would you like to enable Two-Factor Authentication (2FA) for your account?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleEnable2FA('app')}
              >
                Enable 2FA with Authenticator App
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleEnable2FA('email')}
              >
                Enable 2FA with Email
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleSkip2FA}
              >
                Skip for Now
              </Button>
            </>
          ) : setupMethod === 'app' || setupMethod === null ? (
            <QRCodeSetup setNotification={setNotification} navigate={navigate} enabled={is2FAEnabled} />
          ) : (
            <EmailSetup setNotification={setNotification} navigate={navigate} enabled={is2FAEnabled} />
          )}
          {is2FASetupInitiated && setupMethod === 'app' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => setSetupMethod('email')}
            >
              Switch to Email 2FA
            </Button>
          )}
        </Box>
      </Paper>
      <Notification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />
    </Container>
  );
};

export default TwoFactorAuth;
