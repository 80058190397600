import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import columnActions from '../../store/actions/columnActions';
import {
	Collapse,
	Grid,
	TextField,
} from '@material-ui/core';

const mapDispatchToProps = (dispatch) => {
	const Column = bindActionCreators(columnActions, dispatch);
	return {
		UpdateColumnCfg: (data) => {
			Column.UpdateColumnCfg(data);
		},
	};
};

const Expander = ({ expanded, label, name, UpdateColumnCfg }) => {

	const props = useSelector(state => state.ColumnCfg);

	label = label.toLowerCase();


	const handleChange = (event) => {
		UpdateColumnCfg({
			...props, optimizeValues: {
				...props.optimizeValues, [name]: {
					...props.optimizeValues[name], [event.target.name]: parseFloat(event.target.value)
				}
			}
		});
	};

	return (
		<Collapse in={expanded} unmountOnExit>
			<Grid
				container
				spacing={2}
				style={{ 'paddingTop': '10px' }}
			>
				<Grid item md={6}>
					<TextField
						fullWidth
						label={"Max"}
						name="max"
						onChange={handleChange}
						type="number"
						value={props.optimizeValues[name].max}
						variant="outlined"
						inputProps={{
							step: 0.25
						}}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						fullWidth
						label={"Min"}
						name="min"
						onChange={handleChange}
						type="number"
						value={props.optimizeValues[name].min}
						variant="outlined"
						inputProps={{
							step: 0.25
						}}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						fullWidth
						label="Step"
						name="step"
						onChange={handleChange}
						type="number"
						value={props.optimizeValues[name].step}
						variant="outlined"
						inputProps={{
							step: 0.25
						}}
					/>

				</Grid>
			</Grid>
		</Collapse>
	)
}

export default connect(null, mapDispatchToProps)(Expander);