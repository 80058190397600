import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LRTitle from '../../IMG/Logos/LR_Title_Logo.jpg';
import LRStudio from '../../IMG/Logos/LR_Studio_Colour.png'
import Notification from '../shared/notification.component';
import EdgeOrREST from '../../EdgeOrREST';
import { useNavigate } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https:/lightingreality.com/">
                Lighting Reality
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LRTitle: {
        marginTop: theme.spacing(5),
        width: theme.spacing(29),
        height: theme.spacing(11)
    },
    LRStudio: {
        margin: theme.spacing(3),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(30),
        height: theme.spacing(25)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

export default function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [snack, setSnack] = useState({
        open: false
    });
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    function submitForm(e, props, email) {
        EdgeOrREST(null, "SEND_RESET_EMAIL", resetEmail, { email: email });
    }

    const resetEmail = (response) => {
        if (response.status == "200") {
            setSnack({
                open: true,
                message: 'Reset Password Email have been sent to your email address. Please check your email.',
                severity: 'success'
            });
            setTimeout(() => {
                toLogin(props);
            }, 5000);
        } else {
            setSnack({
                open: true,
                message: "Email invaild. Please check your email and try again.",
                severity: 'error'
            });
        }
    }

    function toLogin(props) {
        navigate('/login');
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const onSetEmail = (e) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (re.test(e)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setEmail(e);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <IconButton onClick={() => toLogin(props)} className="customIconButton">
                    <Avatar className={classes.LRStudio} src={LRStudio} variant="square" />
                </IconButton>
                <Typography component="h1" variant="h5">
                    Forget Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            helperText="Please enter your email address."
                            error={disabled}
                            onChange={(e) => onSetEmail(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => submitForm(e, props, email)}
                    disabled={disabled}
                >
                    Forget Password
                </Button>
                <Grid container justify="center">
                    <Grid item>
                        <Link component="button" variant="body2" onClick={() => toLogin(props)}>
                            Go Back to Sign in?
                        </Link>
                    </Grid>
                </Grid>
                <IconButton onClick={() => toLogin(props)} className="customIconButton">
                    <Avatar className={classes.LRTitle} src={LRTitle} variant="square" />
                </IconButton>
            </div>
            <Box mt={1}>
                <Copyright />
            </Box>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Container >
    );

}