
import React, { Component, useEffect } from 'react';    
import { DataGrid } from '@material-ui/data-grid'; 
 import {  
  Card,
  Tabs,
  Tab,
  Divider,
  CardContent,
  makeStyles, 
  Grid, 
  Paper,  
  Button
} from '@material-ui/core';  

const useStyles = makeStyles((theme) => ({
  root: {
        display: 'flex',
        flexDirection: 'column',
        height: '75vh',
        width: '100%',
        '& .MuiFormGroup-options': {
          alignItems: 'center',
          paddingBottom: theme.spacing(1),
          '& > div': {
            minWidth: 100,
            margin: theme.spacing(2, 2, 2, 0),
          },
        },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  margin : {
   margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  container: {
    maxHeight: '40vh',
    minHeight: '40vh', 
  }
}));
  


     
const AutomateReports = (props) => {
  const columns  = [ 
    { field: 'name', headerName: 'Name', width: 125, editable : false },
    { field: 'status', headerName: 'Status', width: 118 },
    { field: 'timestamp', headerName: 'Date', width: 107 }, 
    { field: '', headerName: '', width: 85, 
    renderCell: (cellValues) => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            handleClick(event, cellValues);
          }}
        >
          Download
        </Button>
      );
     }
    } 

  ]; 

    const classes = useStyles();  

     const [reportsState, setReports] = React.useState({
      reports: []  
    });
      
    useEffect(() => {
        let isMounted =  true;  
        //EdgeOrREST(null, 'GET_REPORTS', onGetReports, null);  
        return () => { isMounted= false }; 
    }, []); 

    const onGetReports = (response) => {
      console.log(response.data);  
      for(var i=0; i < response.data.length; i++){
          response.data[i].status = response.data[i].status == 1 ? 'Complete' :  response.data[i].status == 0 ? 'Queued' : 'Error'; 
      }
      setReports({ 'reports': response.data});
    };

    const handleClick = (param, event) => {
      //EdgeOrREST(null, 'DOWNLOAD_AUTOMATE_REPORT', onGetAutomateReport,  { headers: { 'id': event.id } } );  
        //donwloadreports
    };
    
    const handleRowClick = (param, event) => {
      console.log("rowClick"); 
      event.stopPropagation();
    };

    const onGetResults = (response) => {    
        console.log(response); 
        var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"; 
        var a = document.createElement('a');
        a.href = mediaType+response.data.base64; 
        a.download = response.data.fileName + '.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);  
    };
 
   

    return (
      <Card style={{ 'minHeight': 'inherit' }}>
        <Paper square>
          <Tabs 
            indicatorColor="primary"
            textColor="primary" 
            aria-label="disabled tabs example"
            value={0}
          >
           <Tab label="Automate Reports" value={0} /> 
          </Tabs>
          
        </Paper>
      <Divider />
        <CardContent>
            <Grid container spacing={2}>    
                <div className={classes.root} >  
                    <DataGrid
                        rows={reportsState.reports ? reportsState.reports : []}
                        columns={columns}
                        pageSize={10} 
                       // onCellDoubleClick={e => onDoubleClickRow(e)}
                        //disableSelectionOnClick
                    /> 
                 </div>
            </Grid>      
        </CardContent>
     </Card>
    );

}
export default AutomateReports; 