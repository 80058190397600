import { apiService } from './axios.client';
export const UserEndPoints = {
    createUser: function (input, callback) {
        apiService.post('/user/createUser', input.postObject)
            .then((response) => callback(response, input.props))
            .catch(error => alert(error.response.data.message));
    },
    getUser: function (input, callback) {
        apiService.get('/user/getUser')
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getUserById: function (input, callback) {
        apiService.get('/user/getUserById', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getUserAnalyticsById: function (input, callback) {
        apiService.get('/user/GetUserLoginAnById', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getUserMenus: function (input, callback) {
        apiService.get('/user/getMenusForUser')
            .then((response) => callback(response))
            .catch(callback());
    }, 
    setOptimizeSession: function (input, callback) {
        apiService.post('/UserSession/setOptimizeSession', input)
            .then((response) => callback(response, input))
            .catch(callback());
    },
    updateOptimizeSession: function (input, callback) {
        apiService.post('/UserSession/updateOptimizeSession', input)
            .then((response) => callback(response, input))
            .catch(error => alert(error.response.data.message));
    },
    getOptimizeSessionDescription: function (input, callback) {
        apiService.get('/UserSession/getOptimizeSessionDescription', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getOptimizeSessionById: function (input, callback) {
        apiService.get('/UserSession/getOptimizeSessionById', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getAllOptimizeSession: function (input, callback) {
        apiService.get('/UserSession/getOptimizeSession', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    deleteOptimizeSession: function (input, callback) {
        apiService.post('/UserSession/deleteOptimizeSession', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getLrsr: function (input, callback) {
        apiService.get('/UserSession/getLrsr', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    getLrs: function (input, callback) {
        apiService.get('/UserSession/getLrs', input)
            .then((response) => callback(response))
            .catch(error => alert(error));
    },
    importOptimizeSession: function (input, callback) {
        apiService.post('/UserSession/setOptimizeSessionByLrs', input)
            .then((response) => callback(response, input))
            .catch(error => alert(error.response.data.message));
    },
    updatePassword: function (input, callback) {
        apiService.post('/user/updatePassword', input)
            .then((response) => callback(response, input))
            .catch(error => alert(error));
    },
    sendResetEmail: function (input, callback) {
        apiService.post('/user/sendResetEmail', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getUserLogin: function (input, callback) {
        apiService.get('/user/getUserLogin', input)
            .then((response) => callback(response))
            .catch(error => callback(error));
    },
    resetPassword: function (input, callback) {
        apiService.post('/user/resetPassword', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    sendSupportRequest: function (input, callback) {
        apiService.post('/user/sendSupportRequestEmail', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getUserIdentity: function (input, callback) {
        apiService.get('/user/getUserIdentity', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getRSSFeed: function (input, callback) {
        apiService.get('/user/getRSSFeed', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    editUser: function (input, callback) {
        apiService.post('/User/editUser', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
    getCompany: function (input, callback) {
        apiService.get('/User/getCompany', input)
            .then((response) => callback(response, input))
            .catch(error => callback(error));
    },
};