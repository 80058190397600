import React, { useState, useCallback, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Notification from '../components/shared/notification.component';
import {
  makeStyles,
  Button,
  TextField,
  Checkbox,
  Switch,
  Tooltip,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Tabs,
  Tab,
  Box,
  Radio,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  RadioGroup,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete, Skeleton } from '@mui/material';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/AddCircle';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationDialog from './confirmation-dialog.component';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import PhotometricAttributeSelector from './photometice-attributes-menu/photometric-attribute-selector.component';
import PhotometricAttributeStreetlightSelector from './photometice-attributes-menu/photometric-attribute-streetlight-selector.component';
import luminaireActions from '../store/actions/luminaireActions';
import PhotometricUpload from './photometric-upload.component';
import PhotometricMediaUpload from './photometric-media-upload.component';
import tourActions from '../store/actions/tourActions';
import Loading from '../components/shared/loading.component';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `tertiary, ${theme.palette.grey[400]})`,
      color: 'secondary',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    color: 'inherit',
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    flexGrow: 1,
  },
  dialogPaper: {
    minWidth: '550px',
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    height: '75vh',
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto'
  },
  dialogPaper: {
    minWidth: '550px',
  },
  labelText: {
    flexGrow: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function Group(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo: LabelInfo, removeLuminaire: RemoveInfo, editGroupAttributes: EditGroupAttributes, color, bgColor, group, onUpdate, parentGroupId, ...other } = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openSelectedDelete, setOpenSelectedDelete] = React.useState(false);
  const [editGroupError, setEditGroupError] = React.useState(false);
  const [attributeParams, setAttributeParams] = useState({});
  const [groupName, setGroupName] = React.useState(props.name);
  const [isPublish, setIsPublish] = React.useState(props.isPublish == 1 ? true : false);
  const [groupDescription, setGroupDescription] = React.useState(props.description);
  const [downloadType, setDownloadType] = React.useState("V2");
  const [snack, setSnack] = React.useState({
    open: false
  });
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [openDownload, setOpenDownload] = React.useState(false);
  const [lowestGroups, setLowestGroups] = React.useState(other.lowestGroups);
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const isManufacturer = state.user.roles && state.user.roles.find(role => role.name === "manufacturer" || role.name === "admin");
  const [mediaUpload, setMediaUpload] = React.useState({
    data: [],
    fileNames: [],
    groupId: props.groupId,
    luminaireId: props.luminaireId,
    uploadType: []
  });
  const [selectedUplaodGroup, setSelectedUplaodGroup] = React.useState(other.currentGroup);
  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  useEffect(() => {
    console.log("isPublish", props.isPublish);
  }, [])

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
  }, []);

  const handleMediaUpload = useCallback((mediaUpload) => {
    setMediaUpload(mediaUpload);
  }, []);

  const [editTab, setEditTabIndex] = React.useState(0);
  const handleEditTab = (event, value) => {
    setEditTabIndex(value);
  };

  const handleIspublishClicked = (event) => {
    setIsPublish(!isPublish);
  };

  const onClickDelete = (e) => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Delete Group" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you delete your group or luminaire." }))
    } else {
      setOpenDelete(true);
    }
  };

  const onClickRemoveSelected = (e) => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Remove Luminaires" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you remove selected luminaires from your tree. Ensure you select the luminaires you want to remove before pressing this button." }))
    } else {
      setOpenSelectedDelete(true);
    }
  };

  const onRemoveSelectedConfirmed = (confirmed) => {
    if (confirmed) {
      other.onRemoveConfirmed();
    }
    setOpenSelectedDelete(false);
  };

  const onDeleteConfirmation = (confirmed) => {
    if (confirmed) {
      EdgeOrREST(null, 'DELETE_GROUP_OR_GROUP_ITEM', onDeleteGroupOrGroupItem, { headers: { 'id': props.nodeId }, props: props });
    }
    setOpenDelete(false);
  };

  const onDeleteGroupOrGroupItem = (response) => {
    other.resetGotChildren();
    props.forceUpdate();
  };

  const handleEditDialogClose = () => {
    setOpenEdit(false);
  };

  const handleDownloadDialogClose = () => {
    setOpenDownload(false);
  };

  const onClickEditGroup = () => {
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Edit Group" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you edit your group or luminaire." }))
    } else {
      setOpenEdit(true);
    }
  };

  const onClickOpenDownload = () => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Export Group" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you export your groups into one of our given formats." }))
    } else {
      setOpenDownload(true)
    }

  }

  const onClickExportGroup = () => {
    if (downloadType == "V2") {
      EdgeOrREST(null, 'EXPORT_PHOTOMERTRIC_TREE', onExportTree, { headers: { 'id': props.nodeId } });
    } else if (downloadType == "Spektd") {
      EdgeOrREST(null, 'EXPORT_TO_SPEKTD', onExport, { headers: { 'id': props.nodeId } });
    } else if (downloadType == "Selector") {
      EdgeOrREST(null, 'EXPORT_TO_SELECTOR', onExport, { headers: { 'groupId': props.nodeId, "luminaireId": 0 } });
    }
    setOpenDownload(false);
  };

  const onExportTree = (response) => {
    console.log("onExportTree", response);
    if (response && response.status == '200') {
      var mediaType = "data:application/x-gzip;base64,";
      var a = document.createElement('a');
      a.href = mediaType + response.data.base64zip;
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setSnack({
        open: true,
        message: "Export successful.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: "Downloading of tree failed",
        severity: 'error'
      });
    }
  };

  const onExport = (response) => {
    console.log("onExportSpektd", response);
    if (response && response.status == '200') {
      setSnack({
        open: true,
        message: "Export successful",
        severity: 'success'
      });
    } else if (response.response.data) {
      if (response.response.data.message?.includes("all")) {
        setSnack({
          open: true,
          message: response.response.data.message,
          severity: 'error'
        });
      }
      else {
        setSnack({
          open: true,
          message: response.response.data.message,
          severity: 'warning'
        });
      }
    }
    else {
      setSnack({
        open: true,
        message: response.message,
        severity: 'error'
      });

    }
  };

  const editGorup = () => {
    setEditGroupError(true);
    EdgeOrREST(null, 'UPDATE_GROUP_NAME_DESCRIPTION', onUpdateGroupNameDescription, { 'id': props.nodeId, name: groupName, description: groupDescription, isPublish: isPublish ? 1 : 0 });
    EdgeOrREST(null, 'UPDATE_GROUP_ATTRIBUTES', onUpdateGroupAttributes, { id: props.nodeId, attributeParams: attributeParams });
    EdgeOrREST(null, 'UPLOAD_MEDIA_FOR_LUMINAIRES', onUploadMedia, mediaUpload);
    setOpenEdit(false);
    setEditGroupError(false);
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
  };

  const onUploadMedia = (response) => {
    console.log("onUploadMedia", response);
  };

  const onUpdateGroupNameDescription = (response) => {
    console.log("onUpdateGroupNameDescription", response);
  };

  const onUpdateGroupAttributes = (response) => {
    console.log("onUpdateGroupAttributes", response);
    if (response.status == 200) {
      setSnack({
        open: true,
        message: "Group attributes update successful.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: response.status + response.message,
        severity: 'error'
      });
    }
    other.resetGotChildren();
    props.forceUpdate();
  };

  const handleUploadClose = () => {
    setUploadOpen(false);
    setActiveStep(0);
  };

  const handleUploadClick = () => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Add Luminaires" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you add luminaires to your groups." }))
    } else {
      setLowestGroups(other.lowestGroups);
      setSelectedUplaodGroup(lowestGroups.find(group => group.id === props.nodeId));
      setUploadOpen(true);
      setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getSteps() {
    return ['Select Group for the Luminaire(s)', 'Upload Luminaire(s)'];
  }

  //step content
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div style={{ marginLeft: '10px' }}>
            <h6>{'Please select a group.'}</h6>
            {'*First group is selected by default if no group is selected'}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <SelectGroup />
            </div>
          </div>);
      case 1:
        return (
          <>
            <DialogContentText style={{ marginLeft: '10px' }}> {'Upload Luminaire(s) and select attributes for luminaires'}</DialogContentText>
            <DialogContentText style={{ marginLeft: '10px' }}> {'Only selected attributes will be assigned to the luminaires'}</DialogContentText>
            <PhotometricUpload groupId={selectedUplaodGroup ? selectedUplaodGroup.id : lowestGroups[0]} resetGotChildren={other.resetGotChildren}
              setExpanded={other.setExpanded} expanded={other.expanded} setGotChildren={other.setGotChildren} gotChildren={other.gotChildren}
            />
          </>
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  function checkForManufacturerPriv() {
    var isManu = false;
    if (state.user.roles) {
      for (var i = 0; i < state.user.roles.length; i++) {
        if (state.user.roles[i].name === "manufacturer") {
          isManu = true;
        }
      }
    }
    return isManu;
  }

  const handleSelectedGroup = (event, value) => {
    setSelectedUplaodGroup(value);
    console.log("value", value);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function SelectGroup() {
    console.log("selectedUplaodGroup", selectedUplaodGroup);
    return (
      <>
        <FormControl className={classes.formControl} style={{ width: 250, }}>
          <Autocomplete
            id="multiple-limit-tags"
            options={lowestGroups}
            onChange={handleSelectedGroup}
            value={selectedUplaodGroup}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={<Typography>Select Groups</Typography>} />
            )}
          />
        </FormControl>
      </>
    );
  }

  const stepIcons = {
    1: <FolderIcon />,
    2: <UploadFileIcon />,
  };

  return (
    <>
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <Tooltip title={props?.description}>
              <LabelIcon color="inherit" className={classes.labelIcon} />
            </Tooltip>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {other.isLowest ? <PublishIcon color="inherit" className={classes.label} onClick={handleUploadClick} /> : <></>}
              <GetAppIcon color="inherit" className={classes.label} onClick={onClickOpenDownload} />
              <EditGroupAttributes color="inherit" className={classes.label} onClick={onClickEditGroup} />
              <RemoveInfo color="inherit" className={classes.label} onClick={onClickRemoveSelected} />
              <LabelInfo color="inherit" className={classes.label} onClick={onClickDelete} />
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      >
      </TreeItem>

      <Dialog
        className='manageStep5'
        open={uploadOpen}
        onClose={handleUploadClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Add Luminaire</DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label, index) => (
            <Step key={label} >
              <StepLabel StepIconComponent={() =>
                index < activeStep ? <CheckCircleIcon /> : stepIcons[index + 1]
              }>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {
          <>
            <Typography>{getStepContent(activeStep)}</Typography>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button disabled={activeStep !== 0} variant="contained" color="primary" onClick={handleNext}>{'Next'}</Button>
            </div>
          </>
        }

      </Dialog>
      <Dialog
        className='manageStep10'
        open={openEdit}
        onClose={handleEditDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Edit Group</DialogTitle>
        <DialogContent >
          <TextField
            error={editGroupError}
            autoFocus
            margin="dense"
            label="Enter Name"
            id="standard-name"
            type="text"
            fullWidth
            variant="standard"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Enter Description"
            type="text"
            fullWidth
            id="standard-name"
            defaultValue=""
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            inputProps={{ maxLength: 250 }}
          />
          <Paper square>
            <Tabs
              value={editTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleEditTab}
              aria-label="disabled tabs example"
            >
              <Tab index={0} name="lighting" label="street lighting"  {...a11yProps(0)} value={0} />
              <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
            </Tabs>
          </Paper>
          <TabPanel value={editTab} index={0}>
            <PhotometricAttributeStreetlightSelector handleAttributeParams={handleAttributeParams} edit={true} groupId={props.nodeId} parentGroupId={props.parentGroupId} />
          </TabPanel>
          <TabPanel value={editTab} index={1}>
            <PhotometricAttributeSelector handleAttributeParams={handleAttributeParams} edit={true} groupId={props.nodeId} parentGroupId={props.parentGroupId} />
            {other.isLowest ? <PhotometricMediaUpload groupId={props.nodeId} luminaireId={0} handleMediaUpload={handleMediaUpload} /> : <></>}
          </TabPanel>

          {checkForManufacturerPriv() ?
            <Typography>Published:
              <Switch color="primary" checked={isPublish} onClick={handleIspublishClicked} />
            </Typography> : <></>
          }
        </DialogContent>
        <div style={{ textAlign: "right", margin: 10 }}>
          <Button disabled={!groupName || groupName.length <= 0} variant="contained" color="primary" onClick={editGorup}>
            Save
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={openDownload}
        onClose={handleDownloadDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Export Group</DialogTitle>
        <DialogContent >
          <RadioGroup value={downloadType} onChange={event => setDownloadType(event.target.value)} color="primary">
            <FormControlLabel value="V2" control={<Radio />} label="Lighting Reality Pro V2" />
            <FormControlLabel value="Spektd" control={<Radio disabled />} label="LR Spektd" />
            <FormControlLabel value="Studio" control={<Radio disabled />} label="LR Studio" />
            <FormControlLabel value="Selector" control={<Radio disabled={!isManufacturer} />} label="LR Selector" />
          </RadioGroup>
          <div style={{ textAlign: "right" }}>
            <Button disabled={!groupName || groupName.length <= 0} variant="contained" color="primary" onClick={onClickExportGroup}>
              Export
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog confirm={onDeleteConfirmation} open={openDelete} title={"Delete " + props.type.charAt(0).toUpperCase() + props.type.slice(1)} text={"Do you wish to delete " + props.labelText + "?"}></ConfirmationDialog>
      <ConfirmationDialog confirm={onRemoveSelectedConfirmed} open={openSelectedDelete} title={"Remove Selected Luminaires"} text={"Do you wish to remove the selected Luminaire(s)?"}></ConfirmationDialog>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>

  );
}

//good
function Luminaire(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, removeLuminaire: RemoveInfo, color, bgColor, ...other } = props;
  const [selected, setSelected] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [luminDescription, setLuminDescription] = React.useState(props.description);
  const [attributeParams, setAttributeParams] = useState({});
  const [mediaUpload, setMediaUpload] = React.useState({
    data: [],
    fileNames: [],
    groupId: props.groupId,
    luminaireId: props.luminaireId,
    uploadType: []
  });
  const [snack, setSnack] = React.useState({
    open: false
  });
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const [editTab, setEditTabIndex] = React.useState(0);
  const handleEditTab = (event, value) => {
    setEditTabIndex(value);
  };

  const onSelectLuminaire = (e) => {
    setSelected({ selected: !selected });
    console.log("props in onselectlum", e);
    props.onSelectLuminaire({ id: other.nodeId, parentId: other.parentId, selected: e.target.checked });
  };

  const handleEditDialogClose = () => {
    setOpenEdit(false);
  };

  const onClickEditLuminaire = () => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Edit Group" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you edit your group or luminaire." }))
    } else {
      setOpenEdit(true);
      setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
    }
  };

  const editLuminaire = () => {
    console.log("props.nodeId: ", props.nodeId);
    EdgeOrREST(null, 'UPDATE_LUMINAIRE_DESCRIPTION', onUpdateLuminDescription, { 'id': props.nodeId, description: luminDescription });
    EdgeOrREST(null, 'UPDATE_ATTRIBUTES_TO_LUMINAIRE', onUpdateLuminaireAttributes, { 'id': other.luminaireId, attributeParams: attributeParams });
    EdgeOrREST(null, 'UPLOAD_MEDIA_FOR_LUMINAIRES', onUploadMedia, mediaUpload);
    setOpenEdit(false);
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
  };

  const onUpdateLuminDescription = (response) => {
    console.log("onUpdateLuminDescription", response);
  };

  const onUploadMedia = (response) => {
    console.log("onUploadMedia", response);
  };

  const onUpdateLuminaireAttributes = (response) => {
    console.log("onUpdateLuminaireAttributes", response);
    if (response.status == 200) {
      setSnack({
        open: true,
        message: "Luminaire attributes update successful.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: response.status + response.message,
        severity: 'error'
      });
    }
    other.resetGotChildren();
    props.forceUpdate();
  };

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
  }, []);

  const handleMediaUpload = useCallback((mediaUpload) => {
    setMediaUpload(mediaUpload);
  }, []);


  return (
    <>
      <Dialog
        open={openEdit}
        onClose={handleEditDialogClose}
        classes={{ paper: classes.dialogPaper }}
        className='manageStep10'
      >
        <DialogTitle>Edit Luminaire</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Enter Description"
            type="text"
            fullWidth
            id="standard-name"
            defaultValue=""
            value={luminDescription}
            onChange={(e) => setLuminDescription(e.target.value)}
            inputProps={{ maxLength: 250 }}
          />
          <Paper square>
            <Tabs
              value={editTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleEditTab}
              aria-label="disabled tabs example"
            >
              <Tab index={0} name="lighting" label="street lighting"  {...a11yProps(0)} value={0} />
              <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
            </Tabs>
          </Paper>
          <TabPanel value={editTab} index={0}>
            <PhotometricAttributeStreetlightSelector handleAttributeParams={handleAttributeParams} editLumin={true} luminaireId={other.luminaireId} parentGroupId={other.parentId} />
          </TabPanel>
          <TabPanel value={editTab} index={1}>
            <PhotometricAttributeSelector handleAttributeParams={handleAttributeParams} editLumin={true} luminaireId={other.luminaireId} parentGroupId={other.parentId} />
            <PhotometricMediaUpload groupId={props.parentId} luminaireId={props.luminaireId} handleMediaUpload={handleMediaUpload} />
          </TabPanel>
        </DialogContent>
        <div style={{ textAlign: "right", margin: 10 }}>
          <Button variant="contained" color="primary" onClick={editLuminaire}>
            Save
          </Button>
        </div>
      </Dialog>
      <TreeItem
        label={
          <div className={classes.labelRoot} style={(other.luminaireId == other.selectedNode) ? { background: 'lightgreen' } : {}} >
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {props.isCopy == null || props.isCopy != 1 ? <EditIcon color="inherit" className={classes.label} onClick={onClickEditLuminaire} /> : <></>}
              <Checkbox style={{ padding: '0px' }} value={selected} color="primary" onChange={onSelectLuminaire} ></Checkbox>
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>

  );
}

export default function PhotometricTree(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupName, setGroupName] = React.useState("");
  const [groupDescription, setGroupDescription] = React.useState("");
  const [addGroupError, setAddGroupError] = React.useState(false);
  const [selectedToRemove, setSelectedToRemove] = React.useState([]);
  const [privateGroup, setPrivacy] = React.useState({
    checked: true
  });
  const state = useSelector(state => state);
  const [expanded, setExpanded] = React.useState([]);
  const [gotChildren, setGotChildren] = React.useState([]);
  const [parentGroupId, setParentGroupId] = React.useState(0);
  const [listIds, setListIds] = React.useState([]);
  const [folderArray, setFolderArray] = React.useState([]);
  const [selectedNode, setSelectedNode] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [lowestGroups, setLowestGroups] = React.useState([]);
  const handleChange = (event) => {
    setPrivacy({ ...privateGroup, 'checked': event.target.checked });
  };
  const [snack, setSnack] = React.useState({
    open: false
  });
  const [open, setOpen] = React.useState(false);
  const [attributeParams, setAttributeParams] = React.useState({});
  const [addTap, setAddTap] = React.useState(0);
  const [sort, setSort] = React.useState(state.user.sortBy);
  const sortBy = [
    { value: 0, label: 'A-Z' },
    { value: 1, label: 'Z-A' },
    { value: 2, label: 'Created ↑' },
    { value: 3, label: 'Created ↓' }
  ];
  const [isAddingFolders, setIsAddingFolders] = React.useState(false);
  const [postArr, setPostArr] = React.useState([]);
  const [folderDict, setFolderDict] = React.useState({})
  const [canReset, setCanReset] = React.useState(true);
  const [processComplete, setProcessComplete] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [failedCount, setFailedCount] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [alertShown, setAlertShown] = useState(false);
  const [failedFiles, setFailedFiles] = useState([]);
  const [openRepeat, setOpenRepeat] = React.useState(false);
  const [counter, setCounter] = React.useState(10);
  const [loadingGroups, setLoadingGroups] = React.useState([]);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [groupFilter, setGroupFilter] = React.useState(new Map().set(0, ""));

  const createGroup = (event) => {
    if (groupName && groupName.length > 0 && !(groupName.trim() == "")) {
      var treeItem = {
        'parentId': parentGroupId,
        'lumminaireId': 0,
        'name': groupName,
        'description': groupDescription,
        'isPrivate': privateGroup.checked
      };
      EdgeOrREST(null, 'CREATE_GROUP', onCreateGroup, treeItem);
      console.log("attributeParams", attributeParams);
      setGroupName("");
      setGroupDescription("");
      if (!isAddingFolders) {
        setGroupName("");
      }
      setAddGroupError(false);
      setOpen(false);
    } else {
      setAddGroupError(true);
    }
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
  }

  const onCreateGroup = (response) => {
    EdgeOrREST(null, 'ADD_ATTRIBUTES_TO_GROUP', onAddAttributesToGroup, { attributeParams: attributeParams, id: response.data });
    getAllLowestChildrenFolders();
    if (parentGroupId == 0) {
      resetGotChildren();
      props.forceUpdate();
    } else {
      EdgeOrREST(null, 'GET_TREE_CHILDREN', onGetChildren, { headers: { 'id': parentGroupId } });
    }
  };

  const onAddAttributesToGroup = (response) => {

  };

  const onChangeGroup = (response) => {
    props.forceUpdate();
  };

  const onSelectLuminaire = (event) => {
    console.log("event", event);
    if (event.selected) {
      const selectedLuminaires = selectedToRemove.slice();
      selectedLuminaires.push(event);
      setSelectedToRemove(selectedLuminaires);
    } else {
      var lumCopy = selectedToRemove;
      if (selectedToRemove) {
        var removeIndex = selectedToRemove.map(lum => lum.id).indexOf(event.id);
        removeIndex && lumCopy.splice(removeIndex, 1);
        setSelectedToRemove(lumCopy);
      }
    }
  };

  const onRemoveConfirm = (event) => {
    if (selectedToRemove.length > 0) {
      var ids = [];
      for (var i = 0; i < selectedToRemove.length; i++) {
        ids.push(selectedToRemove[i].id);
      }
      console.log("ids", ids);
      EdgeOrREST(null, 'REMOVE_LUMINAIRES_FROM_TREE', onDeleteGroupOrGroupItem, ids);
    }
  }

  const onDeleteGroupOrGroupItem = (response) => {
    if (response.status == 200) {
      setSnack({
        open: true,
        message: "Group or Luminaire(s) removed successfully.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: response.status + response.message,
        severity: 'error'
      });
    }
    resetGotChildren();
    props.forceUpdate();
  };

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  function checkForAdminPriv() {
    var isAdmin = false;
    var checkbox = [];
    if (state.user.roles) {
      for (var i = 0; i < state.user.roles.length; i++) {
        if (state.user.roles[i].name === "admin") {
          isAdmin = true;
        }
      }
    }
    return checkbox;
  }

  const handleNodeToggle = async (event, nodeIds) => {
    const expandedNodes = nodeIds.filter((nodeId) => !expanded.includes(nodeId));
    if (expandedNodes.length > 0) {
      handleExpand(expandedNodes[0]);
    }
    setExpanded(nodeIds);
  };

  const handleExpand = (nodeId) => {
    if (!gotChildren.includes(nodeId)) {
      handleOpenGroup(nodeId);
      EdgeOrREST(null, 'GET_TREE_CHILDREN', onGetChildren, { headers: { 'id': nodeId } });
      setGotChildren([...gotChildren, nodeId]);
    }
  };

  const handleOpenGroup = (groupId) => {
    setLoadingGroups((prevLoadingGroups) => ({
      ...prevLoadingGroups,
      [groupId]: true,
    }));

    setTimeout(() => {
      setLoadingGroups((prevLoadingGroups) => ({
        ...prevLoadingGroups,
        [groupId]: false,
      }));
    }, 750);
  };

  const onGetChildren = (response) => {
    var wholeGroup = props.groups;
    if (response.data != null && response.data.length > 0 && wholeGroup) {
      if (response.data[0].luminaireId == 0) {
        //groups
        dispatch(user.UpdateUserCfg({ groups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'groups') }));
      } else {
        //luminaires
        dispatch(user.UpdateUserCfg({ groups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'luminaires') }));
      }
    }
  };

  const updateGroup = (parentId, wholeGroup, updatedGroup, type) => {
    const update = groups => {
      return groups.map(group => {
        if (group.id === parentId) {
          group[type] = updatedGroup;
          return group;
        } else if (group.groups) {
          return {
            ...group,
            groups: update(group.groups)
          };
        } else {
          return group;
        }
      });
    };
    return update(wholeGroup);
  };

  function resetGotChildren() {
    setExpanded([]);
    setGotChildren([]);
    getAllLowestChildrenFolders();
  }

  useEffect(() => {
    resetGotChildren();
  }, [])

  useEffect(() => {
    console.log("state?.LuminaireCfg", state.LuminaireCfg);
    console.log("selectedNode", selectedNode);
    if (state?.LuminaireCfg?.selectedResultLuminId && state?.LuminaireCfg?.selectedResultLuminId != selectedNode) {
      EdgeOrREST(null, 'GET_ALL_FOLDER_PARENT', onGetParent, { headers: { 'id': state.LuminaireCfg.selectedResultLuminId } });
      setSelectedNode(state.LuminaireCfg.selectedResultLuminId);
    }
  }, [state?.LuminaireCfg])

  const onGetParent = (response) => {
    console.log("onGetParent", response);
    if (response.data?.length > 0) {
      const parentIds = [];
      setSelectedGroup(response.data[0]);
      resetGotChildren();
      response.data.reverse().forEach(({ id }, index) => {
        setTimeout(() => {
          handleExpand(id);
        }, index * 500); // wait 0.1 second between each data
        parentIds.push(id);
      });
      setExpanded(parentIds);
    }
  };

  const handleOpen = (currentId) => {
    if (state.EnabledTour.helpMode) {
      dispatch(tourActions.UpdateHelpURL({ helpURL: "Add Groups" }))
      const video = document.getElementById("help-video")
      video.load();
      dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you create new groups." }))
    } else {
      setIsAddingFolders(false);
      setParentGroupId(currentId);
      setOpen(true);
      setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
    }
  }

  function DirectoryInput() {
    const handleDirectoryChange = async (addingFiles, event) => {
      event.preventDefault();

      setSnack({
        open: true,
        message: "Process has started.",
        severity: 'info'
      });

      setStartTime(new Date());
      setAlertShown(false);

      const files = event.target.files;
      const hasSubfolders = new Set();
      const folderFullPathArray = [];
      const allowedExtensions = ['.ldt', '.ies'];

      for (let i = 0; i < files.length; i++) {
        const filePath = files[i].webkitRelativePath;
        const parentLevel = filePath.substring(0, filePath.lastIndexOf("/"));
        const bottomLevel = parentLevel.substring(0, parentLevel.lastIndexOf("/"));

        hasSubfolders.add(bottomLevel);

        const folderNames = filePath.split("/");
        let currentFullPath = folderNames[0];

        for (let j = 1; j < folderNames.length - 1; j++) {
          currentFullPath += "/" + folderNames[j];
          if (!folderFullPathArray.includes(currentFullPath)) {
            folderFullPathArray.push(currentFullPath);
          }
        }
      }

      if (addingFiles) {
        for (let i = 0; i < files.length; i++) {
          const filePath = files[i].webkitRelativePath;
          const file = files[i];
          const parentLevel = filePath.substring(0, filePath.lastIndexOf("/"));
          const fileExtension = filePath.split('.').pop().toLowerCase();
          // console.log("fileExtension",fileExtension)

          if (!hasSubfolders.has(parentLevel) && allowedExtensions.includes(`.${fileExtension}`)) {
            const base64 = await convertToBase64(file);

            postArr.push({
              'file': base64,
              'filePath': parentLevel,
              'type': fileExtension,
              'name': filePath.split("/").pop(),
              'isPublic': false,
              'attributes': { manufacturer: "any", ...attributeParams }
            });
          }
        }
      }

      if (files.length > 0) {
        const folderFullPathArray = [];
        for (let i = 0; i < files.length; i++) {
          const filePath = files[i].webkitRelativePath;
          const folderNames = filePath.split("/");
          let currentLevel = 1;
          let currentFolder = folderNames[0];
          let currentFullPath = currentFolder;

          for (let j = 0; j < folderNames.length - 1 && currentLevel < folderNames.length - 1; j++) {
            currentFolder = currentFolder + "/" + folderNames[currentLevel];
            currentFullPath += "/" + folderNames[currentLevel];
            if (folderFullPathArray.indexOf(currentFullPath) === -1) {
              folderFullPathArray.push(currentFullPath);
            }
            currentLevel++;
          }
        }
        folderFullPathArray.sort();
        setFolderArray(folderFullPathArray);
        handleGroupNames(folderFullPathArray, files[0].webkitRelativePath.split("/")[0]);
      }
    };

    return (
      <div>
        <input
          type="file"
          directory="" webkitdirectory="" mozdirectory=""
          onChange={(event) => handleDirectoryChange(true, event)}
          style={{ display: 'none' }}
          id="directory-files-input"
        />
        <label htmlFor="directory-files-input">
          <Button variant="text" color="inherit" component="span" onMouseEnter={() => handleResetArr()} onMouseLeave={() => setCanReset(false)}>
            <AddIcon style={{ fontSize: '15pt' }} />
            <Typography variant="body2" className={classes.labelText}>
              {"Add Folders and Files"}
            </Typography>
          </Button>
        </label>

        <input
          type="file"
          directory="" webkitdirectory="" mozdirectory=""
          onChange={(event) => handleDirectoryChange(false, event)}
          style={{ display: 'none' }}
          id="directory-input"
        />
        <label htmlFor="directory-input">
          <Button variant="text" color="inherit" component="span">
            <AddIcon style={{ fontSize: '15pt' }} />
            <Typography variant="body2" className={classes.labelText}>
              {"Add Folders"}
            </Typography>
          </Button>
        </label>
      </div>
    );
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(';base64,').pop(); // Remove prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleGroupNames = (folderNameArray, rootPath) => {
    setIsAddingFolders(true);
    setListIds([]);

    var treeItem = {
      'parentId': 0,
      'lumminaireId': 0,
      'name': rootPath + "_import",
      'description': groupDescription,
      'isPrivate': true
    };
    handleRecursiveGroupCreation(0, folderNameArray, 0, treeItem, []);

    setAddGroupError(false);
  };

  const handleRecursiveGroupCreation = (count, folderArray, parentId, initialTreeItem, localIds) => {
    resetGotChildren();
    props.forceUpdate();
    if (folderArray.length >= count) {
      if (count === 0) {
        EdgeOrREST(null, 'CREATE_GROUP', (response) => {
          localIds.push(response.data);
          handleRecursiveGroupCreation(count + 1, folderArray, response.data, initialTreeItem, localIds);
          setListIds(prevListIds => { const updatedListIds = [...prevListIds, response.data]; return updatedListIds; });
        }, initialTreeItem);
      }
      else {
        const newGroup = folderArray[count - 1];
        if (newGroup) {
          const level = newGroup.split('/').length - 1;
          const newGroupName = newGroup.split("/").pop();
          const parentFullPath = newGroup.substring(0, newGroup.lastIndexOf("/"));
          const parentIndex = folderArray.indexOf(parentFullPath);

          if (parentIndex !== -1) {
            parentId = localIds[parentIndex] + 1;
          }

          if (level === 1) {
            parentId = localIds[0];
          }

          var treeItem = {
            'parentId': parentId,
            'lumminaireId': 0,
            'name': newGroupName,
            'description': groupDescription,
            'isPrivate': true
          };

          EdgeOrREST(null, 'CREATE_GROUP', (response) => {
            localIds.push(response.data);
            handleRecursiveGroupCreation(count + 1, folderArray, response.data, initialTreeItem, localIds);
            setListIds(prevListIds => [...prevListIds, response.data]);
          }, treeItem);
        }
        else {
          setIsAddingFolders(false);
        }
      }
    }
    else {
      setIsAddingFolders(false);
    }
  };

  useEffect(() => {
    let folderMapping = {};

    folderArray.forEach((path, index) => {
      const level = path.split('/').length - 1;
      if (!folderMapping[level]) {
        folderMapping[level] = [];
      }
      folderMapping[level].push({ path, id: listIds[index] + 1 });
    });

    setFolderDict(folderMapping);

    if (!isAddingFolders && listIds.length > folderArray.length && postArr.length > 0) {
      setProcessedCount(0);
      setFailedCount(0);
      setFailedFiles([]);
      handleFiles();
    }
  }, [listIds]);

  const handleFiles = async () => {
    const batchSize = 200; // Number of files to upload per batch

    let localProcessed = 0

    while (localProcessed < (batchSize * 10)) {
      const batchFiles = postArr.slice(0, batchSize);

      await uploadBatch(batchFiles);

      localProcessed += batchSize;
      postArr.splice(0, batchSize);
    }

    if (postArr.length > 0) {
      setCounter(10);
      setOpenRepeat(true);
    } else {
      setProcessComplete(true);
      setCanReset(true);
    }
  };

  function handleRepeatUpload() {
    setOpenRepeat(false)
    handleFiles();
  }

  const uploadBatch = async (batchFiles) => {
    const uploadPromises = batchFiles.map(async (file) => {
      const filePath = file.filePath;

      return new Promise((resolve) => {
        Object.keys(folderDict).forEach((level) => {
          for (let folderIndex = 0; folderDict[level] && folderIndex < folderDict[level].length; folderIndex++) {
            if (filePath === folderDict[level][folderIndex].path) {
              EdgeOrREST(null, 'UPLOAD_LUMINAIRES_AND_ADD_GROUP', (response) => {
                if (response.data.failed) {
                  setFailedCount(prevFailedCount => prevFailedCount + 1);
                  setFailedFiles(prevFailedFiles => [...prevFailedFiles, file.name]);
                } else {
                  setProcessedCount(prevProcessedCount => prevProcessedCount + 1);
                }
                resolve();
              }, { groupId: folderDict[level][folderIndex].id, addLuminaire: [file] });
              break;
            }
          }
        });
      });
    });

    await Promise.all(uploadPromises);
  };

  useEffect(() => {
    if (processComplete && !alertShown) {
      const endTime = new Date();
      const durationSeconds = (endTime - startTime) / 1000; // Duration in seconds
      const minutes = Math.floor(durationSeconds / 60);
      const seconds = Math.floor(durationSeconds % 60);
      const duration = minutes > 0 ? `${minutes} minute(s) ${seconds} second(s)` : `${seconds} second(s)`;
      window.alert(`Process is finished. It took ${duration}. ${processedCount} files were successful. ${failedFiles.length > 0 ? `${failedFiles.length} files failed to import.` : 'No files failed'}`);
      setProcessComplete(false);
      setFailedCount(0);
      setAlertShown(true);
    }
  }, [processComplete]);

  React.useEffect(() => {
    if (openRepeat) {
      if (counter === 0) {
        handleRepeatUpload();
      }
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, openRepeat]);

  const handleResetArr = () => {
    if (canReset) {
      setPostArr([])
    }
  }

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
  }, []);

  const handleClick = (id) => {
    dispatch(luminaireActions.UpdateLuminaireList({ ...state.LuminaireCfg, selectedResultLuminId: id }));
    dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: true }));
    setSelectedNode(id);
  }

  const getAllLowestChildrenFolders = () => {
    EdgeOrREST(null, 'GET_ALL_LOWEST_CHILDREN_FOLDER', getAllLowestChildrenFolder, props);
  }

  const getAllLowestChildrenFolder = (response) => {
    if (response) {
      setLowestGroups(response.data);
    }
  };

  const handleSortChange = (value) => {
    setSort(value);
    state.user.sortBy = value;
    dispatch(user.UpdateUserCfg(state.user));
  }

  const sortedGroups = (groups) => {
    switch (sort) {
      case 0:
        // Code for sorting alphabetically (A-Z)
        return groups?.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        }).map((item) => {
          return (
            renderTree(item)
          );
        });
      case 1:
        // Code for sorting in reverse alphabetical order (Z-A)
        return groups?.slice().sort((a, b) => {
          return b.name.localeCompare(a.name);
        }).map((item) => {
          return (
            renderTree(item)
          );
        });

      case 2:
        // Code for sorting by creation date in ascending order (Created ↑)
        return groups?.slice().map((item) => {
          return (
            renderTree(item)
          );
        });
      case 3:
        // Code for sorting by creation date in descending order (Created ↓)
        return groups?.slice().reverse().map((item) => {
          return (
            renderTree(item)
          );
        });
      default:
        console.error('Invalid option');
        break;
    }
  }

  const handleRefreshTree = () => {
    setIsRefresh(true)
    resetGotChildren();
    props.forceUpdate();
    setTimeout(() => { setIsRefresh(false) }, 750);
  }

  const onGroupFilterChange = (e, id) => {
    if (e.nativeEvent.inputType == "insertText") {
      var currText = groupFilter.get(id) == undefined ? "" : groupFilter.get(id);
      setGroupFilter(new Map(groupFilter.set(id, currText + e.nativeEvent.data)));
    } else if (e.nativeEvent.inputType == "deleteContentBackward") {
      var currText = groupFilter.get(id) == undefined ? "" : groupFilter.get(id);
      setGroupFilter(new Map(groupFilter.set(id, currText.slice(0, -1))));
    } else if (e.nativeEvent.inputType == "deleteWordBackward") {
      setGroupFilter(new Map(groupFilter.set(id, "")));
    }
  };

  function renderTree(item) {
    return (
      <Paper className={classes.paper}>
        <Group forceUpdate={onChangeGroup} name={item?.name} description={item?.description} type="group" nodeId={item.id} labelText={item.name /*+ " - ID: " + item.id*/} labelIcon={FolderIcon}
          color="#1a73e8" bgColor="#e8f0fe" labelInfo={DeleteIcon} removeLuminaire={RemoveIcon} onRemoveConfirmed={onRemoveConfirm} editGroupAttributes={EditIcon} parentGroupId={item.parentId}
          resetGotChildren={resetGotChildren} isLowest={lowestGroups.some(group => group.id === item.id)} lowestGroups={lowestGroups} isPublish={item.isPublish} currentGroup={item}
          setExpanded={setExpanded} expanded={expanded} setGotChildren={setGotChildren} gotChildren={gotChildren}
        >
          <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            style={{ paddingLeft: '10px' }}
          >
            {item?.luminaires ? <></> : <div style={{ textAlign: "center" }}>
              <Button variant="text" id={item.id} color="inherit" onClick={() => handleOpen(item.id)} >
                <AddIcon style={{ marginTop: 10, fontSize: '15pt' }} />
                <Typography variant="body2" className={classes.labelText}>
                  {"Add Group"}
                </Typography>
              </Button>
              <TextField
                onChange={(e) => onGroupFilterChange(e, item.id)}
                value={groupFilter.get(item.id)}
                label={<Typography >Group Filter</Typography>}
                style={{ width: 200, marginLeft: 10, marginBottom: 10, fontSize: '15pt' }}
              />
            </div>}

            {loadingGroups[item.id] ? (
              <Loading skeleton={true} />
            ) : (
              sortedGroups(item.groups?.slice().filter(group => (group.name).includes(groupFilter.get(item.id) == undefined ? "" : groupFilter.get(item.id).toLowerCase())))
            )}

            {!loadingGroups[item.id] ?
              item?.luminaires?.map((luminaire) => {
                return (
                  <Luminaire isCopy={luminaire.isCopy} parentId={luminaire.parentId} nodeId={luminaire.id} luminaireId={luminaire.luminaireId} onSelectLuminaire={onSelectLuminaire} key={luminaire.id} color="#1a73e8" bgColor="#e8f0fe" labelText={luminaire.name}
                    labelIcon={DescriptionIcon} forceUpdate={onChangeGroup} resetGotChildren={resetGotChildren} selectedNode={selectedNode} onLabelClick={(event) => { event.preventDefault(); handleClick(luminaire.luminaireId) }} description={luminaire?.description} />
                );
              }) : <></>}
          </TreeView>
        </Group>
      </Paper>
    );
  }

  return (
    <>
      <Dialog
        className='manageStep8'
        open={open}
        onClose={handleDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please enter the Name, Description and choose the Photometric Attributes.
          </DialogContentText>
          <TextField
            error={addGroupError}
            autoFocus
            margin="dense"
            label="Enter Name"
            id="standard-name"
            type="text"
            fullWidth
            variant="standard"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Enter Description"
            type="text"
            fullWidth
            id="standard-name"
            defaultValue=""
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            inputProps={{ maxLength: 250 }}
          />
          <Paper square>
            <Tabs
              value={addTap}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(e, value) => setAddTap(value)}
              aria-label="disabled tabs example"
            >
              <Tab index={0} name="lighting" label="street lighting"  {...a11yProps(0)} value={0} />
              <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
            </Tabs>
          </Paper>
          <TabPanel value={addTap} index={0}>
            <PhotometricAttributeStreetlightSelector handleAttributeParams={handleAttributeParams} parentGroupId={parentGroupId} />
          </TabPanel>
          <TabPanel value={addTap} index={1}>
            <PhotometricAttributeSelector handleAttributeParams={handleAttributeParams} parentGroupId={parentGroupId} />
          </TabPanel>
        </DialogContent>
        <div style={{ textAlign: "right", margin: 10 }}>
          <Button disabled={!groupName || groupName.length <= 0} variant="contained" color="primary" onClick={createGroup}>
            Create Group
          </Button>
        </div>
      </Dialog>

      {/* Message during the batch upload of files */}
      <Dialog
        open={openRepeat}
        onClose={() => setOpenRepeat(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Continue Adding Files</DialogTitle>
        <DialogContent >
          <DialogContentText>
            You have {postArr.length} files left to upload. Would you like to continue?
            Auto continue in {counter} second(s).
          </DialogContentText>
        </DialogContent>
        <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
          <Button onClick={() => setOpenRepeat(false)}>
            Exit
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleRepeatUpload()}>
            Continue
          </Button>
        </div>
      </Dialog>
      {/* End of message */}

      {checkForAdminPriv()}
      <TextField
        value={sort}
        onChange={(e) => handleSortChange(e.target.value)}
        select
        label="Sort By"
        variant="outlined"
        style={{ width: 120, marginLeft: 10, alignSelf: 'flex-end' }}
      >
        {sortBy.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField onChange={(e) => onGroupFilterChange(e, 0)} value={groupFilter.get(0)} label={<Typography >Group Filter</Typography>} style={{ width: 200, marginLeft: 10 }}
      />
      <Button style={{ position: "absolute", right: "3%" }} variant='outlined' color="primary" onClick={() => handleRefreshTree()}>Refresh</Button>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
      >
        {isRefresh ? (
          <Loading skeleton={true} height={700} />
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button variant="text" color="inherit" onClick={() => handleOpen(0)} >
              <AddIcon style={{ fontSize: '15pt' }} />
              <Typography variant="body2" className={classes.labelText}>
                {"Add Group"}
              </Typography>
            </Button>
            {/* Folder Selection begins here */}
            <DirectoryInput />
          </div>)}
        {!isRefresh ? sortedGroups(props?.groups.slice().filter(item => (item.name).includes(groupFilter.get(0).toLowerCase()))) : <></>}
      </TreeView>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>
  );
}
