import React, { Component } from 'react';
import Page from '../Page';
import {
    Container,
    makeStyles,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Button,
    Typography,
    FormHelperText,
    Box,
    Avatar,
    IconButton,
    CssBaseline,
    Link,
} from '@material-ui/core';
import LRTitle from '../../IMG/Logos/LR_Title_Logo.jpg';
import LRStudio from '../../IMG/Logos/LR_Studio_Colour.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EdgeOrREST from '../../EdgeOrREST';
import Notification from '../shared/notification.component';
import { func } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LRTitle: {
        marginTop: theme.spacing(5),
        width: theme.spacing(29),
        height: theme.spacing(11)
    },
    LRStudio: {
        margin: theme.spacing(3),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(30),
        height: theme.spacing(25)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lightingreality.com/">
                Lighting Reality
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}


function SupportView(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [subjectError, setSubjectError] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [descriptionError, setDescriptionError] = React.useState(false);
    const [fileNames, setFileNames] = React.useState([]);
    const [fileContents, setFileContents] = React.useState([]);
    const [snack, setSnack] = React.useState({
        open: false
    });
    function onAvatarClick(props) {
        navigate('lightingreality.com');
    }

    function toLauncher(props) {
        navigate("/launcher");
    }

    function submitForm(e, props, name, subject, description, fileNames, fileContents) {
        if (name == null || name == "") {
            setNameError(true);
        } else if (subject == null || subject == "") {
            setSubjectError(true);
        } else if (description == null || description == "") {
            setDescriptionError(true);
        } else {
            console.log('File names:', fileNames);
            console.log('File contents:', fileContents);
            EdgeOrREST(null, "SEND_SUPPORT_REQUEST", sendSupportRequest, { name: name, subject: subject, description: description, attachmentList: fileContents, attachmentNameList: fileNames });
        }
    }

    const sendSupportRequest = (response) => {
        if (response.data.status == 200) {
            setSnack({
                open: true,
                message: 'Request sent successfully',
                severity: 'success'
            });
        } else {
            setSnack({
                open: true,
                message: response.status + " " + response.message,
                severity: 'error'
            });
        }
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    function handleName(value) {
        setName(value);
        if (value) {
            setNameError(false);
        } else {
            setNameError(true);
        }
    }

    function handleSubject(value) {
        setSubject(value);
        if (value) {
            setSubjectError(false);
        } else {
            setSubjectError(true);
        }
    }

    function handleDescription(value) {
        setDescription(value);
        if (value) {
            setDescriptionError(false);
        } else {
            setDescriptionError(true);
        }
    }
    
    function handleFileChange(e) {
        const files = e.target.files;
        var fileNames = [];
        var fileContents = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                fileNames.push(file.name);
                fileContents.push(content);
            };
            reader.readAsDataURL(file);
        }
        setFileNames(fileNames);
        setFileContents(fileContents);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <IconButton onClick={() => onAvatarClick(props)} className="customIconButton">
                    <Avatar className={classes.LRStudio} src={LRStudio} variant="square" />
                </IconButton>
                <Typography component="h1" variant="h5">
                    Support Request
                </Typography>
                <Divider />
                    <FormHelperText
                        style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}
                    >
                        Please fill out this form with the details of your problem and our support team will work to resolve it as quickly as possible.
                    </FormHelperText>

                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="name"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Name"
                                                name="name"
                                                autoComplete="name"
                                                value={name}
                                                error={nameError}
                                                onChange={(e) => handleName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="subject"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="subject"
                                                label="Subject"
                                                name="subject"
                                                autoComplete="subject"
                                                value={subject}
                                                error={subjectError}
                                                onChange={(e) => handleSubject(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="description"
                                                variant="outlined"
                                                required
                                                multiline
                                                minRows={2}
                                                fullWidth
                                                id="description"
                                                label="Description"
                                                name="description"
                                                value={description}
                                                error={descriptionError}
                                                onChange={(e) => handleDescription(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <input
                                                id="file"
                                                type="file"
                                                // id="contained-button-file"
                                                onChange={(e) => handleFileChange(e)}
                                                multiple
                                                className={classes.input}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={nameError || subjectError || descriptionError}
                                        onClick={(e) => submitForm(e, props, name, subject, description, fileNames, fileContents)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Submit Support Request
                                    </Button>
                {/* </form> */}
                <Grid container justify="center">
                    <Grid item>
                        <Link component="button" variant="body2" onClick={() => toLauncher(props)}>
                            Go back to Home page?
                        </Link>
                    </Grid>
                </Grid>
                <IconButton onClick={() => onAvatarClick(props)} className="customIconButton">
                    <Avatar className={classes.LRTitle} src={LRTitle} variant="rounded" />
                </IconButton>
                <Box mt={1}>
                    <Copyright />
                </Box>
            </div>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Container >
    );
};

export default SupportView;
