import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Drawer,
	Hidden,
	List,
	Typography,
	makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';
 
const user = {
	avatar: '/static/images/avatars/avatar_6.png',
	jobTitle: 'Senior Developer',
	name: 'Katarina Smith'
}; 

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 70
	},
	desktopDrawer: {
		width: 70,
		top: 64,
		height: 'calc(100% - 64px)',
		overflow: 'hidden'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	}
}));

const NavBar = ({ onMobileClose, openMobile, items }) => {
	
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);


	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
		>
			<Box>
				<List>
					{items.map((item) => (
						<NavItem
							href={item.href}
							key={item.title} 
							icon={item.icon}
							altIcon={item.altIcon}
						/>
					))}
				</List>
			</Box>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

NavBar.defaultProps = {
	onMobileClose: () => { },
	openMobile: false
};

export default NavBar;