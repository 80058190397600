import React, { useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Page from '../components/Page';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    Tabs,
    Typography,
    Tab,
    makeStyles
} from '@material-ui/core';
import AddUser from './userAdministrate-addUser.component';
import EditUser from './userAdministrate-editUser.component';
import EditCompany from './userAdministrate-editCompany.component';
import AddCom from './userAdministrate-addCom.component';
import EditComany from './userAdministrate-editCompany.component';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UserAdministrateDashboard(props) {
    const [leftTabIndex, setLeftTabs] = React.useState(0);
    const [middleTabIndex, setMiddleTabIndex] = React.useState(0);
    const [rightTabIndex, setRightTabIndex] = React.useState(0);
    const classes = useStyles();

    const handleLeftTabs = (event, value) => {
        setLeftTabs(value);
    };

    return (
        <Page className={classes.root} title="UserAdmin" style={{ 'minHeight': '85vh' }}>
            <Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
                {/* First row */}
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={leftTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                onChange={handleLeftTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="Add User" {...a11yProps(0)} value={0} />
                                <Tab index={1} label="Add Company" {...a11yProps(1)} value={1} />
                                <Tab index={2} label="Manage User" {...a11yProps(2)} value={2} />
                                <Tab index={3} label="Manage Company" {...a11yProps(3)} value={3} />
                            </Tabs>
                        </Paper>

                        <Divider />
                        <TabPanel value={leftTabIndex} index={0}>
                            <CardContent>
                                <AddUser/> 
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={leftTabIndex} index={1}>
                            <CardContent>
                                <AddCom/> 
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={leftTabIndex} index={2}>
                            <CardContent>
                                <EditUser/> 
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={leftTabIndex} index={3}>
                            <CardContent>
                                <EditComany/> 
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </Page >
    );
} 