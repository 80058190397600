import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhotometricGroupsViewer from '../../photometricManager/photometric-groups-viewer.component'
import userActions from '../../store/actions/user';
import luminaireActions from '../../store/actions/luminaireActions';
import columnActions from '../../store/actions/columnActions';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: '70vh',
		padding: theme.spacing(3),
		width: '100%',
		overflowY: 'auto'
	},
}));

const LuminCard = ({ index }) => {
	const state = useSelector(state => state);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [groups, setGroups] = React.useState(state?.user?.groups ? state?.user?.groups : []);

	const onGetGroups = (response) => {
		if (response && response.data) {
			console.log("lumin card get onGetGroups", response.data);
			setGroups(response.data);
			dispatch(userActions.UpdateUserCfg({ groups: response.data }));
			dispatch(userActions.UpdateUserCfg({ refreshJobs: true }));
		}
	};

	const handleClick = (data, folder) => {
		console.log("lumin card calling handleClick", data, folder);
		let sendObj = API_OBJECTS.getCalculationObject(state, null, null);
		if (folder) {
			let selectedFolderIds = state.LuminaireCfg.selectedFolderIds;
			selectedFolderIds[index] = data.id;
			sendObj["folderIds"][index] = data.id;
			dispatch(luminaireActions.UpdateLuminaireList({ ...state.LuminaireCfg, selectedFolderIds }));
		}
		else {
			let selectedLuminaireIds = state.LuminaireCfg.selectedLuminaireIds;
			selectedLuminaireIds[index] = data.luminaireId;
			sendObj["luminaireIds"][index] = data.luminaireId;
			sendObj.columns[index].columnFlux = 0;
			dispatch(luminaireActions.UpdateLuminaireList({ ...state.LuminaireCfg, selectedLuminaireIds, selectedResultName: data.name }));
			// dispatch(userActions.UpdateUserCfg({ refreshLuminaireInfo: true }));
		}
		if (window.location.href.indexOf("optimize") > -1 && !folder) {
			EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetLuminaireInfo, { headers: { 'id': data.luminaireId } });
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, sendObj);
		}
	}
	
	const onGetLuminaireInfo = (response) => {
		if (response && response.data) {
			let flux = response.data.info.flux >= 50 ? response.data.info.flux/1000 : response.data.info.flux;
			const updatedColumns = state.ColumnCfg.Columns.map((column, idx) => {
				if (idx === index) {
				  // Update the specific column
				  return { ...column, columnFlux: flux, defaultFlux: flux};
				}
				return column; // Return unchanged columns
			});
			dispatch(columnActions.UpdateColumnCfg({ Columns: updatedColumns }));
		}
	}

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				gridResults
			}));
		}
	}

	useEffect(() => {
		let isMounted = true;
		if (groups.length == 0 || !groups) {
			console.log("lumin card calling get groups from useEffect");
			EdgeOrREST(null, 'GET_USER_ROOT_FOLDERS', onGetGroups, state);
		}
		return () => { isMounted = false };
	}, []);

	return (

		<PhotometricGroupsViewer selectedLuminaires={state.LuminaireCfg.selectedLuminaireIds} groups={groups} handleClick={handleClick} index={index} />
	)
}

export default LuminCard;
