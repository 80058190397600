import React from 'react';
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Box,
  Slider
} from '@material-ui/core';
import Language from './Comp_Language';
import LinearInterp from './Comp_LinearInterp';
import DriveSide from '../../components/Settings/component.driveSide';
import ContourSettings from './Comp_Contour_Values';
import ReiLimit from '../../components/Settings/component.reiLimit';
import { useDispatch } from 'react-redux';
import user from '../../store/actions/user';

const SettingsView = (props) => {
  const dispatch = useDispatch();

  const [scaling, setScaling] = React.useState(window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1);

  const handleFontScalingChange = (event, newValue) => {
    setScaling(newValue);
    window.localStorage.setItem("fontScaling", newValue);
    dispatch(user.UpdateUserCfg({ updateGlobalStyles: true }));
  };

  return (
    <>
        <Container maxWidth="lg">
          <Card>
            <CardHeader title="General Settings" />
            <Divider />
            <CardContent>

              <Grid container spacing={1}>
                <Grid item md={6}>
                  <LinearInterp />
                </Grid>
                <Grid item md={6}>
                  <DriveSide />
                </Grid>
                <Grid item md={6}>
                  <Language />
                </Grid>
                <Grid item md={12}>
                  <ReiLimit />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CardHeader title="Contours" />
                  <ContourSettings />
                </Grid>
                <Grid item md={6} xs={12}>
                  <label> Font Scaling: </label>
                  <Box width={100}>
                    <Slider
                      size="small"
                      defaultValue={scaling}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      step={0.1}
                      min={0.5}
                      max={2.5}
                      onChange={handleFontScalingChange}
                    />
                  </Box>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Container>
    </>
  );
};

export default SettingsView;
