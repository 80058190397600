import axios from 'axios';
import { API_URL } from './apiConfig';

const baseURL = API_URL;
//const baseURL = 'https://localhost:44323/api' 

export const apiService = axios.create({
	baseURL,
});

var token;
export function setAxiosHeaders(setToken) {
	token = setToken;
	axios.defaults.headers.common['lrAuth'] = setToken;
}

apiService.interceptors.request.use(function (config) {
	config.headers.lrAuth = token;
	return config;
});

axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401) {
		window.localStorage.setItem("authedUser", false);
		window.localStorage.setItem("token", null);
		window.localStorage.setItem("uid", null);
		window.localStorage.setItem("cid", null);
		window.location.pathname = "/login";
	}
	return error;
});
