import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import monitorActions from '../store/actions/monitorActions';
import user from '../store/actions/user';

export default function MonitorPagination() {
  const state = useSelector(state => state.MonitorCfg); 
  const dispatch = useDispatch();
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);


  const onChange = (event, value) => {
    setPage(value);
    dispatch(monitorActions.UpdateMonitorCfg({monitorPages: value}));
  };

  useEffect(() => {
    setCount(1);
  }, []);

  useEffect(() => {
    setCount(Math.ceil(state.monitorJobs?.length/20));
    setPage(state.monitorPages);
    //setPage();
  }, [state.monitorJobs]);

  return (
    <Stack spacing={1}>
        <Pagination count={count} color="primary" page={page} onChange={onChange} size="small" />
    </Stack>
  );
}