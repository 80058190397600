import React, { useEffect, useState } from 'react';
import { Layer, Image, Transformer } from "react-konva";

export default function Column(props) {
    const trRef = React.useRef();
    const layerRef = React.useRef();
    const shapeRef = React.useRef();

    const [displayRotation, setDisplayRotation] = useState(0);
    const [actualRotation, setActualRotation] = useState(0);


    useEffect(() => {
        if (trRef.current && props.selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [props.selected])


    return (
        <Layer ref={layerRef} key={props.i} >
            <Image x={0 * props.gridScaleX} y={0 * props.gridScaleY} image={props.image} draggable={true}
                ref={shapeRef}
                onTap={(e) => {
                    props.selectColumn(props.i);
                }}
                onClick={(e) => {
                    props.selectColumn(props.i);
                }}
                onDragMove={
                    (e) => {
                    }
                }
                onDragEnd={
                    (e) => {
                        props.updateColumn(e, props.i);
                    }
                }
            >
            </Image>
            {props.selected ?
                <Transformer ref={trRef} keepRatio={true} enabledAnchors={false}
                    onTransform={
                        (e) => {
                            setActualRotation(360 - e.currentTarget.attrs.rotation);
                        }
                    }
                    onDragMove={
                        (e) => {
                            //console.log("onDragMove", setCoOrds({x: e.currentTarget.attrs.x , y: e.currentTarget.attrs.y}));
                        }
                    }

                    onDragEnd={
                        (e) => {

                        }
                    }

                />
                : <></>}
        </Layer>
    )
}