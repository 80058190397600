import React, { useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import {
  Card,
  CardContent,
  makeStyles,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Typography,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Tooltip
} from '@material-ui/core';
import Notification from '../components/shared/notification.component';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  margin: {
    margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  container: {
    maxHeight: '35vh',
    minHeight: '15vh',
  },
}));


const PhotometricMediaUpload = (props) => {
  const classes = useStyles();
  const state = useSelector(state => state);
  const [uploadType, setUploadType] = React.useState([]);
  const [isCopyGroup, setIsCopyGroup] = React.useState(false);
  const [fileState, setFileState] = React.useState({
    files: []
  });
  const [mediaUpload, setMediaUpload] = React.useState({
    data: [],
    fileNames: [],
    groupId: props.groupId,
    luminaireId: props.luminaireId,
    uploadType: []
  });
  const [uploadMediaList, setuploadMediaList] = React.useState([]);
  const [showMediaList, setShowMediaList] = React.useState(false);
  const [checkedList, setCheckedList] = React.useState([]);
  const [reload, setReload] = React.useState(false);

  //for the copy group
  useEffect(() => {
    setIsCopyGroup(props?.isCopyGroup?props.isCopyGroup:false);
  }, []);

  useEffect(() => {
    props.handleMediaUpload(mediaUpload);
  }, [mediaUpload]);

  useEffect(() => {
    if (props.luminaireId == 0) {
      EdgeOrREST(null, 'GET_GROUP_MEDIAS_LIST', onGetMedias, { headers: { "id": props.groupId } });
    } else {
      EdgeOrREST(null, 'GET_LUMINAIRE_MEDIAS_LIST', onGetMedias, { headers: { "id": props.luminaireId } });
    }
  }, [reload]);

  const onGetMedias = (response) => {
    setuploadMediaList(response.data);
  }

  const options = [
    { value: 0, label: 'images_line_drawing' },
    { value: 1, label: 'photometric_files' },
    { value: 2, label: 'bim_files' },
    { value: 3, label: 'image_variant_photo' },
    { value: 4, label: 'image_polar_curve' },
    { value: 5, label: 'two_d_cad_files' },
    { value: 6, label: 'pdf_files' },
    { value: 7, label: 'specification_sheet' },
    { value: 8, label: 'TM66' },
    { value: 9, label: 'EPD' },
    { value: 10, label: 'OTHER' }
  ];

  const handleFileReader = (event) => {
    if (event.target.files) {
      setFileState({ files: Array.from(event.target.files) });
      var files = [];
      var fileNames = [];
      var fileType = [];

      Array.from(event.target.files).forEach(element => {
        let reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          files = [...files, reader.result.split(',').pop()];
          fileNames = [...fileNames, element.name];
          fileType = [...fileType, 10];
          setMediaUpload({
            ...mediaUpload,
            data: files,
            fileNames: fileNames,
            uploadType: fileType
          });
        };
      });
    }
  }

  const handleChange = (event, index) => {
    var temp = uploadType;
    temp[index] = event.target.value;
    setMediaUpload({
      ...mediaUpload,
      uploadType: temp,
    });
  }

  const handleCheckedChange = (event, index) => {
    var temp = checkedList;
    temp[index] = event.target.checked;
    setCheckedList(temp);
  }

  const handleDelete = () => {
    const getSelectedMedia = () => {
      const selectedMedia = [];
      for (let i = 0; i < checkedList.length; i++) {
        if (checkedList[i]) {
          selectedMedia.push(uploadMediaList[i]);
        }
      }
      return selectedMedia;
    }

    EdgeOrREST(null, 'DELETE_MEDIAS', onDeleteMedia, {
      fileNames: getSelectedMedia(),
      groupId: props.groupId,
      luminaireId: props.luminaireId,
    });
  }

  const onDeleteMedia = (response) => {
    if (response.status == '200') {
      setReload(!reload);
      setSnack({
        open: true,
        message: "Media deleted successfully.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: "Media deletion failed.",
        severity: 'error'
      });
    }
  }

  const handleDownload = (item) => {
    if (props.luminaireId == 0) {
      EdgeOrREST(null, 'GET_GROUP_MEDIA_CONTENT', onDownloadMedia, { headers: { "id": props.groupId, "fileName": item } });
    } else {
      EdgeOrREST(null, 'GET_LUMINAIRE_MEDIA_CONTENT', onDownloadMedia, { headers: { "id": props.luminaireId, "fileName": item } });
    }
  }

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/octet-stream' });
  }

  const onDownloadMedia = (response) => {
    if (response && response.status == '200') {
      const blob = base64ToBlob(response.data);

      // Create download link using blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.config.headers.fileName);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setSnack({
        open: true,
        message: "Download success.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: "Downloading of tree failed",
        severity: 'error'
      });
    }
  }

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };


  return (
    <Card style={{ 'minHeight': 'inherit' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12} spacing={2}>
            <input
              onChange={handleFileReader}
              type="file"
              multiple
              disabled={isCopyGroup}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} spacing={2}>
            <TableContainer className={classes.container} component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Name</TableCell>
                    <TableCell align='right'>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    fileState ?
                      fileState.files.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">{row.name} </TableCell>
                          <TableCell align='right'>
                            <FormControlLabel
                              control={<Select
                                labelId="select-option-label"
                                value={uploadType[index]}
                                onChange={(event) => handleChange(event, index)}
                                style={{ minWidth: "150px" }}
                                defaultValue={10}
                              >
                                {options.map(option => (
                                  <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>}
                              className={classes.margin}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                      : ""
                  }
                </TableBody>
              </Table>
              {fileState.files.length > 0 ? "" : (<div style={{ textAlign: 'center', margin: 'auto', width: '50%', paddingTop: '2rem' }}>Upload Files</div>)}
            </TableContainer>
            <Typography>Uploaded Media List:
              <Switch onClick={() => setShowMediaList(!showMediaList)} color="primary">
                {showMediaList ? 'Hide List' : 'Show List'}
              </Switch>
            </Typography>
            {!showMediaList && uploadMediaList ? <></> : (
              <>
                <List>
                  {uploadMediaList?.map((item, index) => (
                    <ListItem key={item} dense button>
                      <Tooltip title="Check for delete">
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={uploadType[index]}
                            onChange={(event) => handleCheckedChange(event, index)}
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={item} />
                      <Tooltip title="Download">
                        <ListItemIcon>
                          <GetAppIcon onClick={() => handleDownload(item)} />
                        </ListItemIcon>
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
                <Button variant="contained" color="primary" onClick={handleDelete} disabled={isCopyGroup}>
                  Delete</Button>
              </>
            )}
          </Grid>

        </Grid>
      </CardContent>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Card>
  );

}
export default PhotometricMediaUpload; 