import { handleActions } from 'redux-actions';
import actions from '../actions/user';
import { initialState } from '../initial-state';
export default handleActions(
    {
        [actions.UpdateUserCfg]: (state, action) => {
            console.log("in user reducer state, action", state, action);
            return { ...state, ...action.payload };
        },
        [actions.SaveHistory]: (state, action) => {
            var historyItem = action.payload;
            var historyList = state.history;

            if (historyList.length <= 99) {
                historyList.push(historyItem);
            } else if (historyList.length > 99) {
                historyList.shift();
                historyList.push(historyItem);
            }
            return { ...state, history: historyList };
        },
    },
    initialState.user
);
