import React, { Component, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Stack from '@mui/material/Stack';
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Divider,
  CardContent,
  makeStyles,
  Paper,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import Notification from '../components/shared/notification.component';
import MonitorJobTableHolder from './monitor-job-table-holder.component';
import { useDispatch, useSelector} from 'react-redux';
import MonitorPagination from './monitor-jobs-pagination.component';
import monitorActions from '../store/actions/monitorActions';
import user from '../store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2, 2, 2, 0),
      },
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  margin: {
    margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  tab: {
    display: 'content'
  }
}));

const MonitorJobs = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.MonitorCfg); 
  const [jobFilter, setJobFilter] = React.useState("");
  const [monitorJobs, setMonitorJobs] = React.useState([]);

  const classes = useStyles();

  // useEffect( () => {
  //   console.log("monitorJobs change"); 
  //   var jobs = state.monitorJobs?.slice(0, 19);
  //   console.log(jobs);
  //   //jobs = jobs.slice(arrStart, arrEnd);
  //   setMonitorJobs(jobs);
  // }, [state.monitorJobs]);

  useEffect( () => {
    let arrStart = (state.monitorPages - 1) * 20;
    let arrEnd = (state.monitorPages) * 20 - 1;
    console.log("monitorPages change " + arrStart); 
    var jobs = state.monitorJobs?.slice(arrStart, arrEnd);
    console.log(jobs);
    //jobs = jobs.slice(arrStart, arrEnd);
    setMonitorJobs(jobs);
  }, [state.monitorJobs, state.monitorPages]);

  useEffect(() => {
    let isMounted = true;
    //getJobs()
    return () => { isMounted = false };
  }, []);

  function sortJobs(automateJobs) {
    automateJobs.sort(function (x, y) {
      var a = x.timestamp.split(' ');
      var b = y.timestamp.split(' ');

      var splitDateA = a[0].split('/');
      var splitTimeA = a[1].split(':');
      var dateA = new Date(splitDateA[2], splitDateA[1], splitDateA[0]);

      var splitDateB = b[0].split('/');
      var splitTimeB = b[1].split(':');
      var dateB = new Date(splitDateB[2], splitDateB[1], splitDateB[0]);

      if (dateA.getTime() > dateB.getTime()) {
        return -1;
      } else if (dateA.getTime() < dateB.getTime()) {
        return 1;
      } else {
        if (splitTimeA[0] > splitTimeB[0]) {
          return -1;
        } else if (splitTimeA[0] < splitTimeB[0]) {
          return 1;
        } else {
          if (splitTimeA[1] > splitTimeB[1]) {
            return -1;
          } else if (splitTimeA[1] < splitTimeB[1]) {
            return 1;
          } else {
            if (splitTimeA[2] > splitTimeB[2]) {
              return -1;
            } else if (splitTimeA[2] < splitTimeB[2]) {
              return 1;
            }
          }
        }
      }
    });
    return automateJobs;
  }

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  return (
    <Card style={{ 'minHeight': 'inherit'}}>
      <CardContent>          
        <MonitorJobTableHolder rows={monitorJobs? monitorJobs : []} size="small"></MonitorJobTableHolder>        
      </CardContent>
      <Stack spacing={1} alignItems="center">
        <MonitorPagination size='small'></MonitorPagination>
      </Stack> 
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Card>
  );
          
}
export default MonitorJobs; 