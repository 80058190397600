import { apiService } from './axios.client';

export const AutomateEndPoints = {
    getJobList: function (input, callback) {
        apiService.get('/job/getJobsList', input)
            .then(callback)
            .catch(callback);
    },
    getXlsx: function (input, callback) {
        apiService.get('/automate/getXlsx', input)
            .then(callback)
            .catch(callback);
    },
    uploadLrs: function (input, callback) {
        apiService.post('/automate/uploadLrs', input)
            .then(callback)
            .catch(callback);
    },
    getLRSFromResult: function (input, callback) {
        apiService.get('/automate/getLRSFromResult', input)
            .then(callback)
            .catch(callback);
    },
    getTemplate: function (input, callback) {
        apiService.get('/automate/getXlsxTemplate', { headers: { 'roadReq': input.roadReq } })
            .then(callback)
            .catch(callback);
    },
    deleteJob: function (input, callback) {
        apiService.delete('/automate/deleteJob', input)
            .then(callback)
            .catch(callback);
    },
    searchJobs: function(input, callback){
        console.log(input);
        apiService.get('/job/searchJobs', input)
        .then(callback)
        .catch(callback);
    },
    getCals: function(input, callback){
        console.log(input);
        apiService.post('/automate/getJobsCals', input)
        .then(callback)
        .catch(callback);
        //.catch(error => console.log(error.toJSON()));
        // .catch(error => {
        //     if (error.isAxiosError) callback(error.message);
        //     else callback(error.response.data.message);
        // });
    }
};