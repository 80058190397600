import React, { useState } from 'react';
import { connect, useStore, useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Snackbar
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import Notification from '../../components/shared/notification.component';
import tourActions from '../../store/actions/tourActions';
import { initialState } from '../../store/initial-state';

export const Comp_ExcelConvert = (props) => {
    const state = useStore().getState();
    const dispatch = useDispatch()
    const iState = useSelector(state => state)


    const handleClick = () => {
        if (iState.EnabledTour.helpMode){
            dispatch(tourActions.UpdateHelpURL({ helpURL: "Export Results"}))
            const video = document.getElementById("help-video")
            video.load();            
			dispatch(tourActions.UpdateHelpText({ helpText: "This button allows you to export your results as an excel file."}))
		} else {
        EdgeOrREST(null, "EXCEL_CONVERT", getExcelResults, API_OBJECTS.getCalculationObject(state, null, null));
        }
    }

    const getExcelResults = (response) => {
        if(response.status == "200") {
            console.log(response);
            var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"; 
            var a = document.createElement('a');
            a.href = mediaType+response.data;
            a.download = 'OptimizeExport.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else if (response.status == "400") {
            setSnack({
                open: true,
                message : response.data.message,
                severity : 'error'
            });
        }
        else {
            setSnack({
                open: true,
                message : "unexpected error",
                severity : 'error'
            });
        }
    }
 
    const [snack, setSnack] = useState({
      open: false
     });
  
    const handleClose = () => {
      setSnack({
        ...snack,
        open: false
      });
    };

    return (
        <React.Fragment>
        <Button
            color="primary"
            variant="contained"
            className="resultsSummaryButton"
            onClick={handleClick}
            fullWidth={true}
        >
            Export
        </Button>
        <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </React.Fragment>
    )
}

export default Comp_ExcelConvert