import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function CompanyAutocomplete(props) {
  const classes = useStyles();

  const onChange = (event, values) => {
     props.callback(event, values);
  }


  function getAutcomplete(){ 
    if(props.comList && props.comList.length > 0){ 
        return ( <Autocomplete 
          multiple
          limitTags={5}
          id="multiple-limit-tags"
          options={props.comList}
          onChange={onChange}
          getOptionLabel={(option) =>  option.name } 
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={<Typography>Select Companys</Typography>} placeholder="Companys..." />
          )}
        />);
    }
  }
 
  return (
    <div className={classes.root}>
      {getAutcomplete()}
    </div>
  );
}
 