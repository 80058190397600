import React, { useEffect } from 'react';
import EdgeOrREST from '../../EdgeOrREST';
import Page from '../Page';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    Tabs,
    Typography,
    Tab,
    makeStyles
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import SearchApp from './Comp_SearchConditions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));

export default function SearchDashboard(props) {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Search" style={{ 'minHeight': '85vh' }}>
            <Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
                {/* First row */}
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <SearchApp/>
                    </Card>
                </Grid>              
            </Grid>
        </Page >
    );
} 