import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Box,
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import monitorActions from '../store/actions/monitorActions';
import CompanyAutocomplete from '../monitor/monitor-autocomplete.component.js';
import { useInterval } from '../automate/automate-interval-runner.component';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(),
  },
  margin: {
    margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  container: {
    maxHeight: '45vh',
    minHeight: '45vh',
  }
}));

const MonitorSearch = (props) => {
  const classes = useStyles();
  const [jobName, setJobName] = React.useState("");
  const [jobStartDate, setJobStartDate] = React.useState(getCurrentDate());
  const [jobEndDate, setJobEndDate] = React.useState(getCurrentDate());
  const [users, setUsers] = React.useState([]);
  const [comList, setComList] = React.useState([]);
  const [selectedComList, setSelectedComList] = React.useState([]);
  const [filterUsers, setFilterUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  //To store the selectedUsers when selectedAll is click, and can restore the selectedUser when selectedAll is unclick
  const [tempSelectedUsers, setTempSelectedUsers] = React.useState([]);
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [isIncomplete, setIncomplete] = React.useState(false);
  const [typeOptions, setTypeOptions] = React.useState(["Road", "Area"]);
  const dispatch = useDispatch();
  const state = useSelector(state => state.MonitorCfg);
  // const [userComFilter, setUserComFilter] = React.useState("");
  const [snack, setSnack] = React.useState({
    open: false
  });

  useEffect(() => {
    EdgeOrREST(null, 'GET_USER_LIST', onGetUserList, null);
    searchJobs();
  }, []);

  useEffect(() => {
    console.log("isIncomplete", isIncomplete);
  }, [isIncomplete]);


  // useEffect(() => {
  //   if(userComFilter && userComFilter != "") setFilterUsers(users?.filter(item => item.name?.toLowerCase().includes(userComFilter.toLowerCase())));
  //   else setFilterUsers(users);
  // }, [userComFilter]);

  useEffect(() => {
    console.log("selectedComList " + JSON.stringify(selectedComList));
  }, [selectedComList]);


  useEffect(() => {
    console.log("SelectRow " + JSON.stringify(selectedUsers));
  }, [selectedUsers]);

  const onGetUserList = (response) => {
    console.log("Users :" + JSON.stringify(response.data));
    if (response && response.data.userList && response.data.userList.length > 0) {
      setUsers(response.data.userList);
      setFilterUsers(response.data.userList);
      //setUserComFilter("");
    }
    if (response && response.data.comList && response.data.comList.length > 0) {
      setComList(response.data.comList);
    }
  }

  useInterval(() => {
    searchJobs()
  }, 5000);

  function searchJobs() {
    //console.log("Search jobs");
    let tempUsers = JSON.stringify(selectedUsers);
    EdgeOrREST(null, 'SEARCH_MONITOR_JOBS', onGetJobs, {
      'jobName': jobName,
      'jobStartDate': formatStartDateString(),
      'jobEndDate': formatEndDateString(),
      'selectedUser': selectedUsers.length > 0 ? selectedUsers : null,
      'incompleteJobs': isIncomplete
    });
    setSnack({
      open: true,
      message: 'Jobs have been updated.',
      severity: 'success'
    });
  }

  function formatStartDateString() {
    if (!jobStartDate) return '';
    var dataTemp = jobStartDate.split('-');
    return dataTemp[2] + "/" + dataTemp[1] + "/" + dataTemp[0] + " 00:00:00";
  }

  function formatEndDateString() {
    if (!jobEndDate) return '';
    var dataTemp = jobEndDate.split('-');
    return dataTemp[2] + "/" + dataTemp[1] + "/" + dataTemp[0] + " 23:59:59";
  }

  //       response.data.automateJobs = sortJobs(response.data.automateJobs);
  //       console.log("dispatching jobs to redux "); 
  //       dispatch(user.UpdateUserCfg({ jobs: response.data.automateJobs , jobsPages: Math.ceil(response.data.automateJobsCount/50)}));
  //     } 
  //   };

  const onGetJobs = (response) => {
    console.log("onGetJobs, response.data.jobs", response.data);
    if (response && response.data?.jobs && response.data?.jobs?.length > 0) {
      console.log("jobs lenght > 0", response.data.jobs.length);
      for (var i = 0; i < response.data.jobs.length; i++) {
        response.data.jobs[i].status = response.data.jobs[i].status == 1 ? 'Complete' :
          response.data.jobs[i].status == 0 || response.data.jobs[i].status == 2 ? 'Queued' :
            response.data.jobs[i].status == 3 ? 'Calculating' : 'Error';
        console.log("currJob status,", response.data.jobs[i].status);
      }

      response.data.jobs = sortJobs(response.data.jobs);
      console.log("dispatching jobs to monitor redux ", response.data);
      dispatch(monitorActions.UpdateMonitorCfg({
        monitorJobs: response.data.jobs,
        //monitorPages: state.monitorPages,
        monitorJobsCount: response.data.jobsCount,
        monitorJobsCals: response.data.jobsCals,
        monitorAvgLum: response.data.avgLuminCount,
        monitorRoadTypeCount: response.data.roadTypeCount,
        monitorAreaTypeCount: response.data.areaTypeCount,
        monitorClassCount: response.data.classCount,
        monitorAvgCalTime: response.data.avgCalTime,
        monitorTotalValidResults: response.data.totalValidResults
      }));
    } else {
      console.log("dispatching no jobs to monitor redux ");
      dispatch(monitorActions.UpdateMonitorCfg({
        monitorJobs: [],
        monitorPages: 1,
        monitorJobsCount: 0,
        monitorJobsCals: 0,
        monitorAvgLum: 0,
        monitorRoadTypeCount: 0,
        monitorAreaTypeCount: 0,
        monitorClassCount: [],
        monitorAvgCalTime: 0,
        monitorTotalValidResults: 0
      }));
    }
    props.setMiddleTabIndex(0);
  };

  function sortJobs(jobs) {
    jobs.sort(function (x, y) {
      var a = x.timestamp.split(' ');
      var b = y.timestamp.split(' ');

      var splitDateA = a[0].split('/');
      var splitTimeA = a[1].split(':');
      var dateA = new Date(splitDateA[2], splitDateA[1], splitDateA[0]);

      var splitDateB = b[0].split('/');
      var splitTimeB = b[1].split(':');
      var dateB = new Date(splitDateB[2], splitDateB[1], splitDateB[0]);

      if (dateA.getTime() > dateB.getTime()) {
        return -1;
      } else if (dateA.getTime() < dateB.getTime()) {
        return 1;
      } else {
        if (splitTimeA[0] > splitTimeB[0]) {
          return -1;
        } else if (splitTimeA[0] < splitTimeB[0]) {
          return 1;
        } else {
          if (splitTimeA[1] > splitTimeB[1]) {
            return -1;
          } else if (splitTimeA[1] < splitTimeB[1]) {
            return 1;
          } else {
            if (splitTimeA[2] > splitTimeB[2]) {
              return -1;
            } else if (splitTimeA[2] < splitTimeB[2]) {
              return 1;
            }
          }
        }
      }
    });
    return jobs;
  }

  const onJobNameChange = (event) => {
    setJobName(event.target.value)
  };

  const onStartDateChange = (event) => {
    setJobStartDate(event.target.value)
    if (jobStartDate > jobEndDate) setJobEndDate(jobStartDate);
  }

  const onEndDateChange = (event) => {
    setJobEndDate(event.target.value)
    if (jobEndDate < jobStartDate) setJobStartDate(jobEndDate);
  }

  function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    //today = dd + "/" + mm + "/" + yyyy;
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  const handleSelectRow = (row, event) => {
    if (selectedUsers != null && selectedUsers.length > 0) {
      let userList = selectedUsers;
      let isUnChecked = false;
      for (let i = 0; i < userList.length; i++) {
        if (userList[i].id == row.id) isUnChecked = true;
      }
      if (isUnChecked) {
        userList = userList.filter(user => user.id != row.id);
        setSelectedUsers(userList);
        console.log("unChecked");
      } else {
        console.log("push");
        selectedUsers.push({ "id": row.id, "email": row.email });
        setSelectedUsers([...selectedUsers]);
      }
    } else {
      let userList = [{ "id": row.id, "email": row.email }];
      setSelectedUsers(userList);
    }
  };

  // const onUserFilterChange = (e) => {
  //   setUserComFilter(e.target.value);
  // };

  const handleIncomplete = (event) => {
    if (event.target.checked) {
      setIncomplete(true);
    } else {
      setIncomplete(false);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setIsSelectAll(true);
      setTempSelectedUsers(selectedUsers)
      setSelectedUsers(filterUsers);
    } else {
      setIsSelectAll(false);
      if (tempSelectedUsers.length > 0) setSelectedUsers(tempSelectedUsers)
      else setSelectedUsers([]);;
    }
  };

  const onAutoComplete = (event, value) => {
    console.log("autocomplete");
    console.log(event, value);
    setSelectedComList(value);
    setIsSelectAll(false);
    setSelectedUsers([])
    if (value) {
      var comsUsers = [];
      if (value.length > 0) {
        value.forEach(element => {
          users.forEach(user => {
            if (user.name == element.name) comsUsers.push(user);
          })
        });
        setFilterUsers(comsUsers);
      } else {
        setFilterUsers(users);
      }
    }
  };


  return (
    <Card style={{ 'minHeight': 'inherit' }}>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item md={3} sm={4} xs={4} direction="column" justifyContent="flex-end">
            <TextField label="Job Name" id="jobName" defaultValue="" onChange={onJobNameChange} />
          </Grid>
          <Grid item md={3} sm={4} xs={4} direction="column" justifyContent="flex-end">
            <TextField
              value={jobStartDate}
              type="date"
              label="Start Date"
              id="tfjobStartDate"
              inputProps={{ max: jobEndDate }}
              onChange={onStartDateChange}
              InputLabelProps={{ shrink: true, }}
              onKeyDown={(e) => {
                e.preventDefault();
              }} />
          </Grid>
          <Grid item md={3} sm={4} xs={4} direction="column" justifyContent="flex-end">
            <TextField
              value={jobEndDate}
              type="date"
              label="End Date"
              id="tfjobEndDate"
              inputProps={{ min: jobStartDate }}
              onChange={onEndDateChange}
              InputLabelProps={{ shrink: true, }} />
          </Grid>
          <Grid item md={3} sm={4} xs={4} direction="column"  >
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <FormControlLabel control={<Checkbox />} checked={isIncomplete} onChange={handleIncomplete} label="Incomplete Jobs Only" />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div style={{ height: '10px' }} />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1} >
          <Grid item md={3} sm={4} xs={4} direction="column" justifyContent="flex-end" >
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jobs Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //value={typeOptions}
                  label="type"
                  onChange={handleDropBoxChange}
                >
                  <MenuItem value={'Road'}>Road</MenuItem>
                  <MenuItem value={'Area'}>Area</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={4} direction="column" justifyContent="flex-end" />
          <Grid item md={6} sm={4} xs={4} direction="column"  >
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <FormControlLabel control={<Checkbox />} checked={isIncomplete} onChange={handleIncomplete} label="Incomplete Jobs Only" />
            </Box>
          </Grid>
        </Grid> */}
      </CardContent>

      <CardContent>
        <Grid item md={12} sm={12} xs={12}>
          <CompanyAutocomplete callback={onAutoComplete} comList={comList}></CompanyAutocomplete>
        </Grid>
      </CardContent>

      <CardContent>
        {/* <TextField onChange={onUserFilterChange} value={userComFilter} label="Company Filter" id="outlined-size-small-lum" defaultValue="..."/> */}
        <Paper sx={{ height: "50%", display: "flex", alignItems: "center" }}>
          {/* <TableContainer className={classes.container} component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">  */}
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader aria-label="simple table" size='small' className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Select User</TableCell>
                  <TableCell align="left">Company</TableCell>
                  {/* <TableCell padding="checkbox" align="right">
                        <Checkbox
                          checked={filterUsers.length == selectedUsers.length && filterUsers.length > 0}
                          color="primary"
                          onChange={handleSelectAll}
                        />
                      </TableCell> */}

                  <TableCell align="right">
                    <FormControlLabel
                      control={<Checkbox checked={filterUsers.length == selectedUsers.length && filterUsers.length > 0} onChange={handleSelectAll} color="primary" />}
                    />
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {
                  //users?.filter(item => item.name?.toLowerCase().includes(userComFilter.toLowerCase()))
                  filterUsers.map((row) => (
                    <TableRow key={row.email} size="small">
                      <TableCell component="th">{row.email} </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">
                        <FormControlLabel
                          control={<Checkbox checked={selectedUsers.some(user => user.id === row.id)} onChange={e => handleSelectRow(row, e)} color="primary" disabled={isSelectAll} indeterminate={isSelectAll} />}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>



        </Paper>
        <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: 10 }} >
          <Button variant="contained" color="primary" size="small" onClick={searchJobs}>Search</Button>
        </Box>
      </CardContent>

    </Card>
  );
}
export default MonitorSearch; 