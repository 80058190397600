import React from 'react';
import {
    CardContent,
    ClickAwayListener,
    Button,
    Grow,
    Popper,
    Paper,
    MenuList,
} from '@material-ui/core';

const PhotometricAttributeMenu = (props) => {
    const [open, setOpen] = React.useState(false);
    const [anchorRef, setAnchorRef] = React.useState(null);
    const prevOpen = React.useRef(open);

    const handleToggle = () => {
        setAnchorRef(props.buttonRef.current);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCloseList = () => {
        setAnchorRef(null);
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
    }

    React.useEffect(() => {
        prevOpen.current = open;
    }, [open]);

    return (
        <CardContent >
            <Button
                aria-controls={open ? 'menu-list-grow' : undefined}
                color="primary"
                variant={open ? 'contained' : 'outlined'}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ width: '120px', justifyContent: 'center' }}
            >
                {props.category}
            </Button>

            <Popper open={Boolean(anchorRef)} anchorEl={anchorRef} role={undefined} transition disablePortal placement={'right-start'} style={{ zIndex: 100, width: '300px' }}>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'left' }}
                    >
                        <Paper style={{ width: '300px' }} >
                            <ClickAwayListener onClickAway={handleCloseList} mouseEvent="onMouseDown">
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}
                                    style={{
                                        maxHeight: '600px',
                                        overflow: 'auto',
                                        paddingLeft: '15px',
                                        justifyContent: 'center',
                                    }}>
                                    {props.menuList}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper >
                    </Grow>
                )}
            </Popper>
        </CardContent>
    );

}
export default PhotometricAttributeMenu; 