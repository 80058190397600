import React, { useEffect } from 'react';
import MonitorJobs from './monitor-jobs.component';
import MonitorSearch from './monitor-search.component';
import MonitorAnalysis from './monitor-analysis.component';
import MonitorJobAnalysis from './monitor-job-analysis.component';
import Page from '../components/Page';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    Tabs,
    Typography,
    Tab,
    makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EdgeOrREST from '../EdgeOrREST';
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MonitorDashboard(props) {
    const [leftTabIndex, setLeftTabs] = React.useState(0);
    const [middleTabIndex, setMiddleTabIndex] = React.useState(0);
    const [rightTabIndex, setRightTabIndex] = React.useState(0);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector(state => state);

    useEffect(() => {
        console.log("/////////////////////////////////////////////////////////////////////", state?.user?.products);
        if(state?.user?.products?.some((product) => product.name === 'Monitor')) console.log("User Monitor = True");
        else  console.log("User Monitor = False");
        if ( !state?.user?.products?.some((product) => product.name === "Monitor")) {navigate("/launcher");}
    }, []);

    const handleLeftTabs = (event, value) => {
        setLeftTabs(value);
    };
    const handleMiddleTabs = (event, value) => {
        setMiddleTabIndex(value);
    };
    return (
        <Page className={classes.root} title="Monitor" style={{ 'minHeight': '85vh' }}>
            <Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
                {/* First row */}
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            {/* <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="disabled tabs example"
                            value={0}
                            >
                            <Tab className={classes.tab} label={
                                <span className={classes.tab}>
                                Search
                                 </span>} value={0} />
                            </Tabs> */}
                            <Tabs
                                value={leftTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                onChange={handleLeftTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="Search" {...a11yProps(0)} value={0} />
                            </Tabs>
                        </Paper>

                        <Divider />
                        <TabPanel value={leftTabIndex} index={0}>
                            <CardContent>
                                <MonitorSearch setMiddleTabIndex={setMiddleTabIndex} />
                            </CardContent>
                        </TabPanel>

                    </Card>
                </Grid>
                {/* <Grid item lg={7} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                     <MonitorJobs></MonitorJobs>
                </Grid> */}
                <Grid item lg={6} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        {/* <TabPanel value={leftTabIndex} index={0}> 
                        <CardContent>
                            <MonitorJobs/>
                        </CardContent>
                    </TabPanel> */}
                        <Paper square>
                            <Tabs
                                value={middleTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                onChange={handleMiddleTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="Jobs" {...a11yProps(0)} value={0} />
                                <Tab index={1} label="Search Analysis" {...a11yProps(1)} value={1} />
                                {/* <Tab index={2} label="Luminaires" {...a11yProps(2)} value={2} /> */}
                            </Tabs>
                        </Paper>
                        <Divider />
                        <TabPanel value={middleTabIndex} index={0}>
                            <CardContent>
                                <MonitorJobs/>
                            </CardContent>
                        </TabPanel>
                        <TabPanel value={middleTabIndex} index={1}>
                            <CardContent>
                                <MonitorAnalysis />
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
                <Grid item lg={2} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={rightTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                //onChange={handleRightTabs}
                                aria-label="disabled tabs example"
                            >
                                <Tab index={0} label="Jobs Analysis" {...a11yProps(0)} value={0} />
                            </Tabs>
                        </Paper>
                        <Divider />
                        <TabPanel value={rightTabIndex} index={0}>
                            <CardContent>
                                <MonitorJobAnalysis/>
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </Page >
    );




} 