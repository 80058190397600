import { apiService } from './axios.client';

export const AuthenticationEndPoints = {
    login: function (input, callback) {
        apiService.post('/authentication/login', { rememberMe: input.rememberMe }, { headers: { 'Authorization': input.auth } })
            .then((response) => callback(response, input.props, input.rememberMe))
            .catch(error => callback(error));
    },
    checkToken: function (input, callback) {
        apiService.get('/authentication/checkToken')
            .then((response) => callback(response))
            .catch((error) => alert(error))
    },
    keepAlive: function (input, callback) {
        apiService.get('/authentication/keepAlive')
            .then((response) => callback(response))
            .catch(callback)
    },
    signOut: function (input, callback) {
        apiService.get('/authentication/signOut')
            .then((response) => callback(response, input.props))
            .catch((error) => callback(error))
    },
    loginAsUser: function (input, callback) {
        apiService.get('/authentication/AdminLoginAsUser', input)
            .then((response) => callback(response, input))
            .catch((error) => alert(error))
    },
    verifyOTP: function (input, callback) {
        apiService.post('/authentication/verifyOTP', input)
            .then((response) => callback(response))
            .catch((error) => alert(error))
    },
    enable2FA: function (input, callback) {
        apiService.post('/authentication/enable2FA', input)
            .then((response) => callback(response))
            .catch((error) => alert(error))
    },
    validateBypassToken: async function (input) {
        try {
            const response = await apiService.get('/authentication/validateBypassToken');
            return response.data;
        } catch (error) {
            alert(error);
            throw error;
        }
    },
    enableEmail2FA: function (input, callback) {
        apiService.post('/authentication/enableEmail2FA', input)
            .then((response) => callback(response))
            .catch((error) => alert(error))
    },
    verifyEmailOTP: function (input, callback) {
        apiService.post('/authentication/verifyEmailOTP', input)
            .then((response) => callback(response))
            .catch((error) => alert(error))
    },
};