import React from 'react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
export default function ColumnEditor(props) {

    return (
        <List>
            {
                props.columns.map(
                    (column, index) => {
                        return (
                            <ListItem key={index} onClick={() => props.selectColumn(index)} style={column.selected ? { border: "2px solid darkturquoise " } : {}}>
                                <ListItemText primary={"Column " + (1 + index)} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => props.deleteColumn(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                )
            }
        </List>
    );

} 