import React from 'react';
import { Stage, Layer, Line, Shape } from "react-konva";
import {
  IconButton,
  Grid,
  Tooltip,
  Typography,
  Button,
} from '@material-ui/core';
import Mask from './mask.component';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RectangleIcon from '@mui/icons-material/Rectangle';
import { useSelector } from 'react-redux';
import Konva from 'konva';
import CustomMask from './custom-mask.component';
export default function Sandbox() {
  const state = useSelector(state => state);
  const stageRef = React.useRef(); const planRef = React.useRef();
  const [lines, setLines] = React.useState([]);
  const [maskMode, setMaskMode] = React.useState(false);
  const [masks, setMasks] = React.useState([]);
  const [stage, setStage] = React.useState({ scale: 1, x: 0, y: 0, });
  const [poly, setCurrentPoly] = React.useState(null);
  const handleWheel = (e) => {
    e.evt.preventDefault(); const scaleBy = 1.05;
    const stage = e.target.getStage(); const oldScale = stage.scaleX();
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStage({ scale: newScale, x: stage.width() / 2 - (stage.width() / 2) * newScale, y: stage.height() / 2 - (stage.height() / 2) * newScale });
  };

  const updateMask = (e, i, type) => {
    var tempMasks = masks; var points = []; if (type == "rectangle") {
      var x = e.target.attrs.x;
      var y = e.target.attrs.y; var width = e.target.attrs.width; var height = e.target.attrs.height;
      points.push({ x: x, y: y }); points.push({ x: x + width, y: y }); points.push({ x: x + width, y: y + height });
      points.push({ x: x, y: y + height }); points.push({ x: x, y: y });
    } else if (type == "circle") {
      points.push({ x: e.target.attrs.x, y: e.target.attrs.y });
      tempMasks[i].radius = e.target.attrs.radius;
    }
    tempMasks[i].points = points; setMasks(tempMasks);
  }

  const handleAddMask = (event, maskType) => {
    event.preventDefault();
    var newMask = {};
    newMask.type = maskType;
    newMask.points = [];
    if (masks.length > 0) {
      let arr = [...masks];
      arr.push(newMask);
      setMasks(arr);
    } else {
      var arr = [];
      arr.push(newMask);
      setMasks(arr);
    }
  }

  const [isDrawing, setIsDrawing] = React.useState(false);

  const handleDrawLineMouseDown = (e) => {
    if (maskMode) {
      const pointX = (stageRef.current._changedPointerPositions[0].x - stageRef.current.attrs.x) / stageRef.current.attrs.scaleX;
      const pointY = (stageRef.current._changedPointerPositions[0].y - stageRef.current.attrs.y) / stageRef.current.attrs.scaleY;
      if (isDrawing) {
        let lastLine = lines[lines.length - 1];
        // Check if current mouse position is close to starting point
        const [startX, startY] = lastLine.points;
        if (Math.abs(pointX - startX) < 10 && Math.abs(pointY - startY) < 10) {
          // Close shape by adding starting point to end of points array
          lastLine.points = lastLine.points.concat([startX, startY]);
          lines.splice(lines.length - 1, 1, lastLine);
          setLines(lines.concat());
          setIsDrawing(false);
        } else {
          lastLine.points = lastLine.points.concat([pointX, pointY]);
          lines.splice(lines.length - 1, 1, lastLine);
          setLines(lines.concat());

        }
      } else {
        setLines([...lines, { points: [pointX, pointY] }]);
        setIsDrawing(true);
      }
    }
  };


  return (
    <>
      <Grid container direction='row'>
        <Grid item xs={12} style={{ overflowX: "scroll" }}>
          <IconButton onClick={(event) => handleAddMask(event, "circle")}>
            <Tooltip title="Normal">
              <PanoramaFishEyeIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ overflowX: "scroll" }}>
          <IconButton onClick={(event) => handleAddMask(event, "rectangle")}>
            <Tooltip title="Normal">
              <RectangleIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Button variant='outlined' onClick={(e) => setMaskMode(!maskMode)}>
            <Typography>
              Mask Mode: {maskMode ? 'On' : 'Off'}
            </Typography>
          </Button>
        </Grid>

        <Grid> <Button variant='outlined' onClick={(e) => {
          console.log("current lines", lines);
          var poly = new Konva.Line({
            points: lines[0].points,
            fill: '#00D2FF',
            stroke: 'black',
            strokeWidth: 5,
            closed: true,
          });
          setCurrentPoly(poly);
          setLines([]);
        }} > Qwerty Keyboard</Button></Grid>
      </Grid>

      <div id="planWrapper" ref={planRef}>
        <Stage ref={stageRef} width={window.innerWidth / 1.5} height={window.innerHeight / 1.5}
          scaleX={stage.scale} scaleY={stage.scale} x={stage.x} y={stage.y}
          onWheel={handleWheel} onMouseDown={handleDrawLineMouseDown} draggable={true} style={{ border: '1px solid red' }}>
          {masks.map((mask, i) => {
            return <Mask i={i} updateMask={updateMask} maskType={mask.type} state={state} />
          })}

          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke="#df4b26"
                strokeWidth={5}
                tension={0}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  'source-over'
                }
              />

            ))}
          </Layer>

          {poly && <CustomMask poly={poly} />}

        </Stage>
      </div>
    </>
  );
}
