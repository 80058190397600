import { createAction } from 'redux-actions';

export default {
    UpdateTourAccess: createAction('UPDATE_TOUR_ACCESS'),
    UpdateCurrentStep: createAction('UPDATE_CURRENT_STEP'),
    UpdateCurrentTab: createAction('UPDATE_CURRENT_TAB'),

    UpdateHelpMode: createAction('UPDATE_HELP_MODE'),
    UpdateHelpText: createAction('UPDATE_HELP_TEXT'),
    UpdateHelpURL: createAction('UPDATE_HELP_URL'),
};

