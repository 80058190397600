import { handleActions } from 'redux-actions';
import actions from '../actions/roadActions';
import { initialState } from '../initial-state';

export default handleActions(
    {
        [actions.AddToCurrentState]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateStandardsCfg]: (state, action) => {
            return { ...state, StandardsCfg: { ...action.payload } };
        },
        [actions.UpdateRoadCfg]: (state, action) => {
            return { ...state, RoadCfg: { ...state.RoadCfg, ...action.payload } };
        },
    },
     initialState.ProjectConfig
	
);
