import React, { useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import PhotometricAutocomplete from '../photometricManager/photometric-autocomplete.component';
import {
	Card,
	Divider,
	CardContent,
	makeStyles,
	Grid,
	Button,
	Typography,
	Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Notification from '../components/shared/notification.component';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import tourActions from '../store/actions/tourActions';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	formControl: {
		margin: theme.spacing(3),
	},
	margin: {
		margin: '0px',
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
	},
	container: {
		maxHeight: '40vh',
		minHeight: '40vh',
	}
}));



const AutomateUpload = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const state = useSelector(state => state);
	const [attributes, setAttributes] = React.useState({
		groupIds: [],
		error: ""
	});

	const [groups, setGroups] = React.useState([]);

	const [snack, setSnack] = React.useState({
		open: false
	});

	const handleClose = () => {
		setSnack({
			...snack,
			open: false
		});
	};

	const onGetGroups = (response) => {
		if (response && response.data) {
			setGroups(response.data);
			console.log("group props updated");
			dispatch(user.UpdateUserCfg({
				groups: response.data,
				refreshJobs: true
			}));
		}
	};

	useEffect(() => {
		let isMounted = true;
		EdgeOrREST(null, 'GET_ALL_LOWEST_CHILDREN_FOLDER', onGetGroups, props);
		//EdgeOrREST(null, 'GET_USERS_GROUPS_AND_LUMINAIRES', onGetGroups, props);
		return () => { isMounted = false };
	}, []);


	const onAutoComplete = (event, value) => {
		console.log("autocomplete");
		console.log(event, value);
		if (value) {
			var ids = [];
			if (value.length > 0) {
				value.forEach(element => {
					ids.push(element.id);
				});
			}

			console.log("ids", ids);
			setAttributes(prevState => ({
				...prevState,
				"groupIds": ids
			}));
		}
	};

	const onUploadConfiguration = (response) => {
		console.log("uploadConf", response);
		setAttributes(prevState => ({
			...prevState,
			error: response.message
		}));

		if (response && response.status == 200) {
			console.log("GetJobs in Upload");
			dispatch(user.UpdateUserCfg({ getJobFlag: !state.user.getJobFlag }));
		} else {
			setSnack({
				open: true,
				message: response.data,
				severity: 'error'
			});
		}
		setUploadingLrs(false);
	}

	const [uploadFile, setUploadFile] = React.useState("")
	const [uploadConfig, setUploadConfig] = React.useState("")
	const [uploadingLrs, setUploadingLrs] = React.useState(false)
	const [contentCalcNumber, setContentCalcNumber] = React.useState('');
	const [contentCalcTime, setContentCalcTime] = React.useState('');
	const [contentCalcMax, setContentCalcMax] = React.useState('');
	const [isCalsMaxReached, setIsCalsMaxReached] = React.useState(false);
	const [isRequestMax, setIsRequestMax] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const handleDiaOpen = () => {
		if (state.EnabledTour.helpMode){
			dispatch(tourActions.UpdateHelpURL({ helpURL: "Automate"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "See video above on how to use automate."}));
		} else {
		if (uploadConfig.data && uploadConfig.fileName && attributes.groupIds.length != 0) {
			setIsRequestMax(false);
			EdgeOrREST(null, 'GET_CALS', onGetCals, { 'excelAsBase64': uploadConfig.data, 'name': uploadConfig.fileName, 'groupIds': attributes.groupIds });
		} else {
			setSnack({
				open: true,
				message: "No group selected.",
				severity: 'error'
			});
		}
		setTimeout(() => {dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep+1}))},200);
	}};

	const onGetCals = (response) => {
		console.log("///////////////////////////////////////////////////////////////////////")
		console.log(response)
		if (response && response.status == '200') {
			setContentCalcNumber("Multiple Calculations will be Made : " + response.data.jobsCals + " Calculations");
			setContentCalcTime("Estimated Finish Time: " + response.data.finishTime);
			if (response.data.jobsCals >= 350000) {
				setIsCalsMaxReached(true);
				setContentCalcMax("Your current Calc: " + response.data.jobsCals + " .");
			}
			else setIsCalsMaxReached(false);
		} else if (response.message.includes("413")||response.message.includes("Network Error")) {
			setIsRequestMax(true);
		}
		setOpen(true);
	};

	const handleDiaClose = () => {
		setOpen(false);
	};

	const handleConfigurationFileReader = (event) => {
		if (event.target) {
			let reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e) => {
				setUploadConfig({ data: reader.result.split(',').pop(), fileName: event.target.files[0].name })
			};
		}
	}

	const uploadConfiguration = () => {
		setOpen(false);
		if (uploadConfig.data && uploadConfig.fileName && attributes.groupIds.length != 0) {
			EdgeOrREST(null, 'UPLOAD_CONFIGURATION', onUploadConfiguration, { 'excelAsBase64': uploadConfig.data, 'name': uploadConfig.fileName, 'groupIds': attributes.groupIds });
		} else {
			setSnack({
				open: true,
				message: "No group selected.",
				severity: 'error'
			});
		}
	};

	const handleFileReader = (event) => {
		if (event.target) {
			let reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e) => {
				setUploadFile({ data: reader.result.split(',').pop(), fileName: event.target.files[0].name })
			};
		}
	}


	const uploadLrsa = () => {
		setUploadingLrs(true);
		EdgeOrREST(null, 'UPLOAD_LRS', onUploadLrs, uploadFile);
	};

	const onUploadLrs = (response) => {
		if (response && response.status == '200') {
			onCreateGeneratedAreaTemplate(response.data);
		}
		setUploadingLrs(false);
	};


	function onCreateTemplate(roadReq) {
		if (state.EnabledTour.helpMode){
			dispatch(tourActions.UpdateHelpURL({ helpURL: "Automate"}))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "See video above on how to use automate."}));
		} else {
		EdgeOrREST(null, 'GET_TEMPLATE', onUploadLrs, { roadReq: roadReq });
		}
	}

	function onCreateGeneratedAreaTemplate(excel) {
		console.log("excel", excel);
		var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
		var a = document.createElement('a');
		a.href = mediaType + excel.data;
		a.download = excel.fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return (
		<Card className={classes.root}>
			<Divider />
			<CardContent>
				<Grid className='automateStep3 automateStep4' container spacing={2} style={{ border: 'solid 0.5px lightgrey', borderRadius: '8px' }}>
					<Grid item md={12} sm={12} xs={12}>
						<Typography color="textSecondary" gutterBottom>
							Upload an excel configuration and select your photometric group(s). Please note configuration
							results will be deleted after 3 months.
						</Typography>
					</Grid>
					<Grid item md={3} sm={3} xs={3}>
						<Typography gutterBottom>
							Select Configuration:
						</Typography>
					</Grid>
					<Grid item md={6} sm={6} xs={6}>
						<input
							onChange={handleConfigurationFileReader}
							type="file"
							accept=".xlsx"
						/>
					</Grid>

					<Grid item md={9} sm={9} xs={9}>
						<PhotometricAutocomplete callback={onAutoComplete} groups={groups}></PhotometricAutocomplete>
					</Grid>

					<Grid item md={9} sm={9} xs={9}>

					</Grid>

					<Grid item md={3} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'right' }}>
						{uploadingLrs ? <CircularProgress /> :
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={(event) => {
									handleDiaOpen();
									//uploadConfiguration();
								}}
							>
								Submit
							</Button>
						}
					</Grid>
				</Grid>

				<Grid container item spacing={2} style={{ marginTop: '20px', border: 'solid 0.5px lightgrey', borderRadius: '8px' }}>
					<Grid item md={12} sm={12} xs={12}>
						<Typography color="textSecondary" gutterBottom>
							Select a .LRSA/LRSR project folder to convert into an excel configuration.
						</Typography>
					</Grid>
					<Grid item md={3} sm={3} xs={3}>
						<Typography gutterBottom>
							Select .LRSA/LRSR:
						</Typography>
					</Grid>
					<Grid item md={6} sm={6} xs={6}>
						<input
							onChange={handleFileReader}
							type="file"
							accept=".lrsa, .lrsr"
						/>
					</Grid>
					<Grid item md={9} sm={9} xs={9}>

					</Grid>
					<Grid item md={3} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'right' }}>
						{uploadingLrs ? <CircularProgress /> :
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={(event) => {
									uploadLrsa();
								}}
							>
								Submit
							</Button>
						}
					</Grid>
				</Grid>


				<Grid className='automateStep2' container item spacing={2} style={{ marginTop: '20px', border: 'solid 0.5px lightgrey', borderRadius: '8px' }}>
					<Grid item md={12} sm={12} xs={12}>
						<Typography color="textSecondary" gutterBottom>
							Template excel sheets for Road and Area configurations.
						</Typography>
					</Grid>
					<Grid item md={8} sm={4} xs={4}></Grid>
					<Grid item md={2} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onMouseUp={() => {dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep+1}))}}
							onClick={(event) => {
								onCreateTemplate("True");
							}}
						>
							Road
						</Button>
					</Grid>
					<Grid item md={2} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onMouseUp={() => {dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep+1}))}}
							onClick={(event) => {
								onCreateTemplate("False");
							}}
						>
							Area
						</Button>
					</Grid>
				</Grid>

				<Snackbar
					open={snack.open}
					onClose={handleClose}
					key="test"
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					autoHideDuration="7000"
				>
					<Alert onClose={handleClose} severity="success">
						{snack.message}
					</Alert>
				</Snackbar>

				<Grid item md={12} sm={12} xs={12}>
					<Typography color="error" gutterBottom>
						{/* Example Error Text display:none to remove {attributes.error} */}
					</Typography>
				</Grid>
				<Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
			</CardContent>
			<Dialog
				className='automateStep5'
				open={open}
				onClose={handleDiaClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{/* <DialogTitle id="alert-dialog-title">
				{"Reminder"}
				</DialogTitle> */}
				<DialogContent>
					{isCalsMaxReached ? (
						<>
							<DialogContentText id="alert-dialog-description">
								Error: Total number of calculations must be less than 350,000. Please reduce the number of calculations in your configuration and try again.
							</DialogContentText>
							<DialogContentText id="alert-dialog-description">
								{contentCalcMax}
							</DialogContentText>
						</>
					) : isRequestMax ? (<><DialogContentText id="alert-dialog-description">
						Error: File size too large. This is likely due to your uploaded file containing a large number of results. Please remove the results sheet and try again.
					</DialogContentText></>
					) : (<><DialogContentText id="alert-dialog-description">
						{contentCalcNumber}
					</DialogContentText>
						<DialogContentText id="alert-dialog-description">
							{contentCalcTime}
						</DialogContentText></>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDiaClose}>Cancel</Button>
					<Button onClick={uploadConfiguration} disabled={isCalsMaxReached || isRequestMax} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Card>
	);
}
export default AutomateUpload;



