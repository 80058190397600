import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Box} from '@material-ui/core';
import Notification from '../shared/notification.component';
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,  
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import ListIcon from '@mui/icons-material/List';
import searchActions from '../../store/actions/searchActions';

const columns = [
    {
        field: 'id',
        headerName: 'ID', 
        type: 'number',
        headerAlign: 'left',
        width: 80,
        sortable: false,
    },
    {
        field: 'fileType',
        headerName: 'File Type',
        width: 80,
    },
    {
        field: 'company',
        headerName: 'Company',
        width: 80,
    },
    {
        field: 'type',
        headerName: 'Photometric Type Indicator',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'units',
        headerName: 'Unit Type Indicator',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'cPlanes',
        headerName: 'Number of C Planes',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'yPlanes',
        headerName: 'Number of Y Planes',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'totalLuminaireLumens',
        headerName: 'Total Luminaire Lumens',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'reportNum',
        headerName: 'Report Number',
        width: 80,
    },
    {
        field: 'fileName',
        headerName: 'File Name',
        width: 80,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 80,
    },
    {
        field: 'length',
        headerName: 'Length',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'width',
        headerName: 'Width',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'height',
        headerName: 'Height',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'conversionFactor',
        headerName: 'Conversion Factor',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'tilt',
        headerName: 'Tilt',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'numberLamps',
        headerName: 'Number of Lamps',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'typeLamps',
        headerName: 'Type of Lamps',
        width: 80,
    },
    {
        field: 'totalFlux',
        headerName: 'Total Flux',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'colorAppearance',
        headerName: 'Color Appearance',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'wattage',
        headerName: 'Wattage',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'spr',
        headerName: 'SPR',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'ballastFactor',
        headerName: 'Ballast Factor',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'futureUsage',
        headerName: 'Future Usage Factor',
        type: 'number',
        headerAlign: 'left',
        width: 80,
    },
    {
        field: 'other',
        headerName: 'Other',
        width: 160,
        sortable: false,
    },
];

const RefineSearch = () => {
    const state = useSelector(state => state.Search);
    const dispatch = useDispatch();
    const [selectedLuminaires, setSelectedLuminaires] = React.useState([]);
    const [newRows, setNewRows] = React.useState([]);
    const [snack, setSnack] = React.useState({ open: false });
  
    const handleClose = () => {
        setSnack({ ...snack, open: false });
    };

    const handleRemove = () => {
        if (selectedLuminaires.length >= 1){
            const updatedRows = newRows.filter((row) => {
                if (selectedLuminaires.includes(row.id)) {
                    return false;
                }
                return true;
            });
            setNewRows(updatedRows);
            setSnack({ open: true, severity: 'success', message: 'Selected rows have been removed.' });
            console.log(updatedRows)
            dispatch(searchActions.UpdateCondition({luminaireSelection:updatedRows}))
            dispatch(searchActions.UpdateCondition({deletedRows:true}))
        } else {
            setSnack({ open: true, severity: 'error', message: 'Please select at least one row to remove.' });
        }
    }

    const detectFileType = (data) => {
        const lines = data.split('\n');
        const fileType = lines.length >= 2 && lines[2].includes('IES') ? "IES" : "LDT"
        if (fileType === "LDT") {
            return "LDT";
        } else {
            return lines[2].includes("2002") ? "IES2002" : "IES1995";
        }
    };

    const preprocessData = (data, fileType) => {
        const lines = data.split('\n');
        const processedData = [];
        let count = 0;

        for (let line of lines) {
            line = line.trim();
            if (line.startsWith("[LUM")) {
                continue;
            } else {
                let content;
                if (fileType === 'LDT') {
                    content = line.replace(/^\[.*?\]\s+/g, '');
                } else {
                    content = line.replace(/\s+/g, ' ');
                }
                processedData.push(content);
                count++;
                if (fileType === 'LDT' && count >= 50) {
                    break;
                } else if (fileType.includes("IES") && count >= 50) {
                    break;
                }
            }
        }
        return processedData;
    };

    useEffect(() => {
        const updateFileArray = () => {
            if (state.deletedRows === false) {
                const updatedRows = [];

                state.luminaireSelection.forEach((_, i) => {
                    if (state.filesOfSelected[i] && state.filesOfSelected[i] !== " ") {
                        const file = state.filesOfSelected[i];
                        const fileType = detectFileType(file);
                        const splitFile = preprocessData(file, fileType);
                        const item = splitFile;

                        const manufacturerIndex = item.findIndex((line) => line.includes("[MANUFAC]"));
                        const manufacturer = manufacturerIndex !== -1 ? item[manufacturerIndex].replace("[MANUFAC]", "") : "N/A";

                        const dateIndex = item.findIndex((line) => line.includes("[DATE]"));
                        const issueDateIndex = item.findIndex((line) => line.includes("[ISSUEDATE]"));
                        const date = dateIndex !== -1 ? item[dateIndex].replace("[DATE]", "") : issueDateIndex !== -1 ? item[issueDateIndex].replace("[ISSUEDATE]", "") : "N/A";

                        const tiltIndex = item.findIndex((line) => line.includes("TILT="));
                        let tilt = tiltIndex !== -1 ? item[tiltIndex].replace("TILT=", "") : "";

                        const dataStringIndex = tiltIndex + 1;
                        let dataString = item[dataStringIndex] ? item[dataStringIndex].split(" ") : [];
                        let dataString2;
                        if (dataString.length !== 10) {
                            dataString2 = dataString;
                            const nextLineIndex = tiltIndex + 2;
                            dataString = item[nextLineIndex] ? item[nextLineIndex].split(" ") : [];
                        } else {
                            dataString2 = item[tiltIndex + 2] ? item[tiltIndex + 2].split(" ") : [];
                        }

                        const lampIndex = item.findIndex((line) => line.includes("[Lamp]"));
                        const lampcatIndex = item.findIndex((line) => line.includes("[LAMPCAT]"));
                        const lamp = lampIndex !== -1 ? item[lampIndex].replace("[Lamp]", "") : lampcatIndex !== -1 ? item[lampcatIndex].replace("[LAMPCAT]", "") : "N/A";

                        const ballastFactorIndex = item.findIndex((line) => line.includes("[BALLAST]"));
                        const ballastFactor = ballastFactorIndex !== -1 ? item[ballastFactorIndex].replace("[BALLAST]", "") : dataString2[0];

                        const futureUsageIndex = item.findIndex((line) => line.includes("[FUTUREUSAGE]"));
                        const futureUsage = futureUsageIndex !== -1 ? item[futureUsageIndex].replace("[FUTUREUSAGE]", "") : dataString2[1];

                        const wattageIndex = item.findIndex((line) => line.includes("[INPUTPOWER]"));
                        const wattage = wattageIndex !== -1 ? item[wattageIndex].replace("[INPUTPOWER]", "") : dataString2[2];

                        const colourConstantIndex = item.findIndex((line) => line.includes("[COLORCONSTANT]"));
                        const colourConstant = colourConstantIndex !== -1 ? item[colourConstantIndex].replace("[COLORCONSTANT]", "") : "N/A";

                        const totalLumensIndex = item.findIndex((line) => line.includes("[_TOTALLUMINAIRELUMENS]"));
                        const totalLumens = totalLumensIndex !== -1 ? item[totalLumensIndex].replace("[_TOTALLUMINAIRELUMENS]", "") : "N/A";

                        const otherIndex = item.findIndex((line) => line.includes("[OTHER]"));
                        const other = otherIndex !== -1 ? item[otherIndex].replace("[OTHER]", "") : "N/A";

                        const reportNumIndex = item.findIndex((line) => line.includes("[TEST]"));
                        const reportNum = reportNumIndex !== -1 ? item[reportNumIndex].replace("[TEST]", "") : "N/A";

                        const sprIndex = item.findIndex((line) => line.includes("[SPR]"));
                        const _sprIndex = item.findIndex((line) => line.includes("[_SPR]"));
                        const spr = sprIndex !== -1 ? item[sprIndex].replace("[SPR]", "") : _sprIndex !== -1 ? item[_sprIndex].replace("[_SPR]", "") : "N/A";

                        updatedRows.push({
                            id: i + 1,
                            fileType: fileType === "LDT" ? "LDT" : "IES",
                            company: fileType === "LDT" ? item[1] : manufacturer,
                            type: fileType === "LDT" ? item[2] : dataString[5],
                            units: fileType === "LDT" ? "N/A" : dataString[6],
                            cPlanes: fileType === "LDT" ? item[4] : dataString[4],
                            yPlanes: fileType === "LDT" ? item[6] : dataString[3],
                            totalLuminaireLumens: fileType === "LDT" ? item[29] : totalLumens,
                            reportNum: fileType === "LDT" ? item[8] : reportNum,
                            fileName: item[0],
                            date: fileType === "LDT" ? item[12] : date,
                            length: fileType === "LDT" ? item[13] : dataString[7],
                            width: fileType === "LDT" ? item[14] : dataString[8],
                            height: fileType === "LDT" ? item[15] : dataString[9],
                            conversionFactor: fileType === "LDT" ? item[24] : dataString[2],
                            tilt: fileType === "LDT" ? item[25] : tilt,
                            numberLamps: fileType === "LDT" ? item[26] : dataString[0],
                            typeLamps: fileType === "LDT" ? item[28] : lamp,
                            totalFlux: fileType === "LDT" ? item[30] : dataString[1],
                            colorAppearance: fileType === "LDT" ? item[31] : colourConstant,
                            wattage: fileType === "LDT" ? item[32] : wattage,
                            spr: fileType === "LDT" ? "N/A" : spr,
                            ballastFactor: fileType === "LDT" ? "N/A" : ballastFactor,
                            futureUsage: fileType === "LDT" ? "N/A" : futureUsage,
                            other: fileType === "LDT" ? "N/A" : other,
                        });
                    }
                });
                setNewRows(updatedRows);
            } else {
                setNewRows(state.luminaireSelection);
            }
        };

        updateFileArray();
    }, [state.filesOfSelected]);

    function customToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
                <Button 
                    variant='outlined' 
                    startIcon={<ListIcon/>}
                    color='primary'
                    onClick={() => handleRemove()}  
                >
                    Remove Selected Rows
                </Button>
            </GridToolbarContainer>
        );
    }

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        fileType: true,
        company: true,
        type: true,
        units: true,
        cPlanes: false,
        yPlanes: false,
        totalLuminaireLumens: false,
        reportNum: false,
        fileName: false,
        date: false,
        length: true,
        width: true,
        height: true,
        conversionFactor: false,
        tilt: false,
        numberLamps: true,
        typeLamps: true,
        totalFlux: true,
        colorAppearance: true,
        wattage: true,
        spr: true,
        ballastFactor: true,
        futureUsage: true,
        other: false,
    });

    const [filterModel, setFilterModel] = React.useState({
        items: [
            {
                field: 'id',
                operator: '=',
                value: '',
            },
        ],
    });

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid   
                style={{ height: 700, width: '100%' }}
                density="compact"
                slots={{
                    toolbar: customToolbar,
                }}
                rows={newRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                pageSizeOptions={[25,50,75,100]}
                checkboxSelection
                disableRowSelectionOnClick
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                onRowSelectionModelChange={(id) => setSelectedLuminaires(id)}
            />
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Box>
    );
};

export default RefineSearch;