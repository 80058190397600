import { apiService } from './axios.client';

export const HierarchyEndPoints = {
    getChildren: function (input, callback) {
        apiService.get('/hierarchy/getChildren', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getTreeRoots: function (input, callback) {
        apiService.post('/hierarchy/getTreeRoots', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    deleteTreeNode: function (input, callback) {
        apiService.post('/hierarchy/RemoveTreeItem', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    addTreeNodes: function (input, callback) {
        apiService.post('/hierarchy/AddTreeItems', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getRootFolders: function (input, callback) {
        apiService.post('/hierarchy/getRootFolders', input)
            .then(callback)
            .catch(error => alert(error));
    },
    getAllFolderChildren: function (input, callback) {
        apiService.get('/hierarchy/getAllFolderChildren', input)
            .then(callback)
            .catch(error => alert(error));
    },
    GetAllFolderParent: function (input, callback) {
        apiService.get('/hierarchy/getAllFolderParent', input)
            .then(callback)
            .catch(error => alert(error));
    },
    addLuminairesToFolders: function (input, callback) {
        apiService.post('/hierarchy/addLuminairesToFolders', input.body)
            .then((response) => callback(response, input.props))
            .catch(error => console.log(error));
    },

    createGroup: function (input, callback) {
        apiService.post('/hierarchy/createGroup', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getGroupsAndLuminaires: function (input, callback) {
        apiService.post('/hierarchy/getGroupsAndLuminaires', input.input)
            .then(callback)
            .catch(error => alert(error));
    },
    deleteGroupOrGroupItem: function (input, callback) {
        apiService.delete('/hierarchy/deleteGroupOrGroupItem', input)
            .then((response) => callback(response, input.props))
            .catch(error => console.log(error));
    },
    removeTreeItems: function (input, callback) {
        console.log("tree items", input);
        apiService.post('/hierarchy/removeTreeItems', input)
            .then(callback)
            .catch(error => alert(error));
    },
    getAllLowestChildrenFolder: function (input, callback) {
        apiService.get('/hierarchy/getAllLowestChildrenFolder', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    addAttributesToGroup: function (input, callback) {
        apiService.post('/hierarchy/addAttributesToGroup', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    addAttributesToLuminaire: function (input, callback) {
        apiService.post('/hierarchy/addAttributesToLuminaire', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    updateAttributesToLuminaire: function (input, callback) {
        apiService.post('/hierarchy/updateAttributesToLuminaire', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getAllAttributesByGroup: function (input, callback) {
        apiService.get('/hierarchy/getAllAttributesByGroup', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getAllAttributesByLuminaire: function (input, callback) {
        apiService.get('/hierarchy/getAllAttributesByLuminaire', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    updateGroupNameDescription: function (input, callback) {
        apiService.post('/hierarchy/updateGroupNameDescription', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    updateLuminaireDescription: function (input, callback) {
        apiService.post('/hierarchy/updateLuminaireDescription', input)
            .then(callback)
            .catch(error => console.log(error));
    },    
    updateGroupAttributes: function (input, callback) {
        apiService.post('/hierarchy/updateGroupAttributes', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    exportPhotomertricTree: function (input, callback) {
        apiService.get('/hierarchy/exportPhotomertricTree', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    exportToSpektd: function (input, callback) {
        apiService.get('/hierarchy/exportToSpektd', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    uploadMediaForLuminaires: function (input, callback) {
        apiService.post('/hierarchy/uploadMediaForLuminaires', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getLuminaireMediasList: function (input, callback) {
        apiService.get('/hierarchy/getLuminaireMediasList', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getGroupMediasList: function (input, callback) {
        apiService.get('/hierarchy/getGroupMediasList', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getLuminaireMediaContent: function (input, callback) {
        apiService.get('/hierarchy/getLuminaireMediaContent', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getGroupMediaContent: function (input, callback) {
        apiService.get('/hierarchy/getGroupMediaContent', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    deleteMedias: function (input, callback) {
        apiService.post('/hierarchy/deleteMedias', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getAllManufacturersCompany: function (input, callback) {
        apiService.post('/subscribe/GetAllCompanyContainManufacturersUsers', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getAllSubscribedCompanys: function (input, callback) {
        apiService.post('/subscribe/GetAllSubscribedCompanys', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    editSubscribeTreeByCompanyIds: function (input, callback) {
        apiService.post('/subscribe/EditSubscribeTreeByCompanyIds', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    getSubscribedRootFolders: function (input, callback) {
        apiService.post('/subscribe/GetRootFoldersBySubscribe', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    exportToSelector: function (input, callback) {
        apiService.get('/hierarchy/exportLuminaireToSelector', input)
            .then(callback).catch(error => callback(error));
    },
    GetAllLowestChildrenFolderBySubscribe: function (input, callback) {
        apiService.get('/Subscribe/GetAllLowestChildrenFolderBySubscribe', input)
            .then(callback)
            .catch(error => console.log(error));
    },
    CopyGroup: function (input, callback) {
        apiService.get('/Subscribe/CopyTreeToSelectedFolder', input)
            .then(callback)
            .catch(error => console.log(error));
    },
};