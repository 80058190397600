import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { Stage, Layer, Shape } from "react-konva";
import formatNumber from '../formatNumber'


function transform(widthScale, heightScale, xTransform, yTransform) {
	return d3.geoTransform({
		point: function (x, y) {
			this.stream.point(x * widthScale + xTransform, y * heightScale + yTransform);
		}
	});
}



const RoadCanvas = () => {
	const props = useSelector(state => state);
	const targetRef = useRef();
	const canvasRef = useRef(null);
	const stageRef = useRef(null);
	const [height, setHeight] = useState(65);
	const [width, setWidth] = useState(100);
	const [stage, setStage] = useState({
		scale: 0.95,
		x: 0,
		y: 0
	});

	const redraw = () => {
		setWidth(targetRef.current.clientWidth);
		setHeight(targetRef.current.clientHeight);
	}


	const drawBase = (ctx) => {
		ctx.strokeStyle = 'black';
		ctx.fillStyle = 'grey';
		const grids = props.GridsCfg.RoadGrids;
		var totalWidth = 0;
		var extraWidth = 0;
		var offsets = [];
		for (let index = 0; index < grids.length; index++) {
			const grid = grids[index];
			if (index === 0) {
				totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				offsets.push(totalWidth);
			}
			else {
				totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
				offsets.push(totalWidth);
			}
		}
		var maxSpacing = Math.max.apply(Math, props.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
		var linearScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
		var scaledTotalWidth = linearScale(totalWidth);
		for (let i = 0; i < grids.length; i++) {
			const grid = grids[i];
			var roadWidth = grid.NumberOfLanes * grid.LaneWidth;
			if (i !== 0) extraWidth += grid.LeftOffset;
			var scaledRoadWidth = linearScale(roadWidth);
			var scaledExtraWidth = linearScale(extraWidth);

			if (i === 0) {
				ctx.fillRect((width / 2) - (scaledTotalWidth / 2), 0, scaledRoadWidth, height);
				ctx.strokeRect((width / 2) - (scaledTotalWidth / 2), 0, scaledRoadWidth, height);
			}
			else {
				ctx.fillRect(((width / 2) - (scaledTotalWidth / 2)) + scaledExtraWidth, 0, scaledRoadWidth, height);
				ctx.strokeRect(((width / 2) - (scaledTotalWidth / 2)) + scaledExtraWidth, 0, scaledRoadWidth, height);

			}
			extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			//ctx.strokeRect((width * (index + 1)) / (grids.length + 1) - ((scaledWidth * (index + 1)) / (grids.length + 1)), 0, scaledWidth, height);
			//if (grid.NumberOfLanes > 1) drawDashedLine([100, 50], scaledRoadWidth, ctx);
		}
		drawColumns(linearScale, ctx, scaledTotalWidth, offsets);
		drawLabels(linearScale, ctx, scaledTotalWidth, offsets);
	}

	const drawLabels = (scale, ctx, scaledTotalWidth, offsets) => {
		ctx.fillStyle = 'black'; // set text color
		ctx.font = '15px Arial'; // set text font and size
		ctx.textAlign = 'center'; // center the text

		const grids = props.GridsCfg.RoadGrids;
		for (let i = 0; i < grids.length; i++) {
			const grid = grids[i];
			const gridType = grid.GridType;

			// calculate the center x position of the grid
			const xPos = i === 0 ?
				(width / 2) - (scaledTotalWidth / 2) + scale(grid.NumberOfLanes * grid.LaneWidth / 2) :
				((width / 2) - (scaledTotalWidth / 2)) + scale(offsets[i - 1] + grid.LeftOffset + (grid.NumberOfLanes * grid.LaneWidth / 2));

			// calculate the y position of the label
			// you can adjust the 20 value to position the labels as needed
			const yPos = height + 20;
			const gridWidth = formatNumber(grid.NumberOfLanes * grid.LaneWidth);
			ctx.fillText(gridType + `: ${gridWidth}m`, xPos, yPos);
			// draw the length label
			if (i == 0) {
				const Columns = props.ColumnCfg.Columns;
				var spacing = Math.max.apply(Math, Columns.map(function (o) { return o.columnSpacing; }))
				ctx.rotate(-Math.PI / 2);
				ctx.fillText(`${spacing}m`, -height / 2, width / 2 - scaledTotalWidth / 2 - 10);
				ctx.rotate(Math.PI / 2);
			}
		}
	}

	const drawColumns = (scale, ctx, scaledTotalWidth, offsets) => {
		const Columns = props.ColumnCfg.Columns;
		const grids = props.GridsCfg.RoadGrids;
		let maxSpacing = Math.max.apply(Math, Columns.map(function (o) { return o.columnSpacing; }))
		for (let i = 0; i < Columns.length; i++) {
			var columnSetback = scale(Columns[i].columnSetback);
			var columnOutreach = scale(Columns[i].columnOutreach);
			if (columnOutreach === undefined) columnOutreach = 0;
			if (columnSetback === undefined) columnSetback = 0;
			var lampLen = scale(0.4);
			var lampWidth = scale(0.2);
			ctx.fillStyle = 'black';
			for (let j = 0; j <= height; j += height) {
				var scaledHeight = j;
				if (Columns[i].columnSpacing !== maxSpacing && j === 0) scaledHeight = height - scale(Columns[i].columnSpacing);
				var offset = 0;
				switch (Columns[i].columnConfiguration) {
					case "Single Sided Left":
						if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						break;

					case "Single Sided Right":
						if (j == 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
						}
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						break;

					case "Staggered":
						if (j != 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
							offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						}
						else if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);

						break;

					case "Reverse Staggered":
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						if (j == 0) {
							offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						}
						else if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						else offset = 0;
						break;
					case "Opposite":
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
						ctx.beginPath();
						ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
						ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
						ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach + offset, scaledHeight);
						ctx.fill();
						ctx.stroke();
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						else offset = 0;
						break;
					case "Twin Central":
						if (j == 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
						}
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback - columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
						ctx.beginPath();
						ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
						ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
						ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback - columnOutreach + offset, scaledHeight);
						ctx.fill();
						ctx.stroke();
						break;

					default:
						break;
				}
				ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
				ctx.beginPath();
				ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
				ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
				ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach + offset, scaledHeight);
				ctx.fill();
				ctx.stroke();
			}
		}
	}

	const drawResults = (ctx) => {
		if (props.LuminaireCfg.checkedResults) {
			if (props.LuminaireCfg.gridResults[0] && props.LuminaireCfg.gridResults.length === props.GridsCfg.RoadGrids.length) {
				const grids = props.GridsCfg.RoadGrids;
				var totalWidth = 0;
				var extraWidth = 0;
				for (let index = 0; index < grids.length; index++) {
					const grid = grids[index];
					if (index === 0) totalWidth += grid.NumberOfLanes * grid.LaneWidth;
					else totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].RightOffset + grids[index - 1].LeftOffset;
				}
				var maxSpacing = Math.max.apply(Math, props.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
				for (let index = 0; index < grids.length; index++) {
					const grid = grids[index];
					ctx.font = grid.LaneWidth > 3 ? "10px Arial" : grid.LaneWidth > 2 ? "8px Arial" : "6px Arial";
					var myArray = props.LuminaireCfg.gridResults[index];
					var reversedArray = myArray ? myArray.slice().reverse() : null; // create a reversed copy of the array
					var xPoints = 0;
					if (grid.Standard == "IES RP-8-18") xPoints = grid.NumberOfLanes * 2;
					else if (grid.IllumTypeId == "Luminance") xPoints = grid.NumberOfLanes * 3;
					else {
						xPoints = Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5) < 3 ? 3 : Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5);
					}
					var yPoints = reversedArray ? reversedArray.length / xPoints : 0; // use the reversed array to calculate yPoints
					var dataMax = Math.max(...reversedArray);
					var dataMin = Math.min(...reversedArray);
					var heightScale = height / yPoints;
					var heightSpacing = (height / (yPoints + 1) / 2);
					var roadWidth = grid.LaneWidth * grid.NumberOfLanes;
					if (index !== 0) extraWidth += grid.LeftOffset;
					var linearScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
					var scaledRoadWidth = linearScale(roadWidth);
					var scaledTotalWidth = linearScale(totalWidth);
					var scaledExtraWidth = linearScale(extraWidth);
					var point = 0;
					for (let x = xPoints - 1; x >= 0; x--) {
						for (let y = 0; y < yPoints; y++) {
							//ctx.fillText(myArray[point], (80 / state.ProjectConfig.RoadCfg.NumOfLanes) + (window.innerHeight * x / (2 * xPoints)), 40 + (window.innerWidth * y / 25))
							if (reversedArray[point] == dataMax) ctx.fillStyle = 'darkgreen';
							else if (reversedArray[point] == dataMin) ctx.fillStyle = 'red';
							else ctx.fillStyle = 'cyan';
							if (index === 0) ctx.fillText(reversedArray[point], x * (scaledRoadWidth / xPoints) + (width / 2 - scaledTotalWidth / 2) + 2 * grid.LaneWidth, (y * heightScale + heightSpacing + 8));
							else ctx.fillText(reversedArray[point], x * (scaledRoadWidth / xPoints) + (width / 2 - scaledTotalWidth / 2) + 2 * grid.LaneWidth + scaledExtraWidth, (y * heightScale + heightSpacing + 8));
							point++;
						}
					}
					//if (state.ProjectConfig.RoadCfg.NumOfLanes > 1) drawDashedLine([100, 50], scaledTotalWidth, ctx);

					extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				}
			}
		}
	}

	const drawContours = (context) => {
		if (props.LuminaireCfg.checkedContours && props.LuminaireCfg.contoursGridResults[0] && props.LuminaireCfg.contoursGridResults.length === props.GridsCfg.RoadGrids.length) {
			const grids = props.GridsCfg.RoadGrids;
			var totalWidth = 0;
			var extraWidth = 0;
			var lumMaxMin = { max: 0, min: Number.MAX_SAFE_INTEGER };
			var illumMaxMin = { max: 0, min: Number.MAX_SAFE_INTEGER };
			for (let index = 0; index < props.LuminaireCfg.contoursGridResults.length; index++) {
				var tempMax = Math.max(...props.LuminaireCfg.contoursGridResults[index]);
				var tempMin = Math.min(...props.LuminaireCfg.contoursGridResults[index]);
				if (grids[index].IllumTypeId == "Luminance") {
					if (tempMax > lumMaxMin.max) lumMaxMin.max = tempMax;
					if (tempMin < lumMaxMin.min) lumMaxMin.min = tempMin;
				}
				else {
					if (tempMax > illumMaxMin.max) illumMaxMin.max = tempMax;
					if (tempMin < illumMaxMin.min) illumMaxMin.min = tempMin;
				}
				if (tempMax > dataMax) dataMax = tempMax;
				if (tempMin < dataMin) dataMin = tempMin;
			}
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				if (index === 0) totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				else totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
			}
			var maxSpacing = Math.max.apply(Math, props.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				var data = props.LuminaireCfg.contoursGridResults[index];
				var contourValues = props.ProjectConfig.contourValues;
				for (let j = 0; j < data.length; j++) {
					data[j] = parseFloat(data[j]);
				}
				var dataMax = grids[index].IllumTypeId == "Luminance" ? lumMaxMin.max : illumMaxMin.max;
				var dataMin = grids[index].IllumTypeId == "Luminance" ? lumMaxMin.min : illumMaxMin.min;
				var lineColor = props.ProjectConfig.autoContourValues ?
					d3.scaleSequential(d3.interpolateSpectral).domain([dataMax, dataMin]) :
					d3.scaleSequential(d3.interpolateSpectral).domain([contourValues[contourValues.length - 1], [contourValues[0]]]);

				var xPoints = 0;
				if (grid.Standard == "IES RP-8-18") xPoints = grid.NumberOfLanes * 20;
				else if (grid.IllumTypeId == "Luminance") xPoints = grid.NumberOfLanes * 30;
				else {
					var ceiling = Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5);
					xPoints = ceiling < 3 ? 30 : ceiling * 10;
				}
				var yPoints = data.length / xPoints;
				var widthScale = width / xPoints;
				var heightScale = height / yPoints;
				var contours = d3.contours().size([xPoints, yPoints]);
				var cntrs = props.ProjectConfig.autoContourValues ?
					contours.thresholds(d3.range(0, dataMax, (dataMax / 7)))(data) :
					contours.thresholds(contourValues)(data);

				var roadWidth = grid.LaneWidth * grid.NumberOfLanes;
				if (index !== 0) extraWidth += grid.LeftOffset;
				var yScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
				var scaledTotalWidth = yScale(totalWidth);
				var scaledExtraWidth = yScale(extraWidth);
				var translateWidth = yScale(roadWidth);
				var scaledWidth = translateWidth * widthScale / width;
				var projection;
				if (index === 0) projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidth / 2, height);
				else projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidth / 2 + scaledExtraWidth, height);


				var path = d3.geoPath(projection, context);
				for (let i = 0; i < cntrs.length; i++) {
					const c = cntrs[i];
					if (c.coordinates.length == 0) return;
					context.beginPath();
					path(c);
					context.strokeStyle = lineColor(c.value);
					context.stroke();
				}

				extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			}

		}
	}

	const drawFilledContours = (context, color, grid, index, extraWidth, totalWidth, dataMax, dataMin) => {
		var data = props.LuminaireCfg.contoursGridResults[index];
		for (let i = 0; i < data.length; i++) {
			data[i] = parseFloat(data[i]);
		}
		var maxSpacing = Math.max.apply(Math, props.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
		var lineColor = color;
		var xPoints = 0;
		if (grid.Standard == "IES RP-8-18") xPoints = grid.NumberOfLanes * 20;
		else if (grid.IllumTypeId == "Luminance") xPoints = grid.NumberOfLanes * 30;
		else {
			var ceiling = Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5);
			xPoints = ceiling < 3 ? 30 : ceiling * 10;
		}
		var yPoints = data.length / xPoints;
		var widthScale = width / xPoints;
		var heightScale = height / yPoints;
		var contours = d3.contours().size([xPoints, yPoints]);
		var cntrs = contours.thresholds(d3.range(dataMin, dataMax, dataMax / 200))(data);
		var roadWidth = grid.LaneWidth * grid.NumberOfLanes;
		var yScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
		var scaledTotalWidth = yScale(totalWidth);
		var scaledExtraWidth = yScale(extraWidth);
		var translateWidth = yScale(roadWidth);
		var scaledWidth = translateWidth * widthScale / width;
		var projection;
		if (index === 0) projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidth / 2, height);
		else projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidth / 2 + scaledExtraWidth, height);
		var path = d3.geoPath(projection, context);

		cntrs.forEach(c => {
			if (c.coordinates.length == 0) return;
			context.beginPath();
			path(c);
			context.fillStyle = lineColor(c.value);
			context.fill();
		});
	}

	const handleWheel = (e) => {
		e.evt.preventDefault();

		const scaleBy = 1.05;
		const stage = e.target.getStage();
		const oldScale = stage.scaleX();
		const mousePointTo = {
			x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
			y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
		};

		const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

		setStage({
			scale: newScale,
			x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
			y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale
		});
	};

	const drawHeatmap = (ctx) => {
		if (props.LuminaireCfg.checkedColouring && props.LuminaireCfg.contoursGridResults && props.LuminaireCfg.contoursGridResults.length == props.GridsCfg.RoadGrids.length) {
			const grids = props.GridsCfg.RoadGrids;
			var totalWidth = 0;
			var extraWidth = 0;
			var dataMax = 0;
			var dataMin = Number.MAX_SAFE_INTEGER;
			var lumMaxMin = { max: 0, min: Number.MAX_SAFE_INTEGER };
			var illumMaxMin = { max: 0, min: Number.MAX_SAFE_INTEGER };
			for (let index = 0; index < props.LuminaireCfg.contoursGridResults.length; index++) {
				var tempMax = Math.max(...props.LuminaireCfg.contoursGridResults[index]);
				var tempMin = Math.min(...props.LuminaireCfg.contoursGridResults[index]);
				if (grids[index].IllumTypeId == "Luminance") {
					if (tempMax > lumMaxMin.max) lumMaxMin.max = tempMax;
					if (tempMin < lumMaxMin.min) lumMaxMin.min = tempMin;
				}
				else {
					if (tempMax > illumMaxMin.max) illumMaxMin.max = tempMax;
					if (tempMin < illumMaxMin.min) illumMaxMin.min = tempMin;
				}
				if (tempMax > dataMax) dataMax = tempMax;
				if (tempMin < dataMin) dataMin = tempMin;
			}
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				if (index === 0) totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				else totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
			}
			for (let i = 0; i < grids.length; i++) {
				var max = grids[i].IllumTypeId == "Luminance" ? lumMaxMin.max : illumMaxMin.max;
				var min = grids[i].IllumTypeId == "Luminance" ? lumMaxMin.min : illumMaxMin.min;
				const grid = grids[i];
				extraWidth += grid.LeftOffset;
				if (props.LuminaireCfg.checkedGreyscale) drawFilledContours(ctx, d3.scaleSequential(d3.interpolatePlasma).domain([min, max]), grid, i, extraWidth, totalWidth, max, min);
				else drawFilledContours(ctx, d3.scaleSequential(d3.interpolateGreys).domain([max, min]), grid, i, extraWidth, totalWidth, max, min);

				extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			}
		}
	}



	useEffect(() => {
		if (targetRef.current) {
			setWidth(targetRef.current.offsetWidth);
			setHeight(targetRef.current.offsetHeight);
		}
	}, [targetRef]);

	useEffect(() => {
		window.addEventListener("resize", redraw);
		return () => window.removeEventListener("resize", redraw);
	})

	return (
		<div ref={targetRef}>
			<Stage
				ref={stageRef}
				width={width}
				height={height}
				onWheel={handleWheel}
				scaleX={stage.scale}
				scaleY={stage.scale}
				x={stage.x}
				y={stage.y}
				style={{ border: '1px solid #000000', height: '65vh' }}
				draggable={true}
			>
				<Layer ref={canvasRef} style={{ border: '1px solid #000000', maxHeight: '65vh' }}>
					<Shape sceneFunc={(ctx) => drawBase(ctx)} />
					<Shape sceneFunc={(ctx) => drawHeatmap(ctx)} />
					<Shape sceneFunc={(ctx) => drawContours(ctx)} />
					<Shape sceneFunc={(ctx) => drawResults(ctx)} />
				</Layer>
			</Stage>
		</div>
	);
}

export default RoadCanvas;