import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import { makeStyles } from "@material-ui/core/styles";
import { initialState } from '../store/initial-state';

export default function JobPagination({onGetJobs}) {
  const state = useSelector(state => state); 
  const dispatch = useDispatch();
  const [snack, setSnack] = React.useState({
    open: false
  });
  
  const useStyles = makeStyles(() => ({
    ul: {
      "& .css-1ba2zw7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
        background: state.Select.primary || initialState.Select.primary,
        color: "#fff"
      },
      "& .css-1ba2zw7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover": {
        backgroundColor: state.Select.tertiary || initialState.Select.tertiary
      },
    },
  }));

  const classes = useStyles();

  const onChange = (event, value) => {
    console.log(value);
    dispatch(user.UpdateUserCfg({ blockNumber: value })); 
    EdgeOrREST(null, 'GET_JOB_LIST', onGetJobs, { headers: { 'blockNumber': value }});

    setSnack({
      open: true,
      message: 'Jobs have been updated.',
      severity: 'success'
    });
  };

  // useEffect(() => {
  //   Pagination.count = state.user.jobsPages;
  // }, [state.user.jobsPages]);

  return (
    <Stack spacing={1}>      
        <Pagination count={state.user.jobsPages} classes={{ ul: classes.ul }} onChange={onChange} size="small" /> 
    </Stack>
  );//Page number background colour
}