import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {/* <CircularProgress variant="determinate" {...props} /> */}
      <CircularProgress variant="determinate" value={props.value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CircularWithValueLabel(props) { return <CircularProgressWithLabel value={props.value} /> }

// export default function CircularWithValueLabel(props) {
//   const [progress, setProgress] = React.useState(10);

//   function getLabel(props) {
//     if (props.value != 50) return props.value;
//     else {

//         console.log("props.value == 50:", props.value == 50);

//         new Date(props.start)

//         const start = props.start;
//         const now = moment();

//         const moment1 = moment(start, "DD/MM/YYYY HH:mm:ss");
//         const isMoment1Valid = moment1.isValid();

//         const moment2 = moment(now);

//         const differenceInSeconds = moment2.diff(moment1, 'seconds');
//         console.log("Time difference in seconds:", differenceInSeconds);
//         console.log(" props.calcSec:",  props.calcSec);
//         var time = differenceInSeconds / props.calcSec >= 1 ? 90 : (differenceInSeconds / props.calcSec) * 100;
//         console.log(" time   :",  time);
//         return time >= 99 ? 99 : time;
//     }
//   }
//   return <CircularProgressWithLabel value={getLabel(props)} />;
// }