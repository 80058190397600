import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from '@mui/material';


const useStyles = makeStyles((theme) => ({
	backdrop: {
	  zIndex: 1500	//Make it show on top of everything
	}
  }));


const Loading = (props) => {
	const dispatch = useDispatch();
	const state = useSelector(state => state);
	const classes = useStyles();

	if (props.skeleton)
	{
		return (
			<div style={{ position: 'relative', width: '100%', height: `${!props.height ? 100 : props.height}px` }}>
				<Skeleton variant="rect" width="100%" height="100%" />
				<CircularProgress
				size={24}
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
				}}
				color='inherit'
				/>
			</div> 
		)
	}
	else
	{
		return (
			<Backdrop
				className={classes.backdrop}
				open={props.open}
				onClick={(event) => {event.stopPropagation();}} //used to disable backdrop clicking which disables the entire loading screen
			>
				<CircularProgress color="primary" />
			</Backdrop>
		);
	}
}

export default Loading;

// Usage for backdrop loading screen:

// import Loading from '../shared/loading.component';

// Inside the component: (Instantiate State)

//   const [loading, setLoading] = React.useState({
//     open: false
//   }); 

// Or use state.Search.load to control the loading screen

// Known Time:

// const handleLoading = () => {
//     setLoading({open: true});
//
//     setTimeout(() => {
//       setLoading({open: false});
//     }, 5000);
//   }

// Unknown Time:

// setLoading({open: true}); At the start of the function
// setLoading({open: false}); At the end of the function)

//  Put inside the return of the component: (At the bottom with the snack / notification)

//  <Loading open={loading.open}/>

// Can also be used with an if statement like the example below:

// {loading ? (
// 	<Loading open={true}/>
// ) : (
//  <></>
// )}

// This can be used anywhere in the component unlike the backdrop loading screen with the changing of state (Is probably preferable to use it this way).




// Usage for skeleton loading screen:

// import Loading from '../shared/loading.component';

// Inside the component: 

// <Loading skeleton={true} height={Enter height here (takes pixels)}/>

// Doesn't need to be controlled by state. Should be used alongside an if statement.

// Example in src\photometricManager\photometric-groups-viewer.component.js:

// {loadingGroups[item.id] ? (
// 	<Loading skeleton={true} height={100}/>
// ) : (
// sortedGroups(item.groups)
// )}

