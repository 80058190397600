import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card,
	CardContent,
	CardHeader,
	FormControlLabel,
	IconButton,
	Grid,
	Tooltip,
	TextField,
	Zoom,
	makeStyles
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Delete, InfoOutlined } from '@material-ui/icons';
import cloneDeep from "lodash/cloneDeep";
import columnActions from '../../store/actions/columnActions';
import luminActions from '../../store/actions/luminaireActions';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import ColumnField from './ColumnField';
import FluxTextField from './FluxField';
import { ReactComponent as SingleLeft } from '../../IMG/SingleLeft.svg'
import { ReactComponent as SingleRight } from '../../IMG/SingleRight.svg'
import { ReactComponent as Opposite } from '../../IMG/Opposite.svg'
import { ReactComponent as TwinCentral } from '../../IMG/TwinCentral.svg'
import { ReactComponent as Stagger } from '../../IMG/Stagger.svg'
import { ReactComponent as StaggerReverse } from '../../IMG/StaggerReverse.svg'

const mapDispatchToProps = (dispatch) => {
	const Luminaire = bindActionCreators(luminActions, dispatch);
	const Column = bindActionCreators(columnActions, dispatch);
	return {
		UpdateColumnCfg: (data) => {
			Column.UpdateColumnCfg(data);
		},
		FillGridResults: (data) => {
			Luminaire.UpdateAllResults(data);
		},
	};
};

const useStyles = makeStyles(() => ({
	hideFocus: {
		"&:focus": { outline: 'none' },
		"&:hover": { backgroundColor: 'rgba(0, 0, 0, 0)' },
		paddingTop: 8,
		paddingRight: 0
	},
	content: {
		paddingTop: 0
	},
	header: {
		paddingBottom: 10,
	},
	helperText: {
		marginLeft: 0,
	},
	label: {
		marginTop: 25,
		marginLeft: 0
	},
	labelRoot: {
		marginRight: 16,
		marginBottom: 0,
		cursor: 'auto'
	}
}));

const RowCard = ({ ColumnCfg, rowNumber, removeCard, index, UpdateColumnCfg, FillGridResults }) => {

	const props = useSelector(state => state);
	const style = useStyles();
	const dropbox = props.GridsCfg.RoadGrids;

	ColumnCfg = useSelector(state => state.ColumnCfg.Columns[index]);

	const [fluxValue, setFluxValue] = useState(ColumnCfg.columnFlux);

	const [values, setValues] = useState({
		Height: ColumnCfg.columnHeight,
		Tilt: ColumnCfg.columnTilt,
		Spacing: ColumnCfg.columnSpacing,
		Setback: ColumnCfg.columnSetback,
		Outreach: ColumnCfg.columnOutreach,
		Overhang: ColumnCfg.columnOutreach - ColumnCfg.columnSetback,
		Configuration: ColumnCfg.columnConfiguration,
		currentRow: rowNumber,
		Flux: ColumnCfg.columnFlux,
		DefaultFlux: ColumnCfg.defaultFlux,
		gridHook: ColumnCfg.columnGrid,
		MaintFactor: ColumnCfg.columnMaintFactor,
		Dim: ColumnCfg.columnDim,
	});

	useEffect(() => {
		setValues({
			Height: ColumnCfg.columnHeight,
			Tilt: ColumnCfg.columnTilt,
			Spacing: ColumnCfg.columnSpacing,
			Setback: ColumnCfg.columnSetback,
			Outreach: ColumnCfg.columnOutreach,
			Overhang: ColumnCfg.columnOutreach - ColumnCfg.columnSetback,
			Configuration: ColumnCfg.columnConfiguration,
			currentRow: rowNumber,
			Flux: ColumnCfg.columnFlux,
			DefaultFlux: ColumnCfg.defaultFlux,
			gridHook: ColumnCfg.columnGrid,
			MaintFactor: ColumnCfg.columnMaintFactor,
			Dim: ColumnCfg.columnDim,
		});

	}, [
		ColumnCfg.columnHeight,
		ColumnCfg.columnTilt,
		ColumnCfg.columnSpacing,
		ColumnCfg.columnSetback,
		ColumnCfg.columnOutreach,
		ColumnCfg.columnConfiguration,
		ColumnCfg.columnGrid,
		ColumnCfg.columnMaintFactor,
		ColumnCfg.columnDim,
		ColumnCfg.columnFlux,
		ColumnCfg.defaultFlux,
		rowNumber
	])


	const [configIcon, setConfig] = useState('Single Sided Left');
	useEffect(() => {
		setConfig(ColumnCfg.columnConfiguration);

	}, [ColumnCfg.columnConfiguration]);

	const handleConfig = (event, newConfig) => {
		setConfig(newConfig);
		const Columns = cloneDeep(props.ColumnCfg.Columns);
		ColumnCfg.columnConfiguration = newConfig;
		Columns[index] = ColumnCfg;
		UpdateColumnCfg({ ...props.ColumnCfg, Columns });
		EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, "columns", Columns));
	};

	const handleBlur = (value, label) => {
		const Columns = cloneDeep(props.ColumnCfg.Columns);
		if (label === "Outreach") {
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, 'columnOverhang', parseFloat(value - values.Setback)));
			ColumnCfg.columnOutreach = value
			ColumnCfg.columnOverhang = parseFloat(values.Setback + value);
		}
		else if (label === "Setback") {
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, 'columnOverhang', parseFloat(values.Outreach - value)));
			ColumnCfg.columnSetback = value;
			ColumnCfg.columnOverhang = parseFloat(values.Outreach + value);
		}
		else if (label === "gridHook") {
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, null, null));
			var myval = parseInt(value.replace(/[^0-9]/g, '') - 1);
			ColumnCfg.columnGrid = myval;
		}
		else if (label === "Maintenance Factor") {
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, null, null));

			ColumnCfg.columnMaintFactor = value;
		}
		else {
			EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, null, null));
			ColumnCfg["column" + label] = value;
		}
		Columns[index] = ColumnCfg;
		UpdateColumnCfg({ ...props.ColumnCfg, Columns });
	}
	
	const handleFluxBlur = (newValue) => {
		handleBlur(newValue, 'Flux');
	};
	
	const handleChange = (currentVal, label) => {
		if (currentVal.startsWith(".")) currentVal = "0" + currentVal;
		var value = parseFloat(currentVal);
		if (label === "Flux") {
        	setFluxValue(currentVal);
		}
		else if (label === "Outreach") {
			setValues({
				...values,
				[label]: currentVal,
				Overhang: value - parseFloat(values.Setback)
			});
		}
		else if (label === "Setback") {
			setValues({
				...values,
				[label]: currentVal,
				Overhang: parseFloat(values.Outreach) - value
			});
		}
		else if (label === "gridHook") {
			var myval = parseInt(currentVal.replace(/[^0-9]/g, '') - 1);
			setValues({
				...values,
				[label]: myval
			});
		}
		else if (label === "Maintenance Factor") {
			if (value > 1) {
				value = 1;
				currentVal = 1;
			}
			setValues({
				...values,
				MaintFactor: currentVal
			});
		}
		else if (label === "Dim") {
			if (value > 100) {
				value = 100;
				currentVal = 100;
			}
			setValues({
				...values,
				Dim: currentVal
			});
		}
		else if (label === "Height" || label === "Spacing") {
			if (value < 1) {
				value = 1;
				currentVal = 1;
			}
			setValues({
				...values,
				[label]: currentVal
			});
		}
		else if (label === "Tilt") {
			if (value > 180) {
				value = 180;
				currentVal = 180;
			}else if (value < -180) {
				value = -180;
				currentVal = -180;
			}
			setValues({
				...values,
				[label]: currentVal
			});
		}
		else {
			if (value < 0) {
				value = 0;
				currentVal = 0;
			}
			setValues({
				...values,
				[label]: currentVal
			});
		}
	}

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			let index = props.GridsCfg.currentIndex;
			FillGridResults({
				index,
				gridResults
			});
		}
	}

	const DeleteButton = () => {
		return (
			<Tooltip title="Delete" TransitionComponent={Zoom} placement="bottom">
				<IconButton aria-label="remove" className={style.hideFocus}>
					<Delete onClick={(e) => removeCard(e, index)} />
				</IconButton>
			</Tooltip>
		)
	}

	const Header = () => {
		return (
			<CardHeader
				title={
					<>
						<Grid container>
							<Grid
								item
								md={3}
								sm={3}
								xs={3}
							>
							<TextField
								label="Grid"
								name="gridHook"
								onChange={(e) => handleBlur(e.currentTarget.value, "gridHook")}
								select
								SelectProps={{ native: true }}
								value={"Grid " + parseInt(values.gridHook + 1)}
								variant="outlined"
							>
								{dropbox.map((option, i) => (
									<option key={i} >
										{"Grid " + Number.parseInt(i + 1)}
									</option>
								))}
							</TextField>
							</Grid>
							<Grid
								item
								md={3}
								sm={3}
								xs={3}
							>
							<FluxTextField 
								defaultValue={values.DefaultFlux}
								initialValue={values.Flux}
								onBlur={handleFluxBlur}
								label="Flux Override (klm)"
							/>
							</Grid>
						</Grid>
					</>
				}
				action={
					<>
						<FormControlLabel
						
							classes={{ label: style.label, root: style.labelRoot }}
							labelPlacement='bottom'
							label={
								<Tooltip title="Selected boxes will be used for optimization, prioritizes the 1st box selected">
									<IconButton aria-label="info" size="small">
										<InfoOutlined fontSize="inherit" />
									</IconButton>
								</Tooltip>}
							control={<DeleteButton />}
						>
						</FormControlLabel>
					</>
				}
				classes={{ root: style.header }}


			/>
		);
	}

	return (
		<Card>
			<Header style={{ height: 'fit-content' }} />
			<CardContent classes={{ root: style.content }}>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Height}
							label="Height"
							name="height"
							units="m"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Tilt}
							label="Tilt"
							name="tilt"
							units="°"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Spacing}
							label="Spacing"
							name="spacing"
							units="m"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Dim}
							label="Dim"
							name="dim"
							units="%"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Setback}
							label="Setback"
							name="setback"
							units="m"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.Outreach}
							label="Outreach"
							name="outreach"
							units="m"
						/>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<Grid
							item
							md={9}
							sm={9}
							xs={9}>
							<TextField
								fullWidth
								disabled
								label="Overhang (m)"
								name="overhang"
								onChange={handleChange}
								type="number"
								value={values.Overhang.toFixed(2)}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid
						item
						md={6}
						sm={6}
						xs={6}
					>
						<ColumnField
							handleBlur={handleBlur}
							handleChange={handleChange}
							values={values.MaintFactor}
							label="Maintenance Factor"
							name="maintFactor"
							units=""
						/>
					</Grid>
					<Grid
						item
						md={12}
						sm={12}
						xs={12}
					>
						<FormControlLabel
							value="top"
							control={
								<ToggleButtonGroup
									value={configIcon}
									exclusive
									aria-label="configuration"
									size="large"
									title="Configuration"
								>
									<ToggleButton value="Single Sided Left" name="Single Sided Left" aria-label="left" onClick={handleConfig}>
										<Tooltip title="Single Sided Left" TransitionComponent={Zoom}>
											<SingleLeft />
										</Tooltip>
									</ToggleButton>
									<ToggleButton value="Single Sided Right" name="Single Sided Right" aria-label="right" onClick={handleConfig}>
										<Tooltip title="Single Sided Right" TransitionComponent={Zoom}>
											<SingleRight />
										</Tooltip>
									</ToggleButton>
									<ToggleButton value="Opposite" name="Opposite" aria-label="opposite" onClick={handleConfig}>
										<Tooltip title="Opposite" TransitionComponent={Zoom}>
											<Opposite />
										</Tooltip>
									</ToggleButton>
									<ToggleButton value="Twin Central" name="Twin Central" aria-label="twin-central" onClick={handleConfig}>
										<Tooltip title="Twin Central" TransitionComponent={Zoom}>
											<TwinCentral />
										</Tooltip>
									</ToggleButton>
									<ToggleButton value="Staggered" name="Staggered" aria-label="staggered" onClick={handleConfig}>
										<Tooltip title="Staggered" TransitionComponent={Zoom}>
											<Stagger />
										</Tooltip>
									</ToggleButton>
									<ToggleButton value="Reverse Staggered" name="Reverse Staggered" aria-label="reverse-staggered" onClick={handleConfig}>
										<Tooltip title="Reverse Staggered" TransitionComponent={Zoom}>
											<StaggerReverse />
										</Tooltip>
									</ToggleButton>
								</ToggleButtonGroup>
							}
							label="Configuration"
							labelPlacement="top"
						/>

					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default connect(null, mapDispatchToProps)(RowCard);