import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Grid,
	TextField
} from '@material-ui/core';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model'


const NumLanes = ({ index }) => {
	const props = useSelector(state => state);
	const dispatch = useDispatch();
	const dropboxValues = [1, 2, 3, 4, 5, 6, 7]

	const handleChange = (event) => {
		const RoadGrids = [...props.GridsCfg.RoadGrids];
		if (RoadGrids[index]) {
			RoadGrids[index] = {
				...RoadGrids[index],
				NumberOfLanes: event.target.value
			}
		}
		else RoadGrids.splice(index, 0, {
			NumberOfLanes: event.target.value
		});
		dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
		dispatch(roadActions.UpdateRoadCfg({ ...props.ProjectConfig.RoadCfg, "NumOfLanes": event.target.value }));
		var gridResults = EdgeOrREST(props.LuminaireCfg.luminaireFilePath, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, "roadGrids", RoadGrids));
		populateGridResults(gridResults);
	};

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				index: index,
				gridResults
			}));
		}
	}

	return (
		<TextField
			fullWidth
			label="Number of Lanes"
			name="numOfLanes"
			onChange={handleChange}
			select
			SelectProps={{ native: true }}
			value={props.GridsCfg.RoadGrids[index].NumberOfLanes}
			variant="outlined"
		>
			{dropboxValues.map((option) => (
				<option key={option} value={option}>
					{option}
				</option>
			))}
		</TextField>
	);
}

export default NumLanes;