import React, { Component } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import { connect } from 'react-redux';
import {
    Card,
    CardContent,
    Divider,
    Paper,
    Tabs,
    Tab,
    FormGroup,
    FormControl,
    makeStyles,
    Button,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Typography,
    Slider,
    Switch,
    MenuItem,
    Select,
    TextField,
    MenuList,
    Tooltip,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PhotometricAttributeMenu from './photometice-attributes-menu/photometric-attribute-menu.component';
import Notification from '../components/shared/notification.component';
import searchActions from '../store/actions/searchActions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    },
    formControl: {
        width: '230px',
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formGroup: {
        margin: theme.spacing(1),
    },
    formLabel: {
        color: 'darkblue',
        marginBottom: '0px'
    },
    paper: {
        boxShadow: '0 0 0 rgb(63 63 68 / 5%), 0 1px 2px rgb(63 63 68 / 15%)'
    },
    formGroupSlider: {
        marginRight: '10px',
        marginLeft: '10px'
    }

}));

const initialState = {
    //spektd options
    //light
    lightSource: 'any',
    manufacturer: 'any',
    lampBase: 'any',
    cri: [0, 100],
    r9: [0, 100],
    macAdamStep: 'any',
    direction: 'any',
    beamAngle: [0, 360],
    cctOptions: 'any',
    cct: [0, 10000],
    minLor: 0,
    opticalAccessories: 'any',
    lampCount: 0,
    flux: [0, 200000],
    //physical
    finish: 'any',
    housingMaterial: 'any',
    adjustableTilt: 'any',
    adjustableRotation: 'any',
    shape: 'any',
    height: [0, 400],
    length: [0, 3000],
    width: [0, 3000],
    installation: 'any',
    placement: 'any',
    weight: [0, 10],
    //electrical
    voltageType: 'any',
    voltage: [0, 500],
    wattage: [0, 500],
    mA: [0, 1500],
    luminWatt: [0, 500],
    driverType: 'any',
    control: 'any',
    applianceClass: 0,
    //protection
    ip: 0,
    ik: 0,
    operatingTemp: [-30, 100],
    emergencyLight: 'any',
    antiSlip: 'any',
    designLabel: 'any',
    //integration
    daylightSensors: 'any',
    motionSensors: 'any',
    presenceSensors: 'any',
    nema3: 'any',
    nema7: 'any',
    zhagaBook18: 'any',
    photocell: 'any',
    clo: 'any',
    //environmental
    tm66: 0,
    envCert: 'any',
    brands: 'any',
};

const PhotometricStreetlightSearch = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [searchParams, setSearchParams] = React.useState(initialState);
    const buttonRef = React.useRef(null);
    const [disableSearchButton, setDisableSearchButton] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false
    });

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const onCriChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            cri: newValue
        }));
    };

    const onR9Change = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            r9: newValue
        }));
    };

    const onFluxChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            flux: newValue
        }));
    };

    const onCctChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            cct: newValue
        }));
    };

    const onHeightChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            height: newValue
        }));
    };

    const onLengthChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            length: newValue
        }));
    };

    const onWidthChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            width: newValue
        }));
    };

    const onWeightChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            weight: newValue
        }));
    };

    const onVoltageChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            voltage: newValue
        }));
    };

    const onWattageChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            wattage: newValue
        }));
    };
    const onMAChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            mA: newValue
        }));
    };
    const onLuminWattChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            luminWatt: newValue
        }));
    };
    const onOperatingTempChange = (event, newValue) => {
        setSearchParams(prevState => ({
            ...prevState,
            operatingTemp: newValue
        }));
    };

    const onSetSearchParams = (e, object) => {
        const { name, value } = e.target;
        console.log("name, value", name, value);
        if (e.target.type === "checkbox" && object === "brands") {
            setSearchParams(prevState => ({
                ...prevState,
                brands: { ...prevState.brands, [name]: e.target.checked }
            }));
        } else {
            console.log("setting basic name");
            setSearchParams(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const doSearch = (event) => {
        setSnack({
            open: true,
            message: 'Searching...',
            severity: 'info'
        });
        dispatch(searchActions.UpdateCondition({ load: true }));
        setDisableSearchButton(true);
        dispatch(user.UpdateUserCfg({ searchedLuminaires: [] }));
        EdgeOrREST(null, 'SEARCH_LUMINAIRES', onSearchLuminaires, searchParams);
    };

    const onSearchLuminaires = (response) => {
        console.log("on search luminaires");
        console.log("response", response);
        if (response && response.data) {
            dispatch(user.UpdateUserCfg({ searchedLuminaires: response.data }));
            setSnack({
                open: true,
                message: 'Search completed. ' + response.data.length + ' luminaire(s) found.',
                severity: 'success'
            });
        }
        dispatch(searchActions.UpdateCondition({ load: false }));
        setDisableSearchButton(false);
    }

    function selectItem(title, id, labelId, name, value, onSetSearchParams, itemsValue, selectOptions) {
        return (
            <FormControl className={classes.formControl} size="small">
                <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                    {title}
                </Typography>
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    onChange={onSetSearchParams}
                    name={name}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: 'auto',
                            },
                        },
                    }}
                >
                    {itemsValue.map((item, id) => {
                        return (
                            <MenuItem value={item}><Typography >{selectOptions[id]}</Typography ></MenuItem>
                        )

                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <Divider />
            <CardContent>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={doSearch}
                    style={{ float: 'right' }}
                    disabled={disableSearchButton}
                >
                    <KeyboardArrowRightIcon fontSize="small" /> Search
                </Button>

                <div ref={buttonRef} style={{ width: '136px' }} />
                {PhotometricAttributeMenu({
                    props, category: 'Light', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>

                                {selectItem("CCT options", "cctOptions", "cctOptions", "cctOptions", searchParams.cctOptions, onSetSearchParams,
                                    ["any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"],
                                    ["Any", "Fixed white", "Dynamic white", "Colour change", "Warm dimming"])}
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        CCT
                                    </Typography>
                                    <Slider
                                        value={[searchParams.cct[0], searchParams.cct[1]]}
                                        onChange={onCctChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        color='primary'
                                        step={100}
                                        min={0}
                                        max={10000}
                                        marks={[{ value: 0, label: '0K' }, { value: 10000, label: '10000K' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        Flux
                                    </Typography>
                                    <Slider
                                        value={[searchParams.flux[0], searchParams.flux[1]]}
                                        onChange={onFluxChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        color='primary'
                                        step={100}
                                        min={0}
                                        max={200000}
                                        marks={[{ value: 0, label: '0K' }, { value: 200000, label: '200000K' }]}
                                    />
                                </FormControl>
                            </FormGroup>
                        </>
                })}

                {PhotometricAttributeMenu({
                    props, category: 'Physical', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Height(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.height[0], searchParams.height[1]]}
                                        onChange={onHeightChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='height'
                                        id="height"
                                        step={10}
                                        min={0}
                                        max={400}
                                        marks={[{ value: 0, label: '0' }, { value: 400, label: '400' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Length(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.length[0], searchParams.length[1]]}
                                        onChange={onLengthChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='length'
                                        step={50}
                                        min={0}
                                        max={3000}
                                        marks={[{ value: 0, label: '0' }, { value: 3000, label: '3000' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Width(mm)"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.width[0], searchParams.width[1]]}
                                        onChange={onWidthChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='width'
                                        step={10}
                                        min={0}
                                        max={3000}
                                        marks={[{ value: 0, label: '0' }, { value: 3000, label: '3000' }]}
                                    />
                                </FormControl>
                            </FormGroup>
                        </>
                })}

                {PhotometricAttributeMenu({
                    props, category: 'Electrical', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row >
                                <FormControl className={classes.formControl} size="small">
                                    <Typography className={classes.formLabel} gutterBottom>Voltage Type</Typography>
                                    <RadioGroup aria-label="voltageType" name="voltageType" id='voltageType' value={searchParams.voltageType}
                                        row onChange={onSetSearchParams}>
                                        <FormControlLabel
                                            value="any"
                                            control={<Radio color='primary' size='small' />}
                                            label="Any"
                                        />
                                        <FormControlLabel
                                            value="AC"
                                            control={<Radio color='primary' size='small' />}
                                            label="AC"
                                        />
                                        <FormControlLabel
                                            value="DC"
                                            control={<Radio color='primary' size='small' />}
                                            label="DC"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Voltage"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.voltage[0], searchParams.voltage[1]]}
                                        onChange={onVoltageChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='voltage'
                                        step={2}
                                        min={0}
                                        max={500}
                                        marks={[{ value: 0, label: '0' }, { value: 500, label: '500' }]}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} size="small">
                                    <Typography id="range-slider" className={classes.formLabel} gutterBottom>
                                        {"Luminaire Wattage"}
                                    </Typography>
                                    <Slider
                                        value={[searchParams.wattage[0], searchParams.wattage[1]]}
                                        onChange={onWattageChange}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        name='wattage'
                                        step={10}
                                        min={0}
                                        max={500}
                                        marks={[{ value: 0, label: '0' }, { value: 500, label: '500' }]}
                                    />
                                </FormControl>
                            </FormGroup>
                        </>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Protection', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row >
                                {selectItem("Min IP Rating", "ip", "ip", "ip", searchParams.ip, onSetSearchParams,
                                    [0, 20, 23, 44, 54, 65, 66, 67, 68],
                                    ["Any", "IP 20", "IP 23", "IP 44", "IP 54", "IP 65", "IP 66", "IP 67", "IP 68"])}
                                {selectItem("Min IK Rating", "ik", "ik", "ik", searchParams.ik, onSetSearchParams,
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                    ["Any", "IK 01", "IK 02", "IK 03", "IK 04", "IK 05", "IK 06", "IK 07", "IK 08", "IK 09", "IK 10"])}
                            </FormGroup>
                        </>
                })}
                {PhotometricAttributeMenu({
                    props, category: 'Integration', buttonRef: buttonRef, menuList:
                        <>
                            <FormGroup className={classes.formGroup + " MuiFormGroup-options"} row>
                                {selectItem("Daylight sensors", "daylightSensors", "daylightSensors", "daylightSensors", searchParams.daylightSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Motion sensors", "motionSensors", "motionSensors", "motionSensors", searchParams.motionSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Presence sensors", "presenceSensors", "presenceSensors", "presenceSensors", searchParams.presenceSensors, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("NEMA 3", "nema3", "nema3", "nema3", searchParams.nema3, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("NEMA 7", "nema7", "nema7", "nema7", searchParams.nema7, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Zhaga Book 18", "zhagaBook18", "zhagaBook18", "zhagaBook18", searchParams.zhagaBook18, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("Photocell", "photocell", "photocell", "photocell", searchParams.photocell, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                                {selectItem("CLO", "clo", "clo", "clo", searchParams.clo, onSetSearchParams,
                                    ["any", "Yes", "No"], ["Any", "Yes", "No"])}
                            </FormGroup>
                        </>
                })}
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setSearchParams(initialState)}
                    style={{ float: 'right', margin: '10px' }}
                >
                    <RestartAltIcon fontSize="small" /> Reset
                </Button>
            </CardContent>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Card>
    );
}
export default PhotometricStreetlightSearch; 