import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    AreaChart,
    XAxis,
    YAxis,
    Area,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';

const numberFormatter = item => item.toFixed(2);

const Cartesian = () => {

    const props = useSelector(state => state);
    const [array, setArray] = useState({0:[], 90:[]});
    let A = array[0];
    let B = array[90];
    let data = [];
    if (A != undefined && A.length > 0) {
        A.forEach((x, i) => {
            if (i >= 90 && i < 180)
                data[i - 90] = { "A": A[i + 180], "B": B[i + 180], "angle": i - 180 };
            else if (i >= 180 && i < 270)
                data[i - 90] = { "A": A[i - 180], "B": B[i - 180], "angle": i - 180 };
        });
    }


    const RenderChart = () => {
        if (A != undefined && A.length > 0) return (
            <ResponsiveContainer width="100%" height={500}>
                <AreaChart id="cartesianChart" data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" dataKey="angle" ticks={[-90, -60, -30, 0, 30, 60, 90]} />
                    <YAxis />
                    <Tooltip formatter={numberFormatter} />
                    <Area name="270/90" dataKey="A" stroke="#8884d8" fillOpacity={0} />
                    <Area name="180/0" dataKey="B" stroke="#ff0000" fillOpacity={0} />
                    <Legend />
                </AreaChart>
            </ResponsiveContainer>
        )
        else return <>/</>;
    }

    const handlePlot = useCallback((response) => {
        if (response?.data != null && response.status == 200) {
            setArray(response.data);
        }
    }, []);

    useEffect(() => {
        if (array.length == 0 || props.LuminaireCfg.selectedLuminaireIds) {
            EdgeOrREST(null, "GET_POLAR", handlePlot, API_OBJECTS.getCalculationObject(props, null, null));
        }
    }, [array.length, handlePlot, props]);


    return (
        <RenderChart />
    )
}

export default Cartesian;