import React from 'react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
export default function MaskEditor(props) {

    return (
        <List>
            {props.masks.map(
                (mask, index) => {
                    return (
                        <ListItem key={index} onClick={() => props.selectMask(index)} style={mask.selected ? { border: "2px solid darkturquoise " } : {}}>
                            <ListItemText secondary={"Type: " + mask.type} primary={"Mask #" + (1 + index)} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => props.deleteMask(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                }
            )}
        </List>
    );

} 