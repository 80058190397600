import React, { useEffect, useContext } from 'react';
import Page from '../../components/Page';
import optLogo from "../../IMG/Logos/avatar.ico";
import automateLogo from "../../IMG/Automate-min.svg";
import manageLogo from "../../IMG/Manage-min.svg";
import lrLogo from "../../IMG/Logos/R8962 Lighting Reality Logo_RGB.png";
import {
    Grid,
    makeStyles,
    Card,
    CardHeader,
    Divider,
    IconButton,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import LauncherItem from './launcher-item.component';
import LauncherNews from './launcher-news.component';
import EdgeOrREST from '../../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import user from '../../store/actions/user';
import InputIcon from '@material-ui/icons/Input';
import { AuthContext } from '../../auth-provider.component';
import { useNavigate } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import luminaireActions from '../../store/actions/luminaireActions';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import columnActions from '../../store/actions/columnActions';
import userAction from '../../store/actions/user';
import { initialState } from '../../store/initial-state';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '98vh',
        maxHeight: '98vh',
        maxWidth: '98vw',
        minWidth: '98vw',
        position: 'relative',
        margin: 'auto'
    }
}));


const Launcher = (props) => {
    const { logout, userId, companyId } = useContext(AuthContext);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onGetProducts = (response) => {
        console.log("products", response);
        if (response.data) {
            console.log("dispatching products", response.data);

            dispatch(user.UpdateUserCfg({
                ...state.user,
                products: response.data
            }));
        }
    }

    const onGetRoles = (response) => {
        if (response.data) {
            console.log("roles", response.data);
            dispatch(user.UpdateUserCfg({ roles: response.data }));
        }
    }

    useEffect(() => {
        if (userId != null & userId != 0 && companyId && state.user && !(state.user.products)) {
            console.log("authProv", userId);
            console.log("authProv", companyId);
            EdgeOrREST(null, 'GET_ROLES', onGetRoles, { headers: { 'id': userId }, props: props });
            EdgeOrREST(null, 'GET_PRODUCTS', onGetProducts, { headers: { 'id': companyId }, props: props });
        }
    }, [userId, companyId]);

    useEffect(() => {
        if (window.localStorage.getItem('token')) {
            EdgeOrREST(null, 'CHECK_TOKEN', onCheckToken, props);
        } else {
            navigate('/login');
        }
    }, []);


    const onCheckToken = (response) => {
        if (!response.data) {
            navigate('/login');
        }
    }

    function createProductRedirects() {
        var optimize = false;
        var automate = false;
        var manage = false;
        var plan = false;
        var design = false;
        var select = false;
        var search = false;

        var launcherItems = [];
        if (state.user.products) {
            for (var i = 0; i < state.user.products.length; i++) {
                if (state.user.products[i].name === "Optimize") {
                    optimize = true;
                }
                if (state.user.products[i].name === "Automate") {
                    automate = true;
                }
                if (state.user.products[i].name === "Manage") {
                    manage = true;
                }
                if (state.user.products[i].name === "Plan") {
                    plan = true;
                }
                if (state.user.products[i].name === "Design") {
                    design = true;
                }
                if (state.user.products[i].name === "Select") {
                    select = true;
                }
                if (state.user.products[i].name === "Search") {
                    search = true;
                }
            }
        }

        if (optimize) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={optimize} logo={optLogo} redirect="/optimize/roadway" title="Optimize" subTitle="“to make something as good as possible”"
                        description="Replacement for existing Roadway product, which will be capable of running as a web application or
                    as a desktop application. Includes multi-variable optimisation. "></LauncherItem>
                </Grid>
            );
        }
        if (automate) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={automate} logo={automateLogo} redirect="/automate/upload" title="Automate" subTitle="“an advantage or good quality that something has”"
                        description="Enhanced version of Optimize with capability of ACE for bulk cloud computing calculation facility"></LauncherItem>
                </Grid>
            );
        }
        if (manage) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={manage} logo={manageLogo} redirect="/photometry/search" title="Manage" subTitle="“to control or organize someone or something”"
                        description="Photometric data manager, links to the full Lighting Reality Cloud photometry database. Data
                    partner version will allow data partners to publish & update their own data directly."></LauncherItem>
                </Grid>
            );
        }
        if (plan) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={plan} logo={optLogo} redirect="/plan" title="Plan" subTitle="“to think about and decide what you are going to do or how you are going to do something”"
                        description="Basic version of Outdoor/Area product, which will import pdf files only (not AutoCad), be limited to
                one grid, one layer and probably other restrictions (number of luminaires). Will not support street
                lighting standards or features (maybe Glare), but will be ideally suited to simple outdoor area
                lighting designs, such as car parks, sports areas etc."></LauncherItem>
                </Grid>
            );
        }
        if (design) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={design} logo={optLogo} redirect="/launcher" title="Design" subTitle="“to make or draw plans for something, for example clothes or buildings”"
                        description="Full version of the Outdoor/Area replacement, with support for full AutoCad .dwg import, multiple
                grids, layers and street lighting standards."></LauncherItem>
                </Grid>
            );
        }
        if (select) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={select} logo={optLogo} redirect="/launcher" title="Select" subTitle="“to choose a small number of things, or to choose by making careful decisions”"
                        description="A restricted version of Optimize sold to Data Partners (typically manufacturers). Acts as an online
                catalogue which allows customers to enter road/street configuration and return best fit product(s).
                Will be Web only and will not allow import of external data, or multi-variable optimize. Might also
                limit other customisations.
                Product can be branded with the data partners logo/colours and show “powered by Lighting Reality”"
                    ></LauncherItem>
                </Grid>
            );
        }
        if (search) {
            launcherItems.push(
                <Grid item md={1} lg={1} style={{ width: '50%', height: '10%' }}>
                    <LauncherItem active={search} logo={optLogo} redirect="/search/luminaire" title="Search" subTitle="“”"
                        description=""
                    ></LauncherItem>
                </Grid>
            );
        }
        return launcherItems;
    }
    function doSignOut() {
        EdgeOrREST(null, 'SIGN_OUT', onSignOut, null);
    }

    const onSignOut = (response) => {
        if (window.localStorage.getItem("remember")) {
            console.log("remember", true);
            window.localStorage.removeItem("remember");
            window.localStorage.removeItem("token");
        } else {
            console.log("remember", false);
            window.localStorage.clear();
        }
        var resetState = initialState;
        resetState.LuminaireCfg.selectedFolderIds = [];
        resetState.LuminaireCfg.selectedLuminaireIds = [];
        resetState.user.searchedLuminaires = [];
        dispatch(luminaireActions.UpdateLuminCfg(resetState.LuminaireCfg));
        dispatch(gridActions.UpdateGridsCfg(resetState.GridsCfg));
        dispatch(columnActions.UpdateColumnCfg(resetState.ColumnCfg));
        dispatch(roadActions.AddToCurrentState(resetState.ProjectConfig));
        dispatch(roadActions.UpdateStandardsCfg(resetState.ProjectConfig.StandardsCfg));
        dispatch(userAction.UpdateUserCfg(resetState.user));
        logout();
    }

    return (
        <Page title="Launcher" style={{ background: 'white', height: '99vh' }}>
            <Card style={{ overflowY: 'auto', 'height': '100%', 'margin': '5px', 'border': '1px solid navy', background: 'whitesmoke' }}>
                <CardHeader style={{ background: 'white' }} title={<img style={{ "height": "45px" }} src={lrLogo} />} action={
                    <>
                        <IconButton style={{ color: '#4e92b8' }} title="notifications"
                            onClick={handleClickOpen}
                        >
                            <NotificationsActiveIcon />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Introducing the New LR Studio</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    The new release introduces several new features and improvements to our product, including a redesigned user interface. We’ve also made other minor changes to improve the overall user experience.
                                    <p />
                                    <h6 style={{ fontWeight: 'bold' }}>General</h6>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Logout</a> - You can logout at launcher by clicking top right button now.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Update Password</a> - You can update your password in update password page.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Support Request</a> - You can send support request to us with files or photo now.</li>
                                    <li>Update <a style={{ fontWeight: 'bold' }}>Navigation and Setting</a> - We change the setting from the left to the right side of top bar.  We also move navigation bar and logout into the menu at the top.</li>
                                    <p />
                                    <h6 style={{ fontWeight: 'bold' }}>Optimize</h6>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Priority Grid</a> - Optimize for a specific grid with the priority grid checkbox, and get results for all grids including grids with a different calculation type.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Configurations</a> - You can now save you configurations with description and also export or import the config files as LR Studio files!</li>
                                    <li>Update <a style={{ fontWeight: 'bold' }}>Navigation</a> - We remove left side navigation bar and make it as Tab on the left column. We also rename the tab to match what it does.</li>
                                    <li>Added table of multiple grid quality figures.</li>
                                    <p />
                                    <h6 style={{ fontWeight: 'bold' }}>Automate</h6>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Job Grid Pagination</a> - 50 jobs display at once, switch pages by Pagination.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Delete Job Button</a> - You can now delete jobs data that you have uploaded.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Jobs Configuration Submit Dialog Box</a> - Display calculations count before submitting jobs.</li>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Searching jobs by name and date</a> - The jobs can be searched by name and/or in a certain time period.</li>
                                    <li>Improvements - The Jobs' start date change to the time created the job.</li>
                                    <li>The Excel template is updated.</li>
                                    <p />
                                    <h6 style={{ fontWeight: 'bold' }}>Manage</h6>
                                    <li>New feature <a style={{ fontWeight: 'bold' }}>Delete Luminaires</a> - You can now delete photometric data that you have uploaded.</li>
                                    <li>Update <a style={{ fontWeight: 'bold' }}>Navigation</a> - We remove left side navigation bar and make it as Tab on the left column. We also rename the tab to match what it does.</li>
                                    <li>Improvements - Select all button adding luminaires to groups.</li>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" autoFocus>
                                    Noted!
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <IconButton color="inherit" title="Sign Out"
                            onClick={doSignOut}
                        >
                            <InputIcon />
                        </IconButton>
                    </>
                } ></CardHeader>

                <Divider />

                <CardContent>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                        {createProductRedirects()}
                    </Grid>

                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                        <Grid item sm={12} md={6} lg={6}>
                            <LauncherNews></LauncherNews>
                        </Grid>
                        <Grid item sm={12} md={3} lg={3} style={{ height: '90%' }}>
                            <Card>
                                <CardHeader title={<h5> Contact </h5>}   ></CardHeader>
                                <CardContent  >
                                    Lighting Reality Ltd<br />
                                    The Forum<br />
                                    Worcester Road<br />
                                    Stourport-on-Severn<br />
                                    Worcestershire<br />
                                    DY13 9BZ<br />
                                    UK<br />

                                    Tel:  +44 (0)1299 382100<br />

                                    sales@lightingreality.com
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Page>
    );

}

export default Launcher;
