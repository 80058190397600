import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import IconButton from '@material-ui/core/IconButton';
import Add from "@mui/icons-material/AddRoad";
import Minus from "@mui/icons-material/RemoveRoad";
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ColumnLuminTabs from './ColumnLuminTabs.component';
import cloneDeep from "lodash/cloneDeep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import columnActions from '../../store/actions/columnActions';
import luminActions from '../../store/actions/luminaireActions';
import EdgeOrREST from '../../EdgeOrREST';

const mapDispatchToProps = (dispatch) => {
	const Luminaire = bindActionCreators(luminActions, dispatch);
	const Column = bindActionCreators(columnActions, dispatch);
	return {
		UpdateColumnCfg: (data) => {
			Column.UpdateColumnCfg(data);
		},
		FillGridResults: (data) => {
			Luminaire.UpdateAllResults(data);
		},
		UpdateLuminCfg: (data) => {
			Luminaire.UpdateLuminCfg(data);
		},
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={0}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
	tab: {
		minWidth: 50, // a number of your choice
		width: 75, // a number of your choice
	},
	icon: {
		alignItems: "end"
	}


}));

const ColumnTabs = ({ UpdateColumnCfg, FillGridResults, UpdateLuminCfg }) => {
	const theme = useTheme();
	const classes = useStyles();
	const [active, setActive] = React.useState(0);

	const reduxProps = useSelector(state => state);
	const tabList = reduxProps.ColumnCfg.Columns;

	const handleChange = (index) => {
		setActive(index);
		UpdateColumnCfg({ currentIndex: index });
	};

	const addTab = (e) => {
		let Columns = [...reduxProps.ColumnCfg.Columns];
		let id = Number.parseInt(e.currentTarget.id);
		if (id === 0) {
			Columns.unshift(Columns[0]);
		}
		else {
			Columns.push(Columns[id - 1]);
		}
		
		CalcGrid(Columns, active, true);
		UpdateColumnCfg({ ...reduxProps.ColumnCfg, Columns });
	}

	const deleteTab = (e, i) => {
		e.stopPropagation();
		let Columns = cloneDeep(reduxProps.ColumnCfg.Columns);
		let luminIds = reduxProps.LuminaireCfg.selectedLuminaireIds;
		let selectedLuminaireIds = [...luminIds];
		selectedLuminaireIds.splice(i, 1);
		
		let id = Number.parseInt(i);
		if (Columns.length === 1) return;
		let newList = Columns.filter((v, index) => id !== index);
		if (active === id && active !== 0) {
			CalcGrid(newList, active - 1);
			setActive(active - 1);
		}
		else {
			CalcGrid(newList, active);
		}
		UpdateColumnCfg({ ...reduxProps.ColumnCfg, Columns: newList });
		UpdateLuminCfg({selectedLuminaireIds});
	}

	const CalcGrid = (Columns, index, lumId) => {
		let calcObj = reduxProps;
		calcObj.ColumnCfg.currentIndex = index;
		if (lumId) calcObj.LuminaireCfg.selectedLuminaireIds.push(calcObj.LuminaireCfg.selectedLuminaireIds[index]);
		EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, calcObj);
	}

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			let index = reduxProps.ColumnCfg.currentIndex;
			FillGridResults({
				index,
				gridResults
			});
		}
	}

	const onDragEnd = result => {
		if (!result.destination) return;
		if (result.destination.index === result.source.index) return;
		const Columns = cloneDeep(reduxProps.ColumnCfg.Columns);
		const [removed] = Columns.splice(result.source.index, 1);
		Columns.splice(result.destination.index, 0, removed);
		UpdateColumnCfg({ Columns });
		CalcGrid(Columns, active);
	};

	return (
		<Card>
			<AppBar position="relative" color="default">
				<Grid
					container
					alignItems="center"
					justify="center"
				>
					<Grid
						item
						xl={1}
						lg={1}
						md={1}
						sm={1}
						xs={1}
					>
						<IconButton
							id={active}
							onClick={addTab}
						>
							<Minus />
						</IconButton>
					</Grid>
					<Grid
						item
						xl={11}
						lg={11}
						md={11}
						sm={11}
						xs={11}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="tabs" direction="horizontal">
								{props => (
									<Tabs

										ref={props.innerRef}
										{...props.droppableProps}
										value={active}
										indicatorColor="primary"
										textColor="primary"
										variant="scrollable"
									>
										{tabList.map((tab, index) => (
											<Draggable
												key={index}
												draggableId={`id-${index}`} // must be a string
												index={index}
												disableInteractiveElementBlocking={true}
											>
												{dragProps => (
													<Tab
														classes={{ root: classes.tab }}
														ref={dragProps.innerRef}
														{...dragProps.draggableProps}
														{...dragProps.dragHandleProps}
														label={
															"Row " + parseInt(index + 1)
														}
														onClick={() => handleChange(index)}
													/>
												)}
											</Draggable>
										))}
										{props.placeholder}
									</Tabs>
								)}
							</Droppable>
						</DragDropContext>
					</Grid>
				</Grid>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={active}
				onChangeIndex={handleChange}
			>
				{tabList.map((tab, index) => (
					<TabPanel value={active} index={index} dir={theme.direction}>
						<ColumnLuminTabs
							ColumnCfg={reduxProps.ColumnCfg.Columns[index]}
							index={index}
							removeCard={deleteTab}
						/>
					</TabPanel>
				))}
			</SwipeableViews>
		</Card>
	);
}

export default connect(null, mapDispatchToProps)(ColumnTabs);