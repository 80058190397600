import React from 'react'; 
import { ReactComponent as RowIcon } from '../../../IMG/SelectLuminaire.svg';
import {
    AlertCircle as AlertCircleIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon, 
    UserPlus as UserPlusIcon,
    Users as UsersIcon
  } from 'react-feather'; 
 
import { ReactComponent as ManageIcon } from '../../../IMG/ManageEdit.svg';
import { ReactComponent as OptimizeIcon } from '../../../IMG/OptimizeEdit.svg';
import { 
    makeStyles
  } from '@material-ui/core';

const iconList = { 
    settingsIcon: SettingsIcon,
    rowIcon: RowIcon,
    optimizeIcon: OptimizeIcon,
    manageIcon: ManageIcon,
}

const useStyles = makeStyles((theme) => ({
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
  
      justifyContent: 'flex-start',
      letterSpacing: 0,
      padding: '10px 8px',
      textTransform: 'none',
      width: '100%'
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    title: {
      marginRight: 'auto'
    },
    active: {
      color: theme.palette.primary.main,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      },
      '& $icon': {
        // color: theme.palette.primary.main
        color: 'white'
      },
      '& $i': {
        // color: theme.palette.primary.main
        color: 'white'
      },
      backgroundColor: theme.palette.primary.main
    }
  }));
 
export default function CustomIcon(props) {
    const Component = iconList[props.icon];
    const classes = useStyles();

    return (
          <Component className={classes.icon} {...props} />
    );
}
 