import { createAction } from 'redux-actions';

export default {
	UpdateLuminCfg: createAction('UPDATE_LUMIN_CFG'),
	UpdateGridResults: createAction('UPDATE_GRID_RESULTS'),
	UpdateAllResults: createAction('UPDATE_ALL_RESULTS'),
	UpdateSelectedLuminaires: createAction('UPDATE_SELECTED_LUMINAIRES'),
	UpdateLuminaireList: createAction('UPDATE_LUMINAIRE_LIST'),
	UpdateGroupResults: createAction('UPDATE_GROUP_RESULTS'),
	UpdateFolderResultsToCanvas: createAction('UPDATE_FOLDER_RESULTS_TO_CANVAS'),
	ClearGroupResults: createAction('CLEAR_GROUP_RESULTS'),
};
