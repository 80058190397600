import React, { useEffect } from 'react';
import { Stage, Layer, Shape, Image, Rect, Line } from "react-konva";
import {
  IconButton,
  Grid,
  TextField,
  Tooltip,
  Box,
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  InputLabel,
  FormControlLabel,
  makeStyles,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import GestureIcon from '@material-ui/icons/Gesture';
import TimelineIcon from '@material-ui/icons/Timeline';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HeightIcon from '@material-ui/icons/Height';
import GridOnIcon from '@material-ui/icons/GridOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import circlerightradius from './images/arrow-circle.png';
import arrowcircle from './images/arrow-circle.png';
import Column from './column.component';
import Mask from './mask.component';
import CanvasGrid from './grid.component';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RectangleIcon from '@mui/icons-material/Rectangle';
import LuminCard from '../components/Column/LuminCard';
import { useSelector } from 'react-redux';
import EdgeOrREST from '../EdgeOrREST';
import { API_OBJECTS } from '../API/model/calculation.model';
import * as d3 from 'd3';
import ColumnEditor from './column-editor.component';
import MaskEditor from './mask-editor.component';
import GridEditor from './grid-editor.component';
import Konva from 'konva';
import CustomMask from './custom-mask.component';

function transformAsImage(widthScale, heightScale, angle) {
  var rad = angle * Math.PI / 180;
  var cos = Math.cos(rad);
  var sin = Math.sin(rad);

  return d3.geoTransform({
    point: function (x, y) {
      var xRotated = x * cos - y * sin; var yRotated = x * sin + y * cos;
      this.stream.point((xRotated * widthScale), (yRotated * heightScale));

    }
  });
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    position: 'relative',
    '& .MuiDrawer-paper': {
      top: '64px',
      height: `calc(100% - 64px)`,
    },
  },
}));

export default function PlanDashboard() {
  const state = useSelector(state => state);
  const classes = useStyles();

  const [showContours, setShowContours] = React.useState(false);
  const contourGridLayerRef = React.useRef();
  const gridTrRef = React.useRef();
  const [gridSelected, setGridSelected] = React.useState(false);

  const imageRef = React.useRef();
  const imageLayerRef = React.useRef();
  const stageRef = React.useRef();
  const contourImageRef = React.useRef();
  const contourLayerRef = React.useRef();
  const contourShapeRef = React.useRef();
  const planRef = React.useRef();

  const [lines, setLines] = React.useState([]);
  const [poly, setCurrentPoly] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [imagePixelHeight, setImagePixelHeight] = React.useState(0);
  const [imagePixelWidth, setImagePixelWidth] = React.useState(0);

  const [newGridXStart, setNewGridXStart] = React.useState(0);
  const [newGridYStart, setNewGridYStart] = React.useState(0);
  const [newGridHeight, setNewGridHeight] = React.useState(0);
  const [newGridWidth, setNewGridWidth] = React.useState(0);

  const [throttleSceneFunc, setThrottleSceneFunc] = React.useState(true);
  const [realWorldHeight, setRealWorldHeight] = React.useState(500);
  const [realWorldWidth, setRealWorldWidth] = React.useState(800);
  const [gridScaleX, setGridScaleX] = React.useState(1);
  const [gridScaleY, setGridScaleY] = React.useState(1);
  const [metresPerPixelX, setMetresPerPixelX] = React.useState(1);
  const [metresPerPixelY, setMetresPerPixelY] = React.useState(1);
  const [contourImage, setContourImage] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [maskMode, setMaskMode] = React.useState(false);
  const [masks, setMasks] = React.useState([]);
  const [stage, setStage] = React.useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const [areaResults, setAreaResults] = React.useState([]);
  const [contourGridResults, setContourGridResults] = React.useState([]);
  const [visualGrid, setVisualGrid] = React.useState({
    x: 0,
    y: 0,
    height: 100,
    width: 100
  })


  const [grids, setGrids] = React.useState([{
    selected: false,
    angle: 0,
    edgeStart: "False",
    id: 0,
    maskAll: "False",
    maskingLayer: "0",
    obsAngle: 0,
    obsHeight: 1.5,
    xSpacing: 1.5,
    ySpacing: 1.5,
    origin:
    {
      x: {
        start: 0,
        length: 25
      },
      y: {
        start: 0,
        length: 25
      },
      z: {
        start: 0,
        length: 0
      }
    },
    maxSpacing: 1.5,
    type: "Horizontal",
    verticalGridSide: "False"
  }]);


  const drawContours = (context) => {
    if (contourGridResults && contourGridResults.length > 0) {
      setThrottleSceneFunc(true);
      var gridLayerWidth = contourGridLayerRef.current.attrs.width;
      var gridLayerHeight = contourGridLayerRef.current.attrs.height;
      console.log("gridLayerWidth, gridLayerHeight", gridLayerWidth, gridLayerHeight);

      var data = contourGridResults;

      for (let j = 0; j < data.length; j++) { data[j] = parseFloat(data[j]); }
      var dataMax = Math.max(...data);
      var lineColor = d3.scaleSequential(d3.interpolateSpectral).domain([dataMax, 0]);
      var xPoints;
      var yPoints;
      if (realWorldHeight == realWorldWidth) {
        //square
        xPoints = Math.sqrt(contourGridResults.length);
        yPoints = data.length / xPoints;
      } else {
        xPoints = grids[0].origin.x.length / grids[0].xSpacing;
        yPoints = grids[0].origin.y.length / grids[0].ySpacing;
      }

      var widthScale = gridLayerWidth / xPoints;
      var heightScale = gridLayerHeight / yPoints;

      var contours = d3.contours().size([xPoints, yPoints]);
      var cntrs = contours.thresholds(d3.range(0, dataMax, (dataMax / 10)))(data);
      var projection = transformAsImage(widthScale, heightScale, 0);

      const offscreenCanvas = document.createElement('canvas');
      offscreenCanvas.width = gridLayerWidth;
      offscreenCanvas.height = gridLayerHeight;
      const offscreenContext = offscreenCanvas.getContext('2d');

      const path = d3.geoPath(projection, offscreenContext);
      for (let i = 0; i < cntrs.length; i++) {
        if (cntrs[i].coordinates.length == 0) continue;
        offscreenContext.beginPath();
        path(cntrs[i]);
        offscreenContext.lineWidth = 1;
        offscreenContext.strokeStyle = lineColor(cntrs[i].value);
        offscreenContext.stroke();
      }

      const dataURL = offscreenCanvas.toDataURL();
      const newImg = new window.Image();
      newImg.onload = () => {
        setContourImage(newImg);
      };
      newImg.src = dataURL;
    }
  }


  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const relatedTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };

    setStage({
      scale: newScale,
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale
    });
  };

  const onSetHeight = (e) => {
    console.log("called onSetHeight")
    setRealWorldHeight(e.target.value);
    if (image) {
      setGridScaleY(image.naturalHeight / e.target.value);
      setGridScaleX(image.naturalWidth / realWorldWidth);
      console.log("img.naturalHeight, img.naturalWidth, realWorldHeight, realWorldWidth", image.naturalHeight, image.naturalWidth, realWorldHeight, realWorldWidth);
    }

  }

  const onSetWidth = (e) => {
    console.log("called onSetWidth")
    setRealWorldWidth(e.target.value);

    if (image) {
      setGridScaleY(image.naturalHeight / realWorldHeight);
      setGridScaleX(image.naturalWidth / e.target.value);
      console.log("img.naturalHeight, img.naturalWidth, realWorldHeight, realWorldWidth", image.naturalHeight, image.naturalWidth, realWorldHeight, realWorldWidth);

    }
  }

  const onSetNewGridWidth = (e) => {
    setNewGridWidth(e.target.value);
  }
  const onSetNewGridHeight = (e) => {
    setNewGridHeight(e.target.value);
  }
  const onSetNewGridXStart = (e) => {
    setNewGridXStart(e.target.value);
  }
  const onSetNewGridYStart = (e) => {
    setNewGridYStart(e.target.value);
  }

  const onSetImage = (e) => {
    console.log("called onSetImage")
    const img = new window.Image();
    img.src = URL.createObjectURL(e.target.files[0]);
    img.crossOrigin = "Anonymous";

    img.onload = function () {
      console.log("img.naturalHeight, img.naturalWidth, realWorldHeight, realWorldWidth", img.naturalHeight, img.naturalWidth, realWorldHeight, realWorldWidth);
      setGridScaleY(img.naturalHeight / realWorldHeight);
      setGridScaleX(img.naturalWidth / realWorldWidth);
      setImagePixelHeight(img.height);
      setImagePixelWidth(img.width);
      setImage(img);


      var tempGridStr = JSON.stringify(grids);
      var tempGrid = JSON.parse(tempGridStr);
      tempGrid[0].origin.x.length = realWorldWidth;
      tempGrid[0].origin.y.length = realWorldHeight;
      setGrids(tempGrid);
      //grid is being set to the same size as the image 
      setVisualGrid({
        x: grids[0].origin.x.start * (img.naturalWidth / realWorldWidth),
        y: grids[0].origin.y.start * (img.naturalHeight / realWorldHeight),
        width: realWorldWidth * (img.naturalWidth / realWorldWidth),
        height: realWorldHeight * (img.naturalHeight / realWorldHeight)
      });
    }
  }

  const doAreaCalc = (e) => {

    if (columns.length > 0 && image != null && state.LuminaireCfg.selectedLuminaireIds != null && state.LuminaireCfg.selectedLuminaireIds.length > 0) {
      var scaleX = contourGridLayerRef.current.attrs.width / realWorldWidth;
      var scaleY = contourGridLayerRef.current.attrs.height / realWorldHeight;

      var strGrids = JSON.stringify(grids);
      var tempGrids = JSON.parse(strGrids);
      for (var i = 0; i < tempGrids.length; i++) {
        var grid = tempGrids[i];
        var xSpacing = grid.xSpacing;
        var ySpacing = grid.ySpacing;
        var xPoints = Math.ceil(grid.origin.x.length / xSpacing);
        var yPoints = Math.ceil(grid.origin.y.length / ySpacing);

        while (xPoints * yPoints > 60000) {
          if (xPoints * yPoints > 60000) {
            xSpacing += 0.5;
            ySpacing += 0.5;
          }
          xPoints = Math.ceil(grid.origin.x.length / xSpacing);
          yPoints = Math.ceil(grid.origin.y.length / ySpacing);
        }


        grid.xSpacing = xSpacing;
        grid.ySpacing = ySpacing;
        grid.maxSpacing = Math.max(xSpacing, ySpacing);
      }
      setGrids(tempGrids);
      var calculationObject = API_OBJECTS.getCalculationObject(state, null, null);
      calculationObject["areaGrids"] = tempGrids;
      var lampPosts = [];
      for (var i = 0; i < columns.length; i++) {
        var jsontemp = JSON.stringify(columns[i].values);
        var tempValues = JSON.parse(jsontemp);
        console.log("columns[i + 1].values.point.x", columns[i].values.point.x);
        console.log("columns[i + 1].values.point.y", columns[i].values.point.y);
        tempValues.point.x = columns[i].values.point.x / scaleX;
        tempValues.point.y = columns[i].values.point.y / scaleY;
        lampPosts.push(tempValues);
      }
      calculationObject["areaColumns"] = lampPosts;
      //masks only contain polygon square and circles 
      var maskConversions = [];
      for (var i = 0; i < masks.length; i++) {
        var currMask = {};
        currMask.points = [];
        currMask.layer = i;
        for (var j = 0; j < masks[i].points.length; j++) {
          console.log("mask i points", masks[i].points);
          console.log("j", j);
          console.log("i", i);
          console.log("masks[i].points[j].x", masks[i].points[j].x);
          currMask.points.push({ x: masks[i].points[j].x / scaleX, y: masks[i].points[j].y / scaleY });
        }

        if (masks[i].type == "rectangle") {
          currMask.type = "polygon";
        } else if (masks[i].type == "circle") {
          currMask.type = "circle";
          currMask.radius = masks[i].radius / scaleX;
        }
        maskConversions.push(currMask);
      }
      calculationObject["masks"] = maskConversions;

      EdgeOrREST(null, "GET_AREA", onGetAreaCalc, calculationObject);
    }
  }

  const [startCalc, setStartCalc] = React.useState(false);

  useEffect(() => {
    doAreaCalc();
  }, [startCalc]);

  useEffect(() => {
    setThrottleSceneFunc(false);
  }, [contourGridResults]);

  const onGetAreaCalc = ((response) => {
    if (response && response.data) {

      for (var i = 0; i < response.data.length; i++) {

        var item = response.data[i];
        console.log("item", item)
        if (item.calcResults.outdoorResults[0].gridId == 0) setContourGridResults(item.calcResults.outdoorResults[0].contourResults);
        break;
      }
      setAreaResults(response.data);
    }

  });

  const updateGrid = (e, i, isTransform) => {
    console.log("updateGrid", e, i, isTransform);

    var tempGrids = JSON.parse(JSON.stringify(grids));
    var tempGrid = JSON.parse(JSON.stringify(grids[i]));

    var xScaler = realWorldWidth / (imagePixelWidth * stageRef.current.attrs.scaleX);
    var yScaler = realWorldHeight / (imagePixelHeight * stageRef.current.attrs.scaleY);
    if (isTransform) {
      tempGrid.origin.x.start = e.target.attrs.x * xScaler;
      tempGrid.origin.y.start = e.target.attrs.y * yScaler;
    } else {
      tempGrid.origin.x.start = e.currentTarget.attrs.x * xScaler;
      tempGrid.origin.y.start = e.currentTarget.attrs.y * yScaler;
    }
    setMetresPerPixelX(xScaler);
    setMetresPerPixelY(yScaler);
    console.log("updated grid", tempGrid);
    tempGrids[i] = tempGrid;
    setGrids(tempGrids);
  }

  const onSelectGrid = () => {
    if (!gridSelected && contourGridLayerRef.current) {
      gridTrRef.current.nodes([contourGridLayerRef.current]);
      gridTrRef.current.getLayer().batchDraw();
    }

    setGridSelected(!gridSelected);
  }

  const deleteGrid = (i) => {
    setGrids(grids.filter((item, index) => index !== i));
    setStartCalc(Date.now());
  }

  const selectGrid = (i) => {
    var temp = [...grids];
    temp[i].selected = !temp[i].selected;
    setGrids(temp);
  }

  const deleteColumn = (i) => {
    setColumns(columns.filter((item, index) => index !== i));
    setStartCalc(Date.now());
  }

  const selectColumn = (i) => {
    var tempColumns = [...columns];
    tempColumns[i].selected = !tempColumns[i].selected;
    setColumns(tempColumns);
  }

  const updateColumn = (e, i) => {
    var tempColumns = [...columns];
    tempColumns[i].values.point.x = e.currentTarget.attrs.x + e.currentTarget.attrs.image.width / 2;
    tempColumns[i].values.point.y = e.currentTarget.attrs.y + e.currentTarget.attrs.image.height / 2;
    setColumns(tempColumns);
    setStartCalc(Date.now());
  }

  const selectMask = (i) => {
    var tempMasks = [...masks];
    tempMasks[i].selected = !tempMasks[i].selected;
    setMasks(tempMasks);
  }

  const deleteMask = (i) => {
    setMasks(masks.filter((item, index) => index !== i));
    setStartCalc(Date.now());
  }

  const updateMask = (e, i, type) => {
    console.log("updateMask", e, i, type);

    var tempMasks = [...masks];
    var points = [];
    if (type == "rectangle") {
      var x = e.target.attrs.x;
      var y = e.target.attrs.y;
      var width = e.target.attrs.width;
      var height = e.target.attrs.height;
      points.push({ x: x, y: y });
      points.push({ x: x + width, y: y });
      points.push({ x: x + width, y: y + height });
      points.push({ x: x, y: y + height });
      points.push({ x: x, y: y });
    } else if (type == "circle") {
      points.push({ x: e.target.attrs.x, y: e.target.attrs.y });
      tempMasks[i].radius = e.target.attrs.radius;
    }
    tempMasks[i].points = points;
    setMasks(tempMasks);
    setStartCalc(Date.now());
  }

  const handleAddGrid = (e) => {
    e.preventDefault();
    console.log("called handleAddGrid", newGridWidth, newGridHeight, newGridXStart, newGridYStart);
    if (newGridWidth > 0 && newGridHeight > 0) {
      var newGrid = {
        selected: false,
        angle: 0,
        edgeStart: "False",
        id: grids.length,
        maskAll: "False",
        maskingLayer: "0",
        obsAngle: 0,
        obsHeight: 1.5,
        xSpacing: 1.5,
        ySpacing: 1.5,
        origin:
        {
          x: {
            start: newGridXStart,
            length: newGridWidth
          },
          y: {
            start: newGridYStart,
            length: newGridHeight
          },
          z: {
            start: 0,
            length: 0
          }
        },
        maxSpacing: 1.5,
        type: "Horizontal",
        verticalGridSide: "False"
      };
      var tempGrids = JSON.parse(JSON.stringify(grids));
      tempGrids.push(newGrid);
      setNewGridHeight(0);
      setNewGridWidth(0);
      setNewGridXStart(0);
      setNewGridYStart(0);
      setGrids(tempGrids);
    }
  }

  const handleAddColumn = (event, column) => {
    event.preventDefault();
    var newColumn = {};
    const image = new window.Image();
    image.src = column;
    image.crossOrigin = "Anonymous"
    image.width = 10;
    image.height = 10;
    var values = {
      point: {
        x: 10,
        y: 10
      },
      columnConfiguration: "Single Sided Left",
      dimFactor: 1,
      columnGrid: 0,
      height: 6,
      maintenanceFactor: 1,
      outreach: 0,
      cant: 0,
      tilt: 0,
      photometricGroup: 1
    }

    newColumn.image = image;
    newColumn.selected = false;
    newColumn.values = values;
    if (columns.length > 0) {
      let arr = [...columns];
      arr.push(newColumn);
      setColumns(arr);
    } else {
      var arr = [];
      arr.push(newColumn);
      setColumns(arr);
    }

  }

  const handleAddMask = (event, maskType) => {
    event.preventDefault();
    var newMask = {};
    newMask.type = maskType;
    newMask.points = [];
    if (masks.length > 0) {
      let arr = [...masks];
      arr.push(newMask);
      setMasks(arr);
    } else {
      var arr = [];
      arr.push(newMask);
      setMasks(arr);
    }
  }

  const [isDrawing, setIsDrawing] = React.useState(false);
  const [currentLine, setCurrentLine] = React.useState(null);

  const handleDrawLineMouseDown = (e) => {
    if (maskMode) {
      const pointX = (stageRef.current._changedPointerPositions[0].x - stageRef.current.attrs.x) / stageRef.current.attrs.scaleX;
      const pointY = (stageRef.current._changedPointerPositions[0].y - stageRef.current.attrs.y) / stageRef.current.attrs.scaleY;
      if (isDrawing) {
        let lastLine = lines[lines.length - 1];
        // Check if current mouse position is close to starting point
        const [startX, startY] = lastLine.points;
        if (Math.abs(pointX - startX) < 10 && Math.abs(pointY - startY) < 10) {
          // Close shape by adding starting point to end of points array
          lastLine.points = lastLine.points.concat([startX, startY]);
          lines.splice(lines.length - 1, 1, lastLine);
          setLines(lines.concat());
          setIsDrawing(false);
        } else {
          lastLine.points = lastLine.points.concat([pointX, pointY]);
          lines.splice(lines.length - 1, 1, lastLine);
          setLines(lines.concat());

        }
      } else {
        setLines([...lines, { points: [pointX, pointY] }]);
        setIsDrawing(true);
      }
    }
  };

  const [tabIndex, setTabIndex] = React.useState(0);
  const [leftDrawerOpen, setLeftDrawerOpen] = React.useState(true);
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState(true);
  const [uploadAreaOpen, setUploadAreaOpen] = React.useState(false);
  const [addGridOpen, setAddGridOpen] = React.useState(false);

  const toggleLeftDrawer = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };
  const toggleRightDrawer = () => {
    setRightDrawerOpen(!rightDrawerOpen);
  };

  return (
    <>
      {/* toolbar */}
      <Drawer
        anchor="left"
        open={leftDrawerOpen}
        onClose={toggleLeftDrawer}
        variant='persistent'
        className={classes.drawer}
      >
        <Box style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <Grid item={6}>
            <IconButton>
              <Tooltip title="Upload area image">
                <PhotoCamera onClick={(e) => setUploadAreaOpen(true)} />
              </Tooltip>
            </IconButton>
            <Draggable>
              <Dialog
                open={uploadAreaOpen}
                disableEscapeKeyDown
                disableEnforceFocus
                hideBackdrop
                BackdropProps={{ open: false }}
              >
                <DialogTitle>Add your area</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography>
                      Enter the dimensions of your Area
                    </Typography>
                    <TextField style={{ margin: '10px', padding: '4px' }} label={"width (m)"} name="width" onChange={onSetWidth} type="number" value={realWorldWidth} variant="outlined"
                      inputProps={{
                        step: 0.25
                      }}
                    />
                    <TextField style={{ margin: '10px', padding: '4px' }} label={"height (m)"} name="height" onChange={onSetHeight} type="number" value={realWorldHeight} variant="outlined"
                      inputProps={{
                        step: 0.25
                      }}
                    />
                    <Typography>
                      Upload an image of your area
                    </Typography>
                  </DialogContentText>
                  <PhotoCamera color="primary" aria-label="upload picture">
                    <input hidden accept="image/*" type="file"
                      onChange={(e) => onSetImage(e)} />
                  </PhotoCamera>
                </DialogContent>
                <Button onClick={(e) => setUploadAreaOpen(false)} color={"primary"} variant="contained"><Typography>Close</Typography></Button>
              </Dialog>
            </Draggable>
          </Grid>

          <Grid item={6}>
            <IconButton>
              <Tooltip title="Add grid">
                <GridOnIcon onClick={(e) => setAddGridOpen(true)} />
              </Tooltip>
            </IconButton>
            <Draggable>
              <Dialog
                open={addGridOpen}
                disableEscapeKeyDown
                disableEnforceFocus
                hideBackdrop
                BackdropProps={{ open: false }}
              >
                <DialogTitle>Add grid</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Set your grid dimensions
                  </DialogContentText>
                  <TextField onChange={onSetNewGridXStart} style={{ padding: '4px' }} label={"X start"} name="xStart" type="number" value={newGridXStart} variant="outlined"
                    inputProps={{
                      step: 0.25
                    }}
                  />
                  <TextField onChange={onSetNewGridWidth} style={{ padding: '4px' }} label={"Width (m)"} name="xLength" type="number" value={newGridWidth} variant="outlined"
                    inputProps={{
                      step: 0.25
                    }}
                  />
                  <TextField onChange={onSetNewGridYStart} style={{ padding: '4px' }} label={"Y start"} name="yStart" type="number" value={newGridYStart} variant="outlined"
                    inputProps={{
                      step: 0.25
                    }}
                  />
                  <TextField onChange={onSetNewGridHeight} style={{ padding: '4px' }} label={"Height"} name="yLength" type="number" value={newGridHeight} variant="outlined"
                    inputProps={{
                      step: 0.25
                    }}
                  />

                  <Button onClick={(event) => handleAddGrid(event)} style={{ marginTop: '10px', marginLeft: '5px' }} color={"primary"} variant="contained"><Typography>Add Grid</Typography></Button>
                </DialogContent>
                <Button onClick={(e) => setAddGridOpen(false)} color={"primary"} variant="contained"><Typography>Close</Typography></Button>
              </Dialog>
            </Draggable>
            <Grid item={6}>
              <Tooltip title="Add Column">
                <IconButton disabled={state.LuminaireCfg.selectedLuminaireIds == null
                  || state.LuminaireCfg.selectedLuminaireIds.length == 0} onClick={(event) => handleAddColumn(event, circlerightradius)}>
                  <img src={circlerightradius} height="25" />
                </IconButton>
              </Tooltip>

            </Grid>
            <Grid item={6}>
              <Tooltip title="Toggle Contours">
                <IconButton>
                  {showContours ?
                    <VisibilityIcon onClick={(e) => {
                      setShowContours(!showContours);
                    }} /> :
                    <VisibilityOffIcon onClick={(e) => {
                      setShowContours(!showContours);
                    }} />
                  }
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item={6}>
              <IconButton onClick={(event) => handleAddMask(event, "circle")}>
                <Tooltip title="Circle mask">
                  <PanoramaFishEyeIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item={6}>
              <IconButton onClick={(event) => handleAddMask(event, "rectangle")}>
                <Tooltip title="Rectangle/Square mask">
                  <RectangleIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item={6}>
              <IconButton onClick={(event) => {
                if (maskMode) {

                } else {
                  if (lines && lines.length > 0 && lines[0].points.length > 0) {
                    var poly = new Konva.Line({
                      points: lines[0].points,
                      fill: '#00D2FF',
                      stroke: 'black',
                      strokeWidth: 5,
                      closed: true,
                    });
                    setCurrentPoly(poly);
                  }
                  setLines([]);
                }
                setMaskMode(!maskMode)
              }}>
                <Tooltip title="Straight Line">
                  <TimelineIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item={6}>
              <IconButton>
                <Tooltip title="Line">
                  <GestureIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item={6}>
              <IconButton>
                <Tooltip title="Measure">
                  <HeightIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <ArrowBackIosIcon onClick={toggleLeftDrawer} style={{ alignSelf: 'flex-start', bottom: 0, margin: '10px', textAlign: 'center' }} />
        </Box>
      </Drawer >
      {/* main drawing part */}
      <div id="planWrapper" ref={planRef} height={'100vh'}>
        <Stage ref={stageRef} width={window.innerWidth * 0.86} height={window.innerHeight * 0.86}
          scaleX={stage.scale} scaleY={stage.scale} x={stage.x} y={stage.y}
          onWheel={handleWheel} onMouseDown={handleDrawLineMouseDown} draggable={true} style={{ border: "1px solid lightgrey" }}>

          {image != null ?
            <Layer ref={imageLayerRef} style={{ border: '1px solid blue' }} onDragEnd={(e) => {


              console.log("updateImage", e);
              console.log("imageRef", imageRef);
              console.log("x", contourGridLayerRef.current.getAbsolutePosition().x);
              console.log("y", contourGridLayerRef.current.getAbsolutePosition().y);

              var tempGridStr = JSON.stringify(grids);
              var tempGrids = JSON.parse(tempGridStr);
              var xScaler = realWorldWidth / (imagePixelWidth * stageRef.current.attrs.scaleX);
              var yScaler = realWorldHeight / (imagePixelHeight * stageRef.current.attrs.scaleY);
              tempGrids[0].origin.x.start = e.target.attrs.x * xScaler;
              tempGrids[0].origin.y.start = e.target.attrs.y * yScaler;
              setMetresPerPixelX(xScaler);
              setMetresPerPixelY(yScaler);
              setGrids(tempGrids);
              // console.log("imageLayerRef x",contourImageRef.current.attrs.x = contourGridLayerRef.current.getAbsolutePosition().x); 
              // console.log("imageLayerRef y",contourImageRef.current.attrs.x = contourGridLayerRef.current.getAbsolutePosition().y); 
              contourImageRef.current.attrs.width = contourGridLayerRef.current.attrs.width;
              contourImageRef.current.attrs.height = contourGridLayerRef.current.attrs.height;
              contourImageRef.current.attrs.x = contourGridLayerRef.current.getAbsolutePosition().x - (imageLayerRef.current.getAbsolutePosition().x * stage.scale);
              contourImageRef.current.attrs.y = contourGridLayerRef.current.getAbsolutePosition().y - (imageLayerRef.current.getAbsolutePosition().y * stage.scale);

            }}>
              <Image ref={imageRef} x={0} y={0} image={image} />
            </Layer>
            :
            null
          }


          {contourImage != null && contourGridLayerRef != null && contourGridLayerRef.current != null && showContours ?
            <Layer>
              <Image
                ref={contourImageRef}
                image={contourImage}
                height={contourGridLayerRef.current.attrs.height}
                width={contourGridLayerRef.current.attrs.width}
                x={contourGridLayerRef.current.getAbsolutePosition().x - imageLayerRef.current.getAbsolutePosition().x}
                y={contourGridLayerRef.current.getAbsolutePosition().y - imageLayerRef.current.getAbsolutePosition().y}
              />
            </Layer>
            : null
          }

          <Layer id="gridLayer">
            <Rect ref={contourGridLayerRef} sides={10}
              // x={grid.origin.x.start * (image.naturalWidth / realWorldWidth)}
              // y={grid.origin.y.start * (image.naturalHeight / realWorldHeight)}
              // width={realWorldWidth * (image.naturalWidth / realWorldWidth)}
              // height={realWorldHeight * (image.naturalHeight / realWorldHeight)}
              x={visualGrid.x}
              y={visualGrid.y}
              width={visualGrid.width}
              height={visualGrid.height}
              fill="lightblue"
              opacity={0}
              shadowEnabled={false} draggable={false}
            // onDragEnd={(e) => {
            //   updateGrid(e);
            // }}
            // onTap={(e) => {
            //   onSelectGrid();
            // }}
            // onClick={(e) => {
            //   onSelectGrid();
            // }}
            />

            {/* <Transformer ref={gridTrRef} resizeEnabled={true}
                  onTransformEnd={(e) => {
                    if (e.currentTarget.parent.attrs.id == "gridLayer") {
                      console.log("transformed grid", e.currentTarget.children[0]);
                      var jsontemp = JSON.stringify(grid);
                      var tempGrid = JSON.parse(jsontemp);
                      var xScaler = realWorldWidth / (imagePixelWidth * stageRef.current.attrs.scaleX);
                      var yScaler = realWorldHeight / (imagePixelHeight * stageRef.current.attrs.scaleY);
                      tempGrid.origin.x.length = e.currentTarget.children[0].attrs.width * xScaler;
                      tempGrid.origin.y.length = e.currentTarget.children[0].attrs.height * yScaler;
                      setGrid(tempGrid);
                      setMetresPerPixelX(xScaler);
                      setMetresPerPixelY(yScaler);
                    }
                  }}
                /> */}
          </Layer>



          <Layer ref={contourLayerRef}>
            <Shape ref={contourShapeRef}
              sceneFunc={(ctx, shape) => {
                if (!throttleSceneFunc) {
                  console.log("calling doContours", throttleSceneFunc);
                  drawContours(ctx, shape);
                  setThrottleSceneFunc(true);
                }
              }}
            />
          </Layer>

          {grids.map((grid, i) => {
            return <CanvasGrid i={grid.id} grid={grid} doAreaCalc={doAreaCalc}
              updateGrid={updateGrid} selectGrid={selectGrid} state={state} planRef={planRef} selected={grid.selected}
              gridScaleX={gridScaleX} gridScaleY={gridScaleY} areaResults={areaResults}
            />
          })}

          {columns.map((column, i) => {
            return <Column i={i} image={column.image} values={column.values} doAreaCalc={doAreaCalc}
              updateColumn={updateColumn} selectColumn={selectColumn} state={state} planRef={planRef} selected={column.selected}
              gridScaleX={gridScaleX} gridScaleY={gridScaleY}
            />
          })}
          {/* masks */}
          {masks.map((mask, i) => {
            return <Mask i={i} updateMask={updateMask} selectMask={selectMask} maskType={mask.type} state={state} selected={mask.selected} />
          })}

          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke="#df4b26"
                strokeWidth={5}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  //line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  'source-over'
                }
              />
            ))}
          </Layer>

          {poly && <CustomMask poly={poly} />}

        </Stage>
      </div>
      {/* Right hand column */}
      <Grid >
        <Drawer
          anchor="right"
          open={rightDrawerOpen}
          onClose={toggleRightDrawer}
          variant='persistent'
          className={classes.drawer}
        >
          <Box>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Select Luminaire</Typography>
              </AccordionSummary>
              <div style={{ margin: '10px' }}>
                <LuminCard index={0} />
              </div>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Grid</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GridEditor deleteGrid={deleteGrid} selectGrid={selectGrid} grids={grids} gridResults={areaResults} />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Column</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ColumnEditor deleteColumn={deleteColumn} selectColumn={selectColumn} columns={columns} />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography>Mask</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MaskEditor deleteMask={deleteMask} selectMask={selectMask} masks={masks} />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography>Results</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <LuminCard index={0} /> */}
              </AccordionDetails>
            </Accordion>
            <ArrowForwardIosIcon onClick={toggleRightDrawer} style={{ margin: '10px', display: 'flex', textAlign: 'right' }} />
          </Box>
        </Drawer>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <ArrowForwardIosIcon onClick={toggleLeftDrawer} style={{ margin: '10px', display: 'flex', textAlign: 'right' }} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            x:0 y:0
          </div>
          <ArrowBackIosIcon onClick={toggleRightDrawer} style={{ margin: '10px', display: 'flex', textAlign: 'right' }} />
        </div>
      </Grid >
    </>
  );
}


