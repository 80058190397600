import React, { useEffect } from 'react';
import {
	makeStyles,
	Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import notificationActions from '../../store/actions/notificationActions';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	formControl: {
		margin: theme.spacing(3),
	},
	margin: {
		margin: '0px',
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
	},
	container: {
		maxHeight: '40vh',
		minHeight: '40vh',
	}
}));



const Notification = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const state = useSelector(state => state);

	useEffect(() => {
		if (props.severity == 'error') {
			dispatch(notificationActions.AddNotificationHistory(props.message))
			console.log('null state', state);
		}
	}, [props.message]);


	return (
		<Snackbar
			open={props.open}
			onClose={props.onClose}
			key="test"
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			autoHideDuration={7000}
		>
			<Alert onClose={props.onClose} severity={props.severity}>
				{props.message}
			</Alert>
		</Snackbar>
	);


}
export default Notification;



