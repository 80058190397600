import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import columnActions from '../../store/actions/columnActions';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '65vh',
        flexGrow: 1,
        width: '100%',
        overflowY: 'auto'
    },
}));

const LCSResults = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const props = useSelector(state => state);
    var data = [];
    var array = props.ColumnCfg?.LCS?.sliceResults;
    var names = ["FL", "FM", "FH", "FVH", "UL", "UH", "BVH", "BH", "BM", "BL"]
    var total = 0;
    if (array != null) {
        array.forEach((partialSum) => { total += partialSum });
        array.forEach((element, i) => {
            if (element == 0) data[i] = { name: names[i], result: Math.round(element), percent: 0 };
            else data[i] = { name: names[i], result: Math.round(element), percent: Math.round(100 * element / total) };
        });
    }

    const handlePlot = useCallback((response) => {
        if (response != null && response?.data !== null && response.status == 200) {
            dispatch(columnActions.UpdateColumnCfg({LCS: response.data}));
        }
	}, []);

	useEffect(() => {
        EdgeOrREST(null, "GET_LCS", handlePlot, API_OBJECTS.getCalculationObject(props, null, null));		
	}, []);


    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table id="LCSResultsTable" name="LCS Results Table">
                    <TableHead>
                        <TableRow>
                            <TableCell id="NameHeader">
                                Name
                            </TableCell>
                            <TableCell id="ResultHeader">
                                Result
                            </TableCell>
                            <TableCell id="TotalHeader">
                                Total %
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((dataPoint, i) => (
                            <TableRow>
                                <TableCell id={"NameCell" + i}>
                                    {dataPoint.name}
                                </TableCell>
                                <TableCell id={"ResultCell" + i}>
                                    {dataPoint.result}
                                </TableCell>
                                <TableCell id={"TotalCell" + i}>
                                    {dataPoint.percent}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell id="NameCell">
                                Total
                            </TableCell>
                            <TableCell id="ResultCell">
                                {Math.round(total)}
                            </TableCell>
                            <TableCell id="TotalCell">
                                100
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell id="NameCell">
                                BUG: B{props.ColumnCfg?.LCS?.BUG[0]} U{props.ColumnCfg?.LCS?.BUG[1]} G{props.ColumnCfg?.LCS?.BUG[2]}
                            </TableCell>
                            <TableCell id="ResultCell">
                                UWLR: {props.ColumnCfg?.LCS?.UWLR.toFixed(2)}
                            </TableCell>
                            <TableCell id="TotalCell">
                                Glare: G{props.ColumnCfg?.LCS?.Glare}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default LCSResults