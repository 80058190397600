import axios from 'axios';
axios.defaults.headers.common['Authorization'] = "df1dceb8e2d6f6b0894363b085801e36";
//axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
const fileId = '600ad4ecba6de53e146971bb';

export const apiService = axios.create({
    baseURL: 'http://127.0.0.1:8080/'
    //baseURL : 'https://cloud.opendesign.com/api'
});
