import { handleActions } from 'redux-actions';
import actions from '../actions/resultsTableSettingsActions';
import { initialState } from '../initial-state';

export default handleActions(
    {
        [actions.UpdateLav]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLmin]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLmax]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateUO]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateUL]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateRei]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateTI]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateEav]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateEmin]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateEmax]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateAECI]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdatePowerDensity]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateSTV]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateUWLR]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateWetUO]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLvMax_Lav]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLmax_Lmin]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateLav_Lmin]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateEmin_Emax]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.UpdateEmin_Eav]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.SelectAll]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.SelectDefault]: (state, action) => {
            return { ...state, ...action.payload };
        },
        [actions.SelectNone]: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
     initialState.ResultsTableSettings
	
);
