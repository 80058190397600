import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
	Grid,
	CardHeader,
	CardContent,
	Card,
	TextField,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import gridActions from '../../store/actions/gridActions';
import ConfigurationOptions from './configuration-options.component';
import Offset from '../../components/Standards/offset.component';
import Obstruction from './obstruction.component';

const RoadTab = ({ index }) => {

	const dispatch = useDispatch();
	const props = useSelector(state => state.GridsCfg);
	const headerDrop = ["Road", "Hard Shoulder", "Footpath", "Central Reserve"];

	const [header, setHeader] = useState(() => props.RoadGrids[index].GridType || "Road");
	const [priority, setPriority] = useState(() => props.priorityGrid === index);

	const handleChange = (event) => {
		setHeader(event.target.value);
		if (event.target.value === "Obstrusive Light") {
			dispatch(gridActions.UpdateCurrentGrid({
				GridType: event.target.value,
				IllumTypeId: "Vertical",
				CalcGridClassValue: 'P1',
				Standard: 'EN13201:2015 Illuminance',
				CalcGridFile: 'EN13201:2015 Illuminance',
				IllumType: "Vertical",
				startHeight: 5,
				endHeight: 10,
			}));
		}
		else dispatch(gridActions.UpdateCurrentGrid({GridType: event.target.value}));
	};

	const handlePriorityChange = (event) => {
		setPriority(event.target.checked);
		dispatch(gridActions.UpdateGridsCfg({
			priorityGrid: event.target.checked ? index : -1
		}));
	};

	const HeaderContent = () => {
		if(header === "Obstrusive Light") return (
			<CardHeader
				avatar={
					<TextField
						fullWidth
						label="Type"
						name="header"
						onChange={handleChange}
						select
						SelectProps={{ native: true }}
						variant="outlined"
						value={header}
					>
						{headerDrop.map((option) => (
							<option
								key={option}
							>
								{option}
							</option>
						))}
					</TextField>
				}
			/>
		)
		else return (
			<CardHeader
				avatar={
					<TextField
						fullWidth
						label="Type"
						name="header"
						onChange={handleChange}
						select
						SelectProps={{ native: true }}
						variant="outlined"
						value={header}
					>
						{headerDrop.map((option) => (
							<option
								key={option}
							>
								{option}
							</option>
						))}
					</TextField>
				}
                action={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={priority}
                                onChange={handlePriorityChange}
                                color="primary"
                            />
                        }
                        label="Priority Grid"
                    />
                }
			/>
		)
	}

	const BodyContent = () => {
		if(header === "Obstrusive Light") return (
			<CardContent>
				<Obstruction index={index}/>
			</CardContent>
		)
		else return (
			<CardContent>
				<Grid container spacing={2}>

					<Grid item md={12} sm={12} xs={12}>
						<ConfigurationOptions index={index} />

					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Offset left={true} index={index} />
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Offset left={false} index={index} />
					</Grid>
				</Grid>
			</CardContent>
		)
	}

	return (
		<Card>
			<HeaderContent />
			<BodyContent />
		</Card>
	)
}

const mapDispatchToProps = {

}

export default connect(null, mapDispatchToProps)(RoadTab)
