import React, { Component, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  Divider,
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  List,
  ListItem,
  Box,
  Typography,
  TextField,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import * as qs from 'query-string';
import Isolux from '../components/Calc_Area/isolux.component';
import Polar from '../components/Calc_Area/polar.component';
import LCS from '../components/Calc_Area/LCS.component';
import Cartesian from '../components/Calc_Area/cartesian.component';
import LCSResults from '../components/Results/LCSResults.component';
import { useInterval } from '../automate/automate-interval-runner.component';
import { useDispatch } from 'react-redux';
import user from '../store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '70vh',
    padding: theme.spacing(3),
    width: '100%',
    overflowY: 'auto'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PhotometricInformation = (props) => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [luminaireImages, setImages] = React.useState([]);

  const [data, setData] = React.useState({

    info: {
      zero: [],
      ninety: [],
      "manufacturer": "",
      "flux": 0,
      "inputWattage": 0,
      id: 0
    },
    attributes: {

    },
    luminaire: {

    }
  });

  const onGetLuminaireImages = (response) => {
    if (response && response.status == '200') {
      setImages(response.data);
    }
  };


  useInterval(() => {
    if (state.user.refreshLuminaireInfo) {
      //add images
      EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': state.LuminaireCfg.selectedResultLuminId } });
      EdgeOrREST(null, 'GET_LUMINAIRE_IMAGES', onGetLuminaireImages, { headers: { 'id': '227' } });
      dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: false }));
    }
  }, 1000);

  const onGetPhotometricInformation = (response) => {
    console.log("GET_PHOTOMETRIC_INFORMATION, response: ", response.status, response.data);

    if (response && response.status == '200') {
      setData(response.data);
      console.log("photometric data", data);
    }
  };

  useEffect(() => {
    const parsed = qs.parse(window.location.search);
    EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': parsed.id } });
  }, []);

  function luminaireImagesTab() {

    if (luminaireImages.length > 0) {
      var mediaType = "data:image/jpeg;base64,";
      var images = [];
      for (let i = 0; i < luminaireImages.length; i++) {
        images.push(<img src={mediaType + luminaireImages[i].image} />);
      }

      return (
        <Grid container spacing={2} style={{ height: '68vh', overflowY: 'scroll' }}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            {images}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            No media found for luminaire.
          </Grid>
        </Grid>
      );
    }

  }

  return (
    <Card style={{ 'minHeight': 'inherit' }}>
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto" indicatorColor="primary" textColor="primary">
            <Tab name="attributesTab" label="Attributes" {...a11yProps(1)} />
            <Tab name="isoluxTab" label="ISO Lux" {...a11yProps(2)} />
            <Tab name="polarTab" label="Polar Plot" {...a11yProps(3)} />
            <Tab name="cartesianTab" label="Cartesian Plot" {...a11yProps(4)} />
            <Tab name="lcsTab" label="LCS" {...a11yProps(5)} />
            <Tab name="lcsTableTab" label="LCS Table" {...a11yProps(6)} />
            <Tab name="imagesTab" label="LCS Images" {...a11yProps(7)} />
            {/* <Tab name="fileluxTab" label="File" {...a11yProps(8)} /> */}

          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className={classes.root}>
          <Typography variant="body2" className={classes.labelText}>
            Selected Luminaire: {state?.LuminaireCfg?.selectedResultName}
          </Typography>
          <Grid container spacing={7}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Light">
                  Light
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="Light source"
                    secondary={data.attributes.lightSource}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Manufacturer"
                    secondary={data.attributes.manufacturer}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Lamp base"
                    secondary={data.attributes.lampBase}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="CRI"
                    secondary={data.attributes.cri}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="R9 Value"
                    secondary={data.attributes.r9}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="MacAdam step"
                    secondary={data.attributes.macAdamStep}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Direction"
                    secondary={data.attributes.direction}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Beam Angle"
                    secondary={data.attributes.beamAngle + "°"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="CCT options"
                    secondary={data.attributes.cctOptions}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="CCT"
                    secondary={data.attributes.cct + "K"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Colour tuning/Warm dimming CCT min"
                    secondary={data.attributes.cctMin + "K"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Colour tuning/Warm dimming CCT max"
                    secondary={data.attributes.cctMax + "K"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Colour change type"
                    secondary={data.attributes.colourChangeType}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="LOR"
                    secondary={data.attributes.lor}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="ULOR"
                    secondary={data.attributes.ulor}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Optical accessories"
                    secondary={data.attributes.opticalAccessories ? 'Yes' : 'No'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Number of lamps/light engines"
                    secondary={data.attributes.lampCount}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Delivered Lumens"
                    secondary={data.attributes.deliveredLumens}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Secondary Beam Angle"
                    secondary={data.attributes.secondaryBeamAngle}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Direct Element(%)"
                    secondary={data.attributes.directElement}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Indirect Element(%)"
                    secondary={data.attributes.indirectElement}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Lumen Suffix"
                    secondary={data.attributes.lumenSuffix}
                  />
                </ListItem>
              </List>
            </Grid>

            {/* Physical */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Physical">
                  Physical
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="Finish"
                    secondary={data.attributes.finish}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Housing Material"
                    secondary={data.attributes.housingMaterial}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Adjustable Tilt"
                    secondary={data.attributes.adjustableTilt + '°'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Adjustable Rotation"
                    secondary={data.attributes.adjustableRotation + '°'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Shape"
                    secondary={data.attributes.shape}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Height"
                    secondary={data.attributes.height + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Length"
                    secondary={data.attributes.length + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Width"
                    secondary={data.attributes.width + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Installation and Cut-out"
                    secondary={data.attributes.installation}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Placement"
                    secondary={data.attributes.placement}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Weight"
                    secondary={data.attributes.weight + " kg"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Recess Depth Requirement"
                    secondary={data.attributes.recessDepthRequirement}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Cut Out Length"
                    secondary={data.attributes.cutOutLength + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Cut Out Width"
                    secondary={data.attributes.cutOutWidth + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Cut Out Diameter"
                    secondary={data.attributes.cutOutDiameter + " mm"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Height"
                    secondary={data.attributes.anyHeight}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Length"
                    secondary={data.attributes.anyLength}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Width"
                    secondary={data.attributes.anyWidth}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Diameter"
                    secondary={data.attributes.anyDiameter}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Cut Out Length"
                    secondary={data.attributes.anyCutOutLength}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Cut Out Width"
                    secondary={data.attributes.anyCutOutWidth}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Cut Out Diameter"
                    secondary={data.attributes.anyCutOutDiameter}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any Recess Depth Requirement"
                    secondary={data.attributes.anyRecessDepthRequirement}
                  />
                </ListItem>
              </List>
            </Grid>

            {/* Electrical */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Electrical">
                  Electrical
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="Voltage Type"
                    secondary={data.attributes.voltageType}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Voltage"
                    secondary={data.attributes.voltage}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Wattage"
                    secondary={data.attributes.wattage}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="MilliAmp"
                    secondary={data.attributes.mA}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Lumin per Watt"
                    secondary={data.attributes.luminWatt}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Driver Type"
                    secondary={data.attributes.driverType}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Control"
                    secondary={data.attributes.control}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Appliance Class"
                    secondary={data.attributes.applianceClass}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Calc Ma"
                    secondary={data.attributes.calMa}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Wattage Suffix"
                    secondary={data.attributes.wattageSuffix}
                  />
                </ListItem>
              </List>
            </Grid>
            {/* Protection */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Protection">
                  Protection
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="IP Rating"
                    secondary={"IP " + data.attributes.ip}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="IK Rating"
                    secondary={"IK " + data.attributes.ik}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Operating Temperature(°C)"
                    secondary={data.attributes.operatingTempMin + "°C to " + data.attributes.operatingTempMax + "°C"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Emergency Light"
                    secondary={data.attributes.emergencyLight ? 'Yes' : 'No'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Anti-Slip"
                    secondary={data.attributes.antiSlip ? 'Yes' : 'No'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Designation Label"
                    secondary={data.attributes.designLabel}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Explosion Zone Suitability"
                    secondary={data.attributes.explosionZoneSuitability}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Rollover Protection"
                    secondary={data.attributes.rolloverProtection}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Suitable for Clean Rooms"
                    secondary={data.attributes.suitableForCleanRooms}
                  />
                </ListItem>
              </List>
            </Grid>

            {/* Environment */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Environment">
                  Environment
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="Min TM66 Score"
                    secondary={data.attributes.tm66 = 0 ? '' : data.attributes.tm66}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Environmental Certification"
                    secondary={data.attributes.envCert}
                  />
                </ListItem>
              </List>
            </Grid>

            {/* Brands */}
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <List dense={true} subheader={
                <ListSubheader disableSticky disableGutters component="div" id="Brands">
                  Others
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemText
                    primary="Brands"
                    secondary={data.attributes.brands}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="SKU"
                    secondary={data.attributes.sku}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Product Type"
                    secondary={data.attributes.productType}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Isolux />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Polar />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Cartesian />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <LCS />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <LCSResults />
            </Grid>
          </Grid>
        </TabPanel>

        {/* <TabPanel value={value} index={7}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {luminaireFileViewer()}
            </Grid>
          </Grid>
        </TabPanel> */}
      </CardContent>
    </Card>
  );

}
export default PhotometricInformation; 