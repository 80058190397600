import React from 'react';

import {
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

export default class LinearInterp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            checkedState: 'chkBox_off',
        };
    }

    handleChange = () => {
        this.setState({ checked: !this.state.checked });
        this.props.UpdateRoadCfg(this.state.checked);
    };

    render() {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        name="checkboxLinearInterp"
                        color="primary"
                    />
                }
                label="Linear Interpolation"
            />
        );
    }
}
