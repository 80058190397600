import isElectron from 'is-electron';
import { EdgeCaller, EdgeMethods } from './EdgeCaller';
import { CalculationEndPoints } from './API/services/calculation.service';
import { StandardEndPoints } from './API/services/standard.service';
import { UserEndPoints } from './API/services/user.service';
import { RegionEndPoints } from './API/services/region.service';
import { AuthenticationEndPoints } from './API/services/authentication.service';
import { HierarchyEndPoints } from './API/services/hierarchy.service';
import { RoadwayEndPoints } from './API/services/roadway.service'
import { RoleEndPoints } from './API/services/role.service';
import { LuminaireEndPoints } from './API/services/luminaire.service';
import { VisualizeEndPoints } from './API/external/visualize.service';
import { AutomateEndPoints } from './API/services/automate.service';
import { AdminEndPoints } from './API/services/admin.service';
import { SearchEndPoints } from './API/services/search.service';
import { SelectEndPoints } from './API/services/select.service';
import { DesignEndPoints } from './API/services/design.service';
export default async function EdgeOrREST(edgeInput, edgeMethod, callback, restInput) {
	if (isElectron()) {
		return EdgeCaller(edgeInput, EdgeMethods[edgeMethod]);
	} else {

		if (edgeMethod && 'CALC_GRID'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getCalculationResults(restInput, callback);
		}
		if (edgeMethod && 'SET_ROAD_CONFIG'.localeCompare(edgeMethod) == 0) {
			StandardEndPoints.getStandard(restInput, callback);
		}
		if (edgeMethod && 'SET_CALC_GRID_CLASS'.localeCompare(edgeMethod) == 0) {
			StandardEndPoints.getGridClass(restInput, callback);
		}
		if (edgeMethod && 'SET_ILLUM_STANDARD'.localeCompare(edgeMethod) == 0) {
			StandardEndPoints.getStandard(restInput, callback);
		}
		if (edgeMethod && 'SET_ROADSURFACE'.localeCompare(edgeMethod) == 0) {
			RoadwayEndPoints.getQO(restInput, callback);
		}
		if (edgeMethod && 'LOG_IN'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.login(restInput, callback);
		}
		if (edgeMethod && 'LOGIN_AS_USER'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.loginAsUser(restInput, callback);
		}
		if (edgeMethod && 'CHECK_TOKEN'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.checkToken(restInput, callback);
		}
		if (edgeMethod && 'CREATE_USER'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.createUser(restInput, callback);
		}
		if (edgeMethod && 'GET_USER'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUser(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_BY_ID'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUserById(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_ANALYTICS_BY_ID'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUserAnalyticsById(restInput, callback);
		}
		if (edgeMethod && 'GET_ROOT_TREES'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getTreeRoots(restInput, callback);
		}
		if (edgeMethod && 'GET_TREE_CHILDREN'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getChildren(restInput, callback);
		}
		if (edgeMethod && 'REMOVE_LUMINAIRES_FROM_TREE'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.removeTreeItems(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_REGIONS'.localeCompare(edgeMethod) == 0) {
			RegionEndPoints.getAllRegions(restInput, callback);
		}
		if (edgeMethod && 'GET_ROLES'.localeCompare(edgeMethod) == 0) {
			RoleEndPoints.getRoleByUserId(restInput, callback);
		}
		if (edgeMethod && 'GET_PRODUCTS'.localeCompare(edgeMethod) == 0) {
			RoleEndPoints.getProductsByCompanyId(restInput, callback);
		}
		if (edgeMethod && 'OPTIMIZE_GRID'.localeCompare(edgeMethod) == 0) {
			if (restInput.min) {
				CalculationEndPoints.getMinimizedResults(restInput.restObject, callback)
			} else { CalculationEndPoints.getOptimizedResults(restInput.restObject, callback); }
		}
		if (edgeMethod && 'OPTIMIZE_FOLDER'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getOptimizedFolder(restInput, callback)
		}
		if (edgeMethod && 'EXCEL_CONVERT'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getExcelFromOptimize(restInput, callback)
		}
		if (edgeMethod && 'FULL_ROADWAY'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getFullConfigurationResults(restInput, callback);
		}
		if (edgeMethod && 'DELETE_TREE_CHILD'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.deleteTreeNode(restInput, callback);
		}
		if (edgeMethod && 'ADD_TREE_CHILDREN'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.addTreeNodes(restInput, callback);
		}
		if (edgeMethod && 'GET_CONFIG_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.getConfigurationLuminaires(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_ROOT_FOLDERS'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getRootFolders(restInput, callback);
		}
		if (edgeMethod && 'GET_USERS_GROUPS_AND_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getGroupsAndLuminaires(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_FOLDER_CHILDREN'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllFolderChildren(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_FOLDER_PARENT'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.GetAllFolderParent(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_LOWEST_CHILDREN_FOLDER'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllLowestChildrenFolder(restInput, callback);
		}
		if (edgeMethod && 'ADD_LUMINAIRES_TO_FOLDERS'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.addLuminairesToFolders(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_LUMINAIRE'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.uploadLuminaire(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.uploadLuminaires(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_LUMINAIRES_AND_ADD_GROUP'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.uploadLuminairesAndAddToGroup(restInput, callback);
		}
		if (edgeMethod && 'GET_PHOTOMETRIC_INFORMATION'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.getLuminaireData(restInput, callback);
		}
		if (edgeMethod && 'DELETE_LUMINAIRE'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.deleteLuminaire(restInput, callback);
		}
		if (edgeMethod && 'CREATE_GROUP'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.createGroup(restInput, callback);
		}
		if (edgeMethod && 'DELETE_GROUP_OR_GROUP_ITEM'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.deleteGroupOrGroupItem(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_ATTRIBUTES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.updateAttributes(restInput, callback);
		}
		if (edgeMethod && 'SEARCH_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.searchLuminaires(restInput, callback);
		}
		if (edgeMethod && 'GET_MANUFACTURER_NAMES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.getManufacturerNames(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_CONFIGURATION'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.uploadConfiguration(restInput, callback);
		}
		if (edgeMethod && 'GET_AREA'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getArea(restInput, callback);
		}
		if (edgeMethod && 'GET_ISOLUX'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getIsolux(restInput, callback);
		}
		if (edgeMethod && 'GET_POLAR'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getPolarPlot(restInput, callback);
		}
		if (edgeMethod && 'GET_LCS'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getLCSData(restInput, callback);
		}
		if (edgeMethod && 'GET_JOB_LIST'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getJobList(restInput, callback);
		}
		if (edgeMethod && 'DOWNLOAD_AUTOMATE_RESULTS'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getXlsx(restInput, callback);
		}
		if (edgeMethod && 'KEEP_ALIVE'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.keepAlive(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_MENUS'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUserMenus(restInput, callback);
		}
		if (edgeMethod && 'SEARCH_UPLOADED_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.searchUploadedLuminaires(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_LRS'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.uploadLrs(restInput, callback);
		}
		if (edgeMethod && 'GET_LRS_FROM_JOB'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getLRSFromResult(restInput, callback);
		}
		if (edgeMethod && 'SIGN_OUT'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.signOut(restInput, callback);
		}
		if (edgeMethod && 'VERIFY_OTP'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.verifyOTP(restInput, callback);
		}
		if (edgeMethod && 'ENABLE_2FA'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.enable2FA(restInput, callback);
		}
		if (edgeMethod && 'VALIDATE_BYPASS_TOKEN'.localeCompare(edgeMethod) == 0) {
			return AuthenticationEndPoints.validateBypassToken(restInput, callback);
		}
		if (edgeMethod && 'ENABLE_EMAIL_2FA'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.enableEmail2FA(restInput, callback);
		}
		if (edgeMethod && 'VERIFY_EMAIL_OTP'.localeCompare(edgeMethod) == 0) {
			AuthenticationEndPoints.verifyEmailOTP(restInput, callback);
		}
		if (edgeMethod && 'GET_LUMINAIRE_IMAGES'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.getLuminaireImages(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_LUMINAIRE_ROTATION'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.updateLuminaireRotation(restInput, callback);
		}
		if (edgeMethod && 'GET_OPTIMIZE_SESSION_DESCRIPTION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getOptimizeSessionDescription(restInput, callback);
		}
		if (edgeMethod && 'GET_OPTIMIZE_SESSION_BY_ID'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getOptimizeSessionById(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_OPTIMIZE_SESSION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getAllOptimizeSession(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_OPTIMIZE_SESSION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.updateOptimizeSession(restInput, callback);
		}
		if (edgeMethod && 'SET_OPTIMIZE_SESSION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.setOptimizeSession(restInput, callback);
		}
		if (edgeMethod && 'DELETE_OPTIMIZE_SESSION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.deleteOptimizeSession(restInput, callback);
		}
		if (edgeMethod && 'GET_LRSR'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getLrsr(restInput, callback);
		}
		if (edgeMethod && 'GET_LRS'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getLrs(restInput, callback);
		}
		if (edgeMethod && 'IMPORT_OPTIMIZE_SESSION'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.importOptimizeSession(restInput, callback);
		}
		if (edgeMethod && 'GET_ROAD_TEMPLATE'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getRoadTemplate(restInput, callback);
		}
		if (edgeMethod && 'GET_TEMPLATE'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getTemplate(restInput, callback);
		}
		if (edgeMethod && 'DELETE_JOB'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.deleteJob(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_PASSWORD'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.updatePassword(restInput, callback);
		}
		if (edgeMethod && 'SEND_RESET_EMAIL'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.sendResetEmail(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_LOGIN'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUserLogin(restInput, callback);
		}
		if (edgeMethod && 'RESET_PASSWORD'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.resetPassword(restInput, callback);
		}
		if (edgeMethod && 'GET_RSS_FEED'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getRSSFeed(restInput, callback);
		}
		if (edgeMethod && 'GET_JOB_SEARCH'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.searchJobs(restInput, callback);
		}
		if (edgeMethod && 'SEND_SUPPORT_REQUEST'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.sendSupportRequest(restInput, callback);
		}
		if (edgeMethod && 'GET_CALS'.localeCompare(edgeMethod) == 0) {
			AutomateEndPoints.getCals(restInput, callback);
		}
		if (edgeMethod && 'GET_USERS_WITH_QUEUED_JOBS'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getUsersWithQueuedJobs(restInput, callback);
		}
		if (edgeMethod && 'GET_CODE_VERSION'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getCodeVersion(restInput, callback);
		}
		if (edgeMethod && 'GET_NUMBER_OF_JOBS_IN_QUEUE'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getNumberOfJobsInQueue(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_LIST'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getUserList(restInput, callback);
		}
		if (edgeMethod && 'SEARCH_MONITOR_JOBS'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.searchMonitorJobs(restInput, callback);
		}
		if (edgeMethod && 'GET_USER_IDENTITY'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getUserIdentity(restInput, callback);
		}
		if (edgeMethod && 'ADD_ATTRIBUTES_TO_GROUP'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.addAttributesToGroup(restInput, callback);
		}
		if (edgeMethod && 'ADD_ATTRIBUTES_TO_LUMINAIRE'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.addAttributesToLuminaire(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_ATTRIBUTES_TO_LUMINAIRE'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.updateAttributesToLuminaire(restInput, callback);
		}
		if (edgeMethod && 'GET_JOB_CALC_TIME'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getJobCalcTime(restInput, callback);
		}
		if (edgeMethod && 'GET_COMPANY_REGIONS_LISTS'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.getCompanysAndRegionLists(restInput, callback);
		}
		if (edgeMethod && 'ADD_USER'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.addUser(restInput, callback);
		}
		if (edgeMethod && 'EDIT_USER'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.editUser(restInput, callback);
		}
		if (edgeMethod && 'ADD_COMPANY'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.addCom(restInput, callback);
		}
		if (edgeMethod && 'EDIT_COMPANY'.localeCompare(edgeMethod) == 0) {
			AdminEndPoints.editCom(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_ATTRIBUTES_BY_GROUP'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllAttributesByGroup(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_ATTRIBUTES_BY_LUMINAIRE'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllAttributesByLuminaire(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_GROUP_NAME_DESCRIPTION'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.updateGroupNameDescription(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_LUMINAIRE_DESCRIPTION'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.updateLuminaireDescription(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_GROUP_ATTRIBUTES'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.updateGroupAttributes(restInput, callback);
		}
		if (edgeMethod && 'CREATE_PDF'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.createPDF(restInput, callback);
		}
		if (edgeMethod && 'DOWNLOAD_PDF_TEMP'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.downloadPDFTemp(restInput, callback);
		}
		if (edgeMethod && 'EXPORT_PHOTOMERTRIC_TREE'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.exportPhotomertricTree(restInput, callback);
		}
		if (edgeMethod && 'EXPORT_TO_SPEKTD'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.exportToSpektd(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_PDF_TEM'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.uploadPDFTem(restInput, callback);
		}
		if (edgeMethod && 'GET_CUSTOM_TEMP'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.getCustTemp(restInput, callback);
		}
		if (edgeMethod && 'DELETE_PDF_TEMP'.localeCompare(edgeMethod) == 0) {
			CalculationEndPoints.deletePDFTemp(restInput, callback);
		}
		if (edgeMethod && 'CONVERT_TO_SVG'.localeCompare(edgeMethod) == 0) {
			DesignEndPoints.convertToSVG(restInput, callback);
		}
		if (edgeMethod && 'UPLOAD_MEDIA_FOR_LUMINAIRES'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.uploadMediaForLuminaires(restInput, callback);
		}
		if (edgeMethod && 'GET_LUMINAIRE_MEDIAS_LIST'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getLuminaireMediasList(restInput, callback);
		}
		if (edgeMethod && 'GET_GROUP_MEDIAS_LIST'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getGroupMediasList(restInput, callback);
		}
		if (edgeMethod && 'GET_LUMINAIRE_MEDIA_CONTENT'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getLuminaireMediaContent(restInput, callback);
		}
		if (edgeMethod && 'GET_GROUP_MEDIA_CONTENT'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getGroupMediaContent(restInput, callback);
		}
		if (edgeMethod && 'DELETE_MEDIAS'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.deleteMedias(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_MANUFACTURERS_COMPANY'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllManufacturersCompany(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_SUBSCRIBED_COMPANYS'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getAllSubscribedCompanys(restInput, callback);
		}
		if (edgeMethod && 'EDIT_SUBSCRIBE_TREES'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.editSubscribeTreeByCompanyIds(restInput, callback);
		}
		if (edgeMethod && 'GET_SUBSCRIBED_ROOT_FOLDERS'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.getSubscribedRootFolders(restInput, callback);
		}
		if (edgeMethod && 'EXPORT_TO_SELECTOR'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.exportToSelector(restInput, callback);
		}
		if (edgeMethod && 'UPDATE_CFG'.localeCompare(edgeMethod) == 0) {
			SelectEndPoints.updateCfg(restInput, callback);
		}
		if (edgeMethod && 'GET_CFG'.localeCompare(edgeMethod) == 0) {
			SelectEndPoints.getCfg(restInput, callback);
		}
		if (edgeMethod && 'GET_ROLE_ID'.localeCompare(edgeMethod) == 0) {
			SelectEndPoints.getRoleId(restInput, callback);
		}
		if (edgeMethod && 'GET_ALL_LOWEST_SUBSCRIBE_FOLDER'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.GetAllLowestChildrenFolderBySubscribe(restInput, callback);
		}
		if (edgeMethod && 'COPY_GROUP'.localeCompare(edgeMethod) == 0) {
			HierarchyEndPoints.CopyGroup(restInput, callback);
		}
		if (edgeMethod && 'GET_COMPANY'.localeCompare(edgeMethod) == 0) {
			UserEndPoints.getCompany(restInput, callback);
		}
		if (edgeMethod && 'CREATE_VIRTUAL_LUMINAIRE_BY_SKU'.localeCompare(edgeMethod) == 0) {
			LuminaireEndPoints.createVirtualLuminaireAndAddToGroupBySku(restInput, callback);
		}
		if (edgeMethod && 'GET_MANUFACTURER'.localeCompare(edgeMethod) == 0) {
			SearchEndPoints.getManufacturer(restInput, callback);
		}
		if (edgeMethod && 'GET_LUMINAIRE_FILES'.localeCompare(edgeMethod) == 0) {
			SearchEndPoints.getLuminaireFiles(restInput, callback);
		}
	}
}