import React, { useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import resultsTableSettingsActions from '../../store/actions/resultsTableSettingsActions';
import {
    Checkbox,
    FormControlLabel,
    Button,
} from '@material-ui/core';
import { initialState } from '../../store/initial-state';

const ResultsTableSettings = () => {
    const state = useSelector(state => state.ResultsTableSettings)
    const iState = initialState.ResultsTableSettings
    const dispatch = useDispatch();
    const RTS = resultsTableSettingsActions;

    const handleSelectAll = () => {
        dispatch(RTS.SelectAll({SelectAll:true}))
        dispatch(RTS.SelectAll({SelectDefault:false}))
        dispatch(RTS.SelectAll({SelectNone:false}))
        dispatch(RTS.UpdateLav({Lav:true}))
        dispatch(RTS.UpdateLmin({Lmin:true}))
        dispatch(RTS.UpdateLmax({Lmax:true}))
        dispatch(RTS.UpdateUO({UO:true}))
        dispatch(RTS.UpdateUL({UL:true}))
        dispatch(RTS.UpdateRei({Rei:true}))
        dispatch(RTS.UpdateTI({TI:true}))
        dispatch(RTS.UpdateEav({Eav:true}))
        dispatch(RTS.UpdateEmin({Emin:true}))
        dispatch(RTS.UpdateEmax({Emax:true}))
        dispatch(RTS.UpdateAECI({AECI:true}))
        dispatch(RTS.UpdatePowerDensity({powerDensity:true}))
        dispatch(RTS.UpdateSTV({STV:true}))
        dispatch(RTS.UpdateUWLR({UWLR:true}))
        dispatch(RTS.UpdateWetUO({WetUO:true}))
        dispatch(RTS.UpdateLvMax_Lav({LvMax_Lav:true}))
        dispatch(RTS.UpdateLmax_Lmin({Lmax_Lmin:true}))
        dispatch(RTS.UpdateLav_Lmin({Lav_Lmin:true}))
        dispatch(RTS.UpdateEmin_Emax({Emin_Emax:true}))
        dispatch(RTS.UpdateEmin_Eav({Emin_Eav:true}))
    }

    const handleSelectDefault = () => {
        dispatch(RTS.SelectAll({SelectAll:false}))
        dispatch(RTS.SelectDefault({SelectDefault:true}))
        dispatch(RTS.SelectAll({SelectNone:false}))
        dispatch(RTS.UpdateLav({Lav:iState.Lav}))
        dispatch(RTS.UpdateLmin({Lmin:iState.Lmin}))
        dispatch(RTS.UpdateLmax({Lmax:iState.Lmax}))
        dispatch(RTS.UpdateUO({UO:iState.UO}))
        dispatch(RTS.UpdateUL({UL:iState.UL}))
        dispatch(RTS.UpdateRei({Rei:iState.Rei}))
        dispatch(RTS.UpdateTI({TI:iState.TI}))
        dispatch(RTS.UpdateEav({Eav:iState.Eav}))
        dispatch(RTS.UpdateEmin({Emin:iState.Emin}))
        dispatch(RTS.UpdateEmax({Emax:iState.Emax}))
        dispatch(RTS.UpdateAECI({AECI:iState.AECI}))
        dispatch(RTS.UpdatePowerDensity({powerDensity:iState.powerDensity}))
        dispatch(RTS.UpdateSTV({STV:iState.STV}))
        dispatch(RTS.UpdateUWLR({UWLR:iState.UWLR}))
        dispatch(RTS.UpdateWetUO({WetUO:iState.WetUO}))
        dispatch(RTS.UpdateLvMax_Lav({LvMax_Lav:iState.LvMax_Lav}))
        dispatch(RTS.UpdateLmax_Lmin({Lmax_Lmin:iState.LvMax_LMin}))
        dispatch(RTS.UpdateLav_Lmin({Lav_Lmin:iState.Lav_Lmin}))
        dispatch(RTS.UpdateEmin_Emax({Emin_Emax:iState.Emin_Emax}))
        dispatch(RTS.UpdateEmin_Eav({Emin_Eav:iState.Emin_Eav}))
    }

    const handleSelectNone = () => {
        dispatch(RTS.SelectAll({SelectAll:false}))
        dispatch(RTS.SelectAll({SelectDefault:false}))
        dispatch(RTS.SelectNone({SelectNone:true}))
        dispatch(RTS.UpdateLav({Lav:false}))
        dispatch(RTS.UpdateLmin({Lmin:false}))
        dispatch(RTS.UpdateLmax({Lmax:false}))
        dispatch(RTS.UpdateUO({UO:false}))
        dispatch(RTS.UpdateUL({UL:false}))
        dispatch(RTS.UpdateRei({Rei:false}))
        dispatch(RTS.UpdateTI({TI:false}))
        dispatch(RTS.UpdateEav({Eav:false}))
        dispatch(RTS.UpdateEmin({Emin:false}))
        dispatch(RTS.UpdateEmax({Emax:false}))
        dispatch(RTS.UpdateAECI({AECI:false}))
        dispatch(RTS.UpdatePowerDensity({powerDensity:false}))
        dispatch(RTS.UpdateSTV({STV:false}))
        dispatch(RTS.UpdateUWLR({UWLR:false}))
        dispatch(RTS.UpdateWetUO({WetUO:false}))
        dispatch(RTS.UpdateLvMax_Lav({LvMax_Lav:false}))
        dispatch(RTS.UpdateLmax_Lmin({Lmax_Lmin:false}))
        dispatch(RTS.UpdateLav_Lmin({Lav_Lmin:false}))
        dispatch(RTS.UpdateEmin_Emax({Emin_Emax:false}))
        dispatch(RTS.UpdateEmin_Eav({Emin_Eav:false}))
    }

    const handleLav = () => {
        dispatch(RTS.UpdateLav({Lav:!state.Lav}))
    }

    const handleLmin = () => {
        dispatch(RTS.UpdateLmin({Lmin:!state.Lmin}))
    }

    const handleLmax = () => {
        dispatch(RTS.UpdateLmax({Lmax:!state.Lmax}))
    }

    const handleUO = () => {
        dispatch(RTS.UpdateUO({UO:!state.UO}))
    }

    const handleUL = () => {
        dispatch(RTS.UpdateUL({UL:!state.UL}))
    }

    const handleRei = () => {
        dispatch(RTS.UpdateRei({Rei:!state.Rei}))
    }

    const handleTI = () => {
        dispatch(RTS.UpdateTI({TI:!state.TI}))
    }

    const handleEav = () => {
        dispatch(RTS.UpdateEav({Eav:!state.Eav}))
    }

    const handleEmin = () => {
        dispatch(RTS.UpdateEmin({Emin:!state.Emin}))
    }

    const handleEmax = () => {
        dispatch(RTS.UpdateEmax({Emax:!state.Emax}))
    }

    const handleAECI = () => {
        dispatch(RTS.UpdateAECI({AECI:!state.AECI}))
    }

    const handlePowerDensity = () => {
        dispatch(RTS.UpdatePowerDensity({powerDensity:!state.powerDensity}))
    }

    const handleSTV = () => {
        dispatch(RTS.UpdateSTV({STV:!state.STV}))
    }

    const handleUWLR = () => {
        dispatch(RTS.UpdateUWLR({UWLR:!state.UWLR}))
    }

    const handleWetUO = () => {
        dispatch(RTS.UpdateWetUO({WetUO:!state.WetUO}))
    }

    const handleLvMax_Lav = () => {
        dispatch(RTS.UpdateLvMax_Lav({LvMax_Lav:!state.LvMax_Lav}))
    }

    const handleLmax_Lmin = () => {
        dispatch(RTS.UpdateLmax_Lmin({Lmax_Lmin:!state.Lmax_Lmin}))
    }

    const handleLav_Lmin = () => {
        dispatch(RTS.UpdateLav_Lmin({Lav_Lmin:!state.Lav_Lmin}))
    }

    const handleEmin_Emax = () => {
        dispatch(RTS.UpdateEmin_Emax({Emin_Emax:!state.Emin_Emax}))
    }

    const handleEmin_Eav = () => {
        dispatch(RTS.UpdateEmin_Eav({Emin_Eav:!state.Emin_Eav}))
    }
    

    const formStyle = {paddingLeft: 10, paddingRight: 10}

    return (
        <div style={{padding: 15}}>
            <div style={{display: "flex", justifyContent: "center"}}>Results Table Settings:</div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.SelectAll}
                            onChange={handleSelectAll}
                            color="primary"
                        />
                    }
                    label="Display All"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.SelectDefault}
                            onChange={handleSelectDefault}
                            color="primary"
                        />
                    }
                    label="Display Default"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.SelectNone}
                            onChange={handleSelectNone}
                            color="primary"
                        />
                    }
                    label="Display None"
                />
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Lav}
                            onChange={handleLav}
                            color="primary"
                        />
                    }
                    label="Lav"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Lmin}
                            onChange={handleLmin}
                            color="primary"
                        />
                    }
                    label="LMin"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Lmax}
                            onChange={handleLmax}
                            color="primary"
                        />
                    }
                    label="LMax"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.UO}
                            onChange={handleUO}
                            color="primary"
                        />
                    }
                    label="UO"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.WetUO}
                            onChange={handleWetUO}
                            color="primary"
                        />
                    }
                    label="Wet UO"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.UL}
                            onChange={handleUL}
                            color="primary"
                        />
                    }
                    label="UL"
                />
                </div>
                {/*THE SECOND COLUMN STARTS HERE*/}
                <div style={{display: "flex", flexDirection: "column"}}>
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Eav}
                            onChange={handleEav}
                            color="primary"
                        />
                    }
                    label="Eav"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Emin}
                            onChange={handleEmin}
                            color="primary"
                        />
                    }
                    label="EMin"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Emax}
                            onChange={handleEmax}
                            color="primary"
                        />
                    }
                    label="EMax"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.AECI}
                            onChange={handleAECI}
                            color="primary"
                        />
                    }
                    label="AECI"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.powerDensity}
                            onChange={handlePowerDensity}
                            color="primary"
                        />
                    }
                    label="Power Density"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Rei}
                            onChange={handleRei}
                            color="primary"
                        />
                    }
                    label="Rei"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.TI}
                            onChange={handleTI}
                            color="primary"
                        />
                    }
                    label="TI"
                />                
                </div>
                {/*THE THIRD COLUMN STARTS HERE*/}
                <div style={{display: "flex", flexDirection: "column"}}>
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.STV}
                            onChange={handleSTV}
                            color="primary"
                        />
                    }
                    label="STV"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.UWLR}
                            onChange={handleUWLR}
                            color="primary"
                        />
                    }
                    label="UWLR"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.LvMax_Lav}
                            onChange={handleLvMax_Lav}
                            color="primary"
                        />
                    }
                    label="LvMax/Lav Ratio"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Lmax_Lmin}
                            onChange={handleLmax_Lmin}
                            color="primary"
                        />
                    }
                    label="LMax/LMin Ratio"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Lav_Lmin}
                            onChange={handleLav_Lmin}
                            color="primary"
                        />
                    }
                    label="Lav/LMin Ratio"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Emin_Emax}
                            onChange={handleEmin_Emax}
                            color="primary"
                        />
                    }
                    label="EMin/EMax Ratio"
                />
                <FormControlLabel
                    style={formStyle}
                    control={
                        <Checkbox
                            checked={state.Emin_Eav}
                            onChange={handleEmin_Eav}
                            color="primary"
                        />
                    }
                    label="EMin/Eav Ratio"
                />
                </div>
            </div>
            {/* <Button
                onClick={() => console.log("Hi")}
            >
                Apply Changes
            </Button> */}
        </div>
    )
}

export default ResultsTableSettings;