import React from 'react';
import RowCard from './CardNav';

const ColumnPage = (props) => {
	return (
		<RowCard />
	);

}
export default ColumnPage; 
