import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { useState, useEffect } from 'react';

const TemplateSelector = ({ isImportDialogOpen, selectTemplate, onSelectTemplate, onClose, onConfirm, onDownload, onDelete, isOpen, isPdfReady }) => {
  const deflautTemplates = ['EN13201:2015 Illuminance', 'EN13201:2015 Luminance'];
  const [templates, setTemplates] = useState(['EN13201: 2015 Illuminance', 'EN13201:2015 Luminance']);
  //const [selectedTemplate, setSelectedTemplate] = React.useState('');

  useEffect(() => {
    //console.log("TemplateSelector useEffect");
    EdgeOrREST(null, "GET_CUSTOM_TEMP", onGetTamp, null);
  }, [isOpen === true]);

  const onGetTamp = (response) => {
    if (response.status === 200) {
      const temps = deflautTemplates;
      response.data.forEach((filename) => { temps.push(filename); });
      setTemplates(temps);
    } else {
      console.log(response);
    }
  };

  const handleSelect = (event) => {
    onSelectTemplate(event.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    //onSelectTemplate(selectedTemplate);
    onConfirm();
  };

  const handleDownload = () => {
    //onSelectTemplate(selectedTemplate);
    onDownload();
  };

  const handleDelete = () => {
    //onSelectTemplate(selectedTemplate);
    onDelete();
  };

  const handleUpload = () => {
    isImportDialogOpen();
  };


  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Template</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Select Template:</InputLabel>
          <Select value={selectTemplate} onChange={handleSelect}>
            {templates.map((template, index) => (
              <MenuItem key={index} value={template}>
                {template}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
          >
            Upload
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!isPdfReady}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateSelector;
