import React, { useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Page from '../components/Page';
import { 
    Grid, 
    makeStyles,
    Container,
    Box,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));


export default function AdminDashboard(props) { 
    
    const [usersWithQueuedJobs, setUsersWithQueuedJobs] = React.useState([]); 
    const [backendVersion, setBackendVersion] = React.useState(""); 
    const [jobsInQueue, setJobsInQueue] = React.useState(0); 

    useEffect(() => {
        let isMounted = true;
        EdgeOrREST(null, 'GET_CODE_VERSION', onGetCodeVersion,  { headers: { 'admin': "an1982dnkdnasd!" } }); 
        EdgeOrREST(null, 'GET_USERS_WITH_QUEUED_JOBS', onGetUsersWithQueuedJobs,  { headers: { 'admin': "an1982dnkdnasd!" } });  
        EdgeOrREST(null, 'GET_NUMBER_OF_JOBS_IN_QUEUE', onGetJobsInQueue,  { headers: { 'admin': "an1982dnkdnasd!" } });  

        return () => { isMounted = false };
    }, []);

    const onGetJobsInQueue = (response) => {
        if(response.status == "200") setJobsInQueue(response.data);  
    }

    const onGetCodeVersion = (response) => {
        if(response.status == "200") setBackendVersion(response.data);  
    }

    const onGetUsersWithQueuedJobs = (response) => {
        if(response.status == "200") setUsersWithQueuedJobs(response.data);  
    }

    const classes = useStyles();

    return (
        <Page className={classes.root} title="Automate" style={{ 'minHeight': '85vh' }}>
            <Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Container>
                        <Box>
                            {"Backend Version: " + backendVersion}
                        </Box>
                        <Box>
                            {"Jobs in Queue: " + jobsInQueue}
                        </Box>
                    </Container>

                    <Container>
                        <Box>
                           <List>  
                            {usersWithQueuedJobs.length > 0 ? usersWithQueuedJobs.map((user) => (
                                <ListItem>
                                     <ListItemText
                                        primary={user.email}         
                                        />                                                    
                                </ListItem>
                            )) : "No users with queued jobs"} 
                            </List> 
                        </Box>
                    </Container>

                </Grid>  
            </Grid>
        </Page >
    );




} 