import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';
import {
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import resultsTableSettingsActions from '../../store/actions/resultsTableSettingsActions';

const WetU0 = () => {

    const ProjectConfig = useSelector(state => state.ProjectConfig);
    const props = useSelector(state => state);
    const GridsCfg = useSelector(state => state.GridsCfg);
    const [checked, setChecked] = useState(GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoad);
    const dispatch = useDispatch();

    const handleChange = () => {
        setChecked(!checked);
        dispatch(gridActions.UpdateCurrentGrid({ WetRoad: !checked }));
        var postObject = props;
        postObject.GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoad = !checked;        
		EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(postObject, null, null));
    }

    const onCalculateGridResults = (response) => {
        populateGridResults(response.data);
    }

    const populateGridResults = (gridResults) => {
        if (gridResults) {
            dispatch(luminaireActions.UpdateAllResults({
                gridResults
            }));
        }
    }

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    name="checkboxWetu0"
                    color="primary"

                />
            }
            label="Wet Road"
            labelPlacement="top" />
    )
}

export default WetU0;