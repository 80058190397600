import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { Divider, AppBar, Tabs, Tab, Card, Typography, Box, CardContent } from '@material-ui/core';
import Column from './RowCard';
import userActions from '../../store/actions/user';
import luminaireActions from '../../store/actions/luminaireActions';
import EdgeOrREST from '../../EdgeOrREST';
import LuminCard from './LuminCard';
import tourActions from '../../store/actions/tourActions';
import { initialState } from '../../store/initial-state';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={0}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	const User = bindActionCreators(userActions, dispatch);
	const Lumin = bindActionCreators(luminaireActions, dispatch);
	const Tour = bindActionCreators(tourActions, dispatch);	
	return {
		UpdateUserCfg: (data) => {
			User.UpdateUserCfg(data);
		},
		UpdateLuminaireList: (data) => {
			Lumin.UpdateLuminaireList(data);
		},
		UpdateTourAccess: (data) => {
			Tour.UpdateTourAccess(data);
		},
	};
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const FullWidthTabs = ({ ColumnCfg, index, removeCard, UpdateUserCfg, UpdateLuminaireList }) => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);
	const state = useSelector((state) => state);
	const dispatch = useDispatch()

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue == 1){
			dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep+1}))
		};
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	return (
		<Card>
			<Divider />
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					<Tab label="Column" {...a11yProps(0)} value={0} />
					<Tab label="Luminaire" {...a11yProps(1)} value={1} />
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<Column ColumnCfg={ColumnCfg} index={index} removeCard={removeCard} />
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
				<Card>
					<CardContent>
						<LuminCard index={index} />
					</CardContent>
				</Card>
				</TabPanel>
			</SwipeableViews>
		</Card >
	);
}

export default connect(null, mapDispatchToProps)(FullWidthTabs);