import { handleActions } from 'redux-actions';
import actions from '../actions/columnActions';
import { initialState } from '../initial-state';

export default handleActions(
    {
        [actions.UpdateColumnCfg]: (state, action) => {
            return { ...state, ...action.payload };
        },
    }, 
    initialState.ColumnCfg 
);
