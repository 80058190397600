import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
    Grid,
    Card,
    Paper,
    Tabs,
    Tab,
    Divider,
    CardContent,
    TextField,
    Box,
    Typography,
} from "@mui/material";
import Page from "../Page";
import { TabPanel } from "@material-ui/lab";
import Comp_SearchResults from "./Comp_SearchResults";
import Comp_RefineSearch from "./Comp_RefineSearch";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SearchConditions() {
    const state = useSelector(state => state.Search)
    const dispatch = useDispatch();
    const [tabIndex, setTabs] = React.useState(0);

    const handleTabs = (event, value) => {
        if (state.load === false) {
            setTabs(value);
        }
    };

    return (
        <Page title="Search" style={{ 'minHeight': '85vh' }}>
            <Grid container style={{ 'minHeight': 'inherit' }}>
                <Grid style={{ 'minHeight': 'inherit', width:"100%" }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={tabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleTabs}
                            >
                                <Tab index={0} label="Search Results" {...a11yProps(0)} value={0} />
                                <Tab index={1} label="Refine Search" {...a11yProps(1)} value={1} />
                            </Tabs>
                        </Paper>
                        <Divider />
                        {tabIndex === 0 && (
                            <Box sx={{ p: 3 }}>
                                <Comp_SearchResults/>
                            </Box>
                        )}
                        {tabIndex === 1 && (
                            <Box sx={{ p: 3 }}>
                                <Comp_RefineSearch/>
                            </Box>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Page >)
}