import React, { useState, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';

function SVGTest() {
  const [file, setFile] = useState(null);
  const [svgContent, setSvgContent] = useState('');
  const [error, setError] = useState('');
  const [layers, setLayers] = useState([]);
  const [layerVisibility, setLayerVisibility] = useState({});

  const onSetImage = (event) => {
    setFile(event.target.files[0]);
  };

  const onUpload = () => {
    if (file) {
      EdgeOrREST(null, 'CONVERT_TO_SVG', onConvertToSVG, file);        
    } else {
      setError('Please select a file to upload.');
    }
  };

  const onConvertToSVG = (response) => {
    if (response) {
      setSvgContent(response);
      extractLayersFromConvertedSVG(response);
    } else {
      setError('Error converting file to SVG.');
    }
  };

  const onSetSVG = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const svg = e.target.result;
      setSvgContent(svg);
      extractLayersFromSVG(svg);
    };
    reader.readAsText(file);
  };

  const extractLayersFromConvertedSVG = (svg) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, 'image/svg+xml');
    const layerSet = new Set();
    doc.querySelectorAll('[data-layer]').forEach((element) => {
      layerSet.add(element.getAttribute('data-layer'));
    });
    const layerArray = Array.from(layerSet);
    setLayers(layerArray);

    const initialVisibility = {};
    layerArray.forEach(layer => {
      initialVisibility[layer] = true;
    });
    setLayerVisibility(initialVisibility);
  };

  const extractLayersFromSVG = (svg) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, 'image/svg+xml');
    const layerSet = new Set();
    doc.querySelectorAll('g[id]').forEach((element) => {
      layerSet.add(element.getAttribute('id'));
    });
    const layerArray = Array.from(layerSet);
    setLayers(layerArray);

    const initialVisibility = {};
    layerArray.forEach(layer => {
      initialVisibility[layer] = true;
    });
    setLayerVisibility(initialVisibility);
  };

  const toggleLayerVisibility = (layer) => {
    setLayerVisibility((prevVisibility) => {
      const newVisibility = {
        ...prevVisibility,
        [layer]: !prevVisibility[layer],
      };
      updateSvgVisibility(newVisibility);
      return newVisibility;
    });
  };

  const updateSvgVisibility = (visibility) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');
    layers.forEach(layer => {
      doc.querySelectorAll(`g[id='${layer}']`).forEach(element => {
        element.setAttribute('display', visibility[layer] ? 'visible' : 'none');
      });
    });
    setSvgContent(new XMLSerializer().serializeToString(doc));
  };

  useEffect(() => {
    if (svgContent) {
      updateSvgVisibility(layerVisibility);
    }
  }, [layers]); // Initial render after extracting layers

  return (
    <div>
      <div>
        <input type="file" accept=".dwg,.dxf" onChange={onSetImage} />
        <button onClick={onUpload}>Upload and Convert</button>
      </div>
      <div>
        <input type="file" accept=".svg" onChange={onSetSVG} />
        <button onClick={() => setSvgContent('')}>Clear SVG</button>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {svgContent && (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <h3>Layers:</h3>
            {layers.map(layer => (
              <div key={layer}>
                <label>
                  <input
                    type="checkbox"
                    checked={layerVisibility[layer]}
                    onChange={() => toggleLayerVisibility(layer)}
                  />
                  {layer}
                </label>
              </div>
            ))}
          </div>
          <div style={{ flex: 1 }}>
            <h3>SVG Output:</h3>
            <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SVGTest;
