import React from 'react';
import {
	TableCell,
	TableRow
} from '@material-ui/core';
export default class ResultsTableHolder extends React.Component {
	render() {
		var standard = this.props.standard;
		var result = this.props.result;
		if(this.props.name){
			console.log(this.props);
		}

		var symbol = this.props.symbol;
		if (this.props.symbol === undefined) symbol = '';
		if (this.props.defaultBool) standard = this.props.defaultValue;

		var resultClass = this.props.greater
		? standard <= result ? 'passed' : 'fail'
		: standard >= result ? 'passed' : 'fail';

		if (this.props.hide === true) return null;
		else if (this.props.columnProp === true) {
			if (result === '---' || result === 'Failed') {
				return (
					<TableRow>
						<TableCell>{this.props.children}</TableCell>
						<TableCell>N/A</TableCell>
						<TableCell>{result}</TableCell>
					</TableRow>
				);
			}
			else return (
				<TableRow>
					<TableCell>{this.props.children}</TableCell>
					<TableCell>N/A</TableCell>
					<TableCell>{result}{symbol}</TableCell>
				</TableRow>

			);
		}
		else return (
			<TableRow>
				<TableCell>{this.props.children}</TableCell>
				<TableCell>{symbol + ' ' + standard}</TableCell>
				<TableCell className={resultClass} style={resultClass === 'fail' && standard != 'N/A' ? { color: 'red' } : {}}>{result}</TableCell>
			</TableRow>
		);
	}
}
