import { handleActions } from 'redux-actions';
import actions from '../actions/monitorActions';
import { initialState } from '../initial-state';
export default handleActions(
    {
        [actions.UpdateMonitorCfg]: (state, action) => {
            console.log("in monitor reducer state", state);
            console.log("in monitor reducer action", action);
            return { ...state, ...action.payload };
        }
    },
    initialState.MonitorCfg
);
