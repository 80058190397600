import React from 'react';
import LaneWidth from '../../components/Roadway/Comp_LaneWidth';
import NumLanes from '../../components/Roadway/Comp_NumLanes';
import StandardElements from '../../components/Standards/calcGrid.component';
import RoadElements from '../../components/Roadway/roadElements.component';
import {
	Grid
} from '@material-ui/core';

const ConfigurationOptions = ({ index }) => {

	return (
		<Grid container spacing={2}>
			<Grid item md={12} sm={12} xs={12}>
				<StandardElements index={index} />
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<RoadElements index={index} />
				<Grid container spacing={2} >
					<Grid item md={6} sm={12} xs={12}>
						<NumLanes index={index} />
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<LaneWidth index={index} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ConfigurationOptions;