import { apiService } from './axios.client';

export const RoleEndPoints = {
    getRoleByUserId: function (input, callback) {
        apiService.get('/role/GetRolesByUserId', input)
            .then((response) => callback(response, input.props))
            .catch(error => alert(error));
    },
    getProductsByCompanyId: function (input, callback) {
        apiService.get('/role/GetProductsByCompanyId', input)
            .then((response) => callback(response, input.props))
            .catch(error => alert(error));
    }
};

