import React, { Component } from 'react';
import {
	Card,
	CardContent,
	Divider,
	Grid,
	Paper,
	Tabs,
	Tab,
	makeStyles

} from '@material-ui/core';
import RoadTabs from './roadTabs.component';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '85vh',
		padding: theme.spacing(3)
	}
}));




const RoadwayPage = () => {


	return (
		// <Grid item lg={12} md={12} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
		// 	<Card style={{ 'minHeight': 'inherit' }}>
		// 		<Paper square>
					// <Tabs
					// 	//value={value}
					// 	indicatorColor="primary"
					// 	textColor="primary"
					// 	//onChange={handleChange}
					// 	aria-label="disabled tabs example"
					// >
					// 	<Tab label="Configuration" />
					// </Tabs>
		// 		</Paper>
		// 		<Divider />
		// 		<CardContent>
					<RoadTabs />
		// 		</CardContent>
		// 	</Card>
		// </Grid>

		// 	<div>
		// 		<StandardElements />
		// 		<RoadType />
		// 		<CIE115 />
		// 	</div>
		// 	<div>
		// 		<RoadElements />
		// 		<LaneWidth />
		// 		<NumLanes />
		// 		<HardShoulder />
		// 		<HSCalcGridClass />
		// 		<HSWidth />
		// 	</div>
		// 	<div className="col-xs-2">
		// 		<DualCarriageway />
		// 		<CentralReserve />
		// 		<FootpathRight />
		// 		<FootpathLeft />
		// 	</div>
		// 	{/*<div className="col-xs-2">
		// 			<DisableFootpaths />
		// 			<DutchSClass />
		// 			<DutchROVL />
		// 			<FrenchUl />
		// 			<WetU0 />
		// 			<WetRoadSurface />
		// 			<WetQ0 />
		// 		</div>*/}
		// 	<div className="col-xs-2">
		// 		<CustomStandards />
		// 	</div>
		// </>
	);

}
export default RoadwayPage;
//export default connect(mapStateToProps)(RoadwayPage);