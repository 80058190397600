import React, { useEffect, useState } from 'react';
import { TextField, Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({outlineRed: {
    borderColor: red[500],
    borderWidth: '2px'
},}));

const FluxTextField = ({ defaultValue, initialValue, onBlur, label }) => {
    const formattedInitialValue = Number(initialValue).toFixed(2);
    const [value, setValue] = useState(formattedInitialValue);
	const classes = useStyles();
    let highlight = value != defaultValue.toFixed(2) ? classes.outlineRed : null;
    const handleInputChange = (event) => {
        let val = event.target.value
        if (val === null || val === undefined || val < 0) {
            setValue(parseInt(0));
        }
        else setValue(val);
    };

    const handleBlur = () => {
        if (value === '' || value === null || value === undefined || value < 0) {
            onBlur(0);
        }
        else onBlur(value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (value === '' || value === null || value === undefined || value < 0) {
                onBlur(0);
            }
            else onBlur(value);
            event.target.blur();
        }
    };

    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            type="number"
            variant="outlined"
            InputProps={{
                classes:{notchedOutline: highlight}
            }}
            FormHelperTextProps={{ style: { margin: 0 } }}
            helperText={
                <Tooltip  title="Manual override of flux value, use 0 for default flux">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>									
            }
        />
    );
};

export default FluxTextField;
