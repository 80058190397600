import React, { Component, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import GroupPage from '../../components/Results/group.component';
import { Outlet } from 'react-router-dom';
import {
	Box,
	Card,
	CardContent,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Switch,
	Tabs,
	Tab,
	Typography,
	makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import ResultsPage from '../../components/Results/Results';
import ConfigurationPage from '../optimize_configuration';
import ColumnPage from '../../components/Column/Page_Column';
import RoadwayPage from '../../components/Roadway/Page_Roadway';
import PhotometricViewer from '../../components/Luminaire/Hierarchy/photometry-viewer.component';
import luminActions from '../../store/actions/luminaireActions';
import tourActions from '../../store/actions/tourActions';
import Canvas from "../Calc_Area/gridCanvas.component";
import Isolux from '../Calc_Area/isolux.component';
import Layout from '../Calc_Area/layout.component';
import { initialState } from '../../store/initial-state';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const mapDispatchToProps = (dispatch) => {
	const Tour = bindActionCreators(tourActions, dispatch);	
	const Luminaire = bindActionCreators(luminActions, dispatch);
	return {
		UpdateTourAccess: (data) => {
			Tour.UpdateTourAccess(data);
		},
		UpdateLuminCfg: (data) => {
			Luminaire.UpdateLuminCfg(data);
		},
	};
};

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '85vh',
		padding: theme.spacing(3)
	}
}));


const Dashboard = ({ UpdateLuminCfg }) => {
	const state = useSelector((state) => state);
	const [leftTabIndex, setLeftTabs] = React.useState(0);
	const [middleTabIndex, setMiddleTabs] = React.useState(0);
	const [rightTabIndex, setRightTabs] = React.useState(0);
	const dispatch = useDispatch();

	const handleChange = (event) => {
		UpdateLuminCfg({ [event.target.name]: event.target.checked });
	};

	const handleLeftTabs = (event, value) => {
		dispatch(tourActions.UpdateTourAccess({ enabled: true}));
		if (state.EnabledTour.currentTab=="Row"){
			dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep+1}));
		} else if (state.EnabledTour.currentTab=="Roadway"){
			dispatch(tourActions.UpdateTourAccess({ enabled: false}));
		}
		setLeftTabs(value);
	};
	const handleMiddleTabs = (event, value) => {
		setMiddleTabs(value);
	};
	const handleRightTabs = (event, value) => {
		setRightTabs(value);
	};
	const classes = useStyles();

	return (
		<Page className={classes.root} title="Optimize" style={{ 'minHeight': '85vh', 'maxHeight': '85vh' }}>
			<Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
				{/* First row */}
				<Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
					<Card style={{ 'minHeight': 'inherit' }}>
						<Paper square>
							<Tabs
								value={leftTabIndex}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								onChange={handleLeftTabs}
								aria-label="left tabs"
							>
								<Tab index={0} label="Roadway" {...a11yProps(0)} value={0} onMouseDown={() => dispatch(tourActions.UpdateCurrentTab({currentTab: "Roadway"}))}/>
								<Tab index={1} label="Row" {...a11yProps(1)} value={1} className={"optimizeStep3 rowTabId"} onMouseDown={() => dispatch(tourActions.UpdateCurrentTab({currentTab: "Row"}))}/>
								<Tab index={2} label="Photometry" {...a11yProps(2)} value={2}  className={"optimizeStep5 photometryTabId"} onMouseDown={() => dispatch(tourActions.UpdateCurrentTab({currentTab: "Photometry"}))}/>
							</Tabs>
						</Paper>
						
						<Divider />
						<TabPanel className='optimizeStep2' value={leftTabIndex} index={0}>
							<CardContent>
								<RoadwayPage />
							</CardContent>
						</TabPanel>
						<TabPanel className='optimizeStep4' value={leftTabIndex} index={1}>
							<CardContent>
								<ColumnPage />
							</CardContent>
						</TabPanel>
						<TabPanel className="optimizeStep6" value={leftTabIndex} index={2}>
							<CardContent>
								<PhotometricViewer />
							</CardContent>
						</TabPanel>
					</Card>
				</Grid>
				{/* Second row */}
				<Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
					<Card style={{ 'minHeight': 'inherit' }}>
						<Paper square>
							<Tabs
								value={middleTabIndex}
								indicatorColor="primary"
								textColor="primary"
								label="middleTabs"
								variant="fullWidth"
								onChange={handleMiddleTabs}
								aria-label="middle tabs"
							>
								<Tab name="roadVisualTab" label="Road Visual" {...a11yProps(0)} value={0} />
								{/* <Tab name="tr28Tab" label="TR28 Test Grid" {...a11yProps(1)} value={1} /> */}
								{/* <Tab name="layoutTab" label="Layout" {...a11yProps(2)} value={2} /> */}
							</Tabs>
						</Paper>
						<Divider />
						<TabPanel value={middleTabIndex} index={0}>
							<CardContent style={{ paddingBottom: 16 }}>
								<Canvas />
								<Divider />
								<FormGroup row>
									<Grid container spacing={2}>
										<Grid item lg={3} md={3} sm={3} xs={3}>
											<FormControlLabel
												control={
													<Switch
														checked={state.LuminaireCfg.checkedResults}
														onChange={handleChange}
														name="checkedResults"
														color="primary"
													/>
												}
												label="Results"
												labelPlacement="bottom"
											/>
										</Grid>
										<Grid item lg={3} md={3} sm={3} xs={3}>
											<FormControlLabel
												control={
													<Switch
														checked={state.LuminaireCfg.checkedColouring}
														onChange={handleChange}
														name="checkedColouring"
														color="primary"
													/>
												}
												label="Greyscale"
												labelPlacement="bottom"
											/>
										</Grid>
										<Grid item lg={3} md={3} sm={3} xs={3}>
											<FormControlLabel
												control={
													<Switch
														checked={state.LuminaireCfg.checkedGreyscale}
														onChange={handleChange}
														name="checkedGreyscale"
														color="primary"
													/>
												}
												label="Mono/Colour"
												labelPlacement="bottom"
											/>
										</Grid>
										<Grid item lg={3} md={3} sm={3} xs={3}>
											<FormControlLabel
												control={
													<Switch
														checked={state.LuminaireCfg.checkedContours}
														onChange={handleChange}
														name="checkedContours"
														color="primary"
													/>
												}
												label="Contours"
												labelPlacement="bottom"
											/>
										</Grid>
									</Grid>
								</FormGroup>
							</CardContent>

						</TabPanel>
						{/* <TabPanel value={middleTabIndex} index={1}>
							<CardContent style={{ position: 'relative' }}>
								<Isolux id="isoluxId" />
							</CardContent>
						</TabPanel> */}
						{/* <TabPanel value={middleTabIndex} index={2}>
							<Layout />
						</TabPanel> */}
					</Card>
				</Grid>
				{/* Third row */}
				<Grid className='optimizeStep7' item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
					<Card style={{ 'minHeight': 'inherit' }}>
						<Paper square>
							<Tabs
								value={rightTabIndex}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								onChange={handleRightTabs}
								aria-label="disabled tabs example"
							>
								<Tab index={0} label="Results" {...a11yProps(0)} value={0} />
								<Tab index={1} label="Configurations" {...a11yProps(1)} value={1} />
								<Tab index={2} label="Group Optimize" {...a11yProps(2)} value={2} />
							</Tabs>
						</Paper>

						<Divider />
						<TabPanel value={rightTabIndex} index={0}>
							<CardContent>
								<ResultsPage></ResultsPage>
							</CardContent>
						</TabPanel>
						<TabPanel value={rightTabIndex} index={1}>
							<CardContent>
								<ConfigurationPage />
							</CardContent>
						</TabPanel>
						<TabPanel value={rightTabIndex} index={2}>
							<CardContent>
								<GroupPage />
							</CardContent>
						</TabPanel>
					</Card>
				</Grid>

			</Grid >
		</Page >
	);
}

export default connect(null, mapDispatchToProps)(Dashboard);