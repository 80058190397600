import React, { useState, useCallback, Component, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import FileBase64 from 'react-file-base64';
import {
  Card,
  Tabs,
  Tab,
  Box,
  CardContent,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  Chip,
  DialogContent,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PhotometricAttributeSelector from './photometice-attributes-menu/photometric-attribute-selector.component';
import Notification from '../components/shared/notification.component';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import tourActions from '../store/actions/tourActions';
import PhotometricStreetlightSearch from './photometric-streetlight-search.component';
import PhotometricAttributeStreetlightSelector from './photometice-attributes-menu/photometric-attribute-streetlight-selector.component';
import luminaireActions from '../store/actions/luminaireActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  margin: {
    margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  container: {
    maxHeight: '35vh',
    minHeight: '35vh',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const initialState = {
  colors: [],
  bodys: [],
  leds: [],
  drivers: [],
  power: [],
};

const tempAttributes = {
  colors: "",
  bodys: "",
  leds: "",
  drivers: "",
  power: "",
};

const PhotometricUpload = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [privateLuminaire, setPrivacy] = React.useState({
    checked: true
  });
  const [fileState, setFileState] = React.useState({
    files: []
  });
  const [uploading, setUploading] = React.useState(false);
  const [uploadIds, setUploadedIds] = React.useState([]);
  const [attributeParams, setAttributeParams] = useState({});
  const [tabIndex, setTabs] = React.useState(0);
  const [virtualAttributes, setVirtualAttributes] = React.useState(initialState);
  const [virtualTempAttributes, setVirtualTempAttributes] = React.useState(tempAttributes);
  const [totalVirtualLuminaires, setTotalVirtualLuminaires] = useState(0);
  const [sku, setSku] = useState("");
  const [spektdUser, setSpektdUser] = useState(true);
  const [attributesTabIndex, setAttributesTabIndex] = React.useState(0);

  useEffect(() => {
    setTotalVirtualLuminaires(virtualAttributes.leds.length * virtualAttributes.colors.length * virtualAttributes.bodys.length * virtualAttributes.drivers.length * virtualAttributes.power.length);
  }, [virtualAttributes]);

  const handleChange = (event) => {
    setPrivacy({ ...privateLuminaire, 'checked': event.target.checked });
  };

  const handleSelectRow = (row, event) => {
    const currList = fileState.files;
    for (let file of currList) {
      console.log("file name", file.name);
      console.log("row name", row.name);
      console.log("equal?", row.name === file.name);
      if (file.name === row.name) {
        file.selected = !file.selected;
        break;
      }
    }
    setFileState({ 'files': currList });
    console.log("selected list", currList)
  };

  const handleFileInputChange = (event) => {
    const newList = fileState.files ? [...fileState.files] : [];
    const selectedFiles = event.target.files;

    for (const file of selectedFiles) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64 = reader.result;
        let base64string = base64.replace("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", "");
        base64string = base64string.replace("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", "");
        base64string = base64string.replace("data:application/octet-stream;base64,", "");
        base64string = base64string.replace("data:application/reluxLum;base64,", "");
        const tempFile = {
          name: file.name,
          size: file.size,
          type: file.name.split('.').pop().toLowerCase(),
          selected: true,
          base64: base64string,
        };
        newList.push(tempFile);
        setFileState({ files: newList });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
      console.log("newList", newList);
    }
    setUploading(false);
  };

  // const getFiles = (files) => {
  //   const newList = fileState.files ? [...fileState.files] : [];
  //   for (let file of files) {
  //     let base64 = file.base64.replace("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", "");
  //     base64 = base64.replace("data:application/octet-stream;base64,", "");
  //     base64 = base64.replace("data:application/reluxLum;base64,", "");
  //     // console.log("base 64", base64);
  //     file.type = file.name.split('.').pop();
  //     file.selected = true;
  //     file.base64 = base64;

  //     newList.push(file);
  //   }
  //   setFileState({ 'files': newList });
  // };

  const handleSubmit = (event) => {
    //confirm 
    if (tabIndex === 0) {
      uploadLuminaires(attributeParams);
    } else if (tabIndex === 2) {
      uploadVirtualLuminaires(attributeParams);
    }
    setTimeout(() => { dispatch(tourActions.UpdateCurrentStep({ currentStep: state.EnabledTour.currentStep + 1 })) }, 50);
  }

  const uploadVirtualLuminaires = (attributes) => {
    console.log(attributes);
    if (sku == "") {
      setSnack({
        open: true,
        message: "Please enter SKU",
        severity: 'error'
      });
      return;
    }
    var postArr = [];
    sku.split(",").map((name) => {
      const tempAttributes = { ...attributes };
      tempAttributes.sku = name.trim();
      postArr.push({ 'file': "", 'type': "", 'name': name.trim(), 'isPublic': false, 'attributes': tempAttributes });
    });
    setUploading(true);
    console.log("postArr, props.groupId", postArr, props.groupId);
    EdgeOrREST(null, 'CREATE_VIRTUAL_LUMINAIRE_BY_SKU', onUploadLuminairesAndAddGroup, { groupId: props.groupId, addLuminaire: postArr });

  };

  const uploadLuminaires = (attributes) => {
    console.log(attributes);
    if (fileState.files && fileState.files.length > 0 && attributes) {
      var postArr = [];
      for (let i = 0; i < fileState.files.length; i++) {
        postArr.push({ 'file': fileState.files[i].base64, 'type': fileState.files[i].type, 'name': fileState.files[i].name, 'isPublic': false, 'attributes': attributes });
      }
      setUploading(true);
      console.log("postArr, props.groupId", postArr, props.groupId);
      EdgeOrREST(null, 'UPLOAD_LUMINAIRES_AND_ADD_GROUP', onUploadLuminairesAndAddGroup, { groupId: props.groupId, addLuminaire: postArr });
    }
  };

  const onUploadLuminairesAndAddGroup = (response) => {
    if (response && response.data) {
      if (response.status == 200) {
        console.log("response", response.data);
        setUploadedIds(response.data.successIds);
        setSnack({
          open: true,
          message: response.data.successIds.length + ' Luminaire(s) have been uploaded with attributes successfully.',
          severity: 'success'
        });
        //props.resetGotChildren();
        props.setGotChildren(props.gotChildren.filter((item) => item !== props.groupId))
        props.setExpanded(props.expanded.filter((item) => item !== props.groupId));
        setFileState({ 'files': [] = [] });       
      } else {
        setSnack({
          open: true,
          message: response.status + response.message,
          severity: 'error'
        });
      }
      setUploading(false);
    }
  };

  const onSearchUploadedLuminaires = (response) => {
    console.log("onSearchUploadedLuminaires", response);
    if (response && response.data && response.status == 200) {
      dispatch(user.UpdateUserCfg({ searchedLuminaires: response.data }));
    }
  }

  useEffect(() => {
    if (uploadIds && uploadIds.length > 0) EdgeOrREST(null, 'SEARCH_UPLOADED_LUMINAIRES', onSearchUploadedLuminaires, uploadIds);
  }, [uploadIds]);

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  function checkForAdminPriv() {
    var isAdmin = false;
    var checkbox = [];
    if (state.user.roles) {
      for (var i = 0; i < state.user.roles.length; i++) {
        if (state.user.roles[i].name === "admin") {
          isAdmin = true;
        } else if (state.user.roles[i].name === "spektdUser") {
          setSpektdUser(true);
        }
      }

      if (isAdmin) {
        checkbox.push(
          <FormControlLabel
            control={<Checkbox checked={privateLuminaire.checked} onChange={handleChange} name="privateLuminaire" color="primary" />}
            label={<Typography>Private Luminaires</Typography>}
            className={classes.margin}
          />);
      }

    }
    return checkbox;
  }

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
    console.log("upload attributeParams", attributeParams);
  }, []);

  const handleTabs = (event, value) => {
    setTabs(value);
  };

  const handleAttributesTabs = (event, value) => {
    setAttributesTabIndex(value);
  };

  const onVirtualOptionChange = (event) => {
    console.log("onVirtualOptionChange", event.target.value);
    setVirtualTempAttributes({ ...virtualTempAttributes, [event.target.name]: event.target.value });
  }

  const handleAddOptionClick = (e, object) => {
    console.log("handleAddOptionClick", object);
    var temp = virtualAttributes[object];
    temp.push(virtualTempAttributes[object]);
    setVirtualAttributes({
      ...virtualAttributes,
      [object]: temp
    });
    setVirtualTempAttributes({
      ...virtualTempAttributes,
      [object]: ""
    });
    console.log("virtualAttributes: ", virtualAttributes);
  }

  const handleDelete = (name, object) => {
    console.log("handleDelete", name);
    console.log("object", object);

    const target = virtualAttributes[name].filter(item => item !== object);
    const newVirtualAttributes = {
      ...virtualAttributes,
      [name]: target
    };
    setVirtualAttributes(newVirtualAttributes);
  }

  return (
    <Card style={{ 'minHeight': 'inherit' }}>
      <Paper square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={handleTabs}
          aria-label="disabled tabs example"
        >
          <Tab index={0} label="Upload Luminaires" {...a11yProps(0)} value={0} />
          {/* <Tab index={1} name="virtualLuminaires" label="Virtual Luminaires"  {...a11yProps(1)} value={1} /> */}
          {!spektdUser ? <></> : <Tab index={2} name="createBySku" label="Create by SKU"  {...a11yProps(2)} value={2} />}
        </Tabs>
      </Paper>
      <TabPanel value={tabIndex} index={0}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} spacing={2}>
              <input
                type="file"
                multiple
                accept=".ldt,.ies"
                onChange={handleFileInputChange}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} spacing={2}>
              <TableContainer className={classes.container} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Size</TableCell>
                      <TableCell align="right">Selected</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      uploading ?
                        <div style={{ textAlign: 'center', margin: 'auto', width: '375%', paddingTop: '2rem' }}>
                          <IconButton style={{ margin: 'auto' }}>  <CircularProgress />  </IconButton>
                        </div>
                        :
                        fileState.files.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">{row.name} </TableCell>
                            <TableCell align="right">{row.type}</TableCell>
                            <TableCell align="right">{row.size}</TableCell>
                            <TableCell align="right">
                              <FormControlLabel
                                control={<Checkbox checked={row.selected} onChange={e => handleSelectRow(row, e)} color="primary" />}
                                className={classes.margin}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
                {fileState.files.length > 0 ? "" : (<div style={{ textAlign: 'center', margin: 'auto', width: '50%', paddingTop: '2rem' }}>Upload Luminaire Files</div>)}
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </TabPanel>

      {/* <TabPanel value={tabIndex} index={1}>
        <CardContent>
          <FormControl className={classes.formControl} size="medium" >
            <Typography id="textField" gutterBottom>
              {"LED"}
            </Typography>
            <TextField name="leds" type="text" onChange={onVirtualOptionChange} value={virtualTempAttributes.leds} />
            {!virtualAttributes.leds ? <></> : virtualAttributes.leds.map((led) => {
              return (
                <Chip
                  label={led}
                  onDelete={(e) => handleDelete("leds", led)}
                />
              )
            })}
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button
                name="leds"
                variant="contained"
                onClick={(e) => handleAddOptionClick(e, "leds")}
                color='primary'
              >
                Add
              </Button>
            </div>
          </FormControl>
          <FormControl className={classes.formControl} size="medium">
            <Typography id="textField" gutterBottom>
              {"Color Temperature"}
            </Typography>
            <TextField name="colors" type="text" value={virtualTempAttributes.colors} onChange={onVirtualOptionChange} />
            {!virtualAttributes.colors ? <></> : virtualAttributes.colors.map((color) => {
              return (
                <Chip
                  label={color}
                  onDelete={(e) => handleDelete("colors", color)}
                />
              )
            })}
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button
                name="colors"
                variant="contained"
                onClick={(e) => handleAddOptionClick(e, "colors")}
                color='primary'
              >
                Add
              </Button>
            </div>
          </FormControl>
          <FormControl className={classes.formControl} size="medium">
            <Typography id="textField" gutterBottom>
              {"Body"}
            </Typography>
            <TextField name="bodys" type="text" value={virtualTempAttributes.bodys} onChange={onVirtualOptionChange} />
            {!virtualAttributes.bodys ? <></> : virtualAttributes.bodys.map((body) => {
              return (
                <Chip
                  label={body}
                  onDelete={(e) => handleDelete("bodys", body)}
                />
              )
            })}
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button
                name="bodys"
                variant="contained"
                onClick={(e) => handleAddOptionClick(e, "bodys")}
                color='primary'
              >
                Add
              </Button>
            </div>
          </FormControl>
          <FormControl className={classes.formControl} size="medium">
            <Typography id="textField" gutterBottom>
              {"Driver"}
            </Typography>
            <TextField name="drivers" type="text" value={virtualTempAttributes.drivers} onChange={onVirtualOptionChange} />
            {!virtualAttributes.drivers ? <></> : virtualAttributes.drivers.map((driver) => {
              return (
                <Chip
                  label={driver}
                  onDelete={(e) => handleDelete("drivers", driver)}
                />
              )
            })}
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button
                name="drivers"
                variant="contained"
                onClick={(e) => handleAddOptionClick(e, "drivers")}
                color='primary'
              >
                Add
              </Button>
            </div>
          </FormControl>
          <FormControl className={classes.formControl} size="medium">
            <Typography id="textField" gutterBottom>
              {"Power"}
            </Typography>
            <TextField name="power" type="text" value={virtualTempAttributes.power} onChange={onVirtualOptionChange} />
            {!virtualAttributes.power ? <></> : virtualAttributes.power.map((power) => {
              return (
                <Chip
                  label={power}
                  onDelete={(e) => handleDelete("power", power)}
                />
              )
            })}
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px' }}>
              <Button
                name="power"
                variant="contained"
                onClick={(e) => handleAddOptionClick(e, "power")}
                color='primary'
              >
                Add
              </Button>
            </div>
          </FormControl>
        </CardContent>
        Total Virtual Luminaires: {totalVirtualLuminaires}
      </TabPanel> */}

      <TabPanel value={tabIndex} index={2}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} spacing={2} >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="sku"
                label="SKU"
                name="sku"
                autoComplete="sku"
                autoFocus
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                helperText="Please enter SKU(s), each separated by a comma."
              />
              {!sku ? <></> : sku.split(",").map((lumin) => {
                return (
                  <Chip
                    label={lumin}
                  />
                )
              })}
            </Grid>
          </Grid>
        </CardContent>
      </TabPanel>
      <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', marginRight: '10px', marginBottom: '10px', textAlign: "right" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <Tabs
        value={attributesTabIndex}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleAttributesTabs}
        aria-label="disabled tabs example"
      >
        <Tab index={0} label="Street Lighting" {...a11yProps(0)} value={0} />
        {!spektdUser ? <></> : <Tab index={1} label="Spektd"  {...a11yProps(1)} value={1} />}
      </Tabs>

      <TabPanel value={attributesTabIndex} index={0}>
        <PhotometricAttributeStreetlightSelector handleAttributeParams={handleAttributeParams} upload={true} groupId={props.groupId} />
      </TabPanel>

      <TabPanel value={attributesTabIndex} index={1}>
        {spektdUser ?
          <PhotometricAttributeSelector handleAttributeParams={handleAttributeParams} upload={true} groupId={props.groupId} disabledSku={sku != ""} />
          : <></>}
      </TabPanel>

      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Card>
  );

}
export default PhotometricUpload; 