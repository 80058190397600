import React from 'react';
import lrLogo from '../IMG/Logos/R8962 Lighting Reality transparent.png'
import { useDispatch, useSelector } from 'react-redux';
import { initialState } from '../store/initial-state';
import selectActions from '../store/actions/selectActions';

const Logo = (props) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()

  if (state.Select.logo == initialState.Select.logo) {
    dispatch(selectActions.UpdateLogo({logo: lrLogo}))
    dispatch(selectActions.UpdateText({fileText: "Change Logo"}))
  }

  return (
    <img style={{ "height": "32px" }}
      alt="Logo"
      src={state.Select.theme ? state.Select.logo : lrLogo}
      {...props}
    />
  );
};

export default Logo;
