import { handleActions } from 'redux-actions';
import actions from '../actions/searchActions';
import { initialState } from '../initial-state';

export default handleActions(
    {
        [actions.UpdateCondition]: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
     initialState.Search
	
);
