import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Close from "@material-ui/icons/Close";
import Add from "@mui/icons-material/AddRoad";
import EditRoad from "@mui/icons-material/EditRoad";
import cloneDeep from "lodash/cloneDeep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RoadTab from './roadTab.component';
import gridActions from '../../store/actions/gridActions';
import luminActions from '../../store/actions/luminaireActions';
import columnActions from '../../store/actions/columnActions';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';

const mapDispatchToProps = (dispatch) => {
	const Luminaire = bindActionCreators(luminActions, dispatch);
	const Grid = bindActionCreators(gridActions, dispatch);
	const ColumnCfg = bindActionCreators(columnActions, dispatch);
	return {
		UpdateGridsCfg: (data) => {
			Grid.UpdateGridsCfg(data);
		},
		FillGridResults: (data) => {
			Luminaire.UpdateAllResults(data);
		},
		UpdateColumnCfg: (data) => {
			ColumnCfg.UpdateColumnCfg(data);
		}
	};
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={0}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
	tab: {
		minWidth: 50, // a number of your choice
		width: 75, // a number of your choice
	},
	icon: {
		alignItems: "end"
	}


}));

const FullWidthTabs = ({ UpdateGridsCfg, FillGridResults, UpdateColumnCfg }) => {

	const reduxProps = useSelector(state => state);

	const classes = useStyles();
	const theme = useTheme();
	const [active, setActive] = React.useState(reduxProps.GridsCfg.currentIndex);
	const tabList = reduxProps.GridsCfg.RoadGrids;


	const handleChange = (index) => {
		setActive(index);
		UpdateGridsCfg({ currentIndex: index });
	};

	const addTab = (e) => {
		let id = Number.parseInt(e.currentTarget.id);
		let RoadGrids = cloneDeep(reduxProps.GridsCfg.RoadGrids);
		let RoadGrid = cloneDeep(reduxProps.GridsCfg.RoadGrids[id]);
		RoadGrids.push(RoadGrid);
		CalcGrid(RoadGrids, active);
		UpdateGridsCfg({ RoadGrids });
	}

	const deleteTab = (e) => {
		e.stopPropagation();
		let RoadGrids = cloneDeep(reduxProps.GridsCfg.RoadGrids);
		let ColumnCfg = cloneDeep(reduxProps.ColumnCfg);
		let id = Number.parseInt(e.currentTarget.id);
		if (RoadGrids.length === 1) return;
	
		let newList = RoadGrids.filter((v, index) => id !== index);

		let newActive = active;
	
		if (active === id) {
			if (active !== 0) {
				newActive = active - 1;
			}
			else if (newList.length > 0) {
				newActive = 0;
			}
		}

		const newColumns = ColumnCfg.Columns.map((column) => {
			if (column.columnGrid > id) {
			  // If the gridHook value is greater than the index of the deleted RoadGrid,
			  // decrement it by 1 to reflect the new index of the grid it is assigned to
			  return { ...column, columnGrid: column.columnGrid - 1 };
			}
			if (column.columnGrid === id) {
			  // If the column is assigned to the deleted RoadGrid, assign it to the first RoadGrid
			  return { ...column, columnGrid: 0 };
			}
			return column;
		  });
	
		CalcGrid(newList, newActive);
		setActive(newActive);
		UpdateGridsCfg({ RoadGrids: newList });
		UpdateColumnCfg({ ...ColumnCfg, Columns: newColumns });
	}
	

	const onDragEnd = result => {
		if (!result.destination) return;
		if (result.destination.index === result.source.index) return;
		const RoadGrids = cloneDeep(reduxProps.GridsCfg.RoadGrids);
		const [removed] = RoadGrids.splice(result.source.index, 1);
		RoadGrids.splice(result.destination.index, 0, removed);
		UpdateGridsCfg({ RoadGrids });
		CalcGrid(RoadGrids, active);
	};

	const CalcGrid = (RoadGrids, index) => {
		let calcObj = reduxProps;
		calcObj.GridsCfg.currentIndex = index;
		EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(calcObj, "roadGrids", RoadGrids));
	}

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			let index = reduxProps.GridsCfg.currentIndex;
			FillGridResults({
				index,
				gridResults
			});
		}
	}


	return (
		<Card>
			<AppBar style={{ 'height': 'fit-content' }} position="static" color="default">
				<Grid
					container
					alignItems="center"
					justify="center"
				>
					<Grid
						item
						xl={1}
						lg={1}
						md={1}
						sm={1}
						xs={1}
					>
						<IconButton
							id={active}
							onClick={addTab}
						>
							<Add />
						</IconButton>
					</Grid>
					<Grid
						item
						xl={11}
						lg={11}
						md={11}
						sm={11}
						xs={11}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="tabs" direction="horizontal">
								{props => (
									<Tabs

										ref={props.innerRef}
										{...props.droppableProps}
										value={active}
										indicatorColor="primary"
										textColor="primary"
										variant="scrollable"
									>
										{tabList.map((tab, index) => (
											<Draggable
												key={index}
												draggableId={`id-${index}`} // must be a string
												index={index}
												disableInteractiveElementBlocking={true}
											>
												{dragProps => (
													<Tab
														classes={{ root: classes.tab }}
														ref={dragProps.innerRef}
														{...dragProps.draggableProps}
														{...dragProps.dragHandleProps}
														label={
															<EditRoad />
														}
														icon={
															<IconButton
																edge="start"
																size="small"
																id={index}
																onClick={deleteTab}
															>
																<Close />
															</IconButton>
														}
														onClick={() => handleChange(index)}
													/>
												)}
											</Draggable>
										))}
										{props.placeholder}
									</Tabs>
								)}
							</Droppable>
						</DragDropContext>
					</Grid>
				</Grid>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={active}
				onChangeIndex={handleChange}
			>

				{tabList.map((tab, index) => (
					<TabPanel value={active} key={index} index={index} dir={theme.direction}>
						<RoadTab key={index} index={index} />
					</TabPanel>

				))}
			</SwipeableViews>
		</Card>
	);
}

export default connect(null, mapDispatchToProps)(FullWidthTabs);
