import React, { useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../../store/actions/user';
import {
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

const DriveSide = () => {

    const props = useSelector(state => state.user)
    const [checked, setChecked] = useState(props.driveOnRight);
    const dispatch = useDispatch();

    const handleChange = () => {
        setChecked(!checked);
        dispatch(userActions.UpdateUserCfg({driveOnRight: !checked}));
    }

    return (
    <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="checkboxDriveOnRight"
                        color="primary"
                    />
                }
                label="Drive On Right"
            />
    )
}

export default DriveSide;