import { createAction } from 'redux-actions';

export default {
    UpdatePrimary: createAction('UPDATE_PRIMARY'),
    UpdateTertiary: createAction('UPDATE_TERTIARY'),
    UpdateTheme: createAction('UPDATE_THEME'),
    UpdateLogo: createAction('UPDATE_LOGO'),
    UpdateText: createAction('UPDATE_TEXT'),
    UpdateTextColor: createAction('UPDATE_TEXT_COLOR'),
    UpdateBase64: createAction('UPDATE_BASE64'),
    UpdateUserRole: createAction('UPDATE_USER_ROLE'),
};

