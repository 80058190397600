import React from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgressWithLabel from './automate-circular-progress.component';
import CircularProgress from '@mui/material/CircularProgress';
import Notification from '../components/shared/notification.component';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const handleClick = (param, event) => {
    setDownloading(true);
    EdgeOrREST(null, 'DOWNLOAD_AUTOMATE_RESULTS', onGetResults, { headers: { 'id': event.id } });
  };

  const handleDelete = (param, event) => {
    setDeleting(true);
    EdgeOrREST(null, 'DELETE_JOB', onDeleteJob, { headers: { 'id': event.id } });
  };

  const onDeleteJob = (response) => {
    setDeleting(false);
    console.log("To Be Delete " + response.data);
    var jobs = state?.user?.jobs?.filter((job) => job.id != response.data);
    var tempjobsStr = JSON.stringify(jobs);
    var tempJobs = JSON.parse(tempjobsStr);
    dispatch(user.UpdateUserCfg({ jobs: tempJobs }))
    console.log("Test in onDeleteJob   ", state.user);
    if (response && response.status == '200') {
      setSnack({
        open: true,
        message: "Job has been successfully deleted.",
        severity: 'success'
      });
      console.log("Job Delete Success, JobId: " + response.data);
    } else {
      setSnack({
        open: true,
        message: response.message,
        severity: 'error'
      });
    }
  };

  const handleDownloadProjFile = (param, event) => {
    setDownloading(true);
    EdgeOrREST(null, 'GET_LRS_FROM_JOB', onGetLRS, { headers: { 'id': event.id } });
  };

  const onGetLRS = (response) => {
    if (response && response.status == '200') {
      setDownloading(false);
      var mediaType = "data:application/x-gzip;base64,";
      var a = document.createElement('a');
      a.href = mediaType + response.data.base64zip;
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } else {
      setDownloading(false);
      setSnack({
        open: true,
        message: "Downloading of results failed",
        severity: 'error'
      });
    }
  };


  const onGetResults = (response) => {
    if (response && response.status == '200') {
      setDownloading(false);
      var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      var a = document.createElement('a');
      a.href = mediaType + response.data.base64;
      a.download = response.data.fileName + '.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      setDownloading(false);
      setSnack({
        open: true,
        message: "Downloading of results failed",
        severity: 'error'
      });
    }
  };

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  function handleProgress(row) {
    if (row.status == "Complete") return 100;
    else if (row.status == "Calculating") {
      if (row.calcTimestamp == null) return 20;
      else if (row.optiTimestamp == null) return 40;
      else if (row.resultTimestamp == null) return 60;
      else if (row.writerTimestamp == null) return 80;
      else return 90
    } else return 0;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <span>{row.name}</span>
        </TableCell>
        <TableCell align="right">
          {/* <CircularProgressWithLabel value={row.status == "Complete" ? 100 : row.status == "Calculating" ? 50 : 0} start={row.timestamp} calcSec={row.calcSec} /> */}
          <CircularProgressWithLabel value={handleProgress(row)} />
        </TableCell>
        <TableCell align="right"><span>{row.type}</span></TableCell>
        <TableCell align="right">{row.status == "Queued" ? <span style={{ color: "#FDAC53" }}>{row.status}</span> :
          row.status == "Complete" ? <span style={{ color: "#008240" }}>{row.status}</span> :
            row.status == "Calculating" ? <span style={{ color: "#0000FF" }}>{row.status}</span> :
              <span style={{ color: "#D2386C" }}>{row.status}</span>}</TableCell>
        <TableCell align="right"><span>{row.timestamp} </span></TableCell>
        <TableCell align="right">
          {/* <IconButton disabled={row.status != 'Complete' || downloading} onClick={(event) => handleClick(event, row)} aria-label="Example">  */}
          <IconButton disabled={row.status != 'Complete' || downloading} onClick={(event) => handleClick(event, row)} aria-label="Example">
            {downloading ? <CircularProgress /> : <FileDownloadIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <IconButton disabled={deleting} onClick={(event) => handleDelete(event, row)} aria-label="Example">
            {deleting ? <CircularProgress /> : <DeleteIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row.status != "Error" ?
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Calcs</TableCell>
                      <TableCell>Calc. Start</TableCell>
                      <TableCell>Calc. End</TableCell>
                      <TableCell>V2 Project File</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" align='center'>
                        <span>{row.noOfResults}</span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <span>{row.start}</span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <span>{row.end}</span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton disabled={row.status != 'Complete' || downloading} onClick={(event) => handleDownloadProjFile(event, row)} aria-label="Example">
                          {downloading ? <CircularProgress /> : <FileDownloadIcon />}
                        </IconButton>
                      </TableCell>

                    </TableRow>
                  </TableBody>
                </Table>
                :

                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Error Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <span>{row.error}</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>


              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </React.Fragment>
  );
}

export default function AutomateJobTableHolder(props) {

  const onJobFilterChange = (e) => {
    if (e.nativeEvent.inputType == "insertText") {
      var currText = props.jobFilter;
      props.setJobFilter(currText += e.nativeEvent.data);
    } else if (e.nativeEvent.inputType == "deleteContentBackward") {
      var currText = props.jobFilter;
      props.setJobFilter(currText.slice(0, -1));
    } else if (e.nativeEvent.inputType == "deleteWordBackward") {
      var currText = props.jobFilter;
      props.setJobFilter("");
    }
  };

  return (
    <TableContainer component={Paper} style={{ height: '65vh', width: '100%' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {/* <TableCell>Name</TableCell> */}
            <TableCell />
            {/* <TableCell>
              <TextField label="Name" id="outlined-size-small-lum" onChange={onJobFilterChange} value={props.jobFilter} InputProps={{disableUnderline: true }}/>
            </TableCell> */}
            <TableCell>Name</TableCell>
            <TableCell align="right">Progress</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Create Date</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.rows?.filter(item => (item.name.toLowerCase() + item.timestamp.toLowerCase()).includes(props.filter.toLowerCase())
          ).map((row) => {
            return (<Row row={row} />)
          })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}