import React,{useState, useCallback,} from 'react';
 import TextField from '@material-ui/core/TextField';
import {
  FormGroup, 
  FormControl,
  makeStyles,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Typography,
  Slider
} from '@material-ui/core';
import PhotometricAutocomplete from './photometric-autocomplete.component'; 
import EdgeOrREST from '../EdgeOrREST'; 
import Notification from '../components/shared/notification.component';
import PhotometricAttributeSelector from './photometice-attributes-menu/photometric-attribute-selector.component';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8; 
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PhotometricAttributeEditor(props) {
    //int luminaireId, style, colour, colourTemp, DALi, IP, IK, CLO, operatingTemp
  const classes = useStyles();
  const [attributes, setAttributes] = React.useState({
    style: "decorative", 
    colour : ["black"],
    colourTemp : 2700,
    rating : 3, 
    clo : true,
    dali : true, 
    ik : 8,
    ip : 65,
    groupIds : [],
    operatingTempMin : -20,
    operatingTempMax : 50,  
    mountingType: ['postTop','sideEntry'],
    manufacturer : "ASD Lighting"
  });
  const [searchParams, setSearchParams] = useState({});
 
  const handleSearchParams = useCallback((searchParams) => {
    setSearchParams(searchParams);
    console.log("editor searchParams", searchParams);
  }, []);

  const onColourChange = (event) => {
    const {
      target: { value },
    } = event;

    setAttributes(prevState => ({
      ...prevState,
      colour : typeof value === 'string' ? value.split(',') : value,
  }));    
 };

 const onMountingTypeChange = (event) => {
  const {
    target: { value },
  } = event;

  setAttributes(prevState => ({
    ...prevState,
    mountingType : typeof value === 'string' ? value.split(',') : value,
}));    
};

  const onOperatingTempChange = (event, newValue) => {
    console.log("operatingT change", event, newValue); 
    setAttributes(prevState => ({
        ...prevState,
        operatingTempMin : newValue[0],
        operatingTempMax : newValue[1],

    }));   
  };
   
  const onSetAttribute = e => {
     const { name, value } = e.target;
    console.log(e.target); 
    if(e.target.type === "checkbox"){
        setAttributes(prevState => ({
          ...prevState,
          [name]: e.target.checked
      }));
    }else {
      setAttributes(prevState => ({
        ...prevState,
        [name]: value
    }));
    } 
};

const onAutoComplete = (event, value) => {   
    if(value && value.length > 0){ 
        var ids = [];
        value.forEach(element => {
          ids.push(element.id);
        });

        console.log("ids", ids); 
        setAttributes(prevState => ({
          ...prevState,
          "groupIds": ids
      }));
    }
}; 

const doUpdateAttributes = (event) => { 
  if(!(props && props.upload)){
    EdgeOrREST(null, 'UPDATE_ATTRIBUTES', onUpdateAttributes, attributes);  
  }else{ 
    props.submit(attributes);
  }
};

function operatingTempText(value) {
  return `${value}°C`;
}

const onUpdateAttributes = (response) => { 
  if(response.status == 200){
    setSnack({
      open: true,
      message : 'Attributes have been successfully updated.',
      severity : 'success'
     }); 
  } else { 
    setSnack({
      open: true,
      message : response.status + response.message,
      severity : 'error'
     });
  }
}
 
const [snack, setSnack] = React.useState({
  open: false
});

const handleClose = () => {
  setSnack({
    ...snack,
    open: false
  });
};






function getAutcomplete(){
  if(!(props && props.upload)){
      return ( <PhotometricAutocomplete callback={onAutoComplete} groups={props.groups}></PhotometricAutocomplete> );
  }
}

  return (
    //first row
    <> 
    {getAutcomplete()} 
    <PhotometricAttributeSelector handleSearchParams={handleSearchParams}/>
    
    {/* row four */} 
    <FormGroup className="MuiFormGroup-options" row>      
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        id="submitAttributesBUtton"
        disabled={props.upload ? false : (attributes.groupIds == null || attributes.groupIds.length == 0) }
        onClick={doUpdateAttributes}>
        Submit
      </Button>  
    </FormGroup>
    <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
</>
  );
}
 