import React from "react";
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

function steps (){return[
    {
        content: () => (
            <div>
                Welcome to Manage. Here you can manage your luminaires.
                <br/>
                <br/>
                {/* <div className="important"><span><PriorityHighRoundedIcon/></span>If this is your first time using the tour. Please refresh the page to ensure the tour works as intended.<span><PriorityHighRoundedIcon/></span></div> */}
            </div>
        ),
        
    },
    {
        selector: '.manageStep2',
        content: () => (
            <div>
                Click "Add Group" to open the "Create Group" menu.
            </div>
        ),
    },
    {
        selector: '.manageStep8',
        content: 'Enter the name of your group and click create group at the bottom of the menu.',
        position: [1280,300],
    },
    {
        selector: '.manageStep2',
        content: () => (
            <div>
                When you have added a group you can choose to add more groups inside the folder by clicking the "Add Group" button that appears when you open a folder.
            </div>
        ),
    },
    {
        selector: '.manageStep8',
        content: () => (
            <div>
                If you are not adding a second group skip this step.
                <br/>
                <br/>
                Enter the name of your group and click create group at the bottom of the menu.
            </div>
        ),
        position: [1280,300],
    },
    {
        selector: '.manageStep2',
        content: () => (
            <div>
                To upload photometry:
                <br/>
                <br/>
                Please click the <span><PublishRoundedIcon className="tour-icon" /></span> button.
            </div>
        ),
    },
    {
        selector: '.manageStep5',
        content: 'Select your group and click next.',
        position: [1180,300],
    },
    {
        selector: '.manageStep5',
        content: () => (
            <div>
                Select your luminaires by clicking <input type="file" className='input-button'/>
            </div>
        ),
        position: [1280,300],
    },
    {
        selector: '.manageStep5',
        content: 'Assuming you have selected your luminaires, scroll down in our menu and click submit to add them to your tree.',
        position: [1280,300],
    },
    {
        selector: '.manageStep9',
        content: () => (
            <div>
                To export a group:
                <br/>
                <br/>
                Click the <span><FileDownloadRoundedIcon className="tour-icon" /></span> button.
            </div>
        ),
        position: [1045,125],
    },
    {
        selector: '.manageStep2',
        content: () => (
            <div>
                To edit a group or luminaire:
                <br/>
                <br/>
                Click the <span><EditRoundedIcon className="tour-icon" /></span> button.
            </div>
        ),
        position: [1045,125],
    },
    {
        selector: '.manageStep10',
        content: 'Enter your new settings and press the "Save" button.',
        position: [1250,400],
    },
    {
        selector: '.manageStep9',
        content: () => (
            <div>
                To remove a luminaire: Select the luminaire(s) you want to delete by checking the box.
                <br/>
                <br/>
                Then click the <span><RemoveCircleOutlineRoundedIcon className="tour-icon" /></span> button in the folder.
            </div>
        ),
        position: [935,125],
    },
    {
        selector: '.manageStep9',
        content: () => (
            <div>
                To delete a group:
                <br/>
                <br/>
                Click the <span><DeleteRoundedIcon className="tour-icon" /></span> button.
            </div>
        ),
        position: [1045,125],
    }, 
]}

export default steps;