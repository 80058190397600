import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Card,
	CardContent,
	Grid,
	TextField,
	makeStyles
} from '@material-ui/core';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';
import Wetu0 from '../Roadway/wetU0.component';
import WetRoadSurface from '../Roadway/wetRoadSurface.component';
import WetQ0 from '../Roadway/wetQ0.component';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';

const CalcGrid = ({ index }) => {

	const props = useSelector(state => state);
	const [q0, setQ0] = useState(props.GridsCfg.RoadGrids[index].Q0)
	const dispatch = useDispatch();


	const getGridResults = (props, newId, newValue) => {
		EdgeOrREST(null, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, newId, newValue));
	};

	const onRoadSurfaceChange = (event) => {
		var roadSurfaceId = event.target.value
		EdgeOrREST(null, 'SET_ROADSURFACE', onGetQO, {
			postObject: { roadSurfaceId }
		});
	}

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				gridResults
			}));
		}
	}

	const onQOBlur = (event) => {
		updateRoadCfg({ ...props.ProjectConfig.RoadCfg, Q0: event.target.value });
		const RoadGrids = [...props.GridsCfg.RoadGrids];
		if (RoadGrids[index]) {
			RoadGrids[index] = {
				...RoadGrids[index],
				Q0: parseFloat(event.target.value),
			}
		}
		var postObject = props;
		postObject.ProjectConfig.RoadCfg.Q0 = parseFloat(event.target.value);
		postObject.GridsCfg.RoadGrids = RoadGrids;
		getGridResults(postObject, null, null);
	}

	const onQOChange = (event) => {
		setQ0(event.target.value);
	}

	const onGetQO = (response) => {
		if (response) {
			const RoadGrids = [...props.GridsCfg.RoadGrids];
			if (RoadGrids[index]) {
				RoadGrids[index] = {
					...RoadGrids[index],
					Q0: response.data.roadCfg.Q0,
					RoadSurfaceName: response.data.roadSurfaceId
				}
			}
			else RoadGrids.splice(index, 0, {
				Q0: response.data.roadCfg.Q0,
				RoadSurfaceName: response.data.roadSurfaceId
			});
			dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
			dispatch(roadActions.UpdateRoadCfg({
				...props.ProjectConfig.RoadCfg,
				Q0: response.data.roadCfg.DefaultQ0,
				RoadSurface: response.data.roadSurfaceId
			}));
			var postObject = props;
			postObject.ProjectConfig.RoadCfg.Q0 = response.data.roadCfg.DefaultQ0;
			postObject.GridsCfg.RoadGrids = RoadGrids;
			getGridResults(postObject, null, null);
		}
	}

	const updateRoadCfg = (RoadCfg) => {
		if (RoadCfg) {
			const RoadGrids = [...props.GridsCfg.RoadGrids];
			if (RoadGrids[index]) {
				RoadGrids[index] = {
					...RoadGrids[index],
					Q0: RoadCfg.Q0,
				}
			}
			else RoadGrids.splice(index, 0, {
				Q0: RoadCfg.Q0,
			});
			dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));
			dispatch(roadActions.UpdateRoadCfg(RoadCfg));
		}
	}

	if (props.GridsCfg.RoadGrids[index].CalcGridFile == "EN13201:2015 Illuminance" || props.GridsCfg.RoadGrids[index].CalcGridFile == "BS5489:2020 Illuminance") return <></>;
	else return (
		<>
			<Grid container spacing={2} >
				<Grid item xs="3" >
					<TextField
						fullWidth
						label="Road Surface"
						name="roadSurface"
						onChange={(e) => onRoadSurfaceChange(e, props)}
						select
						SelectProps={{ native: true }}
						value={props.GridsCfg.RoadGrids[index].RoadSurfaceName}
						variant="outlined"
					>
						{props.ProjectConfig.RoadCfg.RoadSurfaceArray.map((option) => (
							<option
								key={option}
							>
								{option}
							</option>
						))}
					</TextField>
				</Grid>
				<Grid item xs="3" >
					<TextField
						type="number"
						inputProps={{ step: 0.01 }}
						fullWidth
						label="QO"
						name="qO"
						value={q0}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								event.preventDefault();
								event.target.blur();
							}
						}}
						onChange={(e) => onQOChange(e, props)}
						onBlur={(e) => onQOBlur(e, props)}
						variant="outlined"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs="3" >
					<WetRoadSurface getGridResults={getGridResults} />
				</Grid>
				<Grid item xs="3" >
					<WetQ0 getGridResults={getGridResults} />
				</Grid>
				<Grid item xs="2" >
					<Wetu0 />
				</Grid>
			</Grid>
		</>
	);
};

export default CalcGrid;