import React, { useState, useCallback, useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST.js';
import Notification from '../components/shared/notification.component.js';
import {
  makeStyles,
  Button,
  TextField,
  Checkbox,
  Grid,
  Tooltip,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Tabs,
  Tab,
  Box,
  Radio,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  RadioGroup,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import CopyrightIcon from '@mui/icons-material/Copyright';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/AddCircle';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationDialog from './confirmation-dialog.component.js';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user.js';
import PhotometricAttributeSelector from './photometice-attributes-menu/photometric-attribute-selector.component.js';
import PhotometricAttributeStreetlightSelector from './photometice-attributes-menu/photometric-attribute-streetlight-selector.component.js';
import luminaireActions from '../store/actions/luminaireActions.js';
import PhotometricUpload from './photometric-upload.component.js';
import PhotometricMediaUpload from './photometric-media-upload.component.js';
import PhotometricSubscribeGroupsViewer from './photometric-subscribe-groups-viewer.component.js';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  dialogPaper: {
    minWidth: '550px',
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    height: '75vh',
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto'
  },
  dialogPaper: {
    minWidth: '550px',
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const initialDisabledStreetList = {
  //spektd options
  //light
  lightSource: true,
  manufacturer: true,
  lampBase: true,
  cri: true,
  r9: true,
  macAdamStep: true,
  direction: true,
  beamAngle: true,
  cctOptions: true,
  cct: true,
  lor: true,
  opticalAccessories: true,
  lampCount: true,
  //physical
  finish: true,
  housingMaterial: true,
  adjustableTilt: true,
  adjustableRotation: true,
  shape: true,
  height: true,
  length: true,
  width: true,
  installation: true,
  placement: true,
  weight: true,
  //electrical
  voltageType: true,
  voltage: true,
  wattage: true,
  mA: true,
  luminWatt: true,
  driverType: true,
  control: true,
  applianceClass: true,
  //protection
  ip: true,
  ik: true,
  operatingTempMin: true,
  operatingTempMax: true,
  emergencyLight: true,
  antiSlip: true,
  designLabel: true,
  //integration
  daylightSensors: true,
  motionSensors: true,
  presenceSensors: true,
  nema3: true,
  nema7: true,
  zhagaBook18: true,
  photocell: true,
  clo: true,
  //environmental
  tm66: true,
  envCert: true,
  //brands
  brands: true,
};

const initialDisabledAttListForCopy = {
  //spektd options
  //light
  lightSource: true,
  manufacturer: true,
  lampBase: true,
  cri: true,
  r9: true,
  macAdamStep: true,
  direction: true,
  beamAngle: true,
  cctOptions: true,
  cct: true,
  lor: true,
  opticalAccessories: true,
  lampCount: true,
  deliveredLumens: true,
  ulor: true,
  secondaryBeamAngle: true,
  directElement: true,
  indirectElement: true,
  cctMin: true,
  cctMax: true,
  colourChangeType: true,
  lumenSuffix: true,

  //physical
  finish: true,
  housingMaterial: true,
  adjustableTilt: true,
  adjustableRotation: true,
  shape: true,
  height: true,
  length: true,
  width: true,
  installation: true,
  placement: true,
  weight: true,
  recessDepthRequirement: true,
  cutOutLength: true,
  cutOutWidth: true,
  cutOutDiameter: true,
  anyHeight: true,
  anyLength: true,
  anyWidth: true,
  anyDiameter: true,
  anyCutOutLength: true,
  anyCutOutWidth: true,
  anyCutOutDiameter: true,
  anyRecessDepthRequirement: true,

  //electrical
  voltageType: true,
  voltage: true,
  wattage: true,
  mA: true,
  luminWatt: true,
  driverType: true,
  control: true,
  applianceClass: true,
  calMa: true,
  wattageSuffix: true,

  //protection
  ip: true,
  ik: true,
  operatingTempMin: true,
  operatingTempMax: true,
  emergencyLight: true,
  antiSlip: true,
  designLabel: true,
  explosionZoneSuitability: true,
  rolloverProtection: true,
  suitableForCleanRooms: true,

  //integration
  daylightSensors: true,
  motionSensors: true,
  presenceSensors: true,

  //environmental
  tm66: true,
  envCert: true,
  //brands
  brands: true,
  sku: true,
  productType: true,
  status: true,
  imagesLineDrawing: true,
  imageVariantPhoto: true,
  imagePolarcurve: true,
  twoDCadFiles: true,
  pdfFiles: true,
  specificationSheet: true,
  published: true,
  allFilesUploaded: true,
  controlsData: true,
};

function Group(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo: LabelInfo, removeLuminaire: RemoveInfo, editGroupAttributes: EditGroupAttributes, color, bgColor, group, onUpdate, parentGroupId, ...other } = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openSelectedDelete, setOpenSelectedDelete] = React.useState(false);
  const [editGroupError, setEditGroupError] = React.useState(false);
  const [attributeParams, setAttributeParams] = useState({});
  const [groupName, setGroupName] = React.useState(props.name);
  const [groupDescription, setGroupDescription] = React.useState(props.description);
  const [downloadType, setDownloadType] = React.useState("V2");
  const [snack, setSnack] = React.useState({
    open: false
  });
  const [openCopy, setOpenCopy] = React.useState(false);
  const [lowestGroups, setLowestGroups] = React.useState(other.lowestGroups);
  const [selectedUplaodGroup, setSelectedUplaodGroup] = React.useState(lowestGroups?.find(group => group.id === props.nodeId));
  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const handleMediaUpload = (event, value) => {
  };

  const [editTab, setEditTabIndex] = React.useState(0);
  const handleEditTab = (event, value) => {
    setEditTabIndex(value);
  };

  const handleEditDialogClose = () => {
    setOpenEdit(false);
  };

  const onClickCopy = () => {
    setOpenCopy(true);
  };

  const onClickEditGroup = () => {
    setOpenEdit(true);
  };

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
  }, []);

  const handleCopyClose = () => {
    setOpenCopy(false);
  };

  // const handleUploadClick = () => {
  //   setLowestGroups(other.lowestGroups);
  //   setSelectedUplaodGroup(lowestGroups.find(group => group.id === props.nodeId));
  //   setUploadOpen(true);
  // }

  const handleSelectedGroup = (event, value) => {
    setSelectedUplaodGroup(value);
    console.log("value", value);
  };

  return (
    <>
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <Tooltip title={props?.description}>
              <LabelIcon color="inherit" className={classes.labelIcon} />
            </Tooltip>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {/* {other.isLowest ? <PublishIcon color="inherit" className={classes.label} onClick={handleUploadClick} /> : <></>} */}
              {other.isLowest ? <CopyrightIcon color="inherit" className={classes.label} onClick={onClickCopy} /> : <></>}
              <EditGroupAttributes color="inherit" className={classes.label} onClick={onClickEditGroup} />
              {/* <RemoveInfo color="inherit" className={classes.label} onClick={onClickRemoveSelected} />
              <LabelInfo color="inherit" className={classes.label} onClick={onClickDelete} /> */}
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      >
      </TreeItem>

      <Dialog
        className='copyGroup'
        open={openCopy}
        onClose={handleCopyClose}
        classes={{ paper: classes.upload }}
      >
        <DialogTitle>Copy Group</DialogTitle>
        <PhotometricSubscribeGroupsViewer forceUpdate={props.forceUpdate} selectedGroupToCopy={props.selectedGroupToCopy} close={handleCopyClose}/>
      </Dialog>
      <Dialog
        className='manageStep10'
        open={openEdit}
        onClose={handleEditDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Group</DialogTitle>
        <DialogContent >
          <TextField
            error={editGroupError}
            autoFocus
            margin="dense"
            label="Enter Name"
            id="standard-name"
            type="text"
            fullWidth
            disabled={true}
            variant="standard"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            id="standard-name"
            defaultValue=""
            disabled={true}
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            inputProps={{ maxLength: 250 }}
          />
          <Paper square>
            <Tabs
              value={editTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleEditTab}
              aria-label="disabled tabs example"
            >
              <Tab index={0} name="lighiting" label="street lighiting"  {...a11yProps(0)} value={0} />
              <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
            </Tabs>
          </Paper>
          <TabPanel value={editTab} index={0}>
            <PhotometricAttributeStreetlightSelector disabledStreetList={initialDisabledStreetList} handleAttributeParams={handleAttributeParams} edit={true} groupId={props.nodeId} parentGroupId={props.parentGroupId} />
          </TabPanel>
          <TabPanel value={editTab} index={1}>
            <PhotometricAttributeSelector disabledAttList={initialDisabledAttListForCopy} handleAttributeParams={handleAttributeParams} edit={true} groupId={props.nodeId} parentGroupId={props.parentGroupId} />
          </TabPanel>
          {other.isLowest ? <PhotometricMediaUpload groupId={props.nodeId} luminaireId={0} handleMediaUpload={handleMediaUpload} isCopyGroup={true} /> : <></>}
          <div style={{ textAlign: "right" }}>
          </div>
        </DialogContent>
      </Dialog>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>

  );
}

//good
function Luminaire(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, removeLuminaire: RemoveInfo, color, bgColor, ...other } = props;
  const [selected, setSelected] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [attributeParams, setAttributeParams] = useState({});
  const [snack, setSnack] = React.useState({
    open: false
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };
  const [editTab, setEditTabIndex] = React.useState(0);
  const handleEditTab = (event, value) => {
    setEditTabIndex(value);
  };

  const handleMediaUpload = (event, value) => {
  };

  const onSelectLuminaire = (e) => {
    setSelected({ selected: !selected });
    console.log("props in onselectlum", e);
    props.onSelectLuminaire({ id: other.treeId, parentId: other.parentId, selected: e.target.checked });
  };

  const handleEditDialogClose = () => {
    setOpenEdit(false);
  };

  const onClickEditLuminaire = () => {
    setOpenEdit(true);
  };

  const onUpdateLuminaireAttributes = (response) => {
    console.log("onUpdateLuminaireAttributes", response);
    if (response.status == 200) {
      setSnack({
        open: true,
        message: "Luminaire attributes update successful.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: response.status + response.message,
        severity: 'error'
      });
    }
    other.resetGotChildren();
    props.forceUpdate();
  };

  const handleAttributeParams = useCallback((attributeParams) => {
    setAttributeParams(attributeParams);
  }, []);

  return (
    <>
      <Dialog
        open={openEdit}
        onClose={handleEditDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Luminaire</DialogTitle>
        <DialogContent >
          <Paper square>
            <Tabs
              value={editTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleEditTab}
              aria-label="disabled tabs example"
            >
              <Tab index={0} name="lighting" label="street lighting"  {...a11yProps(0)} value={0} />
              <Tab index={1} label="Spektd" {...a11yProps(1)} value={1} />
            </Tabs>
          </Paper>
          <TabPanel value={editTab} index={0}>
            <PhotometricAttributeStreetlightSelector disabledStreetList={initialDisabledStreetList} handleAttributeParams={handleAttributeParams} editLumin={true} luminaireId={other.luminaireId} parentGroupId={other.parentId} />
          </TabPanel>
          <TabPanel value={editTab} index={1}>
            <PhotometricAttributeSelector disabledAttList={initialDisabledAttListForCopy} handleAttributeParams={handleAttributeParams} editLumin={true} luminaireId={other.luminaireId} parentGroupId={other.parentId} />
          </TabPanel>
          <PhotometricMediaUpload groupId={props.parentId} luminaireId={props.luminaireId} handleMediaUpload={handleMediaUpload} isCopyGroup={true}/>
        </DialogContent>
      </Dialog>
      <TreeItem
        label={
          <div className={classes.labelRoot} style={(other.luminaireId == other.selectedNode) ? { background: 'lightgreen' } : {}} >
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              <EditIcon color="inherit" className={classes.label} onClick={onClickEditLuminaire} />
              <Checkbox style={{ padding: '0px' }} value={selected} color="primary" onChange={onSelectLuminaire} ></Checkbox>
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>

  );
}

export default function SubscribedTree(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupName, setGroupName] = React.useState("");
  const [groupDescription, setGroupDescription] = React.useState("");
  const [addGroupError, setAddGroupError] = React.useState(false);
  const [selectedToRemove, setSelectedToRemove] = React.useState([]);
  const [privateGroup, setPrivacy] = React.useState({
    checked: true
  });
  const state = useSelector(state => state);
  const [expanded, setExpanded] = React.useState([]);
  const [gotChildren, setGotChildren] = React.useState([]);
  const [parentGroupId, setParentGroupId] = React.useState(0);
  const [selectedNode, setSelectedNode] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedGroupToCopy, setSelectedGroupToCopy] = React.useState(null);
  const [lowestGroups, setLowestGroups] = React.useState([]);
  const handleChange = (event) => {
    setPrivacy({ ...privateGroup, 'checked': event.target.checked });
  };
  const [snack, setSnack] = React.useState({
    open: false
  });
  const [open, setOpen] = React.useState(false);
  const [attributeParams, setAttributeParams] = useState({});
  const [addTap, setAddTap] = React.useState(0);
  const [sort, setSort] = React.useState(0);
  const sortBy = [
    { value: 0, label: 'A-Z' },
    { value: 1, label: 'Z-A' },
    { value: 2, label: 'Created ↑' },
    { value: 3, label: 'Created ↓' }
  ];

  const onChangeGroup = (response) => {
    props.forceUpdate();
  };

  const onSelectLuminaire = (event) => {
    if (event.selected) {
      const selectedLuminaires = selectedToRemove.slice();
      selectedLuminaires.push(event);
      setSelectedToRemove(selectedLuminaires);
    } else {
      var lumCopy = selectedToRemove;
      if (selectedToRemove) {
        var removeIndex = selectedToRemove.map(lum => lum.id).indexOf(event.id);
        removeIndex && lumCopy.splice(removeIndex, 1);
        setSelectedToRemove(lumCopy);
      }
    }
  };

  const onRemoveConfirm = (event) => {
    if (selectedToRemove.length > 0) {
      var ids = [];
      for (var i = 0; i < selectedToRemove.length; i++) {
        ids.push(selectedToRemove[i].id);
      }
      EdgeOrREST(null, 'REMOVE_LUMINAIRES_FROM_TREE', onDeleteGroupOrGroupItem, ids);
    }
  }

  const onDeleteGroupOrGroupItem = (response) => {
    if (response.status == 200) {
      setSnack({
        open: true,
        message: "Group or Luminaire(s) removed successfully.",
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: response.status + response.message,
        severity: 'error'
      });
    }
    resetGotChildren();
    props.forceUpdate();
  };

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  function checkForAdminPriv() {
    var isAdmin = false;
    var checkbox = [];
    if (state.user.roles) {
      for (var i = 0; i < state.user.roles.length; i++) {
        if (state.user.roles[i].name === "admin") {
          isAdmin = true;
        }
      }

      if (false) { //TODO fix this shit
        checkbox.push(<Grid item lg={4} md={4} sm={4} xs={4}>
          <FormControlLabel
            control={<Checkbox checked={privateGroup.checked} onChange={handleChange} name="privateGroup" color="primary" />}
            label={<Typography>Private Group</Typography>}
            className={classes.margin}
          />
        </Grid>);
      }
    }
    return checkbox;
  }

  const handleNodeToggle = async (event, nodeIds) => {
    const expandedNodes = nodeIds.filter((nodeId) => !expanded.includes(nodeId));
    if (expandedNodes.length > 0) {
      handleExpand(expandedNodes[0]);
    }
    setExpanded(nodeIds);
  };

  const handleExpand = (nodeId) => {
    if (!gotChildren.includes(nodeId)) {
      EdgeOrREST(null, 'GET_TREE_CHILDREN', onGetChildren, { headers: { 'id': nodeId } });
      setGotChildren([...gotChildren, nodeId]);
    }
  };

  const onGetChildren = (response) => {
    var wholeGroup = props.groups;
    if (response.data != null && response.data.length > 0 && wholeGroup) {
      if (response.data[0].luminaireId == 0) {
        //groups
        dispatch(user.UpdateUserCfg({ subscribedGroups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'groups') }));
      } else {
        //luminaires
        dispatch(user.UpdateUserCfg({ subscribedGroups: updateGroup(response.data[0].parentId, wholeGroup, response.data, 'luminaires') }));
      }
    }
  };

  const updateGroup = (parentId, wholeGroup, updatedGroup, type) => {
    const update = groups => {
      return groups.map(group => {
        if (group.id === parentId) {
          group[type] = updatedGroup;
          return group;
        } else if (group.groups) {
          return {
            ...group,
            groups: update(group.groups)
          };
        } else {
          return group;
        }
      });
    };
    return update(wholeGroup);
  };

  function resetGotChildren() {
    setExpanded([]);
    setGotChildren([]);
    getAllLowestSubFolders();
  }

  useEffect(() => {
    resetGotChildren();
  }, [])

  useEffect(() => {
    console.log("state?.LuminaireCfg", state.LuminaireCfg);
    if (state?.LuminaireCfg?.selectedResultLuminId && state?.LuminaireCfg?.selectedResultLuminId != selectedNode) {
      EdgeOrREST(null, 'GET_ALL_FOLDER_PARENT', onGetParent, { headers: { 'id': state.LuminaireCfg.selectedResultLuminId } });
      setSelectedNode(state.LuminaireCfg.selectedResultLuminId);
    }
  }, [state?.LuminaireCfg])

  const onGetParent = (response) => {
    if (response.data?.length > 0) {
      const parentIds = [];
      setSelectedGroup(response.data[0]);
      resetGotChildren();
      response.data.reverse().forEach(({ id }, index) => {
        setTimeout(() => {
          handleExpand(id);
        }, index * 500); // wait 0.1 second between each data
        parentIds.push(id);
      });
      setExpanded(parentIds);
    }
  };

  // const handleOpen = (currentId) => {
  //   setParentGroupId(currentId);
  //   setOpen(true);
  // }

  const handleGroupClick = (item) => {
    setSelectedGroupToCopy(item);
  }

  const handleClick = (id) => {
    dispatch(luminaireActions.UpdateLuminaireList({ ...state.LuminaireCfg, selectedResultLuminId: id }));
    dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: true }));
    setSelectedNode(id);
  }

  const getAllLowestSubFolders = () => {
    EdgeOrREST(null, 'GET_ALL_LOWEST_SUBSCRIBE_FOLDER', getAllLowestSubFolder, props);
  }

  const getAllLowestSubFolder = (response) => {
    if (response) {
      console.log("getAllLowestChildrenFolder", response.data);
      setLowestGroups(response.data);
    }
  };

  const sortedGroups = (groups) => {
    switch (sort) {
      case 0:
        // Code for sorting alphabetically (A-Z)
        return groups?.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        }).map((item) => {
          return (
            renderTree(item)
          );
        });
      case 1:
        // Code for sorting in reverse alphabetical order (Z-A)
        return groups?.slice().sort((a, b) => {
          return b.name.localeCompare(a.name);
        }).map((item) => {
          return (
            renderTree(item)
          );
        });

      case 2:
        // Code for sorting by creation date in ascending order (Created ↑)
        return groups?.slice().map((item) => {
          return (
            renderTree(item)
          );
        });
      case 3:
        // Code for sorting by creation date in descending order (Created ↓)
        return groups?.slice().reverse().map((item) => {
          return (
            renderTree(item)
          );
        });
      default:
        console.error('Invalid option');
        break;
    }
  }

  function renderTree(item) {
    return (
      <Paper className={classes.paper}>
        <Group forceUpdate={onChangeGroup} name={item?.name} description={item?.description} type="group" nodeId={item.id} labelText={item.name /*+ " - ID: " + item.id*/} labelIcon={FolderIcon}
          color="#1a73e8" bgColor="#e8f0fe" labelInfo={DeleteIcon} removeLuminaire={RemoveIcon} onRemoveConfirmed={onRemoveConfirm} editGroupAttributes={EditIcon} parentGroupId={item.parentId}
          resetGotChildren={resetGotChildren} isLowest={lowestGroups.some(group => group.id === item.id)} lowestGroups={lowestGroups}
          selectedGroupToCopy={selectedGroupToCopy} onClick={(event) => handleGroupClick(item)}
        >
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          expanded={expanded}
          onNodeToggle={handleNodeToggle}
          style={{ paddingLeft: '10px' }}
        >
          {/* {item?.luminaires ? <></> : <div style={{ textAlign: "center" }}>
              <Button variant="text" id={item.id} color="inherit" onClick={() => handleOpen(item.id)} >
                <AddIcon style={{ fontSize: '15pt' }} />
                <Typography variant="body2" className={classes.labelText}>
                  {"Add Group"}
                </Typography>
              </Button>
            </div>} */}

          {sortedGroups(item?.groups)}

          {item?.luminaires?.map((luminaire) => {
            return (
              <Luminaire parentId={luminaire.parentId} treeId={luminaire.id} luminaireId={luminaire.luminaireId} onSelectLuminaire={onSelectLuminaire} key={luminaire.id} color="#1a73e8" bgColor="#e8f0fe" labelText={luminaire.name}
                labelIcon={DescriptionIcon} forceUpdate={onChangeGroup} resetGotChildren={resetGotChildren} selectedNode={selectedNode} onLabelClick={(event) => { event.preventDefault(); handleClick(item.luminaireId) }} />
            );
          })}

        </TreeView>
      </Group>
      </Paper >
    );
  }

  return (
    <>
      {checkForAdminPriv()}
      <TextField
        value={sort}
        onChange={(e) => setSort(e.target.value)}
        select
        label="Sort By"
        variant="outlined"
        style={{ width: 120, marginLeft: 10, alignSelf: 'flex-end' }}
      >
        {sortBy.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
      >
        {/* <div style={{ textAlign: "center" }}>
          <Button variant="text" color="inherit" onClick={() => handleOpen(0)} >
            <AddIcon style={{ fontSize: '15pt' }} />
            <Typography variant="body2" className={classes.labelText}>
              {"Add Group"}
            </Typography>
          </Button>
        </div> */}
        {sortedGroups(props?.groups)}
      </TreeView>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>
  );
}
