import React, { useEffect } from 'react';
import { Circle, Layer, Line, Transformer } from "react-konva";

export default function CustomMask(props) {
    const trRef = React.useRef();
    const shapeRef = React.useRef();
    const [selected, setSelected] = React.useState(false);
    const [points, setPoints] = React.useState(props.poly.points());

    useEffect(() => {
        if (trRef.current && selected) {
            console.log("setting shape ref")
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selected])

    const handleDragMove = (e) => {
        const { x, y } = e.target.attrs;
        const index = e.target.index - 1;
        const newPoints = [...points];
        newPoints[index * 2] = x;
        newPoints[index * 2 + 1] = y;
        setPoints(newPoints);
    };

    return (
        <Layer>
            {props.poly && (
                <Line
                    ref={shapeRef}
                    points={points}
                    fill={props.poly.fill()}
                    stroke={props.poly.stroke()}
                    strokeWidth={props.poly.strokeWidth()}
                    closed={props.poly.closed()}
                    onTap={(e) => {
                        console.log("called setSelected")
                        //      setSelected(!selected)
                    }}
                    onClick={(e) => {
                        console.log("called setSelected")

                        setSelected(!selected)
                    }}
                />
            )}

            {props.poly.points().map((_, index) => {
                const x = points[index * 2];
                const y = points[index * 2 + 1];
                return (
                    <Circle
                        key={index}
                        x={x}
                        y={y}
                        radius={5}
                        fill="white"
                        stroke="black"
                        strokeWidth={1}
                        draggable
                        onDragMove={handleDragMove}
                    />
                );
            })}

            {selected ?
                <Transformer ref={trRef} keepRatio={true}
                // onTransformEnd={(e) => {
                //     props.updateMask(e, props.i, props.maskType);
                // }}
                />
                :
                <></>
            }
        </Layer>
    )
} 