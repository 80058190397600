import React, { useEffect } from 'react';
import { Layer, Rect, Transformer } from "react-konva";
import * as d3 from 'd3';

export default function Grid(props) {
    const trRef = React.useRef();
    const shapeRef = React.useRef();
    const [gridResults, setGridResults] = React.useState([]);
    useEffect(() => {
        if (trRef.current && props.selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [props.selected])

    useEffect(() => {
        console.log("useEffect in grid");
        for (var i = 0; i < props.areaResults.length; i++) {
            var item = props.areaResults[i];
            console.log("item", item)
            if (item.calcResults.outdoorResults[0].gridId == props.i) {
                setGridResults(item.calcResults.outdoorResults[0].gridResults);
                break;
            }
        }
    }, [props.areaResults])

    const drawResults = (ctx) => {
        console.log("ctx called rect", ctx);
        console.log("gridResults in rect", gridResults);
        if (gridResults && gridResults.length > 0) {
            const n = 10;
            //var reducedGridResults = gridResults.filter((_, i) => i % n === 0);
            var reducedGridResults = gridResults;

            console.log("gridResults in rect", reducedGridResults);
            const grid = props.grid;
            var width = grid.origin.x.length;
            var height = grid.origin.y.length;
            var maxSpacing = grid.maxSpacing;

            ctx.font = "2px Arial" //grid.LaneWidth > 3 ? "12px Arial" : grid.LaneWidth > 2 ? "10px Arial" : "8px Arial";
            var xPoints;
            var yPoints;

            if (width == height) {
                //square
                xPoints = Math.sqrt(reducedGridResults.length);
                yPoints = reducedGridResults.length / xPoints;
            } else {
                xPoints = width / grid.xSpacing;
                yPoints = height / grid.ySpacing;
            }

            //xPoints = xPoints / 10;
            // yPoints = yPoints / 10;



            var dataMax = Math.max(...reducedGridResults);
            var dataMin = Math.min(...reducedGridResults);
            var heightScale = height / yPoints;
            var widthScale = width / xPoints;


            var linearScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
            var scaledWidth = linearScale(width);

            console.log("gsX", props.gridScaleX)
            console.log("gSy", props.gridScaleY)
            var point = 0;
            for (let y = 0; y < yPoints; y++) {
                for (let x = 0; x < xPoints; x++) {
                    if (reducedGridResults[point] == dataMax) ctx.fillStyle = 'darkgreen';
                    else if (reducedGridResults[point] == dataMin) ctx.fillStyle = 'red';
                    else ctx.fillStyle = 'cyan';
                    if (reducedGridResults[point] == undefined) continue;
                    ctx.fillText(reducedGridResults[point],
                        props.gridScaleX * (x * (widthScale)),
                        props.gridScaleY * (y * (heightScale)));


                    point++;
                }
            }

            // for (let x = xPoints - 1; x >= 0; x--) {
            //     for (let y = 0; y < yPoints; y++) {
            //         if (reducedGridResults[point] == dataMax) ctx.fillStyle = 'darkgreen';
            //         else if (reducedGridResults[point] == dataMin) ctx.fillStyle = 'red';
            //         else ctx.fillStyle = 'cyan';

            //         ctx.fillText(reducedGridResults[point], x * widthScale + grid.xSpacing, y * heightScale + grid.ySpacing);
            //         point++;
            //     }
            // }


        }
    }

    return (
        props.grid.id != 0 ?
            <Layer key={props.i} >
                <Rect
                    sceneFunc={(ctx) => drawResults(ctx)}
                    ref={shapeRef}
                    x={props.grid.origin.x.start * props.gridScaleX}
                    y={props.grid.origin.y.start * props.gridScaleY}
                    width={props.grid.origin.x.length * props.gridScaleX}
                    height={props.grid.origin.y.length * props.gridScaleY}
                    strokeWidth={1} // border width
                    stroke="crimson"
                    draggable={true}
                    onTap={(e) => {
                        props.selectGrid(props.i);
                    }}
                    onClick={(e) => {
                        props.selectGrid(props.i);
                    }}
                    onDragEnd={
                        (e) => {
                            props.updateGrid(e, props.i, false);
                        }
                    }
                />
                {props.selected ?
                    <Transformer ref={trRef} keepRatio={true}
                        onTransformEnd={(e) => {
                            props.updateGrid(e, props.i, true);
                        }}

                    />
                    : <></>
                }
            </Layer>
            :
            null
    )
} 