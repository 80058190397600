import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    RadarChart,
    PolarAngleAxis,
    PolarRadiusAxis,
    PolarGrid,
    Radar,
    Legend,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import EdgeOrREST from '../../EdgeOrREST';
import { API_OBJECTS } from '../../API/model/calculation.model';

const numberFormatter = item => item.toFixed(2);

const Polar = () => {

    const props = useSelector(state => state);
    const [array, setArray] = useState({0: [], 90: [], 25: []});
    let keys = Object.keys(array);
    let remainingKey = keys.find(key => key !== "0" && key !== "90");
    let B = array["0"];
    let A = array["90"];
    let C = array[remainingKey];
    let remainingKeyAdded = parseFloat(remainingKey)+180;
    let data = [];
    if(A != undefined && A.length != 0){
        A.forEach((x, i) => {
            if (i == 0) {
                data[i] = { "A": A[i], "B": B[i], "C": C[i], "angle": i }
            }
            else data[i] = { "A": A[i], "B": B[i], "C": C[i], "angle": i }
        });
    }

    const RenderPolarPlot = () => {
        if (A != undefined && A.length != 0) return (
            <>
                <ResponsiveContainer width="100%" height={500}>
                    <RadarChart id="polarChart" cx="50%" cy="50%" startAngle={-90} endAngle={270} data={data}>
                        <PolarGrid gridType='circle' polarAngles={[0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330]} />
                        <PolarAngleAxis type="number" dataKey="angle" tickCount={13} ticks={[0, 90, 180]} />
                        <PolarRadiusAxis angle={90} type="number" fontSize={16} orientation="left" domain={['auto', 'auto']} />
                        <Radar name="270/90" dataKey="A" stroke="#8884d8" fillOpacity={0} />
                        <Radar name="180/0" dataKey="B" stroke="#ff0000" fillOpacity={0} />
                        <Radar name={remainingKeyAdded + "/" + remainingKey} dataKey="C" stroke="#50C878" fillOpacity={0} />
                        <Tooltip isAnimationActive={false} formatter={numberFormatter} />
                        <Legend />
                    </RadarChart>
                </ResponsiveContainer>
            </>
        ) 
        else return (<></>);
    }

    const handlePlot = useCallback((response) => {
        if (response?.data != null && response.status == 200) {
            setArray(response.data);
        }
    }, []);

    useEffect(() => {
        if (array.length == 0 || props.LuminaireCfg.selectedLuminaireIds) {
            EdgeOrREST(null, "GET_POLAR", handlePlot, API_OBJECTS.getCalculationObject(props, null, null));
        }
    }, [array.length, handlePlot, props]);


    return (
        <RenderPolarPlot />
    )
}

export default Polar;