import React, { useState, useEffect } from 'react';
import {
	Button
} from '@material-ui/core';
import Notification from '../../components/shared/notification.component';
import { useDispatch, useSelector } from 'react-redux';
import EdgeOrREST from '../../EdgeOrREST';
import * as d3 from 'd3';
import TemplateSelector from './pdf_TemplateSelector';
import TemplateImport from './pdf_TemplateImport';
import tourActions from '../../store/actions/tourActions';

export default function PdfConfig() {
	const state = useSelector(state => state);
	const dispatch = useDispatch();
	const [height, setHeight] = useState(623);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [uploadFile, setUploadFile] = React.useState("")
	//const [width, setWidth] = useState(368);
	//const [scaledTotalWidth, setScaledTotalWidth] = useState(0);
	//const [offsets, setOffsets] = useState([]);
	const [isPdfReady, setIsPdfReady] = useState(false);
	const [snack, setSnack] = useState({
		open: false
	});

	useEffect(() => {
		var optValues = state.LuminaireCfg.optimizedValues;
		let nullVal = false;
		optValues.forEach((vals, index) => {
			vals.gridNo = index + 1;
			if (vals?.Eav == "---" || vals?.Lav == "---") { nullVal = true; }
		});
		setIsPdfReady(!nullVal && selectedTemplate != '');
	}, [state.LuminaireCfg.optimizedValues, selectedTemplate]);



	let scaledTotalWidth;
	let offsets = [];
	let width = 623;

	const handleClose = () => {
		setSnack({
			...snack,
			open: false
		});
	};

	function transform(widthScale, heightScale, xTransform, yTransform) {
		return d3.geoTransform({
			point: function (x, y) {
				this.stream.point(x * widthScale + xTransform, y * heightScale + yTransform);
			}
		});
	}

	const calScaledTotalWidth = (linearScale) => {
		scaledTotalWidth = 0;
		offsets = [];
		const grids = state.GridsCfg.RoadGrids;
		var totalWidth = 0;
		offsets = [];
		for (let index = 0; index < grids.length; index++) {
			const grid = grids[index];
			if (index === 0) {
				totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				offsets.push(totalWidth);
			}
			else {
				totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
				offsets.push(totalWidth);
			}
		}
		scaledTotalWidth = linearScale(totalWidth);
	}

	const drawBase = (linearScale, ctx) => {
		ctx.strokeStyle = 'black';
		ctx.fillStyle = "white";
		//scaledTotalWidth = 0;
		//offsets = [];

		const grids = state.GridsCfg.RoadGrids;
		//console.log(grids);
		//var totalWidth = 0;
		var extraWidth = 0;
		//var offsets = [];
		// for (let index = 0; index < grids.length; index++) {
		// 	const grid = grids[index];
		// 	if (index === 0) {
		// 		totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
		// 		offsets.push(totalWidth);
		// 	}
		// 	else {
		// 		totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
		// 		offsets.push(totalWidth);
		// 	}
		// }
		//scaledTotalWidth = linearScale(totalWidth);
		for (let i = 0; i < grids.length; i++) {
			const grid = grids[i];
			var roadWidth = grid.NumberOfLanes * grid.LaneWidth;
			if (i !== 0) extraWidth += grid.LeftOffset;
			var scaledRoadWidth = linearScale(roadWidth);
			var scaledExtraWidth = linearScale(extraWidth);

			if (i === 0) {
				ctx.fillRect((width / 2) - (scaledTotalWidth / 2), 0, scaledRoadWidth, height);
				ctx.strokeRect((width / 2) - (scaledTotalWidth / 2), 0, scaledRoadWidth, height);
			}
			else {
				ctx.fillRect(((width / 2) - (scaledTotalWidth / 2)) + scaledExtraWidth, 0, scaledRoadWidth, height);
				ctx.strokeRect(((width / 2) - (scaledTotalWidth / 2)) + scaledExtraWidth, 0, scaledRoadWidth, height);

			}
			extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			//ctx.strokeRect((width * (index + 1)) / (grids.length + 1) - ((scaledWidth * (index + 1)) / (grids.length + 1)), 0, scaledWidth, height);
			//if (grid.NumberOfLanes > 1) drawDashedLine([100, 50], scaledRoadWidth, ctx);
		}
		//drawColumns(linearScale, ctx, scaledTotalWidth, offsets);
		//drawLabels(linearScale, ctx, scaledTotalWidth, offsets);
		//setScaledTotalWidth(scaledTotalWidth);
		//setOffsets(offsets);
	}

	const drawLabels = (scale, ctx) => {
		ctx.fillStyle = 'black'; // set text color
		ctx.font = '14px Arial'; // set text font and size

		const grids = state.GridsCfg.RoadGrids;
		for (let i = 0; i < grids.length; i++) {
			const grid = grids[i];
			const gridType = grid.GridType;

			// calculate the center x position of the grid
			const xPos = i === 0 ?
				(width / 2) - (scaledTotalWidth / 2) + scale(grid.NumberOfLanes * grid.LaneWidth / 2) :
				((width / 2) - (scaledTotalWidth / 2)) + scale(offsets[i - 1] + grid.LeftOffset + (grid.NumberOfLanes * grid.LaneWidth / 2));

			// calculate the y position of the label
			// you can adjust the 20 value to position the labels as needed
			const yPos = height + 20;

			ctx.textAlign = 'center'; // center the text
			ctx.fillText(gridType + `: ${grid.NumberOfLanes * grid.LaneWidth}m`, xPos, yPos);
			// draw the length label
			if (i == 0) {
				const Columns = state.ColumnCfg.Columns;
				var spacing = Math.max.apply(Math, Columns.map(function (o) { return o.columnSpacing; }))
				ctx.rotate(-Math.PI / 2);
				ctx.fillText(`${spacing}m`, -height / 2, width / 2 - scaledTotalWidth / 2 - 10);
				ctx.rotate(Math.PI / 2);
			}
		}
	}

	const drawColumns = (scale, ctx) => {
		const Columns = state.ColumnCfg.Columns;
		const grids = state.GridsCfg.RoadGrids;
		let maxSpacing = Math.max.apply(Math, Columns.map(function (o) { return o.columnSpacing; }))
		for (let i = 0; i < Columns.length; i++) {
			var columnSetback = scale(Columns[i].columnSetback);
			var columnOutreach = scale(Columns[i].columnOutreach);
			if (columnOutreach === undefined) columnOutreach = 0;
			if (columnSetback === undefined) columnSetback = 0;
			var lampLen = scale(0.4);
			var lampWidth = scale(0.2);
			ctx.fillStyle = 'black';
			for (let j = 0; j <= height; j += height) {
				var scaledHeight = j;
				if (Columns[i].columnSpacing !== maxSpacing && j === 0) scaledHeight = height - scale(Columns[i].columnSpacing);
				var offset = 0;
				switch (Columns[i].columnConfiguration) {
					case "Single Sided Left":
						if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						break;

					case "Single Sided Right":
						if (j == 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
						}
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						break;

					case "Staggered":
						if (j != 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
							offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						}
						else if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);

						break;

					case "Reverse Staggered":
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						if (j == 0) {
							offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						}
						else if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						else offset = 0;
						break;
					case "Opposite":
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
						ctx.beginPath();
						ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
						ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
						ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach + offset, scaledHeight);
						ctx.fill();
						ctx.stroke();
						columnSetback *= -1;
						columnOutreach *= -1;
						lampLen *= -1;
						if (Columns[i].columnGrid > 0) offset = scale(offsets[Columns[i].columnGrid - 1] + grids[Columns[i].columnGrid].LeftOffset);
						else offset = 0;
						break;
					case "Twin Central":
						if (j == 0) {
							columnSetback *= -1;
							columnOutreach *= -1;
							lampLen *= -1;
						}
						offset = scale(offsets[Columns[i].columnGrid] - grids[Columns[i].columnGrid].RightOffset);
						ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback - columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
						ctx.beginPath();
						ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
						ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
						ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback - columnOutreach + offset, scaledHeight);
						ctx.fill();
						ctx.stroke();
						break;

					default:
						break;
				}
				ctx.fillRect((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach - lampLen / 2 + offset, scaledHeight - lampWidth / 2, lampLen, lampWidth)
				ctx.beginPath();
				ctx.arc((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight, 4, 0, Math.PI * 2, false);
				ctx.moveTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + offset, scaledHeight);
				ctx.lineTo((width / 2) - (scaledTotalWidth / 2) - columnSetback + columnOutreach + offset, scaledHeight);
				ctx.fill();
				ctx.stroke();
			}
		}
	}

	const drawContours = (linearScale, ctx) => {
		if (state.LuminaireCfg.contoursGridResults[0] && state.LuminaireCfg.contoursGridResults.length === state.GridsCfg.RoadGrids.length) {
			const grids = state.GridsCfg.RoadGrids;
			var totalWidth = 0;
			var extraWidth = 0;
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				if (index === 0) totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
				else totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].LeftOffset;
			}
			//This spacing will affect the height of the final picture. In the optimse's canvas, zooming scale is needed while not appling on PDF picture
			//var maxSpacing = Math.max.apply(Math, state.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				var data = state.LuminaireCfg.contoursGridResults[index];
				var contourValues = state.ProjectConfig.contourValues;
				for (let j = 0; j < data.length; j++) {
					data[j] = parseFloat(data[j]);
				}
				var dataMax = Math.max(...state.LuminaireCfg.contoursGridResults[index]);
				var dataMin = Math.min(...state.LuminaireCfg.contoursGridResults[index]);
				var lineColor = state.ProjectConfig.autoContourValues ?
					d3.scaleSequential(d3.interpolateSpectral).domain([dataMax, dataMin]) :
					d3.scaleSequential(d3.interpolateSpectral).domain([contourValues[contourValues.length - 1], [contourValues[0]]]);

				var xPoints = 0;
				if (grid.Standard == "IES RP-8-18") xPoints = grid.NumberOfLanes * 20;
				else if (grid.IllumTypeId == "Luminance") xPoints = grid.NumberOfLanes * 30;
				else {
					var ceiling = Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5);
					xPoints = ceiling < 3 ? 30 : ceiling * 10;
				}
				var yPoints = data.length / xPoints;
				var widthScale = width / xPoints;
				var heightScale = height / yPoints;
				var contours = d3.contours().size([xPoints, yPoints]);
				var cntrs = state.ProjectConfig.autoContourValues ?
					contours.thresholds(d3.range(0, dataMax, (dataMax / 7)))(data) :
					contours.thresholds(contourValues)(data);

				var roadWidth = grid.LaneWidth * grid.NumberOfLanes;
				if (index !== 0) extraWidth += grid.LeftOffset;
				//The picture height in PDF report is fixed height based on the picture size, no scale is needed
				//var yScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
				var scaledTotalWidthC = linearScale(totalWidth);
				var scaledExtraWidth = linearScale(extraWidth);
				var translateWidth = linearScale(roadWidth);
				var scaledWidth = translateWidth * widthScale / width;
				var projection;
				if (index === 0) projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidthC / 2, height);
				else projection = transform(scaledWidth, -heightScale, width / 2 - scaledTotalWidthC / 2 + scaledExtraWidth, height);


				var path = d3.geoPath(projection, ctx);
				for (let i = 0; i < cntrs.length; i++) {
					const c = cntrs[i];
					if (c.coordinates.length == 0) return;
					ctx.beginPath();
					path(c);
					ctx.strokeStyle = lineColor(c.value);
					ctx.stroke();
				}

				extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			}

		}
	}

	const drawResults = (linearScale, ctx) => {
		ctx.textAlign = 'left';
		if (state.LuminaireCfg.gridResults[0] && state.LuminaireCfg.gridResults.length === state.GridsCfg.RoadGrids.length) {
			const grids = state.GridsCfg.RoadGrids;
			var totalWidth = 0;
			var extraWidth = 0;
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				if (index === 0) totalWidth += grid.NumberOfLanes * grid.LaneWidth;
				else totalWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset + grid.LeftOffset + grids[index - 1].RightOffset + grids[index - 1].LeftOffset;
			}
			//var maxSpacing = Math.max.apply(Math, state.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }))
			for (let index = 0; index < grids.length; index++) {
				const grid = grids[index];
				ctx.font = grid.LaneWidth > 4 ? "10px Arial" : grid.LaneWidth > 2 ? "8px Arial" : "6px Arial";
				var myArray = state.LuminaireCfg.gridResults[index];
				var reversedArray = myArray ? myArray.slice().reverse() : null; // create a reversed copy of the array
				var xPoints = 0;
				if (grid.Standard == "IES RP-8-18") xPoints = grid.NumberOfLanes * 2;
				else if (grid.IllumTypeId == "Luminance") xPoints = grid.NumberOfLanes * 3;
				else {
					xPoints = Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5) < 3 ? 3 : Math.ceil((grid.LaneWidth * grid.NumberOfLanes) / 1.5);
				}
				var yPoints = reversedArray ? reversedArray.length / xPoints : 0; // use the reversed array to calculate yPoints
				var dataMax = Math.max(...reversedArray);
				var dataMin = Math.min(...reversedArray);
				var heightScale = height / yPoints;
				var heightSpacing = (height / (yPoints + 1) / 2);
				var roadWidth = grid.LaneWidth * grid.NumberOfLanes;
				if (index !== 0) extraWidth += grid.LeftOffset;
				//var linearScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
				var scaledRoadWidth = linearScale(roadWidth);
				var scaledTotalWidthR = linearScale(totalWidth);
				var scaledExtraWidth = linearScale(extraWidth);
				var point = 0;
				for (let x = xPoints - 1; x >= 0; x--) {
					for (let y = 0; y < yPoints; y++) {
						//ctx.fillText(myArray[point], (80 / state.ProjectConfig.RoadCfg.NumOfLanes) + (window.innerHeight * x / (2 * xPoints)), 40 + (window.innerWidth * y / 25))
						if (reversedArray[point] == dataMax) ctx.fillStyle = 'forestgreen';
						else if (reversedArray[point] == dataMin) ctx.fillStyle = 'red';
						else ctx.fillStyle = 'darkcyan';
						if (index === 0) ctx.fillText(reversedArray[point], x * (scaledRoadWidth / xPoints) + (width / 2 - scaledTotalWidthR / 2) + 2 * grid.LaneWidth, (y * heightScale + heightSpacing + 8));
						else ctx.fillText(reversedArray[point], x * (scaledRoadWidth / xPoints) + (width / 2 - scaledTotalWidthR / 2) + 2 * grid.LaneWidth + scaledExtraWidth, (y * heightScale + heightSpacing + 8));
						point++;
					}
				}
				//if (state.ProjectConfig.RoadCfg.NumOfLanes > 1) drawDashedLine([100, 50], scaledTotalWidth, ctx);

				extraWidth += grid.NumberOfLanes * grid.LaneWidth + grid.RightOffset;
			}
		}
	}

	function generatePDF() {
		var maxSpacing = Math.max.apply(Math, state.ColumnCfg.Columns.map(function (o) { return o.columnSpacing; }));
		var linearScale = d3.scaleLinear().domain([0, maxSpacing]).range([0, height]);
		//var canvas = document.createElement("canvas");
		//canvas.width = 2000; // Set the width to 400 pixels
		//canvas.height = 2000; // Set the height to 300 pixels

		let imageDataList = [];
		//var ctx = canvas.getContext('2d');
		//drawBase(linearScale, ctx);
		calScaledTotalWidth(linearScale);
		var newCanvas = document.createElement("canvas");
		var newCtx = newCanvas.getContext("2d");
		//drawBase(linearScale, newCtx);
		//newCtx.clearRect(0, 0, newCanvas.width, newCanvas.height);
		if (scaledTotalWidth > width) {
			newCanvas.width = scaledTotalWidth + 20;
			width = scaledTotalWidth;
		} else {
			newCanvas.width = width + 20;
		}
		//newCanvas.width = scaledTotalWidth > width? scaledTotalWidth: width;
		console.log("newCanvas.width", newCanvas.width);
		newCanvas.height = height + 20;

		drawBase(linearScale, newCtx);
		drawColumns(linearScale, newCtx);
		drawLabels(linearScale, newCtx);
		drawContours(linearScale, newCtx);
		drawResults(linearScale, newCtx);
		let imgData = newCanvas.toDataURL('image/png');

		// const a = document.createElement('a');
		// a.href = imgData;
		// a.download = 'exported_image.png';
		// a.click();
		// a.remove();

		imageDataList.push({ imageName: "GridResult_RC", imageData: imgData });

		newCtx.clearRect(0, 0, newCanvas.width, newCanvas.height);
		drawBase(linearScale, newCtx);
		drawColumns(linearScale, newCtx);
		drawLabels(linearScale, newCtx);
		drawResults(linearScale, newCtx);
		imgData = newCanvas.toDataURL('image/png');
		imageDataList.push({ imageName: "GridResult_R", imageData: imgData });

		newCtx.clearRect(0, 0, newCanvas.width, newCanvas.height);
		drawBase(linearScale, newCtx);
		drawColumns(linearScale, newCtx);
		drawLabels(linearScale, newCtx);
		drawContours(linearScale, newCtx);
		imgData = newCanvas.toDataURL('image/png');
		imageDataList.push({ imageName: "GridResult_C", imageData: imgData });

		// const a = document.createElement('a');
		// a.href = imgData;
		// a.download = 'exported_image.png';
		// a.click();
		// a.remove();

		var optValues = state.LuminaireCfg.optimizedValues;
		let valueGridLists = []
		optValues.forEach((vals, index) => {
			vals.gridNo = index + 1;
			let nullVal = false;
			if (vals?.Eav == "---" || vals?.Lav == "---") nullVal = true;
			if (!nullVal) valueGridLists.push(vals)
		});
		console.log(optValues);
		if (valueGridLists.length == 0 || valueGridLists == null) {
			setSnack({
				open: true,
				message: 'No Gird Result.',
				severity: 'error'
			});
		}
		if (selectedTemplate == null || selectedTemplate == "") {
			setSnack({
				open: true,
				message: 'Please select template.',
				severity: 'error'
			});
		}
		if (imgData != null && (selectedTemplate != null && selectedTemplate != "") && (valueGridLists.length > 0 && valueGridLists != null)) {
			EdgeOrREST(null, "CREATE_PDF", onCreatePDF, {
				resultImagelist: imageDataList,
				lumId: state.LuminaireCfg.selectedLuminaireIds[0],
				template: selectedTemplate,
				columnConfiguration: state.ColumnCfg.Columns[0].columnConfiguration,
				columnDim: state.ColumnCfg.Columns[0].columnDim,
				columnGrid: state.ColumnCfg.Columns[0].columnGrid,
				columnHeight: state.ColumnCfg.Columns[0].columnHeight,
				columnMaintFactor: state.ColumnCfg.Columns[0].columnMaintFactor,
				columnOutreach: state.ColumnCfg.Columns[0].columnOutreach,
				columnOverhang: state.ColumnCfg.Columns[0].columnOutreach - state.ColumnCfg.Columns[0].columnSetback,
				columnSetback: state.ColumnCfg.Columns[0].columnSetback,
				columnSpacing: state.ColumnCfg.Columns[0].columnSpacing,
				columnTilt: state.ColumnCfg.Columns[0].columnTilt,
				CalcGridClassValue: state.GridsCfg.RoadGrids[0].CalcGridClassValue,
				Standard: state.GridsCfg.RoadGrids[0].Standard,
				LaneWidth: state.GridsCfg.RoadGrids[0].LaneWidth,
				NumberOfLanes: state.GridsCfg.RoadGrids[0].NumberOfLanes,
				LeftOffset: state.GridsCfg.RoadGrids[0].LeftOffset,
				RightOffset: state.GridsCfg.RoadGrids[0].RightOffset,
				RTable: state.GridsCfg.RoadGrids[0].RoadSurfaceName,
				Q0: state.GridsCfg.RoadGrids[0].Q0,
				valueGridLists: valueGridLists//optimse value
			});
		}
	}

	const onCreatePDF = (response) => {
		var a = document.createElement('a');
		var mediaType = "data:application/pdf;base64,";
		a.href = mediaType + response.data.PDFFile;
		a.download = response.data.fileName + '.pdf';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const onDownloadPDFTemp = (response) => {
		var aDoc = document.createElement('a');
		if (response.data.fileType == "docx") {
			var docMediaType = "data:vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
			aDoc.href = docMediaType + response.data.file;
			aDoc.download = response.data.fileName + '.docx';
			document.body.appendChild(aDoc);
			aDoc.click();
			document.body.removeChild(aDoc);
		} else if (response.data.fileType == "pdf") {
			var docMediaType = "data:application/pdf;base64,";
			aDoc.href = docMediaType + response.data.file;
			//aDoc.download = response.data.fileName + '.pdf';
			aDoc.download = response.data.fileName;
			document.body.appendChild(aDoc);
			aDoc.click();
			document.body.removeChild(aDoc);
		}
	};

	const openDialog = () => {
		if (state.EnabledTour.helpMode) {
			dispatch(tourActions.UpdateHelpURL({ helpURL: "Export pdf" }))
			const video = document.getElementById("help-video")
			video.load();
			dispatch(tourActions.UpdateHelpText({ helpText: "This button lets you export your results in a PDF format. Select a template or upload your own." }))
		} else {
			setIsDialogOpen(true);
		}
		//generatePDF();
	};

	const closeDialog = () => {
		setIsDialogOpen(false);
	};

	const closeImportDialog = () => {
		setIsImportDialogOpen(false);
		//setIsDialogOpen(true);
	};

	const comfirmImportDialog = () => {
		EdgeOrREST(null, 'UPLOAD_PDF_TEM', onUploadFile, uploadFile);
		setIsImportDialogOpen(false);
		//setIsDialogOpen(true);
	};

	const onUploadFile = (response) => {
		if (response && response.status == '200') {
			setSnack({
				open: true,
				message: 'Finish Upload',
				severity: 'success'
			});
			setIsImportDialogOpen(false);
			setIsDialogOpen(true);
		}
		else {
			setSnack({
				open: true,
				message: 'Upload Fail.',
				severity: 'error'
			});
		}
	};


	const confirmDialog = () => {
		setIsDialogOpen(false);
		generatePDF();
	};

	const downloadDialog = () => {
		setIsDialogOpen(false);
		downloadPDF();
	};

	const deleteDialog = () => {
		setIsDialogOpen(false);
		EdgeOrREST(null, "DELETE_PDF_TEMP", onDeletePDFTemp, {
			headers: { 'templateName': selectedTemplate }
		});
	};

	const onDeletePDFTemp = (response) => {
		if (response.status == 200) {
			setIsDialogOpen(true);
		}
	};

	function downloadPDF() {
		if (selectedTemplate == null || selectedTemplate == "") {
			setSnack({
				open: true,
				message: "Please Select Template.",
				severity: 'error'
			});
			return;
		}
		EdgeOrREST(null, "DOWNLOAD_PDF_TEMP", onDownloadPDFTemp, {
			template: selectedTemplate,
		});
	}
	const handleSelectTemplate = (template) => {
		setSelectedTemplate(template);
	};

	const handleImportDialogOpen = () => {
		setIsDialogOpen(false);
		setIsImportDialogOpen(true);
	};

	const handleChangeFile = (file) => {
		setUploadFile(file);
		//setIsDialogOpen(false);
		//setIsImportDialogOpen(true);
	};


	return (
		<>
			<Button
				variant="contained"
				color="primary"
				fullWidth={true}
				className="generatePDFButton"
				onClick={openDialog}
			>
				Create PDF
			</Button>
			<TemplateSelector isOpen={isDialogOpen} isImportDialogOpen={handleImportDialogOpen} selectTemplate={selectedTemplate} onSelectTemplate={handleSelectTemplate}
				onClose={closeDialog} onConfirm={confirmDialog} onDownload={downloadDialog} onDelete={deleteDialog} isPdfReady={isPdfReady} />
			<TemplateImport isOpen={isImportDialogOpen} onClose={closeImportDialog} onConfirm={comfirmImportDialog} onChangeFile={handleChangeFile} />
			<Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
		</>
	);

}