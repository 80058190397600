import * as React from 'react';
import { DataGrid, GridFooterContainer, GridPagination } from '@material-ui/data-grid';
import {
  createTheme,
  makeStyles,
  Tooltip,
  Box,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';
import Notification from '../components/shared/notification.component';
import luminaireActions from '../store/actions/luminaireActions';
import searchActions from '../store/actions/searchActions';
import { GridToolbarContainer } from '@material-ui/data-grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '75vh',
      width: '100%',
      '& .MuiFormGroup-options': {
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '& > div': {
          minWidth: 100,
          margin: theme.spacing(2, 2, 2, 0),
        },
      },
    },
    paper: {
      minHeight: '500px',
      flexDirection: "column"
    },
  }),
  { defaultTheme },
);


export default function PhotometricTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector(state => state);
  const loading = useSelector(state => state.Search.load);

  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const onDoubleClickRow = (event) => {
    let selectedResultLuminId = event.id;
    console.log("selectedResultLuminId", selectedResultLuminId)
    dispatch(luminaireActions.UpdateLuminaireList({ ...state.LuminaireCfg, selectedResultLuminId: selectedResultLuminId, selectedResultName: event.value }));
    dispatch(user.UpdateUserCfg({ refreshLuminaireInfo: true }));
  }

  // const deleteLuminaire = (e, id) => {
  //   EdgeOrREST(null, 'DELETE_LUMINAIRE', onDeleteLuminaire, { headers: { 'id': id } });
  // }

  // const onDeleteLuminaire = (response) => {
  //   console.log("response", response)
  //   if (response && response.status == '200') {
  //     var id = response.data;
  //     var tempSearchedLums = JSON.parse(JSON.stringify(props.data));
  //     var index = tempSearchedLums.findIndex(x => x.id == id);
  //     tempSearchedLums.splice(index, 1);
  //     dispatch(user.UpdateUserCfg({ searchedLuminaires: tempSearchedLums }));
  //     setSnack({
  //       open: true,
  //       message: "Luminaire deleted successfully.",
  //       severity: 'success'
  //     });
  //   } else {
  //     setSnack({
  //       open: true,
  //       message: response.status + response.message,
  //       severity: 'error'
  //     });
  //   }
  // }

  const columns = [
    {
      field: 'lumcat', headerName: 'File Name', width: 200,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          File Name
          <Tooltip placement='top' title="Double click a luminaire to view it in the tree">
            <IconButton aria-label="info" size="small" style={{ marginLeft: 4 }}>
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.lumcat} >
          <span className="table-cell-trucate">{cellValues.row.lumcat}</span>
        </Tooltip>
      ),
    },
    {
      field: 'description', headerName: 'Description', width: 200,
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.description} >
          <span className="table-cell-trucate">{cellValues.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: 'brands', headerName: 'Brands', width: 165,
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.brands} >
          <span className="table-cell-trucate">{cellValues.row.brands}</span>
        </Tooltip>
      ),
    },
    { field: 'cct', headerName: 'CCT', width: 140 },
    { field: 'luminWatt', headerName: 'Lumin per Watt', width: 180 },
    // {
    //   field: 'delete', headerName: 'Delete', width: 75, hide: true,
    //   renderCell: (cellValues) => (
    //     <IconButton onClick={(e) => deleteLuminaire(e, cellValues.row.id)}> <DeleteIcon /> </IconButton>
    //   ),
    // }
  ];
  const CustomNoRowsOverlay = ({ loading }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography>No rows</Typography>
        )}
      </Box>
    );
  };


  return (
    <div className={classes.root}>
      <DataGrid 
      style={{ width: '100%' }} 
      rows={props.data ? props.data : []} 
      columns={columns} 
      onCellDoubleClick={e => onDoubleClickRow(e)} 
      components={{
        NoRowsOverlay: () => <CustomNoRowsOverlay loading={loading} />,
      }}
      />
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </div>
  );
}
