import React, { useEffect } from 'react';
import { useInterval } from './automate-interval-runner.component';
import EdgeOrREST from '../EdgeOrREST';
import Stack from '@mui/material/Stack';
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Divider,
  CardContent,
  makeStyles,
  Paper,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import AutomateJobTableHolder from './automate-job-table-holder.component';
import JobPagination from './automate-jobs-pagination.component';
import Notification from '../components/shared/notification.component';
import { useDispatch, useSelector } from 'react-redux';
import user from '../store/actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '75vh',
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2, 2, 2, 0),
      },
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  margin: {
    margin: '0px',
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  tab: {
    display: 'content'
  }
}));

const AutomateJobs = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 125, flex: 1, editable: false },
    { field: 'type', headerName: 'Type', minWidth: 107, flex: 1 },
    {
      field: 'status', headerName: 'Status', minWidth: 118, flex: 1,
      renderCell: (cellValues) => {
        return (
          cellValues.row.status == "Queued" ? <span style={{ color: "#FDAC53" }}>{cellValues.row.status}</span> :
            cellValues.row.status == "Complete" ? <span style={{ color: "#008240" }}>{cellValues.row.status}</span> :
              <span style={{ color: "#D2386C" }}>{cellValues.row.status}</span>
        )
      }
    },
    { field: 'timestamp', headerName: 'Date', minWidth: 107, flex: 1 },
    {
      field: '  ', headerName: '', minWidth: 85, flex: 1,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
            disabled={cellValues.row.status != 'Complete'}
          >
            Results
          </Button>
        );
      }
    }

  ];

  const classes = useStyles();

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'timestamp',
      sort: 'desc',
    }
  ]);

  useInterval(() => {
    if (!isSearch) {
      getJobs();
      //dispatch(user.UpdateUserCfg({ jobsPages: 1 })); 
    }
    else searchJobs()
  }, 5000);

  useEffect(() => {
    getJobs();
    //dispatch(user.UpdateUserCfg({ jobsPages: 1 }));
    console.log("GetJobs after submit");
    //dispatch(user.UpdateUserCfg({isGetJobs: false}));
  }, [state.user.getJobFlag]);

  useEffect(() => {
    let isMounted = true;
    getJobs()
    return () => { isMounted = false };
  }, []);

  function getJobsManually() {
    getJobs();
    setSnack({
      open: true,
      message: 'Jobs Search have been updated.',
      severity: 'success'
    });
  }

  function getJobs() {
    setIsSearch(false);
    setJobName('');
    setJobStartDate('');
    setJobEndDate('');
    EdgeOrREST(null, 'GET_JOB_LIST', onGetJobs, { headers: { 'blockNumber': state.user.blockNumber } });
    // setSnack({
    //   open: true,
    //   message: 'Jobs have been updated.',
    //   severity: 'success'
    // });
  }

  function searchJobs() {
    if (!jobName && !jobStartDate && !jobEndDate) {
      getJobs();
      //dispatch(user.UpdateUserCfg({ jobsPages: 1 }));
      setIsSearch(false);
    }
    else {
      setIsSearch(true);
      EdgeOrREST(null, 'GET_JOB_SEARCH', onSearchJobs, { headers: { 'jobName': jobName, 'jobStartDate': formatStartDateString(), 'jobEndDate': formatEndDateString() } });
    }

    setSnack({
      open: true,
      message: 'Jobs Search have been updated.',
      severity: 'success'
    });
  }

  function formatStartDateString() {
    if (!jobStartDate) return '';
    var dataTemp = jobStartDate.split('-');
    return dataTemp[2] + "/" + dataTemp[1] + "/" + dataTemp[0] + " 00:00:00";
  }

  function formatEndDateString() {
    if (!jobEndDate) return '';
    var dataTemp = jobEndDate.split('-');
    return dataTemp[2] + "/" + dataTemp[1] + "/" + dataTemp[0] + " 23:59:59";
  }

  function sortJobs(automateJobs) {
    automateJobs.sort(function (x, y) {
      var a = x.timestamp.split(' ');
      var b = y.timestamp.split(' ');

      var splitDateA = a[0].split('/');
      var splitTimeA = a[1].split(':');
      var dateA = new Date(splitDateA[2], splitDateA[1], splitDateA[0]);

      var splitDateB = b[0].split('/');
      var splitTimeB = b[1].split(':');
      var dateB = new Date(splitDateB[2], splitDateB[1], splitDateB[0]);

      if (dateA.getTime() > dateB.getTime()) {
        return -1;
      } else if (dateA.getTime() < dateB.getTime()) {
        return 1;
      } else {
        if (splitTimeA[0] > splitTimeB[0]) {
          return -1;
        } else if (splitTimeA[0] < splitTimeB[0]) {
          return 1;
        } else {
          if (splitTimeA[1] > splitTimeB[1]) {
            return -1;
          } else if (splitTimeA[1] < splitTimeB[1]) {
            return 1;
          } else {
            if (splitTimeA[2] > splitTimeB[2]) {
              return -1;
            } else if (splitTimeA[2] < splitTimeB[2]) {
              return 1;
            }
          }
        }
      }
    });
    return automateJobs;
  }

  const onGetJobs = (response) => {
    if (response && response.data?.automateJobs && response.data?.automateJobs?.length > 0) {
      console.log("onGetJobs, response.data", response.data);
      for (var i = 0; i < response.data.automateJobs.length; i++) {
        response.data.automateJobs[i].status = response.data.automateJobs[i].status == 1 ? 'Complete' :
          response.data.automateJobs[i].status == 0 || response.data.automateJobs[i].status == 2 ? 'Queued' :
            response.data.automateJobs[i].status == 3 ? 'Calculating' : 'Error';
        console.log("currJob status,", response.data.automateJobs[i].status);
      }

      response.data.automateJobs = sortJobs(response.data.automateJobs);
      console.log("dispatching jobs to redux ");
      console.log("response.data.automateJobsCount  ", response.data.automateJobsCount);
      console.log("response.data.automateJobsCount / 50 ", response.data.automateJobsCount / 50);
      dispatch(user.UpdateUserCfg({ jobs: response.data.automateJobs, jobsPages: Math.ceil(response.data.automateJobsCount / 50) }));
    }
    else {
      console.log("No Jobs Found");
      dispatch(user.UpdateUserCfg({ jobs: [], jobsPages: 1 }));
    }
  };

  const onSearchJobs = (response) => {
    if (response && response.data?.automateJobs && response.data?.automateJobs?.length > 0) {
      console.log("onSearchJobs, response.data", response.data);
      for (var i = 0; i < response.data.automateJobs.length; i++) {
        response.data.automateJobs[i].status = response.data.automateJobs[i].status == 1 ? 'Complete' :
          response.data.automateJobs[i].status == 0 || response.data.automateJobs[i].status == 2 ? 'Queued' :
            response.data.automateJobs[i].status == 3 ? 'Calculating' : 'Error';
        console.log("currJob status,", response.data.automateJobs[i].status);
      }

      response.data.automateJobs = sortJobs(response.data.automateJobs);
      console.log("dispatching jobs to redux ");
      console.log("isSearch read " + isSearch);
      dispatch(user.UpdateUserCfg({ jobs: response.data.automateJobs, jobsPages: 1 }));
    }
    else {
      console.log("No Jobs Found");
      dispatch(user.UpdateUserCfg({ jobs: [], jobsPages: 1 }));
    }
  };


  const [snack, setSnack] = React.useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  const handleClick = (event, cellValue) => {
    console.log("event", cellValue);
    EdgeOrREST(null, 'DOWNLOAD_AUTOMATE_RESULTS', onGetResults, { headers: { 'id': cellValue.row.id } });
  };

  const onGetResults = (response) => {
    console.log(response);
    var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var a = document.createElement('a');
    a.href = mediaType + response.data.base64;
    a.download = response.data.fileName + '.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const [jobFilter, setJobFilter] = React.useState("");
  const [jobName, setJobName] = React.useState("");
  const [jobStartDate, setJobStartDate] = React.useState("");
  const [jobEndDate, setJobEndDate] = React.useState("");
  const [isSearch, setIsSearch] = React.useState(false);
  useEffect(() => {
    console.log("isSearch Change " + isSearch);
  }, [isSearch]);

  useEffect(() => {
    console.log("  state?.user?.jobs ? " + state?.user?.jobs);
  }, [state?.user?.jobs]);

  const onJobFilterChange = (e) => {
    if (e.nativeEvent.inputType == "insertText") {
      var currText = jobFilter;
      setJobFilter(currText += e.nativeEvent.data);
    } else if (e.nativeEvent.inputType == "deleteContentBackward") {
      var currText = jobFilter;
      setJobFilter(currText.slice(0, -1));
    } else if (e.nativeEvent.inputType == "deleteWordBackward") {
      var currText = jobFilter;
      setJobFilter("");
    }
  };

  const onJobNameChange = (event) => {
    setJobName(event.target.value)
  };

  const onStartDateChange = (event) => {
    setJobStartDate(event.target.value.toString())
    if (Date.parse(jobStartDate) > Date.parse(jobEndDate)) setJobEndDate(jobStartDate);
  }

  const onEndDateChange = (event) => {
    setJobEndDate(event.target.value.toString())
    if (Date.parse(jobEndDate) < Date.parse(jobStartDate)) setJobStartDate(jobEndDate);
  }


  return (
    <Card style={{ 'minHeight': 'inherit' }}>
      <Paper square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          value={0}
        >
          <Tab className={classes.tab} label={
            <span className={classes.tab}>
              Automate Jobs
              <Tooltip title="Jobs are automatically refreshed every minute." placement="right-start">
                <InfoIcon style={{ margin: '4px' }}></InfoIcon>
              </Tooltip> </span>} value={0} />
          <IconButton onClick={getJobsManually}><RefreshIcon></RefreshIcon> </IconButton>
        </Tabs>
      </Paper>
      <Divider />
      <CardContent>
        <Grid container spacing={0}>
          <Grid item md={3} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
            <TextField label="Job Name" id="jobName" defaultValue="" onChange={onJobNameChange} focused />
          </Grid>
          <Grid item md={3} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <TextField
              value={jobStartDate}
              type="date"
              label="Start Date"
              id="tfjobStartDate"
              inputProps={{ max: jobEndDate }}
              onChange={onStartDateChange}
              InputLabelProps={{ shrink: true, }}
              onKeyDown={(e) => {
                e.preventDefault();
              }} />
          </Grid>
          <Grid item md={3} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <TextField
              value={jobEndDate}
              type="date"
              label="End Date"
              id="tfjobEndDate"
              inputProps={{ min: jobStartDate }}
              onChange={onEndDateChange}
              InputLabelProps={{ shrink: true, }} />
          </Grid>
          <Grid item md={3} sm={4} xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <Button variant="contained" color="primary" size="small" onClick={searchJobs}>Search</Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        {/* <TextField onChange={onJobFilterChange} value={jobFilter} label="Job Filter" id="outlined-size-small-lum" defaultValue="..."/> */}
        <AutomateJobTableHolder rows={state?.user?.jobs ? state.user.jobs : []} filter={jobFilter} size="small" setJobFilter={setJobFilter} onChange={onJobFilterChange} jobFilter={jobFilter}></AutomateJobTableHolder>
      </CardContent>
      <Stack spacing={1} alignItems="center">
        <JobPagination onGetJobs={onGetJobs} size='small'></JobPagination>
      </Stack>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Card>
  );

}
export default AutomateJobs; 