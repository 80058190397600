import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Card,
	makeStyles,
	CardHeader,
	Avatar
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '85vh',
		padding: theme.spacing(3)
	},
	hover: {
		"&:hover": {
			boxShadow: '3px 3px lightgrey',
			backgroundColor: 'whitesmoke',
			cursor: 'pointer',
			//color: 'white!important',
		},
		"&:hover .MuiCardHeader-subheader": {
			//color: 'white!important',
		},
	}
}));

const LauncherItem = (props) => {
	const navigate = useNavigate();
	const classes = useStyles();

	function reRoute() {
		console.log("calling navigate on:"+props.redirect);
		navigate(props.redirect);
		//return <Navigate to={} push={true}/> ;
	}

	return (
		<Card raised={props.active} className={classes.hover} onClick={props.active ? reRoute : ""} style={{ "minHeight": "100%" }}>
			<CardHeader avatar={
				<Avatar className={classes.avatar}>
					<img style={{ 'height': '42px' }} src={props.logo}></img>
				</Avatar>
			}
			>

				<Tooltip title={props.subTitle} placement="right-start">
					<span></span>
				</Tooltip>
			</CardHeader>


		</Card >
	);

}
export default LauncherItem;