import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Button
} from '@material-ui/core';
import EdgeOrREST from '../EdgeOrREST';
import Notification from '../components/shared/notification.component';

const SubscribeManu = (props) => {
    const [manufacturers, setManufacturers] = React.useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedManufacturers, setSelectedManufacturers] = React.useState([]);
    const [snack, setSnack] = React.useState({
        open: false
    });

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    useEffect(() => {
        EdgeOrREST(null, 'GET_ALL_MANUFACTURERS_COMPANY', onGettAllManufacturers, {});
        EdgeOrREST(null, 'GET_ALL_SUBSCRIBED_COMPANYS', onGettAllSubscribedCompanys, {});
    }, []);

    useEffect(() => {
        console.log("selectedManufacturers", selectedManufacturers);
    }, [selectedManufacturers]);

    const onGettAllManufacturers = (response) => {
        console.log("MANUFACTURERS", response);
        if (response && response.status == '200') {
            setManufacturers(response.data);
        }
    };

    const onGettAllSubscribedCompanys = (response) => {
        console.log("SUBMANUFACTURERS", response);
        if (response && response.status == '200') {
            setSelectedManufacturers(response.data);
        }
    };

    const handleCheckboxChange = (manufacturer) => {
        const updatedSelection = selectedManufacturers.some((selectedManufacturer) => selectedManufacturer.id === manufacturer.id)
            ? selectedManufacturers.filter((selectedManufacturer) => selectedManufacturer.id !== manufacturer.id)
            : [...selectedManufacturers, manufacturer];
        setSelectedManufacturers(updatedSelection);
    };

    const filteredManufacturers = manufacturers.filter((manufacturer) =>
        manufacturer.name?.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleSubmit = () => {
        var companyIds = selectedManufacturers.map((manufacturer) => manufacturer.id);
        //console.log("selectedIds", selectedIds);
        EdgeOrREST(null, 'EDIT_SUBSCRIBE_TREES', onEditSubscribeTrees, companyIds);
    };

    const onEditSubscribeTrees = (response) => {
        //console.log("MANUFACTURERS", response);
        if (response && response.status == '200') {
            EdgeOrREST(null, 'GET_ALL_MANUFACTURERS_COMPANY', onGettAllManufacturers, {});
            EdgeOrREST(null, 'GET_ALL_SUBSCRIBED_COMPANYS', onGettAllSubscribedCompanys, {});
            //props.handleSubscribedListupdated();
            setSnack({
                open: true,
                message: "Subscribed list updated",
                severity: 'success'
            });
            props.handleSubscribedListupdated();
        }
    };

    return (
        <Card style={{ 'minHeight': 'inherit' }}>
            <CardContent>
                <div style={{ marginBottom: 16 }}>
                    <TextField
                        label="Filter Manufacturers"
                        variant="outlined"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                </div>
                <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                    {filteredManufacturers.map((manufacturer) => (
                        <div key={manufacturer.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedManufacturers?.some((selectedManufacturer) => selectedManufacturer.id === manufacturer.id)}
                                        onChange={() => handleCheckboxChange(manufacturer)}
                                    />
                                }
                                label={manufacturer.name}
                            />
                        </div>
                    ))}
                </div>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </CardContent>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Card>
    );
};

export default SubscribeManu;