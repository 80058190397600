import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Notification from '../components/shared/notification.component';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EdgeOrREST from '../EdgeOrREST';
import { useDispatch, useSelector } from 'react-redux';
import staticProductList from './staticProductList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '85vh',
    padding: theme.spacing(3)
  }
}));
const EditComany = (props) => {
  const [selectedComList, setSelectedComList] = useState([]);
  const [selectedComInfo, setSelectedComInfo] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [selectedComProducts, setSelectedComProducts] = useState([]);
  const [productNamesArray, setProductNamesArray] = useState([]);
  const [comList, setComList] = useState([]);
  const [manufacturer, setManufacturer] = useState(false);
  const [products, setProducts] = useState([]);

  const classes = useStyles();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [snack, setSnack] = useState({
    open: false
  });

  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  useEffect(() => {
    console.log("selectedComProducts", selectedComProducts);
  }, [selectedComProducts]);

  useEffect(() => {
    EdgeOrREST(null, 'GET_USER_LIST', onGetUserList, null);
  }, []);

  useEffect(() => {
    if (selectedComList != null && selectedComList?.id > 0) {
      EdgeOrREST(null, 'GET_COMPANY', onGetCompany, { headers: { companyId: selectedComList.id } });
      EdgeOrREST(null, 'GET_PRODUCTS', onGetProduct, { headers: { id: selectedComList.id } });
    }
  }, [selectedComList]);

  const onChange = (event, value) => {
    setSelectedComList(value);
  };

  const onGetCompany = (response) => {
    if (response) {
      setSelectedComInfo(response.data);
      setCompanyName(response.data.name)
      setManufacturer(response.data.isManufacturer == 1 ? true : false);
    }
  }

  const onGetProduct = (response) => {
    if (response && response.data.length > 0) {
      const productNamesArray = response.data.map(product => product.name);
      setSelectedComProducts(productNamesArray);
    } else {
      setSelectedComProducts([]);
    }
  }
  const onGetUserList = (response) => {
    if (response && response.data.comList && response.data.comList.length > 0) {
      setComList(response.data.comList);
    }
  }

  function getAutocomplete() {
    if (comList && comList.length > 0) {
      return (
        <Autocomplete
          id="multiple-limit-tags"
          options={comList}
          onChange={onChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={<Typography>Select Companies</Typography>} placeholder="Companies..." />
          )}
        />
      );
    }
  }

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleManufacturerChange = (event) => {
    setManufacturer(event.target.checked);
  };

  const handleProductChange = (event, product) => {
    if (selectedComProducts.some(item => item === product)) {
      setSelectedComProducts(selectedComProducts.filter((comProduct) => comProduct !== product));
    } else {
      setSelectedComProducts([...selectedComProducts, product]);
    }
  };

  const handleSubmit = () => {
    EdgeOrREST(null, 'EDIT_COMPANY', onEditCom, { companyId: selectedComInfo.id, comName: companyName, isManufacturer: manufacturer ? "1" : "0", selectedProducts: selectedComProducts });
  };

  const onEditCom = (response) => {
    if (response && response.status == '200') {
      setSnack({
        open: true,
        message: 'Edit Company Successfully',
        severity: 'success'
      });
    } else {
      setSnack({
        open: true,
        message: 'Edit Company Fail.',
        severity: 'error'
      });
    }
    if (selectedComList != null && selectedComList?.id > 0) {
      EdgeOrREST(null, 'GET_COMPANY', onGetCompany, { headers: { companyId: selectedComList.id } });
      EdgeOrREST(null, 'GET_PRODUCTS', onGetProduct, { headers: { id: selectedComList.id } });
    }
  }

  return (
    <Page title="Monitor" style={{ minHeight: '85vh' }}>
      <Card style={{ minHeight: 'inherit' }}>
        <CardContent>
          <Grid item md={12} sm={12} xs={12}>
            <div>
              {getAutocomplete()}
            </div>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Company Name"}
                value={companyName}
                variant="outlined"
                onChange={handleCompanyNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={manufacturer} onChange={handleManufacturerChange} />}
                label="Manufacturer"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Products:</Typography>
              <FormControl component="fieldset">
                <Grid container>
                  {staticProductList.map((product) => (
                    <Grid item key={product} xs={12} sm={6} md={4} lg={3}>
                      <FormControlLabel
                        control={<Checkbox
                          checked={selectedComProducts.some(item => item === product)}
                          onChange={(event) => handleProductChange(event, product)} />}
                        label={product}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </Page>
  );
}

export default EditComany;
