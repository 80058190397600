import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, compose } from 'redux';
import { batchedSubscribe } from 'redux-batched-subscribe';  
import logger from 'redux-logger';
import userActions from './actions/user';
import monitorActions from './actions/monitorActions';
import columnActions from './actions/columnActions';
import luminaireActions from './actions/luminaireActions';
import roadActions from './actions/roadActions';
import gridActions from './actions/gridActions';
import user from './reducers/userReducers';
import monitor from './reducers/monitorReducers';
import column from './reducers/columnReducers';
import project from './reducers/roadReducers';
import luminaire from './reducers/luminReducers';
import grid from './reducers/gridReducers'; 
import { initialState } from './initial-state';
import notification from './reducers/notificationReducers';
import notificationActions from './actions/notificationActions';
import EnabledTour from './reducers/tourReducers';
import tourActions from './actions/tourActions';
import Select from './reducers/selectReducers';
import selectActions from './actions/selectActions';
import resultsTableSettingsActions from './actions/resultsTableSettingsActions';
import RTS from './reducers/resultsTableSettingsReducers';
import searchActions from './actions/searchActions';
import searchReducers from './reducers/searchReducers';

const actions = {
    ...userActions,
    ...roadActions,
    ...columnActions,
    ...luminaireActions,
    ...gridActions,
    ...monitorActions,
    ...notificationActions,
    ...tourActions,
    ...selectActions,
    ...resultsTableSettingsActions,
    ...searchActions,
}; 

const rootReducer = combineReducers({
    user: user,
	ColumnCfg: column,
    ProjectConfig: project,
    LuminaireCfg: luminaire,
    GridsCfg: grid,
    MonitorCfg: monitor,
    NotificationHistory: notification,
    EnabledTour: EnabledTour,
    Select: Select,
    ResultsTableSettings: RTS,
    Search: searchReducers
}) 

const enhancers = compose(
	batchedSubscribe(fn => fn())
) 

export const store = configureStore({
    reducer: rootReducer,
    actions: {},
    middleware: (getDefaultMiddleware)  => getDefaultMiddleware().concat(logger),
	devTools: process.env.NODE_ENV !== 'production', 
    enhancers: enhancers, 
    initialState: initialState
})

console.log("redux store", store); 