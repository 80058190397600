import React from "react";
import Add from "@mui/icons-material/AddRoad";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function steps (){return[
    {
        content: () => (
            <div>
                Welcome to Optimize.
                <br/>
                <br/>
                Here you can define and hone the parameters of your Roadway design.
            </div>
        ),
    },
    {
        selector: '.optimizeStep2',
        content: () => (
            <div>
                Let's begin with selecting the key design parameters that define the scope of your design in the Roadway Tab.
                <br/>
                <br/>
                If you would like to add multiple grids, click the <span><Add className="tour-icon"/></span> button. To Delete grids click the <span><CloseRoundedIcon className="tour-icon"/></span> button.
            </div>
        ),
    },
    {
        selector: '.optimizeStep3',
        content: () => (
            <div>
                Configuring Columns and Luminaires take place in the Row tab.
            </div>
        ),
    },
    {
        selector: '.optimizeStep4',
        content: () => (
            <div> 
                Select the Column tab and choose which parameters are active in the optimisation by the tick box
                <br/>
                <br/>
                And then the boundaries are set via the drop-down arrow.
                <br/>
                <br/>
                If you would like to add multiple rows of columns, click the <span><Add className="tour-icon"/></span> button. To delete the added row Click the <span><DeleteRoundedIcon className="tour-icon"/></span> button.
                <br/>
                <br/>
                Please note that the first row is the default row and cannot be deleted.
                <br/>
                <br/>
                Click into the luminaire tab to continue.
            </div>
        ),
    },
    {
        selector: '.optimizeStep4',
        content: ({goTo}) => (
            <div>
                Selecting the Luminaire tab presents you with your Photometry group folders.
                <br/>
                <br/>
                Click through the expanding tree to select a sub folder or even a single luminaire to optimise against for results.
                <br/>
                <br/>
                If you would like to skip to Group Optimize help. Press the button below.
                <br/>
                <br/>
                <div className='tour-to-step'>
                    <button onClick={() => goTo(10)} >Skip to "Group Optimize"</button>
                </div>
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                The results tab on the right side of the screen gives you the choice to optimise against the selected folder(s) to generate results for the active parameters. 
                <br/>
                <br/>
                If you see no results then go back and ensure you selected an optimise variable.
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                Optimize button will calculate greatest spacings and minimum the smallest spacing for compliance to standards and class for first selected luminaire.
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                Configurations Tab will enable you to name and save the current configured calculation after or before optimisation.
                <br/>
                <br/>
                You can also select to recall previously saved projects or import a LR Pro project via the LRS format.
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                Reset under the configuration tab will reset the whole configuration for a fresh design to be started.
                <br/>
                <br/>
                Below the Reset Tab is the option to Export LR Studio so a LRS format file can be saved and migrated to LR Pro if required.
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                Within the Results tab you can also select Export to save an excel Automate file which is output to the downloads folder of the browser.
                <br/>
                <br/>
                Create PDF will invoke the Template choice and once confirmed a PDF is generated and saved to the browser download folder. Initially these reports will follow the classic format of LR Pro.
            </div>
        ),
    },
    {
        content: () => (
            <div>
                The following steps will show you how to Group Optimize.
            </div>
        ),
    },
    {
        selector: '.optimizeStep4',
        content: () => (
            <div>
                To group optimize, select the luminaire tab here.
            </div>
        ),
    },
    {
        selector: '.optimizeStep4',
        content: () => (
            <div>
                Selecting the Luminaire tab presents you with your Photometry group folders.
                <br/>
                <br/>
                Click through the expanding tree to select a sub folder or even a single luminaire to optimise against for results.
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: ({goTo}) => (
            <div>
                Select the group optimize tab and then click the Optimize button to display all results line by line for each photometry file of the selected group. 
                <br/>
                <br/>
                If this is not working, please close the tour and select optimize variables.
                <br/>
                <br/> 
                Or go back to step 3 and continue from there:
                <br/>
                <br/>
                <div className='tour-to-step'>
                    <button onClick={() => goTo(3)} >To Step 4</button>
                </div>
            </div>
        ),
    },
    {
        selector: '.optimizeStep7',
        content: () => (
            <div>
                When the optimization is complete click the <span><ExpandMoreRoundedIcon className="tour-icon" /></span> icon to view results in a table.
                <br/>
                <br/>
                Or click the <span><VisibilityRoundedIcon className="tour-icon" /></span> icon to view them in the "Results" tab.
            </div>
        ),
    },
]}

export default steps;