export const API_OBJECTS = {
	getCalculationObject: function (args, key, value) {
		var calcObj = {
			"columns": args.ColumnCfg.Columns,
			"optimizeValues": args.ColumnCfg.optimizeValues,
			"centralReserve": args.ProjectConfig.RoadCfg.CentralReserve,
			"roadGrids": args.GridsCfg.RoadGrids,
			"gridIndex": args.GridsCfg.currentIndex,
			"driveOnRight": args.user.driveOnRight,
			"enableRei": args.user.enableRei,
			"reiWidth": args.user.reiWidth,
			"priorityGrid": args.GridsCfg.priorityGrid,
		};
		//"luminaireIds": args.LuminaireCfg.selectedLuminaireIds,
		if (args.LuminaireCfg.selectedLuminaireIds) {
			calcObj["luminaireIds"] = args.LuminaireCfg.selectedLuminaireIds;
		}
		if (args.LuminaireCfg.selectedConfigurationIds) {
			calcObj["configurationIds"] = args.LuminaireCfg.selectedConfigurationIds;
		}
		if (args.LuminaireCfg.selectedFolderIds) {
			calcObj["folderIds"] = args.LuminaireCfg.selectedFolderIds;
		}
		calcObj[key] = value;
		for (let index = 0; index < calcObj.columns.length; index++) {
			if (calcObj["luminaireIds"][index] == undefined) calcObj["luminaireIds"][index] = 2;
		}
		return calcObj;
	}, 
	getFullConfigurationFolderIds: function (args) {
		if (args && args.LuminaireCfg && args.LuminaireCfg.selectedFolderIds) {
			return args.LuminaireCfg.selectedFolderIds;
		} else {
			return { folderIds: [] };
		}
	},
	getFullConfigurationLuminaireIds: function (args) {
		if (args && args.LuminaireCfg && args.LuminaireCfg.selectedConfigurationIds) {
			return args.LuminaireCfg.selectedConfigurationIds;
		} else {
			return { configurationIds: [] };
		}
	}

}