
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LRTitle from '../../IMG/Logos/LR_Title_Logo.jpg';
import LRStudio from '../../IMG/Logos/LR_Studio_Colour.png'
import EdgeOrREST from '../../EdgeOrREST';
import Notification from '../shared/notification.component';
import { useDispatch } from 'react-redux';
import { EmailSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.lightingreality.com/">
                Lighting Reality
            </Link>{' '}
            {new Date().getFullYear()}

        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LRTitle: {
        marginTop: theme.spacing(5),
        width: theme.spacing(29),
        height: theme.spacing(11)
    },
    LRStudio: {
        margin: theme.spacing(3),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(30),
        height: theme.spacing(25)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export default function UpdatePassword(props) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSamePassword, setIsSamePassword] = useState(true);
    const [error, setError] = useState(false);
    const [snack, setSnack] = React.useState({
        open: false
    });
    const classes = useStyles();
    const navigate = useNavigate();

    function toLauncher(props) {
        navigate("/launcher");
    }

    const submitForm = (e, props, email, password, newPassword) => {
        if (password != newPassword) {
            e.preventDefault();
            var postObject = {
                email: email,
                password: btoa(password),
                newPassword: btoa(newPassword)
            }
            EdgeOrREST(null, 'UPDATE_PASSWORD', updatePassword, postObject);
        } else {
            setSnack({
                open: true,
                message: "New Password cannot same as current password!",
                severity: 'error'
            });
        }
    }

    const updatePassword = (response) => {
        console.log(response);
        if (response != null) {
            if (response.data) {
                setSnack({
                    open: true,
                    message: "Password updated!",
                    severity: 'success'
                });
                window.localStorage.setItem("isPasswordExpiry", false);
                setTimeout(() => {
                    toLauncher(props);
                }, 3000);
            } else {
                setSnack({
                    open: true,
                    message: "Update fail " + response.message,
                    severity: 'error'
                });
            }
        }
    }

    const handleNewPassword = (e) => {
        // regex 1 upper case 1 lower case 1 number 1 special character at least 8 characters long
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        // regex 1 lower case
        // const re = /^(?=.*[a-z]).+$/;
        if (re.test(e)) {
            setError(false);
        } else {
            setError(true);
        }
        setNewPassword(e);
    }

    const handleConfirmPassword = (e) => {
        if (e != newPassword) {
            setIsSamePassword(false);
        } else {
            setIsSamePassword(true);
        }
        setConfirmPassword(e);
    }

    function onAvatarClick(props) {
        navigate('/launcher');
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    useEffect(() => { 
        if(window.localStorage.getItem("isPasswordExpiry")){
            setSnack({
                open: true,
                message: "Password expired, please update your password!",
                severity: 'warning'
            });
        }
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <IconButton onClick={() => onAvatarClick(props)} className="customIconButton">
                    <Avatar className={classes.LRStudio} src={LRStudio} variant="square" />
                </IconButton>
                <Typography component="h1" variant="h5">
                    Update Password
                </Typography>
                <form className={classes.form} onSubmit={(e) => submitForm(e, props, email, password, newPassword)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                type="email"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Current Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                error={error}
                                name="newPassword"
                                label="New Password"
                                type="password"
                                id="newPassword"
                                autoComplete="new-password"
                                helperText="Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character."
                                value={newPassword}
                                onChange={(e) => handleNewPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                error={!isSamePassword}
                                name="confirmPassword"
                                label="Confirm New Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="confirm-password"
                                helperText="Must be same as New Password."
                                value={confirmPassword}
                                onChange={(e) => handleConfirmPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={error || !isSamePassword}
                    >
                        Update Password
                    </Button>
                </form>
                <Grid container justify="center">
                    <Grid item>
                        <Link component="button" variant="body2" onClick={() => toLauncher(props)}>
                            Go back to Home page?
                        </Link>
                    </Grid>
                </Grid>
                <IconButton onClick={() => onAvatarClick(props)} className="customIconButton">
                    <Avatar className={classes.LRTitle} src={LRTitle} variant="square" />
                </IconButton>
            </div>
            <Box mt={1}>
                <Copyright />
            </Box>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Container>
    );
}