import React from 'react';

import {
    ListItemText,
    IconButton,
    Box,
    Typography
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


function ResultsTable(props) {

    return (
        <TreeItem key={props.id + 1000} nodeId={props.id + 1000}
            label={
                <Box>
                    <ListItemText>
                        {"Valid:" + props.results.valid}
                    </ListItemText>
                    <ListItemText>
                        {"Target Class:" + props.results.targetClass}
                    </ListItemText>
                    <ListItemText>
                        {"Eav:" + props.results.outdoorResults[0].eav}
                    </ListItemText>
                    <ListItemText>
                        {"Emin:" + props.results.outdoorResults[0].emin}
                    </ListItemText>
                    <ListItemText>
                        {"Emax:" + props.results.outdoorResults[0].emax}
                    </ListItemText>
                    <ListItemText>
                        {"Dim:" + props.results.outdoorResults[0].dim * 100}
                    </ListItemText>
                </Box>
            }
        >
        </TreeItem>
    );
}


export default function GridEditor(props) {

    function getResultsTable(grid) {
        console.log("props gridEditor", props);
        if (props.gridResults && props.gridResults[grid.id] && props.gridResults[grid.id].calcResults) {
            return (<ResultsTable id={grid.id} results={props.gridResults[grid.id].calcResults} />);
        }
        return null;
    }


    return (
        <TreeView aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }} >
            {props.grids.map(
                (grid, index) => {
                    return (
                        grid.id != 0 ?
                            <TreeItem key={index} nodeId={index} style={grid.selected ? { border: "2px solid darkturquoise " } : {}}
                                label={
                                    <Box display={'flex'} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography onClick={() => props.selectGrid(index)} variant="body2">
                                            {"Grid #" + grid.id}
                                        </Typography>

                                        <IconButton style={{ zIndex: 99 }} edge="end" aria-label="delete" onClick={() => props.deleteGrid(grid.id)}>  <DeleteIcon /> </IconButton>

                                    </Box>
                                }
                            >
                                {getResultsTable(grid)}
                            </TreeItem>

                            // <ListItem key={index} onClick={() => props.selectGrid(index)} style={grid.selected ? { border: "2px solid darkturquoise " } : {}}>
                            //     <ListItemText primary={"Grid #" + grid.id} />
                            //     <ListItemButton>
                            //         {getResultsTable(grid)}
                            //     </ListItemButton>
                            //     <ListItemSecondaryAction>
                            //         <IconButton edge="end" aria-label="delete" onClick={() => props.deleteGrid(index)}>
                            //             <DeleteIcon />
                            //         </IconButton>
                            //     </ListItemSecondaryAction>
                            // </ListItem>
                            :
                            null
                    )
                }
            )}
        </TreeView>
    );

} 