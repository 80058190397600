export const initialState = {
	user: {
		personalNote: '',
		authToken: '',
		pathname: '/login',
		topRoute: '/project',
		botRoute: '/luminance',
		consoleRoute: '/history',
		Language: 'en',
		reportLanguage: 'en',
		LanguageDisplay: 'English',
		Feet: false,
		Meter: true,
		driveOnRight: false,
		LaneWidthInc: 0.25,
		autosaveTimeout: 5,
		history: [],
		filename: '',
		designerName: '',
		frontPage:
			'Design Software from Lighting Reality Ltd.\nPark Business Centre,\nWood Lane,\nErdington,\nBirmingham,\nB24 9QR,\nUnited Kingdom',
		footer:
			'Lighting Reality Ltd. Park Business Centre, Wood Lane, Erdington, Birmingham, B24 9QR UK e-mail: sales@lightingreality.com website: www.lightingreality.com',
		numOfOptimizes: 0,
		numOfCalcs: 0,
		blockNumber : 1,
		sortBy: 0
	},
	ColumnCfg: {
		currentIndex: 0,
		optimizeValues: {
			height: {
				name: "Height",
				min: 5,
				max: 10,
				step: 0.5
			},
			spacing: {
				name: "Spacing",
				min: 5,
				max: 50,
				step: 1
			},
			tilt: {
				name: "Tilt",
				min: 0,
				max: 5,
				step: 0.25
			},
			setback: {
				name: "Setback",
				min: 0,
				max: 5,
				step: 0.25
			},
			outreach: {
				name: "Outreach",
				min: 0,
				max: 5,
				step: 0.25
			},
			overhang: {
				name: "Overhang",
				min: 0,
				max: 5,
				step: 0.25
			},
			maintFactor: {
				name: "MaintFactor",
				min: 0.01,
				max: 1,
				step: 0.01
			},
			dim: {
				name: "Dim",
				min: 1,
				max: 100,
				step: 1
			},
			chosenValues: []
		},
		Columns: [
			{
				columnGrid: 0,
				columnFlux: 0,
				defaultFlux: 0,
				columnHeight: 6,
				columnTilt: 0,
				columnSpacing: 20,
				columnSetback: 0,
				columnOutreach: 0,
				columnOverhang: 0,
				columnDim: 100,
				columnMaintFactor: 1,
				columnConfiguration: 'Single Sided Left',
			}
		]
	},
	ProjectConfig: {
		RoadCfg: {
			DualCarriageway: false,
			CentralReserve: 1,
			NumOfLanes: 1,
			LaneWidth: 3,
			DefaultQ0: 0.1,
			Q0: 0.1,
			WetQ0: 0.11,
			DefaultWetQ0: 0.11,
			HardShoulderLeft: false,
			HardShoulderRight: false,
			HSWidthLeft: 0,
			HSWidthRight: 0,
			RoadSurfaceArray: [
				'R1',
				'R2',
				'R3',
				'R4',
				'C1',
				'C2',
				'N1',
				'N2',
				'N3',
				'N4',
				'ZOAB',
				'BE240',
				'RLA6A',
				'RT6A',
			],
			WetSurfaceArray: [
				'W1',
				'W2',
				'W3',
				'W4',
			],
			RoadSurface: 'R1',
			WetRoadSurface: 'W1',
			WetRoad: false
		},
		StandardsCfg: {
			Standards: [
				'EN13201:2015 Illuminance',
				'EN13201:2015 Luminance',
				'BS5489:2020 Illuminance',
				//'AS1158:2005 Cat V',
				//'AS1158:2005 Cat P',
				//'NZS1158:2005 Cat V',
				//'NZS1158:2005 Cat P',
				//'CIE140 Luminance',
				//'CIE140 Illuminance',
				'IES RP-8-18',
				//'IESNA RP-8-00',
				//'EN13201:2003 Luminance',
				//'EN13201:2003 Illuminance',
				//'BS5489:1992 Part 2',
				//'BS5489:1992 Part 3',
				//'BS5489:1992 Part 10',
			],
			EavGreaterThan: 15,
			EavLessThan: 22.5,
			EMinGreaterThan: 3,
			CalcGridClass: [
				'P1',
				'P2',
				'P3',
				'P4',
				'P5',
				'P6',
				'C0',
				'C1',
				'C2',
				'C3',
				'C4',
				'C5',
			],
			CalcGridFile: 'EN13201:2015 Illuminance',
			CurrentStandards: {
				EavGreaterThan: 15,
				EavLessThan: 22.5,
				EMinGreaterThan: 3,
			},
			DefaultStandards: {
				EavGreaterThan: 15,
				EavLessThan: 22.5,
				EMinGreaterThan: 3,
			},
			IllumTypeId: "Horizontal",
			CurrentHSStandards: null,
			CustomAddDimCalcGridOn: false,
			CustomCalcGridOn: false,
			CustomDimCalcGridOn: false,
			DefaultEMaxGreaterThan: true,
			DefaultEMinEavRatioGreaterThan: true,
			DefaultEMinEavRatioLessThan: true,
			DefaultEMinEMaxRatioGreaterThan: true,
			DefaultEMinEMaxRatioLessThan: true,
			DefaultEMinGreaterThan: true,
			DefaultEMinLessThan: true,
			DefaultEavGreaterThan: true,
			DefaultEavLessThan: true,
			DefaultEphGreaterThan: true,
			DefaultEpvGreaterThan: true,
			DefaultEsGreaterThan: true,
			DefaultLMaxLMinRatioGreaterThan: true,
			DefaultLMinGreaterThan: true,
			DefaultLavGreaterThan: true,
			DefaultLavLMinRatioGreaterThan: true,
			DefaultLvMaxLavRatioGreaterThan: true,
			DefaultReiGreaterThan: true,
			DefaultSRGreaterThan: true,
			DefaultTILessThan: true,
			DefaultU0GreaterThan: true,
			DefaultUE1LessThan: true,
			DefaultUE2LessThan: true,
			DefaultULGreaterThan: true,
			DefaultUWLRLessThan: true,
			DisableFootpath: false,
			RoadType: 'FreewayClassA',
			RoadWidthOn: true,
			HardShoulderOn: false,
			CalcGridClassOn: true,
			FootpathOnLeft: false,
			FootpathOnRight: false,
			RoadTypeOn: false,
			CIE115On: false,
			MedianBoolOn: false,
			FrenchUlMin: false,
			DutchSClass: false,
			DutchROVL: false,
			CIE115: 'CIE115_2010',
			HSWidth: 1,
			FootpathLeft: 0,
			FootpathRight: 0,
			CalcGridClassValue: 'P1',
			DimCalcGridClassValue: 'P1',
			AddDimCalcGridClassValue: 'P1',
		},
		showPoints: false,
		showGreyscale: false,
		showResults: false,
		showContours: false,
		contourValues: [1, 2, 3, 4, 5],
		autoContourValues: true,
	},
	LuminaireCfg: {
		optimizedValues: [{

			Lav: '---',
			LMin: '---',
			UOMin: '---',
			ULRatio: '---',

		}],
		checkedColouring: false,
		checkedGreyscale: false,
		checkedResults: false,
		checkedContours: false,
		contoursGridResults: [],
		groupResults: [],
		gridResults: [],
		addFileNameData: '',
		fileListData: [],
		dirData: [],
		favListData: [],
		zeroDegreeData: [],
		ninetyDegreeData: [],
		lumcatData: '',
		lampNameData: '',
		fileNameData: '',
		fluxData: '',
		inputWattageData: '',
		efficiencyData: '',
		addEfficiencyData: '',
		addInputWattageData: '',
		addFluxData: '',
		directory: './PMD',
		addMaintFactor: 1.0,
		addLLL: false,
		addDim: false,
		addDimPercent: 100,
		maintFactor: 1.0,
		LLL: false,
		Dim: false,
		dimPercent: 100,
		luminaireFilePath: '',
		selectedLuminaireIds: [],
		selectedResultLuminId: null,
		selectedFolderIds: [],
		optimizedFolder: [],
		colours: ['red', 'yellow', 'green', 'cyan', 'blue', 'magenta'],
		selectedResultName: '',
	}, 
	GridsCfg: {
		currentIndex: 0,
		priorityGrid: -1,
		RoadGrids: [
			{
				CalcGridClass: [
					'P1',
					'P2',
					'P3',
					'P4',
					'P5',
					'P6',
					'C0',
					'C1',
					'C2',
					'C3',
					'C4',
					'C5',
				],
				CurrentStandards: {
					EavGreaterThan: 15,
					EavLessThan: 22.5,
					EMinGreaterThan: 3,
				},
				DefaultStandards: {
					EavGreaterThan: 15,
					EavLessThan: 22.5,
					EMinGreaterThan: 3,
				},
				IllumTypeId: "Horizontal",
				CalcGridClassValue: 'P1',
				Standard: 'EN13201:2015 Illuminance',
				CalcGridFile: 'EN13201:2015 Illuminance',
				IllumType: "Horizontal",
				GridType: "Road",
				LaneWidth: 4,
				NumberOfLanes: 1,
				LeftOffset: 0,
				RightOffset: 0,
				Q0: 0.1,
				RoadSurfaceName: "R1",
				WetRoad: false,
				WetRoadSurface: "W1",
				WetQ0: 0.11,
				startHeight: 0,
				endHeight: 0,
				offset: 0,
				right: false
			},
		]
	},
	MonitorCfg: {
		monitorPages : 1
	},
	NotificationHistory: {
		history: [],
	},
	NotificationHistory: {
		history: [],
	},
	EnabledTour: {
		enabled: false,
		currentStep: 0,
		currentTab: "",
		helpMode: false,
		helpText: "Please select what you need help with. You are able to drag me around!",
		helpURL: "Help",
	},
	Select: {
		primary: "#3F51B5",
		tertiary: "#38479F",
		theme: false,
		logo: "",
		fileText: "",
		textColor: "white",
		base64: "",
		userRole: "",
	},
	ResultsTableSettings: {
		Lav: true,
		Lmin: true,
		Lmax: true,
		UO: true,
		UL: true,
		Rei: false,
		TI: true,
		Eav: true,
		Emin: true,
		Emax: true,
		AECI: true,
		powerDensity: true,
		WetUO: false,
		STV: false,
		UWLR: false,
		LvMax_Lav: false,
		Lmax_Lmin: false,
		Lav_Lmin: false,
		Emin_Emax: false,
		Emin_Eav: false,
		SelectAll: false,
		SelectDefault: true,
		SelectNone: false,
	},
	Search: {
		load: false,
		oneTime: false,
		deletedRows: false,
		luminaireSelection: [" "],
		manufacturers: [" "],
		rows: [],
		filesOfSelected: [" "],
	},
};