import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux';
import gridActions from '../../store/actions/gridActions';
import {
	TextField,
} from '@material-ui/core';

const WetQ0 = ({getGridResults}) => {

    const props = useSelector(state => state);
    const GridsCfg = useSelector(state => state.GridsCfg);
    const [value, setValue] = useState(GridsCfg.RoadGrids[GridsCfg.currentIndex].WetQ0);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setValue(e.target.value);
        dispatch(gridActions.UpdateCurrentGrid({WetQ0: e.target.value}));
        var postObject = props;
        GridsCfg.RoadGrids[GridsCfg.currentIndex].WetQ0 = e.target.value;
        getGridResults(props, null, null);
    }

    return (
        <TextField
            type="number"
            inputProps={{ step: 0.01 }}
            disabled={!GridsCfg.RoadGrids[GridsCfg.currentIndex].WetRoad}
            fullWidth
            label="Wet QO"
            name="qO"
            value={value}
            onChange={handleChange}
            variant="outlined"
        />
    )

    
}

export default WetQ0;