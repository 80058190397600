import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import columnActions from '../../store/actions/columnActions';
import clsx from 'clsx';
import {
	Checkbox,
	IconButton,
	Grid,
	TextField,
	makeStyles
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Expander from './Expander';
import { initialState } from '../../store/initial-state';
import tourActions from '../../store/actions/tourActions';

const mapDispatchToProps = (dispatch) => {
	const Column = bindActionCreators(columnActions, dispatch);
	return {
		UpdateColumnCfg: (data) => {
			Column.UpdateColumnCfg(data);
		},
	};
};


const useStyles = makeStyles((theme) => ({
	expand: {
		"&:focus": { outline: 'none' },
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		"&:focus": { outline: 'none' },
		transform: 'rotate(180deg)',
	},
	outlineGreen: {
		borderColor: theme.palette.secondary.main,
		borderWidth: '2px'
	},
	root: {
		marginRight: '0'
	},
	labelPlacementTop: {
		marginLeft: '0'
	},
	expandRoot: {
		padding: 3
	},
	checkboxRoot: {
		padding: 3
	}


}));

const ColumnField = ({ values, handleChange, label, name, units, UpdateColumnCfg, handleBlur }) => {

	const props = useSelector(state => state.ColumnCfg);

	var optimize = props.optimizeValues.chosenValues.includes(name.toLowerCase());

	const [state, setState] = useState({ expanded: false });
	const dispatch = useDispatch();
	const iState = useSelector(state => state);

	const handleClick = () => {
		setState({ ...state, expanded: !state.expanded });
	};

	const optimizeClick = () => {
		if (iState.EnabledTour.helpMode){
			dispatch(tourActions.UpdateHelpText({ helpText: "Selecting these checkboxes indicates what you would like to optimize with. You are able to select multiple but the one you select first will be prioritised."}))
		} else {
		if (optimize) {
			UpdateColumnCfg({
				...props, optimizeValues: {
					...props.optimizeValues, chosenValues: props.optimizeValues.chosenValues.filter(value => value != name.toLowerCase())
				}
			});
		}
		else {
			UpdateColumnCfg({
				...props, optimizeValues: {
					...props.optimizeValues, chosenValues: [...props.optimizeValues.chosenValues, name.toLowerCase()]
				}
			});
		}
	}}

	const classes = useStyles();

	let FieldClass = (optimize) ? classes.outlineGreen : null;

	let labelText = label;
	if (units) {
		labelText += ` (${units})`;
	}

	let checkLabel = name === "maintFactor" ? <></> : <Checkbox color="secondary" checked={optimize} onClick={optimizeClick} classes={{ root: classes.checkboxRoot }} />;


	return (
		<Grid container>
			<Grid item md={9}>
				<TextField
					fullWidth
					label={labelText}
					name={name}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							handleBlur(event.target.value, label);
							event.preventDefault();
							event.target.blur();
						}
					}}
					onBlur={(e) => handleBlur(e.target.value, label)}
					onChange={(e) => handleChange(e.target.value, label)}
					type="number"
					value={values}
					variant="outlined"
					InputProps={{
						classes: {
							notchedOutline: FieldClass
						},
						inputProps: {
							step: Number.parseFloat(props.optimizeValues[name].step),
							max: Number.parseFloat(props.optimizeValues[name].max),
							min: Number.parseFloat(props.optimizeValues[name].min),

						}
					}}
				/>

			</Grid>
			<Grid item md={3}>
				<IconButton
					className={clsx(classes.expand, {
						[classes.expandOpen]: state.expanded,
					})}
					onClick={handleClick}
					aria-expanded={state.expanded}
					aria-label="show more"
					classes={{ root: classes.expandRoot }}
				>
					<ExpandMore />
				</IconButton>
				{checkLabel}
			</Grid>
			<Expander
				expanded={state.expanded}
				label={label}
				name={name}
			/>
		</Grid >
	)
}

export default connect(null, mapDispatchToProps)(ColumnField);
