import { apiService } from './axios.client';

export const CalculationEndPoints = {
	getCalculationResults: function (input, callback) {
		if (input.props) {
			apiService.post('/optimize/getCalculation', input.postObject)
				.then((response) => callback(response, input.props))
				.catch(error => console.log(error));
		}
		else {
			apiService.post('/optimize/getCalculation', input)
				.then(callback)
				.catch(error => console.log(error));
		}
	},
	getOptimizedResults: function (input, callback) {
		apiService.post('/optimize/getOptimizedCalculation', input)
			.then(callback)
			.catch(error => {
				if (error.isAxiosError) callback(error.message);
				else callback(error.response.data.message);
			});
	},
	getExcelFromOptimize: function (input, callback) {
		apiService.post('/optimize/getExcelFromUpload', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	getArea: function (input, callback) {
		apiService.post('/optimize/getArea', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	}, 
	getIsolux: function (input, callback) {
		apiService.post('/optimize/getIsolux', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	getPolarPlot: function (input, callback) {
		apiService.post('/optimize/getPolarPlot', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	getLCSData: function (input, callback) {
		apiService.post('/optimize/getLCSData', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	getMinimizedResults: function (input, callback) {
		apiService.post('/optimize/getMinimizedCalculation', input)
			.then(callback)
			.catch(error => alert(error.response.data.message));
	},
	getOptimizedFolder: function (input, callback) {
		apiService.post('/optimize/getOptimizedFolder', input)
			.then(callback)
			.catch(error => {
				if (error.response.data) alert(error.response.data.message);
				else alert(error.response);
			});
	},
	getFullConfigurationResults: function (input, callback) {
		apiService.post('/fullConfiguration/roadwayConfiguration', input)
			.then(callback)
			.catch(error => alert(error.response.data.message));
	},
	uploadConfiguration: function (input, callback) {
		apiService.post('/automate/createJob', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	createPDF: function (input, callback) {
		apiService.post('/ReportChart/createPDF', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	uploadPDFTem: function (input, callback) {
		apiService.post('/ReportChart/uploadPDFTem', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	getCustTemp: function (input, callback) {
		apiService.post('/ReportChart/getCustTemp', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	downloadPDFTemp: function (input, callback) {
		apiService.post('/ReportChart/downloadPDFTemp', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	},
	deletePDFTemp: function (input, callback) {
		apiService.get('/ReportChart/deleteCustPDFTemp', input)
			.then(callback)
			.catch(error => {
				callback(error.response);
			});
	}
};