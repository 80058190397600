import React from 'react';
import {
  makeStyles,
  Button
} from '@material-ui/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from "html2canvas";
import EdgeOrREST from '../../EdgeOrREST';
import { useInterval } from '../../automate/automate-interval-runner.component';
import 'svg2pdf.js'
import { Canvg } from 'canvg';
import { CollectionsBookmarkOutlined, WatchOff } from '@mui/icons-material';
import Notification from '../../components/shared/notification.component';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
// Create Document Component
export default function PdfRenderer() {
  const props = useSelector(state => state);
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  const [isGettingData, setGettingData] = React.useState(false);
  const [hasData, setHasData] = React.useState(false);
  const [base64images, setBase64Images] = React.useState([]);
  const [data, setData] = React.useState({

    info: {
      zero: [],
      ninety: [],
      "manufacturer": "",
      "flux": 0,
      "inputWattage": 0,
      id: 0
    },
    attributes: {

    },
    luminaire: {

    }
  });    
  const [snack, setSnack] = React.useState({
    open: false
  });


  useInterval(() => {
    if (hasData) {
      console.log("Generating Report");
      generateReport(props);
      setHasData(false);
    }
  }, 1500);

  const onGetPhotometricInformation = (response) => {
    console.log("GET_PHOTOMETRIC_INFORMATION, response: ", response.status, response.data);

    if (response && response.status == '200') {
      setData(response.data);
      console.log("photometric data", data);
      setHasData(true);
    }
  };

  const onGetLuminaireImages = (response) => {
    console.log("IAMGE DATA, response: ", response.status, response.data);

    if (response && response.status == '200') {
      console.log("photo image data", response.data);

      setBase64Images(response.data);
      var mediaType = "data:image/jpeg;base64,";

      for (let i = 0; i < response.data.length; i++) {
        var a = document.createElement('img');
        a.src = mediaType + response.data[i].image;
        a.id = "base64image" + i;
        console.log("imgsrc a: ", a);
        document.body.appendChild(a);
        a.click();
      }

    }
  };

  function startReportGeneration(e, props) {
    if (props.LuminaireCfg == null) {
      setSnack({
          open: true,
          message: 'Get Luminaire failed. Please ensure correct Luminaire is selected.',
          severity: 'error'
      });
      return ;
   }
    setGettingData(true);
    EdgeOrREST(null, 'GET_PHOTOMETRIC_INFORMATION', onGetPhotometricInformation, { headers: { 'id': props.LuminaireCfg.selectedLuminaireIds[0] } });
   EdgeOrREST(null, 'GET_LUMINAIRE_IMAGES', onGetLuminaireImages, { headers: { 'id': '227' } });
  }

  function generatePDF(e, props) {
    startReportGeneration(e, props);
  }

  const handleClose = () => {
    setSnack({
        ...snack,
        open: false
    });
};

  async function generateReport(props) {
    console.log("e + props", props);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.setFontSize(12);//optional
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setLineWidth(0.4);
    pdf.rect(10, 10, 190, 275);
    pdf.setFont('Arial');
    pdf.text("Roadway Report", 10, 5);// set your margins
    pdf.text(today, 45, 5);// set your margins 
    pdf.setFontSize(20);//optional
    pdf.text("Roadway Report Summary", 60, 20);// set your margins
    pdf.setFontSize(12);//optional

    const roadMenuItem = document.getElementsByClassName("rowTabId");
    console.log("roadMenuItem", roadMenuItem);
    simulateMouseClick(roadMenuItem[0]);
 
    //layout canvas
    const layoutTab = document.getElementsByName('layoutTab');
    console.log("layoutTab", layoutTab);
    simulateMouseClick(layoutTab[0]);
    await wait(500);

    const input = document.getElementsByTagName('canvas');
    html2canvas(input[0])
      .then((canvas) => {
        console.log("rendering canvas,:", canvas);
        const imgData = canvas.toDataURL('img/png');
        var width = 70;
        var height = 95;
        var x = 30;
        var y = 35;
        pdf.addImage(imgData, 'PNG', x, y, width, height, '', 'SLOW');
        //pdf.addImage(imgData, 'JPEG', 0, 0, width, height);  
    }); 
    await wait(1000);

    pdf.text("Layout", 25, 40);// set your margins  
    // ROAD TABLE
    const roadTableHeaders = [["Road Data", ""]];
    let roadTableContent = {
      margin: { left: 120 },
      startY: 35,
      tableWidth: 65,
      head: roadTableHeaders,
      body: [
        ["Standard", props.GridsCfg.RoadGrids[0].CalcGridFile],
        ["Lane Width (m)", props.GridsCfg.RoadGrids[0].CalcGridLaneWidthdFile],
        ["No. of Lanes", props.GridsCfg.RoadGrids[0].NumberOfLanes],
        ["Q0", props.GridsCfg.RoadGrids[0].Q0],
        ["Left Offset (m)", props.GridsCfg.RoadGrids[0].LeftOffset],
        ["Right Offset (m) ", props.GridsCfg.RoadGrids[0].RightOffset],
      ]
    };
    pdf.autoTable(roadTableContent); 

    // COLUMN DATA 
    const columnTableHeaders = [["Column Data", ""]];
    let columnTableData = {
      margin: { left: 120 },
      startY: 150,
      tableWidth: 65,
      head: columnTableHeaders,
      body: [
        ["Configuration", props.ColumnCfg.Columns[0].columnConfiguration],
        ["Height", props.ColumnCfg.Columns[0].columnHeight],
        ["Dim", props.ColumnCfg.Columns[0].columnDim],
        ["Maint Factor", props.ColumnCfg.Columns[0].columnMaintFactor],
        ["Outreach", props.ColumnCfg.Columns[0].columnOutreach],
        ["Overhang", props.ColumnCfg.Columns[0].columnOverhang],
        ["Setback", props.ColumnCfg.Columns[0].columnSetback],
        ["Spacing", props.ColumnCfg.Columns[0].columnSpacing],
        ["Tilt", props.ColumnCfg.Columns[0].columnTilt],
      ]
    };
    pdf.autoTable(columnTableData);
    wait(500);

    // COLUMN DATA 
    console.log("pm data", data);
    const luminaireTableHeaders = [["Luminaire Data", ""]];
    let luminaireTableData = {
      margin: { left: 30 },
      startY: 150,
      tableWidth: 65,
      head: luminaireTableHeaders,
      body: [
        ["Manufacturer", data.luminaire.manufacturer],
        ["Lumcat", data.luminaire.lumcat],
        ["Flux", data.luminaire.flux],
        ["File Name", data.luminaire.filePath],
        ["Input Wattage", data.luminaire.inputWattage],
        ["Glare Class", "G" + data.luminaire.gClass],
      ]
    };
    pdf.autoTable(luminaireTableData);
    wait(500);

    //PAGE TWO
    pdf.addPage('p', 'mm', 'a4');
    pdf.setFontSize(12);//optional
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setFont('Arial');
    pdf.setLineWidth(0.4);
    pdf.rect(10, 10, 190, 275);
    pdf.text("Roadway Report", 10, 5);// set your margins
    pdf.text(today, 45, 5);// set your margins 
    pdf.setFontSize(20);//optional
    pdf.text("Roadway Results", 75, 20);// set your margins
    pdf.setFontSize(12);//optional
    wait(500); 

    // const resultstable = document.getElementsByName('#resultsTableName');
    autoTable(pdf, {
      margin: { left: 30 },
      startY: 35,
      tableWidth: 100,
      html: '#resultsTableName',
    });
    wait(1000); 

    const roadVisualTab = document.getElementsByName('roadVisualTab');
    console.log("roadVisualTab", roadVisualTab);
    simulateMouseClick(roadVisualTab[0]);
    // const turnResultsOn = document.getElementsByName('checkedResults');
    // console.log("turnResultsOn", turnResultsOn);
    // simulateMouseClick(turnResultsOn[0]);
    await wait(500);

    const roadCanvas = document.getElementsByTagName('canvas');
    html2canvas(roadCanvas[0])
      .then((canvas) => {
        console.log("rendering canvas,:", canvas);
        const imgData = canvas.toDataURL('img/png');
        var width = 110;
        var height = 150;
        var x = 50;
        var y = 100;
        pdf.addImage(imgData, 'PNG', x, y, width, height, '', 'MEDIUM');
        //pdf.addImage(imgData, 'JPEG', 0, 0, width, height); 

    });
    await wait(1000); 
 

    //PAGE THREE LUMINAIRE MEDIA
    pdf.addPage('p', 'mm', 'a4');
    pdf.setFontSize(12);//optional
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setFont('Arial');
    pdf.setLineWidth(0.4);
    pdf.rect(10, 10, 190, 275);
    pdf.text("Roadway Report", 10, 5);// set your margins
    pdf.text(today, 45, 5);// set your margins 
    pdf.setFontSize(20);//optional
    pdf.text("Luminaire Images", 75, 20);// set your margins
    pdf.setFontSize(12);//optional
  
    for (let i = 0; i < base64images.length; i++) { 
      const luminaireImage = document.getElementById('base64image' + i);
      console.log("luminaireImage", luminaireImage);
      html2canvas(luminaireImage)
        .then((canvas) => {
          console.log("rendering canvas,:" + i, canvas);
          const imgData = canvas.toDataURL('img/png');
          var width = 80;
          var height = 95;
          var x = 25;
          var y = 35;
          if (i == 1) {
            x = 100;
            y = 35;
          }
          if (i == 2) {
            x = 25;
            y = 145;
          }
          if (i == 3) {
            x = 100;
            y = 145;
          }
          pdf.addImage(imgData, 'PNG', x, y, width, height, '', 'MEDIUM');
          //pdf.addImage(imgData, 'JPEG', 0, 0, width, height);  
          document.body.removeChild(luminaireImage);
        });
      await wait(1000);

    }

    //PAGE FOUR PLOTS
    pdf.addPage('p', 'mm', 'a4');
    pdf.setFontSize(12);//optional
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setFont('Arial');
    pdf.setLineWidth(0.4);
    pdf.rect(10, 10, 190, 275);
    pdf.text("Roadway Report", 10, 5);// set your margins
    pdf.text(today, 45, 5);// set your margins 
    pdf.setFontSize(20);//optional
    pdf.text("Photometric Plots", 75, 20);// set your margins
    pdf.setFontSize(12);//optional 

    const luminaireMenuItem = document.getElementsByClassName("photometryTabId");
    console.log("luminaireMenuItem", luminaireMenuItem);
    simulateMouseClick(luminaireMenuItem[0]); 

    const isoluxTab = document.getElementsByName('isoluxTab');
    console.log("isoluxTab", isoluxTab);
    simulateMouseClick(isoluxTab[0]);
    await wait(1000);


    const isoluxCanvas = document.getElementsByTagName('canvas');
    html2canvas(isoluxCanvas[0])
      .then((canvas) => {
        console.log("rendering canvas,:", canvas);
        const imgData = canvas.toDataURL('img/png');
        var width = 70;
        var height = 95;
        var x = 25;
        var y = 45;
        pdf.addImage(imgData, 'PNG', x, y, width, height, '', 'SLOW');
      });
    await wait(2000);

    const polarTab = document.getElementsByName('polarTab');
    simulateMouseClick(polarTab[0]);
    await wait(1500);

    //create image from svg
    var svg = document.getElementById('polarChart').outerHTML;
    var computedWidth = 510;
    var computedHeight = 500;
    if (svg)
      svg = svg.replace(/\r?\n|\r/g, '').trim();


    let dpi = window.devicePixelRatio * 30;
    console.log("dpi" + dpi);
    console.log("computedWidth" + computedWidth);
    console.log("computedHeight" + computedHeight);

    var canvas = document.createElement('canvas');
    canvas.setAttribute('height', computedHeight * dpi);
    canvas.setAttribute('width', computedWidth * dpi);
    console.log("Computed Width: " + computedWidth * dpi + "  Height: " + computedHeight * dpi);

    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    let v = null;
    v = await Canvg.from(context, svg);
    v.start();
    var imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 100, 45, 100, 95, '', 'SLOW');
    console.log("passed image load");
    await wait(1000);

    //cartesian chart 
    const cartesianTab = document.getElementsByName('cartesianTab');
    console.log("cartesianTab", cartesianTab);
    simulateMouseClick(cartesianTab[0]);
    await wait(2000);

    //create image from svg
    var svg = document.getElementById('cartesianChart').outerHTML;
    var computedWidth = svg.width;
    var computedHeight = svg.height;
    if (svg)
      svg = svg.replace(/\r?\n|\r/g, '').trim();


    var canvas = document.createElement('canvas');
    canvas.setAttribute('height', computedHeight * dpi);
    canvas.setAttribute('width', computedWidth * dpi);

    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    let vc = null;
    vc = await Canvg.from(context, svg);
    vc.start();
    var imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 15, 175, 100, 95, '', 'SLOW');
    console.log("passed image load");
    await wait(1000);

    console.log("saving pdf");
    pdf.text("Isolux Plot", 25, 40);
    pdf.text("Polar Plot", 120, 40);
    pdf.text("Cartesian Plot", 25, 160);

    //LCS Plots //svg
    //PAGE FOUR PLOTS
    pdf.addPage('p', 'mm', 'a4');
    pdf.setFontSize(12);//optional
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setFont('Arial');
    pdf.setLineWidth(0.4);
    pdf.rect(10, 10, 190, 275);
    pdf.text("Roadway Report", 10, 5);// set your margins
    pdf.text(today, 45, 5);// set your margins 
    pdf.setFontSize(20);//optional
    pdf.text("LCS Data", 75, 20);// set your margins
    pdf.setFontSize(12);//optional  

    const lcsTab = document.getElementsByName('lcsTab');
    console.log("lcsTab", lcsTab);
    simulateMouseClick(lcsTab[0]);
    await wait(2000);

    //create image from svg
    var svg = document.getElementById('lcsChart').outerHTML;
    var computedWidth = svg.width;
    var computedHeight = svg.height;
    if (svg)
      svg = svg.replace(/\r?\n|\r/g, '').trim(); 

    var canvas = document.createElement('canvas');
    canvas.setAttribute('height', computedHeight * dpi);
    canvas.setAttribute('width', computedWidth * dpi);

    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    let vc2 = null;
    vc2 = await Canvg.from(context, svg);
    vc2.start();
    var imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 15, 45, 100, 95, '', 'SLOW');
    console.log("passed image load");
    await wait(1000);
   
    const lcsTableTab = document.getElementsByName('lcsTableTab');
    console.log("lcsTableTab", lcsTableTab);
    simulateMouseClick(lcsTableTab[0]);
    await wait(2000);
 
    autoTable(pdf, {
      margin: { left: 30 },
      startY: 160,
      tableWidth: 100,
      html: '#LCSResultsTable',
    });
 
    console.log("saving pdf");
    pdf.text("LCS Plot", 25, 40);  
    pdf.text("LCS Table", 25, 150);  
    setGettingData(false);
    pdf.save("generatedReport.pdf");

  }


  /* Same as width, sets height */
  /* (100-32*3)/2 */
  function reportSummaryPage() {
    return (
      <html>
        <body style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <div align="left" width="49%" widthstyle={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">1</font></p>
          </div>
          <div style={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">2</font></p>
          </div>
          <div align="left" width="49%" widthstyle={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">3</font></p>
          </div>
          <div style={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">4</font></p>
          </div>
          <div style={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">5</font></p>
          </div>
          <div style={{ width: '49%', paddingBotton: '32%', marginBottom: '2%', position: 'relative' }}>
            <p><font size="3" color="red">6</font></p>
          </div>
        </body>
      </html>
    );
  }

  function roadDataTable(props) {
    const headers = [["Variable", "Value"]];
    let content = {
      startY: 50,
      head: headers,
      body: [
        ["Standard", props.GridsCfg[0].CalcGridFile],
        ["Lane Width (m)", props.GridsCfg[0].CalcGriLaneWidthdFile],
        ["No. of Lanes", props.GridsCfg[0].NumberOfLanes],
        ["Q0", props.GridsCfg[0].Q0],
        ["Left Offset (m)", props.GridsCfg[0].LeftOffset],
        ["Right Offset (m) ", props.GridsCfg[0].RightOffset],

      ]
    };

  }

  async function wait(milliseconds) {
    console.log('start timer');
    await new Promise(resolve => setTimeout(resolve, milliseconds));
    console.log('after 1 second');
  }

  function simulateMouseClick(element) {
    console.log("element", element);
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
        })
      )
    );
  }

  return (
    //   <Card style={{ 'minHeight': 'inherit' }}>
    //     <Paper square>
    //       <Tabs
    //         indicatorColor="primary"
    //         textColor="primary"
    //         aria-label="disabled tabs example"
    //         value={0}
    //       >
    //         <Tab label="Pdf Viewer" value={0} />
    //       </Tabs>
    //     </Paper>
    //     <Divider />
    //     <CardContent style={{ 'height': '80vh' }}>
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        className="generatePDFButton"
        onClick={(e) => generatePDF(e, props)}
      >
        Create PDF
      </Button>
      <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
    </>



  );

}

// </CardContent> </Card>
       //  <PDFViewer style={{ 'height': '100%', 'width': '100%' }}>
        //  <PdfWriter />
      //  </PDFViewer >  */


/*
props.UpdateStandardsCfg({
...props.ProjectConfig.StandardsCfg,
CalcGridClassValue: response.CalcGridClassValue,
CurrentStandards: response.CurrentStandards,
DefaultStandards: response.DefaultStandards,
})
props.UpdateGridsCfg({ RoadGrids });
*/