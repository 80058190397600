import { createAction } from 'redux-actions';

export default {
    UpdateLav: createAction('UPDATE_LAV'),
    UpdateLmin: createAction('UPDATE_LMIN'),
    UpdateLmax: createAction('UPDATE_LMAX'),
    UpdateUO: createAction('UPDATE_UO'),
    UpdateUL: createAction('UPDATE_UL'),
    UpdateRei: createAction('UPDATE_REI'),
    UpdateTI: createAction('UPDATE_TI'),
    UpdateEav: createAction('UPDATE_EAV'),
    UpdateEmin: createAction('UPDATE_EMIN'),
    UpdateEmax: createAction('UPDATE_EMAX'),
    UpdateAECI: createAction('UPDATE_AECI'),
    UpdatePowerDensity: createAction('UPDATE_POWER_DENSITY'),
    UpdateSTV: createAction('UPDATE_STV'),
    UpdateUWLR: createAction('UPDATE_UWLR'),
    UpdateWetUO: createAction('UPDATE_WETUO'),
    UpdateLvMax_Lav: createAction('UPDATE_LVMAX_LAV'),
    UpdateLmax_Lmin: createAction('UPDATE_LMAX_LMIN'),
    UpdateLav_Lmin: createAction('UPDATE_LAV_LMIN'),
    UpdateEmin_Emax: createAction('UPDATE_EMIN_EMAX'),
    UpdateEmin_Eav: createAction('UPDATE_EMIN_EAV'),
    SelectAll: createAction('SELECT_ALL'),
    SelectDefault: createAction('SELECT_DEFAULT'),
    SelectNone: createAction('SELECT_NONE'),    
};
