import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LRTitle from '../../IMG/Logos/LR_Title_Logo.jpg';
import LRStudio from '../../IMG/Logos/LR_Studio_Colour.png'
import Notification from '../shared/notification.component';
import EdgeOrREST from '../../EdgeOrREST';
import * as qs from 'query-string';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LRTitle: {
        marginTop: theme.spacing(5),
        width: theme.spacing(29),
        height: theme.spacing(11)
    },
    LRStudio: {
        margin: theme.spacing(3),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(30),
        height: theme.spacing(25)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

export default function NewPassword(props) {
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [snack, setSnack] = useState({
        open: false
    });
    const classes = useStyles();
    const navigate = useNavigate();

    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https:/lightingreality.com/">
                    Lighting Reality
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
        );
    }

    function toLogin(props) {
        navigate('/login');
    }

    useEffect(() => {
        setToken(window.location.search.replace("?token=", ""));
        EdgeOrREST(null, "GET_USER_LOGIN", getUserLogin, { headers: { token: window.location.search.replace("?token=", "") } });
    }, []);

    const getUserLogin = (response) => {
        if (response != null) {
            setDisabled(false);
        } else {
            setDisabled(true);
            setSnack({
                open: true,
                message: 'Incorrect password reset URL. Redirecting to forgot password.',
                severity: 'error'
            });
            //wait 5 seconds then navigate to forgot
            // setTimeout(() => {
            //     navigate('/forgot');
            //     console.log("navigate to forgot");
            // }, 5000);
        }
    }

    function handleNewPassword(e) {
        EdgeOrREST(null, "RESET_PASSWORD", resetPassword, { newPassword: btoa(password), resetPasswordToken: token });
    }

    const resetPassword = (response) => {
        if (response != null) {
            if (response.status == "200") {
                setSnack({
                    open: true,
                    message: 'Password updated!',
                    severity: 'success'
                });
                setTimeout(() => {
                    toLogin(props);
                }, 3000);
            } else {
                setSnack({
                    open: true,
                    message: response.message,
                    severity: 'error'
                });
            }
        }
    }

    const handlePassword = (e) => {
        // regex 1 upper case 1 lower case 1 number 1 special character at least 8 characters long
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        // regex 1 lower case
        // const re = /^(?=.*[a-z]).+$/;
        if (re.test(e)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setPassword(e);
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <IconButton onClick={() => toLogin(props)} className="customIconButton">
                    <Avatar className={classes.LRStudio} src={LRStudio} variant="square" />
                </IconButton>
                <Typography component="h1" variant="h5">
                    New Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="password"
                            variant="outlined"
                            margin="normal"
                            error={disabled}
                            required
                            fullWidth
                            id="password"
                            label="New Password"
                            name="password"
                            autoComplete="password"
                            helperText="Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character."
                            autoFocus
                            value={password}
                            onChange={(e) => handlePassword(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => handleNewPassword(e, props, password)}
                    disable={disabled}
                >
                    New Password
                </Button>
                <Grid container justify="center">
                    <Grid item>
                        <Link component="button" variant="body2" onClick={() => toLogin(props)}>
                            Go Back to Sign in?
                        </Link>
                    </Grid>
                </Grid>
                <IconButton onClick={() => toLogin(props)} className="customIconButton">
                    <Avatar className={classes.LRTitle} src={LRTitle} variant="square" />
                </IconButton>
                <Box mt={1}>
                    <Copyright />
                </Box>
            </div>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Container >
    );

}