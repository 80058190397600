import { handleActions } from 'redux-actions';
import actions from '../actions/luminaireActions';
import { initialState } from '../initial-state';

export default handleActions(
	{
		[actions.UpdateLuminCfg]: (state, action) => {
			return { ...state, ...action.payload };
		},
		[actions.UpdateGridResults]: (state, action) => {
			const index = action.payload.index;
			const gridResults = [...state.gridResults];
			const contoursGridResults = [...state.contoursGridResults];
			const optimizedValues = [state.optimizedValues];
			if (gridResults[index]) {
				gridResults[index] = action.payload.gridResults;
			}
			else {
				gridResults.splice(index, 0, action.payload.gridResults);
			}
			if (contoursGridResults[index]) {
				contoursGridResults[index] = action.payload.contoursGridResults;
			}
			else {
				contoursGridResults.splice(index, 0, action.payload.contoursGridResults);
			}
			if (optimizedValues[index]) {
				optimizedValues[index] = action.payload.optimizedValues;
			}
			else {
				optimizedValues.splice(index, 0, action.payload.optimizedValues);
			}
			return { ...state, gridResults, optimizedValues, contoursGridResults };
		},
		[actions.UpdateAllResults]: (state, action) => {
			const allResults = action.payload.gridResults;
			let gridResults = [];
			let contoursGridResults = [];
			let optimizedValues = [];
			if(allResults != undefined && allResults != null && allResults.length > 0) {
				allResults.forEach(element => {
					gridResults.push(element.gridArray);
					contoursGridResults.push(element.superGrid);
					optimizedValues.push(element.calcValues);
				});
			}
			return { ...state, gridResults, contoursGridResults, optimizedValues };
		},
		[actions.UpdateFolderResultsToCanvas]: (state, action) => {
			const allResults = action.payload.gridResults;
			let gridResults = [];
			let contoursGridResults = [];
			let optimizedValues = [];
			gridResults.push(allResults.gridArray);
			contoursGridResults.push(allResults.contourArray);
			optimizedValues.push(allResults);
			
			return { ...state, gridResults, contoursGridResults, optimizedValues };
		},
		[actions.UpdateGroupResults]: (state, action) => {
			let groupResults = state.groupResults;
			action.payload.data.optValues.forEach((element, index) => {
				groupResults.push({luminaire: action.payload.data.photometries[index].name, max: element.Item1, min: element.Item2, allGrids: element.Item3});
			});
			return { ...state, groupResults: [...groupResults] };
		},
		[actions.ClearGroupResults]: (state, action) => {
			return { ...state, groupResults: [] };
		},
		[actions.UpdateLuminaireList]: (state, action) => {
			return { ...state, ...action.payload };
		},
		[actions.UpdateSelectedLuminaires]: (state, action) => {

			var id = action.payload.id;
			var luminaireType = action.payload.type;
			if (action && action.payload && action.payload.id && action.payload.type) {
				var luminairesOrFolders = null;
				if (luminaireType == 'configuration') {
					luminairesOrFolders = state.selectedConfigurationIds;
				} else if (luminaireType == 'folder') {
					luminairesOrFolders = state.selectedFolderIds;
				} else {
					luminairesOrFolders = state.selectedLuminaireIds;
				}

				if (luminairesOrFolders && luminairesOrFolders.length > 0) {
					var found = false;
					var i;
					for (i = 0; i < luminairesOrFolders.length; i++) {
						if (luminairesOrFolders[i].id == id) {
							found = true;
							luminairesOrFolders.splice(i, 1);
							break;
						}
					}

					if (!found) {
						luminairesOrFolders.push(action.payload)
					}
				} else {
					luminairesOrFolders = []
					luminairesOrFolders.push(action.payload);
				}



				if (luminaireType == 'configuration') {
					return { ...state, selectedConfigurationIds: luminairesOrFolders };
				} else if (luminaireType == 'folder') {
					return { ...state, selectedFolderIds: luminairesOrFolders };
				} else {
					return { ...state, selectedLuminaireIds: luminairesOrFolders };
				}
			}

		}
	},
	initialState.LuminaireCfg 
	
);
