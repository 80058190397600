import React, { useEffect } from 'react';
import EdgeOrREST from '../EdgeOrREST';
import Page from '../components/Page';
import { Outlet } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    Tabs,
    Typography,
    Tab,
    makeStyles
} from '@material-ui/core';
import AutomateJobs from './automate-jobs.component';
import PhotometricGroupsViewer from '../photometricManager/photometric-groups-viewer.component';
import { useDispatch } from 'react-redux';
import user from '../store/actions/user';
import AutomateUpload from './automate-upload.component';
import AutomateReports from './automate-reports.component';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '85vh',
        padding: theme.spacing(3)
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AutomateDashboard(props) {
    const dispatch = useDispatch();
    const [groups, setGroups] = React.useState([]);
    const onGetGroups = (response) => {
        if (response && response.data) {
            setGroups(response.data);
            console.log("group props updated");
            dispatch(user.UpdateUserCfg({ groups: response.data }));
        }
    };
    const [leftTabIndex, setLeftTabs] = React.useState(0);

    const handleClick = e => {

    };

    const handleLeftTabs = (event, value) => {
        setLeftTabs(value);
    };

    useEffect(() => {
        let isMounted = true;
        EdgeOrREST(null, 'GET_USERS_GROUPS_AND_LUMINAIRES', onGetGroups, props);
		dispatch(user.UpdateUserCfg({getJobFlag: false}));
        return () => { isMounted = false };
    }, []);

    const classes = useStyles();

    return (
        <Page className={classes.root} title="Automate" style={{ 'minHeight': '85vh' }}>
            <Grid container spacing={2} style={{ 'minHeight': 'inherit' }}>
                {/* First row */}
                <Grid className='automateStep4' item lg={3} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs
                                value={leftTabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleLeftTabs}
                            >
                                <Tab index={0} label="Automate Upload" {...a11yProps(0)} value={0} />
                            </Tabs>
                        </Paper>

                        <Divider />
                        <TabPanel value={leftTabIndex} index={0}>
                            <CardContent>
                                <AutomateUpload />
                            </CardContent>
                        </TabPanel>
                    </Card>
                </Grid>
                {/* Second row */}
                <Grid item lg={5} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <AutomateJobs></AutomateJobs>
                </Grid>
                {/* Third row */}
                <Grid item lg={4} md={6} sm={12} xs={12} style={{ 'minHeight': 'inherit' }}>
                    <Card style={{ 'minHeight': 'inherit' }}>
                        <Paper square>
                            <Tabs indicatorColor="primary" textColor="primary" aria-label="disabled tabs example" 
                                variant="fullWidth" value={0}>
                                <Tab label="Photometric Groups" value={0} />
                            </Tabs>
                        </Paper>
                        <Divider />
                        <CardContent style={{ maxHeight: '75vh', height: '75vh', overflowY: 'auto' }}>
                            <PhotometricGroupsViewer handleClick={handleClick} groups={groups} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </Page >
    );




} 