import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableHolder from './Comp_ResultsTableHolder';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@material-ui/core';

const mapStateToProps = (state) => {
	return state;
};

class ResultsTable extends Component {

	render() {
		var StandardsCfg = this.props.ProjectConfig.StandardsCfg;
		var RoadGrid = this.props.GridsCfg.RoadGrids[this.props.tableIndex];
		var optValues = this.props.LuminaireCfg.optimizedValues[this.props.tableIndex];
		if (optValues === undefined) optValues = {
			Height: "---",
			Spacing: "---",
			Tilt: "---",
			Setback: "---",
			Outreach: "---",
			Overhang: "---",
			Lav: "---",
			LMin: "---",
			UOMin: "---",
			ULRatio: "---",
			Eav: "---",
			EMin: "---",
			EMinEavRatio: "---",
			TI: "---",
			Rei: "---",
			STV: "---",
			LvMaxLavRatio: "---",
			LMaxLMinRatio: "---",
			LavLMinRatio: "---",	
		}
		const RP8Horizontal = StandardsCfg.CalcGridFile === 'IES RP-8-18' && StandardsCfg.IllumTypeId === 'Illuminance';
		const state = this.props.ResultsTableSettings;
		var TISymbol = StandardsCfg.DefaultStandards['TILessThan'] !== undefined ? "≤" : "";
		var test = RP8Horizontal || StandardsCfg.CurrentStandards.LavGreaterThan;
		return (
			<TableContainer component={Paper}>
				<Table id="resultsTableName" name="resultsTableName">
					<TableHead>
						<TableRow>
							<TableCell id="Name">
								Variable
							</TableCell>
							<TableCell id="Standard">
								Standard
							</TableCell>
							<TableCell id="Results">
								Results
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.LavGreaterThan}
							greater={true}
							result={
								optValues.Lav || '---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['LavGreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultLavGreaterThan}
							hide={
								!state.Lav ? true : RP8Horizontal ? true : (RoadGrid.CurrentStandards.LavGreaterThan || true)
							}>
							Lav
						</TableHolder>
						<TableHolder
							result={
								optValues.LMin ||
								'---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['LMinGreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultLMinGreaterThan}
							hide={
								!state.Lmin ? true : RP8Horizontal ? true : (RoadGrid.CurrentStandards.LavGreaterThan || true)
							}>
							LMin
						</TableHolder>
						<TableHolder
							result={
								optValues.LMax ||
								'---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['LMinGreaterThan'] ||
								'N/A'
							}
							defaultBool={true}
							hide={
								!state.Lmax ? true : RP8Horizontal ? true : (RoadGrid.CurrentStandards.LavGreaterThan || true)
							}>
							LMax
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.U0GreaterThan}
							greater={true}
							result={optValues.UOMin}
							defaultValue={
								RoadGrid.DefaultStandards['U0GreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultU0GreaterThan}
							hide={
								!state.UO ? true : RoadGrid.CurrentStandards.U0GreaterThan || true
							}>
							UO
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.WetU0GreaterThan}
							greater={true}
							result={optValues.WetUOMin}
							defaultValue={
								RoadGrid.DefaultStandards['WetU0GreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultWetU0GreaterThan}
							hide={
								(state.WetUO ? false :  true)//RoadGrid.CurrentStandards.WetU0GreaterThan !== undefined) ? RoadGrid.CurrentStandards.WetU0GreaterThan : true
							}>
							Wet UO
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.ULGreaterThan}
							greater={true}
							result={optValues.ULRatio}
							defaultValue={
								RoadGrid.DefaultStandards['ULGreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultULGreaterThan}
							hide={
								!state.UL ? true : RoadGrid.CurrentStandards.ULGreaterThan || true
							}>
							UL
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.ReiGreaterThan}
							greater={true}
							result={optValues.Rei}
							defaultValue={
								RoadGrid.DefaultStandards['ReiGreaterThan'] ||
								true
							}
							defaultBool={StandardsCfg.DefaultReiGreaterThan}
							hide={
								state.Rei ? false : true
							}>
							Rei
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.EavGreaterThan}
							greater={true}
							result={
								optValues.Eav || '---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['EavGreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultEavGreaterThan}
							hide={
								!state.Eav ? true : RP8Horizontal ? false : (RoadGrid.CurrentStandards.EavGreaterThan || true)
							}>
							Eav
						</TableHolder>
						<TableHolder
							symbol="≤"
							standard={StandardsCfg.EavLessThan}
							result={
								optValues.Eav || '---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['EavLessThan'] || 'N/A'
							}
							defaultBool={StandardsCfg.DefaultEavLessThan}
							hide={
								!state.Eav ? true : RP8Horizontal ? true : (RoadGrid.CurrentStandards.EavLessThan || true)
							}>
							Eav
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.EMinGreaterThan}
							greater={true}
							result={
								optValues.EMin ||
								'---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['EMinGreaterThan'] ||
								'N/A'
							}
							defaultBool={StandardsCfg.DefaultEMinGreaterThan}
							hide={
								!state.Emin ? true : RP8Horizontal ? false : (RoadGrid.CurrentStandards.EavGreaterThan ||
								true)
							}>
							Emin
						</TableHolder>
						<TableHolder
							symbol=""
							standard={StandardsCfg.EMinGreaterThan}
							greater={false}
							result={
								optValues.EMax ||
								'---'
							}
							defaultValue={
								RoadGrid.DefaultStandards['EMaxGreaterThan'] ||
								'N/A'
							}
							defaultBool={true}
							hide={
								!state.Emax ? true : RP8Horizontal ? false : (RoadGrid.CurrentStandards.EavGreaterThan ||
								true)
							}>
							Emax
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.EMinEavRatioGreaterThan}
							greater={true}
							result={
								optValues.EMinEavRatio || '---'
							}
							defaultValue={
								RoadGrid.DefaultStandards[
								'EMinEavRatioGreaterThan'
								] || true
							}
							defaultBool={
								StandardsCfg.DefaultEMinEavRatioGreaterThan
							}
							hide={
								state.Emin_Eav ? false : RP8Horizontal ? true : (RoadGrid.CurrentStandards
									.EMinEavRatioGreaterThan || true)
							}>
							Emin/Eav
						</TableHolder>
						<TableHolder
							symbol={TISymbol}
							standard={StandardsCfg.TILessThan}
							result={optValues.TI || '---'}
							defaultValue={
								RoadGrid.DefaultStandards['TILessThan'] || 'N/A'
							}
							defaultBool={StandardsCfg.DefaultTILessThan}
							hide={state.TI ? false : true}>
							TI
						</TableHolder>
						<TableHolder
							symbol="≥"
							standard={StandardsCfg.EMinEMaxRatioGreaterThan}
							greater={true}
							result="---"
							defaultValue={
								RoadGrid.DefaultStandards[
								'EMinEMaxRatioGreaterThan'
								] || true
							}
							defaultBool={
								StandardsCfg.DefaultEMinEMaxRatioGreaterThan
							}
							hide={
								state.Emin_Emax ? false : RP8Horizontal ? true : (RoadGrid.CurrentStandards
									.EMinEMaxRatioGreaterThan || true)
							}>
							Emin/Emax
						</TableHolder>
						<TableHolder
							symbol="≤"
							standard={StandardsCfg.LavLMinRatioLessThan}
							greater={false}
							result={optValues.LavLMinRatio || '---'}
							defaultValue={
								RoadGrid.DefaultStandards[
								'LavLMinRatioLessThan'
								] || true
							}
							defaultBool={
								StandardsCfg.DefaultLavLMinRatioLessThan
							}
							hide={
								state.Lav_Lmin ? false : true //RP8Horizontal ? true : (RoadGrid.CurrentStandards.LavLMinRatioLessThan || true)
							}>
							Lav/Lmin
						</TableHolder>
						<TableHolder
							symbol="≤"
							standard={StandardsCfg.LMaxLMinRatioLessThan}
							greater={false}
							result={optValues.LMaxLMinRatio || '---'}
							defaultValue={
								RoadGrid.DefaultStandards[
								'LMaxLMinRatioLessThan'
								] || true
							}
							defaultBool={
								StandardsCfg.DefaultLMaxLMinRatioLessThan
							}
							hide={
								state.Lmax_Lmin ? false : true //RP8Horizontal ? true : (RoadGrid.CurrentStandards.LMaxLMinRatioLessThan || true)
							}>
							Lmax/Lmin
						</TableHolder>
						<TableHolder
							symbol="≤"
							standard={StandardsCfg.LvMaxLavRatioLessThan}
							greater={false}
							result={optValues.LvMaxLavRatio || '---'}
							defaultValue={
								RoadGrid.DefaultStandards[
								'LvMaxLavRatioLessThan'
								] || true
							}
							defaultBool={
								StandardsCfg.DefaultLvMaxLavRatioLessThan
							}
							hide={
								state.LvMax_Lav ? false : true //RP8Horizontal ? true : (RoadGrid.CurrentStandards.LvMaxLavRatioLessThan || true)
							}>
							LvMax/Lav
						</TableHolder>
						<TableHolder
							symbol=""
							standard="N/A"
							greater={true}
							result={optValues.STV || '---'}
							defaultValue={
								RoadGrid.DefaultStandards['STVGreaterThan'] ||
								"N/A"
							}
							defaultBool={StandardsCfg.DefaultSTVGreaterThan}
							hide={state.STV ? false : optValues.STV || true}
							>
							
							STV
						</TableHolder>
						<TableHolder
							symbol="≤"
							standard={StandardsCfg.UWLRLessThan}
							result="---"
							defaultValue={
								RoadGrid.DefaultStandards['UWLRLessThan'] ||
								true
							}
							defaultBool={StandardsCfg.DefaultUWLRLessThan}
							hide={
								state.UWLR ? false : RoadGrid.CurrentStandards.UWLRLessThan || true
							}>
							UWLR
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={state.AECI ? false : true}
							result={optValues.AECI || '---'}
							symbol={' kWh/m²'}
						>
							AECI
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={state.powerDensity ? false : true}
							result={optValues.PowerDensity || '---'}
							symbol={' mW/lx/m²'}
						>
							Power Density
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('height')}
							result={optValues.Height || '---'}
							symbol={'m'}
						>
							Optimized Height
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('spacing')}
							result={optValues.Spacing || '---'}
							symbol={'m'}
						>
							Optimized Spacing
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('tilt')}
							result={optValues.Tilt || '---'}
							symbol={'°'}
						>
							Optimized Tilt
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('setback')}
							result={optValues.Setback || '---'}
							symbol={'m'}
						>
							Optimized Setback
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('outreach')}
							result={optValues.Outreach || '---'}
							symbol={'m'}
						>
							Optimized Outreach
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={!this.props.ColumnCfg.optimizeValues.chosenValues.includes('overhang')}
							result={optValues.Overhang || '---'}
							symbol={'m'}
						>
							Optimized Overhang
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={this.props.ColumnCfg.optimizeValues.chosenValues.length == 0}
							result={this.props.user.optimizeTime || '---'}
							symbol={'s'}
						>
							Time to Optimize
						</TableHolder>
						<TableHolder
							columnProp={true}
							hide={this.props.ColumnCfg.optimizeValues.chosenValues.length == 0}
							result={this.props.user.numOfCalcs || '---'}
						>
							Calcs Performed
						</TableHolder>
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}

export default connect(mapStateToProps)(ResultsTable);
