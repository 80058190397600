import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LRTitle from '../../IMG/Logos/LR_Title_Logo.jpg';
import LRStudio from '../../IMG/Logos/LR_Studio_Colour.png'
import EdgeOrREST from '../../EdgeOrREST';
import { useDispatch } from 'react-redux';
import user from '../../store/actions/user';
import Notification from '../shared/notification.component';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth-provider.component';
import { setAxiosHeaders } from '../../API/services/axios.client';
import selectActions from '../../store/actions/selectActions';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LRTitle: {
        marginTop: theme.spacing(1),
        width: theme.spacing(29),
        height: theme.spacing(11)
    },
    LRStudio: {
        margin: theme.spacing(1),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(25),
        height: theme.spacing(20)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

export default function Login(props) {
    const dispatch = useDispatch();
    const { login } = useContext(AuthContext);

    useEffect(() => onMount(props), [props]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snack, setSnack] = useState({
        open: false
    });
    const classes = useStyles();
    const navigate = useNavigate();

    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://lightingreality.com/">
                    Lighting Reality
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
        );
    }

    function toForgot(props) {
        dispatch(user.UpdateUserCfg({ pathname: '/forgot' }));
        navigate('/forgot');
    }

    function submitForm(e, props, username, password, rememberMe) {
        setLoading(true);
        e.preventDefault();
        var basicAuth = 'Basic ' + btoa(username + ':' + password);
        console.log("logging in");
        EdgeOrREST(null, 'LOG_IN', onLoginAttempt, { auth: basicAuth, rememberMe, props: props });
    }

    function onLoginAttempt(response) {
        if (response.status == "200") {
            console.log("remember", remember);
            if (response.data.token && response.data.id) {
                setAxiosHeaders(response.data.token);
                console.log("username", username);
                if (remember) {
                    window.localStorage.setItem("remember", true);
                    window.localStorage.setItem("username", username);
                }
                window.localStorage.setItem("token", response.data.token);

                if (response.data.isPasswordExpiry) {
                    // write to redirect to updatePassword page
                    dispatch(user.UpdateUserCfg({ pathname: '/updatePassword' }));
                    navigate('/updatePassword');
                } else {
                    console.log("response login", response.data.companyId);
                    console.log("response login", response.data.id);
                    login(response.data.token, response.data.id, response.data.companyId, response.data.isPasswordExpiry);

                    EdgeOrREST(null, 'GET_CFG', getThemeCfg, { 'companyId' : response.data.companyId });    //Remember to change this component inside the app.js component also
                    EdgeOrREST(null, 'GET_ROLE_ID', getRoleId, { headers: {'userId' : response.data.id }});

                    dispatch(user.UpdateUserCfg({ pathname: '/launcher' }));
                    navigate('/launcher');
                }
            }
        }else{
            setSnack({
                open: true,
                message: "Your login attempt has failed. Please verify your credentials and try again later.",
                severity: 'error'
            });
        }
        setLoading(false);
    }

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };
        

    const getThemeCfg = (response) => {
        if (response.data != null){
            let string = response.data
            let colour = ("#"+string.substring(1,7));
            let logo = ("data:image/png;base64,"+string.substring(7));
            const t = newShade(colour,-30);
            dispatch(selectActions.UpdateLogo({logo:logo}))
            dispatch(selectActions.UpdatePrimary({primary:colour}))
            dispatch(selectActions.UpdateTertiary({tertiary:t}))
            dispatch(selectActions.UpdateText({fileText:"Change Logo"}))
            setTimeout(() => dispatch(selectActions.UpdateTheme({theme:true})),300)
        }
    }

    const getRoleId = (response) => {
        if (response.data != 0){
            let roleNum = response.data
            if (roleNum == 1){
                dispatch(selectActions.UpdateUserRole({userRole:"admin"}))
            }
        }
        else {
            dispatch(selectActions.UpdateUserRole({userRole:"not admin"}))
            console.log("Not a Select Admin")
        }
    }

    const handleClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    function onMount(props) {
        if (window.localStorage.getItem('remember')) {
            EdgeOrREST(null, 'CHECK_TOKEN', onCheckToken, props);
        }
    }
    
    function onCheckToken(response, props) {
        if (response.data && response.data.id) {

        }
    }

    useEffect(() => {
        if(window.localStorage.getItem("username")!=null){
            setUsername(window.localStorage.getItem("username"));
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <IconButton className="customIconButton" href="https://lightingreality.com/">
                    <Avatar className={classes.LRStudio} src={LRStudio} variant="square" />
                </IconButton>
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
                <form className={classes.form} onSubmit={(e) => submitForm(e, props, username, password, remember)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                type="email"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                defaultValue={window.localStorage.getItem("username")!=null?window.localStorage.getItem("username"):""}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value={remember} color="primary" onChange={(e) => setRemember(!remember)}/>}
                                label="Remember me"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="loginButton"
                        disabled={loading}
                    >
                        {loading?"Loading...":"Log in"}
                    </Button >
                </form>
                <Grid container justify="center">
                    <Grid item>
                        <Link component="button" variant="body2" onClick={() => toForgot(props)}>
                            Forgotten password?
                        </Link>
                    </Grid>
                </Grid>
                <IconButton className="customIconButton" href="https://lightingreality.com/">
                    <Avatar className={classes.LRTitle} src={LRTitle} variant="rounded" />
                </IconButton>
                <Box mt={1}>
                    <Copyright />
                </Box>
            </div>
            <Notification onClose={handleClose} open={snack.open} severity={snack.severity} message={snack.message}></Notification>
        </Container >
    );

}


