import { createStyles, makeStyles } from '@material-ui/core';
import { useInterval } from '../automate/automate-interval-runner.component';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import user from '../store/actions/user';

const useStyles = makeStyles((scaling) => (
  {
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%'
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      p: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt !important'
      },
      span: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      },
      input: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      },
      th: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt !important'
      },
      td: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      },
      div: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      },
      legend: {
        fontSize: (10 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      },
      svg: {
        fontSize: (20 * (window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1)) + 'pt'
      }
    }
  })
);


const GlobalStyles = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1); // decimal state
  const state = useSelector(state => state);

  useStyles(value);


  useInterval(() => {
    if (state.user.updateGlobalStyles) {
      console.log("updated global styles", value);
      dispatch(user.UpdateUserCfg({ updateGlobalStyles: false })); 
      setValue(window.localStorage.getItem("fontScaling") ? window.localStorage.getItem("fontScaling") : 1);
      window.location.reload();
    }
  }, 2500);

  return null;
};

export default GlobalStyles;
