import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	TextField,
	Slider,
	Tooltip,
	Grid
} from '@material-ui/core';
import roadActions from '../../store/actions/roadActions';
import gridActions from '../../store/actions/gridActions';
import luminaireActions from '../../store/actions/luminaireActions';
import EdgeOrREST from '../../EdgeOrREST'
import { API_OBJECTS } from '../../API/model/calculation.model'

const LaneWidth = ({ index }) => {
	const props = useSelector(state => state);
	const dispatch = useDispatch();
	const [laneWidth, setLaneWidth] = React.useState(props.GridsCfg.RoadGrids[index].LaneWidth);

	const handleChange = (event) => {
		var LaneWidth = event.target.value || 0;
		EdgeOrREST(LaneWidth, 'SET_LANE_WIDTH');
		if (LaneWidth === 0) LaneWidth = '';
		LaneWidth = Number.parseFloat(LaneWidth);
		dispatch(roadActions.UpdateRoadCfg({
			...props.ProjectConfig.RoadCfg,
			LaneWidth
		}));
		const RoadGrids = [...props.GridsCfg.RoadGrids];
		if (RoadGrids[index]) RoadGrids[index] = {
			...RoadGrids[index],
			LaneWidth
		}

		else RoadGrids.splice(index, 0, {
			LaneWidth
		});
		dispatch(gridActions.UpdateGridsCfg({ RoadGrids }));



		var gridResults = EdgeOrREST(props.LuminaireCfg.luminaireFilePath, 'CALC_GRID', onCalculateGridResults, API_OBJECTS.getCalculationObject(props, "roadGrids", RoadGrids));
		populateGridResults(gridResults);
	};

	const onCalculateGridResults = (response) => {
		populateGridResults(response.data);
	}

	const populateGridResults = (gridResults) => {
		if (gridResults) {
			dispatch(luminaireActions.UpdateAllResults({
				index: index,
				gridResults
			}));
		}
	}


	return (
		<TextField
			fullWidth
			id="laneWidth"
			label="Lane Width (m)"
			name="laneWidth"
			value={laneWidth}
			min="0.00"
			type="number"
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					handleChange(event);
					event.preventDefault();
					event.target.blur();
				}
			}}
			onBlur={(event) => handleChange(event)}
			onChange={(event) => setLaneWidth(event.target.value)}
			variant="outlined"
			placeholder="0.00"
		/>
	);
}

export default LaneWidth;