import React, { createContext, useEffect, useState } from "react";
import EdgeOrREST from "./EdgeOrREST";

const AuthContext = createContext();

function AuthProvider(props) {
    const [authed, setAuthed] = useState(false);
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [is2FAAuthenticated, setIs2FAAuthenticated] = useState(false);

    const login = async (token, userId, companyId, isPasswordExpired) => {
        setAuthed(true);
        setToken(token);
        setUserId(userId);
        setCompanyId(companyId);
        setIsPasswordExpired(isPasswordExpired);

        const data = await EdgeOrREST(null, "VALIDATE_BYPASS_TOKEN", null, null);
        if (data.valid) {
            setIs2FAEnabled(true);
            if(data.bypassExpired) {
                setIs2FAAuthenticated(false);
            } else {
                setIs2FAAuthenticated(true);
            }
                
        }
    };

    const logout = () => {
        setAuthed(false);
        setToken(null);
        setUserId(null);
        setCompanyId(null);
        setIsPasswordExpired(false);
        setIs2FAEnabled(false);
        setIs2FAAuthenticated(false);
    };

    const complete2FASetup = () => {
        setIs2FAEnabled(true);
        setIs2FAAuthenticated(true);
    };

    const value = {
        authed,
        token,
        userId,
        companyId,
        isPasswordExpired,
        is2FAEnabled,
        is2FAAuthenticated,
        login,
        logout,
        complete2FASetup,
    };

    return <AuthContext.Provider value={value} {...props} />;
}

export { AuthContext, AuthProvider };
